// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ReactCropper from '../../../components/ReactCropper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import axios from 'axios';
import { updateObject, checkValidity } from '../../../common/utility';
import { urls } from '../../../common/urls';

const styles = theme =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    modalPaper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`
    },
    content: {
      padding: theme.spacing(2)
    },
    TextField: {
      width: '100%'
    },
    bigAvatar: {
      margin: '10px auto',
      width: 120,
      height: 120,
      fontSize: 60
    },
    button: {},
    loader: {
      minHeight: 400
    },
    verificationIcon: {
      marginRight: theme.spacing(1),
      color: '#606060',
      fontSize: 20
    },
    formControl: {
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  });

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      cropResult: null,
      user: {},
      roles: [],
      uploading: false,
      submitted: false,
      controls: {
        role: {
          value: '',
          validation: {
            required: true
          },
          valid: false,
          error: '',
          touched: false
        },
        name: {
          value: '',
          validation: {
            required: true,
            minLength: 2
          },
          valid: true,
          error: '',
          touched: false
        },
        email: {
          value: '',
          validation: {
            required: true,
            isEmail: true
          },
          valid: true,
          error: '',
          touched: false
        },
        phone: {
          value: '',
          validation: {
            required: true,
            minLength: 10
          },
          valid: true,
          error: '',
          touched: false
        },
        password: {
          value: '',
          validation: {
            required: true,
            minLength: 6
          },
          valid: true,
          error: '',
          touched: false,
          showPassword: false
        }
      }
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
  }
  componentDidMount() {
    this.props.onGetRoles();
  }

  onImageCrop(resultImg, name, type) {
    this.setState({ cropResult: resultImg });
  }

  handleInputChange(event, controlName) {
    let { isValid, errorText } = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation,
      controlName
    );
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        valid: isValid,
        error: errorText,
        touched: true
      })
    });
    this.setState({ controls: updatedControls });
  }
  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true, uploading: true });
    const controls = this.state.controls;
    let data = new FormData();
    for (let field in controls) {
      data.append(field, controls[field].value);
    }
    const notValid = await Object.keys(controls).filter(field => {
      let { isValid, errorText } = checkValidity(
        controls[field].value,
        this.state.controls[field].validation,
        field
      );
      controls[field].valid = isValid;
      controls[field].error = errorText;
      if (!isValid) return field;
    });
    if (!notValid.length) {
      if (this.state.cropResult) {
        await fetch(this.state.cropResult)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], 'profile-photo.png', {
              type: 'image/png'
            });
            data.append('profile_photo', file);
            data.append('mimetype', 'image/png');
            data.append('originalname', 'profile-photo.png');
            //this.props.onUserUpdate(data);
          });
      }
      axios({
        method: 'post',
        url: urls.storeUser,
        data: data,
        config: { headers: { 'content-type': `multipart/form-data` } }
      })
        .then(resp => {
          console.log(resp);
          this.setState({ uploading: false });
          if (resp.status === 200) {
            if (resp.data.error) {
              let errorData = resp.data.errorData;
              const updatedControls = updateObject(this.state.controls, {
                email: updateObject(this.state.controls.email, {
                  valid: false,
                  error: errorData.email
                }),
                phone: updateObject(this.state.controls.phone, {
                  valid: errorData.phone ? false : true,
                  error: errorData.phone
                })
              });
              this.setState({ controls: updatedControls });
              this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
            } else {
              this.props.onEnqueueSnackbar('Success!!', 3000, 'success');
              this.props.history.push(`/system/users/${resp.data.data.userId}`);
            }
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.onEnqueueSnackbar(error.message, 3000);
        });
    } else {
      this.setState({ uploading: false });
    }
  }
  handleClickShowPassword() {
    let controls = Object.assign({}, this.state.controls);
    controls.password.showPassword = !this.state.controls.password.showPassword;
    this.setState({ controls: controls });
  }
  render() {
    const { classes } = this.props;
    const { row } = this.props.user;
    const { controls, uploading } = this.state;
    const roles =
      this.props.roles.rows.length > 0
        ? this.props.roles.rows.filter(
            r => !['centre', 'teacher', 'learner'].includes(r.name)
          )
        : [];
    return (
      <Box>
        <CssBaseline />
        <Grid container justify="center" spacing={2}>
          <Grid item xs={10}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Add New User</Typography>
                <Paper className={classes.paper}>
                  {uploading && <LinearProgress />}
                  <div className={classes.content}>
                    <div>
                      <Avatar
                        src={this.state.cropResult}
                        className={classes.bigAvatar}
                      >
                        <AddAPhotoIcon fontSize="large" />
                      </Avatar>
                      <ReactCropper
                        btnText={'Upload'}
                        onImageCrop={(result, name, type) =>
                          this.onImageCrop(result, name, type)
                        }
                      />
                    </div>
                    <form className={classes.root} autoComplete="off">
                      <FormControl
                        className={classes.formControl}
                        error={
                          this.state.submitted &&
                          (!controls.role.valid || this.props.error)
                        }
                      >
                        <InputLabel htmlFor="select-role">Role</InputLabel>
                        <Select
                          value={controls.role.value}
                          onChange={e => this.handleInputChange(e, 'role')}
                          inputProps={{
                            name: 'role',
                            id: 'select-role'
                          }}
                          input={<Input id="select-role" />}
                        >
                          {' '}
                          {roles.length > 0 &&
                            roles.map(role => (
                              <MenuItem key={role.name} value={role.name}>
                                {role.description}
                              </MenuItem>
                            ))}
                        </Select>
                        {this.state.submitted && !controls.role.valid && (
                          <FormHelperText>
                            {controls.role.error || this.props.errorText}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="user-name"
                          label="Name"
                          className={classes.TextField}
                          value={controls.name.value}
                          margin="normal"
                          onChange={e => this.handleInputChange(e, 'name')}
                          error={
                            this.state.submitted &&
                            (!controls.name.valid || this.props.error)
                          }
                          helperText={
                            this.state.submitted &&
                            (controls.name.error || this.props.errorText)
                          }
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="user-email"
                          label="Email"
                          className={classes.TextField}
                          margin="normal"
                          value={controls.email.value}
                          onChange={e => this.handleInputChange(e, 'email')}
                          error={
                            this.state.submitted &&
                            (!controls.email.valid || this.props.error)
                          }
                          helperText={
                            this.state.submitted &&
                            (controls.email.error || this.props.errorText)
                          }
                        />
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <TextField
                          id="user-phone"
                          label="Phone"
                          className={classes.TextField}
                          margin="normal"
                          value={controls.phone.value}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +44
                              </InputAdornment>
                            )
                          }}
                          onChange={e => this.handleInputChange(e, 'phone')}
                          error={
                            this.state.submitted &&
                            (!controls.phone.valid || this.props.error)
                          }
                          helperText={
                            this.state.submitted &&
                            (controls.phone.error || this.props.errorText)
                          }
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="user-password"
                          label="Password"
                          className={classes.TextField}
                          margin="normal"
                          value={controls.password.value}
                          type={
                            controls.password.showPassword ? 'text' : 'password'
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {controls.password.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          onChange={e => this.handleInputChange(e, 'password')}
                          error={
                            this.state.submitted &&
                            (!controls.password.valid || this.props.error)
                          }
                          helperText={
                            this.state.submitted &&
                            (controls.password.error || this.props.errorText)
                          }
                        />
                      </FormControl>
                    </form>
                  </div>
                  <Divider />
                  <Box className={classes.content}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleSubmit}
                      disabled={uploading}
                    >
                      Add User
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    roles: state.roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetRoles: () => dispatch(actions.getRoles()),
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AddUser))
);
