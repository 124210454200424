import { put, call } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';
import { urls } from '../../common/urls';

/*
 * Logout Authenticated User
 */
export function* logoutSaga(action) {
  try {
    const token = yield localStorage.getItem('token');
    if (token) {
      yield axios.post(urls.logout);
    }
    yield call([localStorage, 'clear']);
    // yield call([localStorage, 'removeItem'], 'token');
    // yield call([localStorage, 'removeItem'], 'expirationTime');
    // yield call([localStorage, 'removeItem'], 'userId');
    // yield call([localStorage, 'removeItem'], 'current_role');
  } catch (e) {
    console.log(e);
  }
  yield put(actions.logoutSucceed());
}
/*
 * Authenticate User by username & Password
 */
export function* authUserSaga(action) {
  yield put(actions.authStart());
  const authData = {
    email: action.email,
    password: action.password,
    rememberMe: action.rememberMe
  };
  try {
    const response = yield axios.post(urls.login, authData);
    if (response.status === 200 && !response.data.error) {
      const expirationTime = yield new Date(response.data.expirationTime);
      yield localStorage.setItem('token', response.data.token);
      yield localStorage.setItem('expirationTime', expirationTime);
      yield localStorage.setItem('userId', response.data.userId);
      yield put(actions.authSuccess(response.data));
      // Call saga to get Authenticated user data;
      yield call(getAuthUserSaga);
    } else {
      yield put(
        actions.enqueueSnackbar({
          message: response.data.errorText,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      yield put(actions.authFail(response.data.errorText));
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.response.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error'
        }
      })
    );
    yield put(actions.authFail(error.response.data.message));
  }
}

/*
 * Check if user is Authenticated
 */

export function* authCheckStateSaga(action) {
  const token = yield localStorage.getItem('token');
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationTime = yield new Date(
      localStorage.getItem('expirationTime')
    );
    if (expirationTime <= new Date()) {
      yield put(
        actions.enqueueSnackbar({
          message: 'Session Expired',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning'
          }
        })
      );
      yield put(actions.logout());
    } else {
      const userId = yield localStorage.getItem('userId');
      yield put(
        actions.authSuccess({
          token: token,
          userId: userId,
          error: false,
          errorText: ''
        })
      );
      // Call saga to get Authenticated user data;
      yield call(getAuthUserSaga);
    }
  }
}

/*
 * Get Authenticated User Data
 * Called after User is logged in ( Manual / Auto).
 */

export function* getAuthUserSaga(action) {
  yield put(actions.getAuthUserStart());
  try {
    const response = yield axios.post(urls.authUser);
    if (response.status === 200 && !response.data.error) {
      let currentRole = response.data.user.currentRole;
      yield localStorage.setItem(
        'current_role',
        currentRole ? currentRole.name : null
      );
      yield put(actions.getAuthUserSuccess(response.data));
    } else {
      yield put(actions.getAuthUserFail(response.data));
      yield put(actions.logout());
      yield put(
        actions.enqueueSnackbar({
          message: response.data.errorText,
          options: {
            variant: 'error'
          }
        })
      );
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.response
          ? error.response.data.errorText
          : 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error'
        }
      })
    );
    yield put(
      actions.getAuthUserFail(
        error.response
          ? error.response.data.errorText
          : 'Network Request Failed'
      )
    );
  }
}
