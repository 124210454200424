import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

function ErrorPage(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '60vh' }}
    >
      <Paper alignItems="center" className={classes.paper}>
        <Typography variant="h2" align="center">
          {props.error || 'Error!'}
        </Typography>
        <Typography variant="h4" align="center" className={classes.message}>
          {props.message || 'Something Went Wrong!!.'}
        </Typography>
        <img
          src={process.env.PUBLIC_URL + '/images/error_bg.png'}
          alt="Error"
        />
      </Paper>
    </Grid>
  );
}

export default ErrorPage;
