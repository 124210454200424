import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

function LoadingPage(props) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    function tick() {
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grow in={true}>
        <CircularProgress
          className={classes.progress}
          variant="determinate"
          value={progress}
          color="secondary"
        />
      </Grow>
      <Grow in={true} timeout={{ enter: 2000, exit: 3000 }}>
        <Typography variant="subtitle1" align="center">
          Loading!!
        </Typography>
      </Grow>
      <Grow in={true} timeout={{ enter: 3500, exit: 4000 }}>
        <Typography variant="subtitle1" align="center">
          Please be patient
        </Typography>
      </Grow>
    </Grid>
  );
}

export default LoadingPage;
