import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { urls } from '../../common/urls';
import * as actions from '../../store/actions/index';
import { updateObject, checkValidity } from '../../common/utility';
import Logo from '../../components/Logo';
import MenuItem from '@material-ui/core/MenuItem';

class CentreSignup extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      submitted: false,
      regions: [],
      controls: {
        name: {
          value: '',
          validation: {
            required: true,
            minLength: 2
          },
          valid: true,
          error: '',
          touched: false
        },
        region_id: {
          value: '',
          validation: {
            required: true
          },
          valid: true,
          error: '',
          touched: false
        },
        contact: {
          value: '',
          validation: {
            required: true,
            minLength: 2
          },
          valid: true,
          error: '',
          touched: false
        },
        phone: {
          value: '',
          validation: {
            required: true,
            minLength: 6
          },
          valid: true,
          error: '',
          touched: false
        },
        email: {
          value: '',
          validation: {
            required: true,
            isEmail: true
          },
          valid: true,
          error: '',
          touched: false
        },
        password: {
          value: '',
          validation: {
            required: true,
            minLength: 6
          },
          valid: true,
          error: '',
          touched: false
        }
      }
    };
  }
  handleInputChange(event, controlName) {
    let { isValid, errorText } = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation,
      controlName
    );
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        valid: isValid,
        error: errorText,
        errorText: errorText,
        touched: true
      })
    });
    this.setState({ controls: updatedControls });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    if (
      this.state.controls.name.valid &&
      this.state.controls.contact.valid &&
      this.state.controls.phone.valid &&
      this.state.controls.email.valid &&
      this.state.controls.password.valid
    ) {
      let data = {
        registration: true,
        centre: {
          name: this.state.controls.name.value,
          region_id: this.state.controls.region_id.value,
          status: 4,
          password: this.state.controls.password.value
        },
        address: {
          PRIMARY: {
            contact_name: this.state.controls.contact.value,
            phone: this.state.controls.phone.value,
            email: this.state.controls.email.value,
            address_type: 'PRIMARY'
          }
        }
      };
      axios
        .post(urls.createCentre, data)
        .then(async resp => {
          if (resp.status === 200 && resp.data.error === false) {
            this.setState({ errorData: '' });
            this.props.onEnqueueSnackbar('Success!!', 1000, 'success');
            if (resp.data.loginResponse.token) {
              await localStorage.setItem(
                'token',
                resp.data.loginResponse.token
              );
              await localStorage.setItem(
                'expirationTime',
                resp.data.loginResponse.expirationTime
              );
              await localStorage.setItem(
                'userId',
                resp.data.loginResponse.userId
              );
              await this.props.authSuccess(resp.data.loginResponse);
              //this.props.history.push(`/`);
              window.location.href = '/';
              window.location.reload();
            } else {
              this.props.history.push(`/login`);
            }
            if (isNaN(this.props.match.params.id) && resp.data.result) {
            } else {
            }
          } else {
            if (resp.data.errorData) {
              const controls = Object.assign({}, this.state.controls);
              Object.keys(resp.data.errorData).map(key => {
                controls[key].errorText = resp.data.errorData[key];
                controls[key].valid = false;
              });
              this.setState({ controls: controls });
            }
            this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch(error => {
          if (403 === error.response.status) {
            this.props.onEnqueueSnackbar('Access Denied', 1000, 'warning');
          } else {
            this.props.onEnqueueSnackbar(
              error.response.data.errorText,
              3000,
              'error'
            );
          }
        });
    }
  }
  async componentDidMount() {
    axios
      .get(urls.getRegions)
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          this.setState({
            regions: resp.data.row
          });
        }
      })
      .catch(error => {
        this.props.onEnqueueSnackbar(
          error.response.data.errorText,
          3000,
          'error'
        );
      });

    let controls = this.state.controls;
    this.setState({ controls: controls });
  }

  render() {
    return (
      <Box bgcolor="grey.300" css={{ height: '100%' }}>
        <Container maxWidth="xs" style={{ paddingTop: '10%' }}>
          <Card>
            <CardContent>
              <Logo />
              <h3 style={{ textAlign: 'center' }}>
                Institute of British Sign Language
              </h3>
              <h2 style={{ textAlign: 'center' }}>Register as Centre</h2>
              <form onSubmit={this.handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="name"
                  label="Centre Name"
                  type="text"
                  id="name"
                  defaultValue={this.state.controls.name.value}
                  error={
                    this.state.submitted &&
                    (!this.state.controls.name.valid || this.props.error)
                  }
                  helperText={
                    this.state.submitted && this.state.controls.name.errorText
                  }
                  autoComplete="current-name"
                  onChange={event => this.handleInputChange(event, 'name')}
                />
                <TextField
                  id="select-region"
                  select
                  label="Ofqual Region"
                  required={true}
                  value={this.state.controls.region_id.value}
                  onChange={event => this.handleInputChange(event, 'region_id')}
                  // SelectProps={{
                  //   MenuProps: {
                  //     className: classes.menu
                  //   }
                  // }}
                  margin="normal"
                  fullWidth={true}
                  error={
                    this.state.submitted &&
                    (!this.state.controls.region_id.valid || this.props.error)
                  }
                  helperText={
                    this.state.submitted &&
                    this.state.controls.region_id.errorText
                  }
                >
                  {this.state.regions.map(region => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="contact"
                  label="Contact Name"
                  type="text"
                  id="contact"
                  defaultValue={this.state.controls.contact.value}
                  error={
                    this.state.submitted &&
                    (!this.state.controls.contact.valid || this.props.error)
                  }
                  helperText={
                    this.state.submitted &&
                    this.state.controls.contact.errorText
                  }
                  autoComplete="current-contact"
                  onChange={event => this.handleInputChange(event, 'contact')}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                  label="Phone"
                  type="text"
                  id="phone"
                  defaultValue={this.state.controls.phone.value}
                  error={
                    this.state.submitted &&
                    (!this.state.controls.phone.valid || this.props.error)
                  }
                  helperText={
                    this.state.submitted && this.state.controls.phone.errorText
                  }
                  autoComplete="current-phone"
                  onChange={event => this.handleInputChange(event, 'phone')}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={this.state.controls.email.value}
                  autoComplete="email"
                  autoFocus
                  error={
                    this.state.submitted &&
                    (!this.state.controls.email.valid || this.props.error)
                  }
                  helperText={
                    this.state.submitted &&
                    (this.state.controls.email.error || this.props.errorText)
                  }
                  onChange={event => this.handleInputChange(event, 'email')}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  defaultValue={this.state.controls.password.value}
                  error={
                    this.state.submitted &&
                    (!this.state.controls.password.valid || this.props.error)
                  }
                  helperText={
                    this.state.submitted &&
                    this.state.controls.password.errorText
                  }
                  autoComplete="current-password"
                  onChange={event => this.handleInputChange(event, 'password')}
                />
                <Box mt={4} position={'relative'}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    m={2}
                    disabled={this.props.loading}
                  >
                    Sign Up
                  </Button>
                  {this.props.loading && (
                    <CircularProgress
                      size={24}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12
                      }}
                    />
                  )}
                </Box>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    errorText: state.auth.errorText,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authSuccess: data => dispatch(actions.authSuccess(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CentreSignup);
