import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Gaurd from "../../hoc/Gaurd";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import MomentUtils from "@date-io/moment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ViewFile from "../../components/ViewFile";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  container: {},
  title: {
    fontSize: 18
  },
  textField: {
    minWidth: 200
  },
  menu: {
    width: 200
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  statusGroup: {
    flexDirection: "row"
  },
  legend: {
    fontSize: "0.75rem"
  },
  levelLabel: {
    width: 200
  },
  uploadButton: {
    height: "30px"
  },
  approvalBtn: {
    marginRight: theme.spacing(1)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

function CentreLevelApproval(props) {
  const classes = useStyles();
  const [approval, setApproval] = useState({});
  const [loading, setLoading] = useState(false);
  const [approvalConfig, setApprovalConfig] = useState({
    statusCodes: {}
  });
  const [config, setConfig] = useState({
    status: {},
    statusCodes: {},
    policyDocuments: []
  });
  const [centre, setCentre] = useState({
    number: "",
    name: "",
    pay_before_media: 1,
    registered_at: "",
    po_required: 0,
    status: 0,
    region_id: "",
    centre_statuses: []
  });
  const [policies, setPolicies] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [reason, setReason] = useState("");
  const [errorData, setErrorData] = useState({});
  const [addressTab, setAddressTab] = React.useState(0);
  const [uploading, setUploading] = useState(false);
  const [address, setAddress] = useState({
    PRIMARY: {
      address: "",
      address_type: "PRIMARY",
      contact_name: "",
      email: "",
      lat: "",
      lng: "",
      phone: "",
      postcode: ""
    },
    ACCOUNT: {
      address: "",
      address_type: "ACCOUNT",
      contact_name: "",
      email: "",
      lat: "",
      lng: "",
      phone: "",
      postcode: ""
    },
    ALTERNATE: {
      address: "",
      address_type: "ALTERNATE",
      contact_name: "",
      email: "",
      lat: "",
      lng: "",
      phone: "",
      postcode: ""
    },
    doctypeControl: {
      width: "50%"
    }
  });

  const [levels, setLevels] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileItem, setFileItem] = useState({});
  const [comment, setComment] = useState("");

  // Get Qulification with Docs on Mount
  useEffect(() => {
    fetchApproval();
  }, []);

  useEffect(() => {
    if (approval.entity_id) {
      fetchCentre();
      fetchCentrePolicies();
    }
  }, [approval.entity_id]);

  useEffect(() => {
    console.log("[EditCentre] Updated ");
  });
  const fetchApproval = async () => {
    let resp = await axios.post(urls.getApproval(props.match.params.id));
    if (resp.status === 200 && resp.data.error === false) {
      setApproval({ ...resp.data.approval, entity: resp.data.entity });
      setApprovalConfig({ ...resp.data.config });
    }
  };
  function fetchCentre() {
    axios
      .post(urls.getCentre(approval.entity_id))
      .then(async resp => {
        if (resp.status == 200 && !resp.data.error) {
          let newStatus = resp.data.centre.centre_statuses.pop();
          newStatus = newStatus ? newStatus : {};
          setCentre({ ...resp.data.centre, status: newStatus });
          setNewStatus(newStatus);
          setAddress({ ...address, ...resp.data.address });
          let levels = resp.data.centre.centre_levels.map(item => ({
            level_id: item.level_id,
            approved: item.approved
          }));
          setLevels(levels);
          setConfig({ ...resp.data.config });
        }
      })
      .catch(err => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }

  function fetchCentrePolicies(deleteId = null) {
    axios
      .post(urls.getCentreDocuments(approval.entity_id), { deleteId })
      .then(async resp => {
        if (resp.status == 200 && !resp.data.error) {
          setPolicies(resp.data.rows);
        }
      })
      .catch(err => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }

  // handle form field change
  const handleChange = name => e => {
    if (name === "reason") {
      setReason(e.target.value);
    } else {
      setCentre({ ...centre, [name]: e.target.value });
    }
  };
  // Update Entity
  function handleApproval(approve) {
    try {
      axios
        .post(urls.approveEntity(props.match.params.id), {
          approve: approve,
          comment: comment
        })
        .then(resp => {
          if (resp.status === 200 && resp.data.error === false) {
            props.onEnqueueSnackbar(resp.data.message, 3000, "success");
            fetchApproval();
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000, "error");
          }
        });
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000, "error");
    }
  }
  // Update Centre
  function handleSubmit(event) {
    event.preventDefault();
    if (
      (parseInt(newStatus) === config.status.CENTRE_ON_HOLD ||
        parseInt(newStatus) === config.status.CENTRE_CLOSED) &&
      (reason == null || reason == "")
    ) {
      return setErrorData({
        reason: "Reason is required when Status is On Hold or Closed"
      });
    }
    let reqUrl = isNaN(approval.entity_id)
      ? urls.createCentre
      : urls.updateCentre(approval.entity_id);
    let data = isNaN(approval.entity_id)
      ? { centre, address }
      : {
          centreObj: centre,
          newStatus: newStatus.status,
          reason: reason
        };
    data = { ...data };
    axios
      .post(reqUrl, data)
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(approval.entity_id) && resp.data.result) {
            props.history.push(`/centres/list/${resp.data.result}`);
          } else {
            fetchCentre();
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000, "error");
        }
      })
      .catch(error => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  function handleAddressTabChange(event, newValue) {
    setAddressTab(newValue);
  }
  function handleAddressChange(e, name, type) {
    setAddress({
      ...address,
      [type]: { ...address[type], [name]: e.target.value }
    });
  }
  async function fetchGeoLocation() {
    try {
      await fetch(
        `https://api.postcodes.io/postcodes/${address.PRIMARY.postcode}`
      )
        .then(async resp => await resp.json())
        .then(async resp => {
          if (resp.status === 200) {
            let primaryAddress = address.PRIMARY;
            primaryAddress.lat = resp.result.latitude;
            primaryAddress.lng = resp.result.longitude;
            await setAddress({ ...address, PRIMARY: primaryAddress });
          }
        });
      return;
    } catch (err) {
      props.onEnqueueSnackbar(
        "Unable to fetch Latitude & Longitude",
        1000,
        "warning"
      );
      return;
    }
  }
  async function handleAddressSubmit(event) {
    event.preventDefault();
    if (address.PRIMARY.lat === null || address.PRIMARY.lat === "") {
      await fetchGeoLocation();
    }
    await axios
      .post(urls.updateCentreAddress(approval.entity_id), { address })
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(approval.entity_id)) {
            props.history.push("/centres/list/");
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch(error => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  // handle Level Change
  // const handleLevelChange = (levelId, levelName) => async e => {
  //   let checked = e.target.checked;
  //   if (approval.entity_id) {
  //     axios
  //       .post(urls.updateCentreLevel(approval.entity_id), {
  //         levelId,
  //         checked,
  //         levelName,
  //         centreName: `${centre.name} (${centre.number})`
  //       })
  //       .then(async resp => {
  //         let clevels = [];
  //         let respLevel = resp.data.level;
  //         if (resp.status === 200 && resp.data.error === false) {
  //           clevels = await levels.filter(
  //             item => item.level_id !== respLevel.level_id
  //           );
  //           if (checked) {
  //             clevels.push({ level_id: levelId, approved: respLevel.approved });
  //             setLevels(clevels);
  //           } else {
  //             setLevels(clevels);
  //           }
  //           props.onEnqueueSnackbar(resp.data.message, 3000, "success");
  //         } else {
  //           props.onEnqueueSnackbar(resp.data.errorText, 3000);
  //         }
  //       })
  //       .catch(err => props.onEnqueueSnackbar(err.message, 3000));
  //   } else {
  //     let clevels = [];
  //     clevels = await levels.filter(item => item.level_id !== levelId);
  //     if (checked) {
  //       clevels.push({ level_id: levelId, approved: false });
  //       setLevels(clevels);
  //     } else {
  //       setLevels(clevels);
  //     }
  //   }
  // };

  function getCentreStatus() {
    let time = moment(centre.status.updatedAt).format("DD-MM-YYYY");
    switch (parseInt(centre.status.status)) {
      case config.status.CENTRE_PENDING:
        return (
          <React.Fragment>
            {" "}
            {`Centre is `}{" "}
            <Chip label={"PENDING"} color="primary" size="small" />{" "}
            {` for approval since ${time}`}
          </React.Fragment>
        );
      case config.status.CENTRE_ACTIVE:
        return (
          <React.Fragment>
            {" "}
            {`Centre is `}{" "}
            <Chip label={"ACTIVE"} color="primary" size="small" />{" "}
            {` since ${time}`}
          </React.Fragment>
        );
      case config.status.CENTRE_CLOSED:
        return (
          <React.Fragment>
            {" "}
            {`Centre is `}{" "}
            <Chip label={"CLOSED"} color="primary" size="small" />{" "}
            {` since ${time}`}
          </React.Fragment>
        );
      case config.status.CENTRE_ON_HOLD:
        return (
          <React.Fragment>
            {" "}
            {`Centre is `}{" "}
            <Chip label={"ON HOLD"} color="primary" size="small" />{" "}
            {` since ${time}`}
          </React.Fragment>
        );
      default:
        return "";
    }
  }
  const handleNewStatus = event => {
    setNewStatus(event.target.value);
    console.log(event.target.value);
  };
  function handleDocumentTypeChange(event) {
    setSelectedDocType(event.target.value);
  }

  // Handle Media File Upload
  const handleFileUpload = async () => {
    if (props.uploader.uploading) {
      props.onEnqueueSnackbar(
        "Please wait for previous upload to finish",
        3000
      );
      return;
    }
    //let fileName = `${learnername.toString().split(' ').join('-')}`;
    let destination = `media/centre/${centre.number}/documents`;
    try {
      let data = {
        url: urls.updateCentreDocuments(centre.id),
        uploadType: "Media",
        id: centre.id,
        uid: Date.now(),
        location: props.history.location.pathname,
        destination: destination,
        documentType: selectedDocType
      };
      props.uploadFile(data);
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000);
      setUploading(false);
    }
  };
  useEffect(() => {
    if (props.uploader.finished) {
      fetchCentrePolicies();
    }
  }, [props.uploader.finished]);

  const handleViewFile = item => {
    setOpenFileViewer(true);
    setFileItem(item);
  };

  const handleCloseFileViewer = () => {
    setOpenFileViewer(false);
    setFileItem({});
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = agree => {
    if (agree === true) {
      handleApproval(false);
    }
    setOpen(false);
  };
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Box display="flex" mb={2} justifyContent="space-between">
          <Typography>{`New Centre Level Request`}</Typography>
          <Box>
            <Gaurd check="update_centre">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.approvalBtn}
                disabled={
                  approval.status == approvalConfig.statusCodes.APPROVED
                }
                onClick={() => handleApproval(true)}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                disabled={
                  approval.status === approvalConfig.statusCodes.REJECTED
                }
                onClick={() => setOpen(true)}
              >
                Reject
              </Button>
            </Gaurd>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {centre.number
                    ? `Centre - ${centre.number}`
                    : `Add New Centre`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="centre-name"
                    label="Name"
                    className={classes.textField}
                    value={centre.name}
                    onChange={handleChange("name")}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("name")}
                    helperText={errorData.name}
                  />

                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="select-region"
                        select
                        label="Ofqual Region"
                        required={true}
                        className={classes.textField}
                        value={centre.region_id}
                        onChange={handleChange("region_id")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                        margin="normal"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("region_id")}
                        helperText={errorData.region_id}
                      >
                        {props.levels &&
                          props.regions.map(region => (
                            <MenuItem key={region.id} value={region.id}>
                              {region.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel
                      component="legend"
                      required={true}
                      className={classes.legend}
                    >
                      PO Required
                    </FormLabel>
                    <RadioGroup
                      aria-label="po_required"
                      name="po_required"
                      className={classes.statusGroup}
                      value={`${centre.po_required}`}
                      onChange={handleChange("po_required")}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="No"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Yes"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel
                      component="legend"
                      required={true}
                      className={classes.legend}
                    >
                      Pay Before Media Is Issued
                    </FormLabel>
                    <RadioGroup
                      aria-label="pay_before_media"
                      name="pay_before_media"
                      className={classes.statusGroup}
                      value={`${centre.pay_before_media}`}
                      onChange={handleChange("pay_before_media")}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="No"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Yes"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    id="primary-address"
                    label="Address"
                    required={true}
                    className={classes.textField}
                    value={address.PRIMARY.address || ""}
                    onChange={e => handleAddressChange(e, "address", "PRIMARY")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("address")}
                    helperText={errorData.address}
                  />
                  <TextField
                    id="primary-postcode"
                    label="Post code"
                    required={true}
                    className={classes.textField}
                    value={address.PRIMARY.postcode || ""}
                    onChange={e =>
                      handleAddressChange(e, "postcode", "PRIMARY")
                    }
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("postcode")}
                    helperText={errorData.postcode}
                  />
                  <TextField
                    id="primary-name"
                    label="Contact"
                    required={true}
                    className={classes.textField}
                    value={address.PRIMARY.contact_name || ""}
                    onChange={e =>
                      handleAddressChange(e, "contact_name", "PRIMARY")
                    }
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("contact_name")}
                    helperText={errorData.contact_name}
                  />
                  <TextField
                    id="primary-email"
                    label="Email"
                    required={true}
                    type="email"
                    className={classes.textField}
                    value={address.PRIMARY.email || ""}
                    onChange={e => handleAddressChange(e, "email", "PRIMARY")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("email")}
                    helperText={errorData.email}
                  />
                  <TextField
                    id="primary-phone"
                    label="Phone"
                    required={true}
                    className={classes.textField}
                    value={address.PRIMARY.phone || ""}
                    onChange={e => handleAddressChange(e, "phone", "PRIMARY")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("phone")}
                    helperText={errorData.phone}
                  />
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Qualification Levels
                </Typography>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup row>
                    {props.levels &&
                      props.levels.map(level => {
                        let clevel = levels.filter(
                          item => item.level_id === level.id
                        );
                        return (
                          level.name !== "ALL" && (
                            <FormControlLabel
                              className={classes.levelLabel}
                              key={level.id}
                              control={
                                <Checkbox
                                  checked={clevel.length > 0}
                                  readOnly
                                  inputProps={{
                                    readOnly: true
                                  }}
                                  // onChange={handleLevelChange(
                                  //   level.id,
                                  //   level.name
                                  // )}
                                  color={
                                    clevel[0] &&
                                    clevel[0].approved ===
                                      config.status.LEVEL_APPROVED
                                      ? "primary"
                                      : "secondary"
                                  }
                                />
                              }
                              label={level.name}
                            />
                          )
                        );
                      })}
                  </FormGroup>
                </FormControl>
              </CardContent>
            </Card>
            <Box>
              <Typography variant="caption">** Color of checkboxes</Typography>
              <div>
                <Checkbox color="primary" checked={true} /> Level is Approved
                <Checkbox color="secondary" checked={true} /> Level is Pending
                for Approval
              </div>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-reason"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-reason">
            Reason for Rejection
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div
                dangerouslySetInnerHTML={{
                  __html: approval.comments
                    ? approval.comments
                        .replace(/###/g, "<br /><br />")
                        .replace(/##/g, "<br/>")
                    : ""
                }}
              ></div>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              multiline
              rows="4"
              label="Reason"
              fullWidth
              onChange={e => setComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleClose(true)}
              color="secondary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    uploader: state.uploader,
    levels: state.common.levels,
    regions: state.common.regions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: data => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CentreLevelApproval)
);
