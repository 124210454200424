import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function UnitTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [units, setUnits] = React.useState([]);
  const [errorData, setErrorData] = React.useState(false);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  /** Handle Field Change */
  const handleFieldChange = (id, name) => (e) => {
    let units = props.units.map((item) =>
      item.id === id ? { ...item, [name]: e.target.value } : item
    );
    props.onChange(units);
  };

  return (
    <div className={classes.root}>
      {props.units.length > 0 && (
        <React.Fragment>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {props.units.map((item, index) => (
                <Tab
                  key={item.id}
                  label={item.unit_code}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {props.units.map((item, index) => (
              <TabPanel key={item.id} value={value} index={index}>
                <p>{item.unit_description}</p>
                <TextField
                  id="unit-qan"
                  label="Ofqual NAN"
                  className={classes.textField}
                  value={item.nan || ""}
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="unit-tqt"
                  label="TQT"
                  className={classes.textField}
                  value={item.tqt || ""}
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="unit-learner-fee"
                  label="Learner Fee"
                  className={classes.textField}
                  value={item.learner_fee || ""}
                  onChange={handleFieldChange(item.id, "learner_fee")}
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  error={errorData.hasOwnProperty("learner_fee")}
                  helperText={errorData.learner_fee}
                  InputProps={{
                    readOnly: props.readOnly,
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  id="unit-rest-fee"
                  label="Resit Fee"
                  className={classes.textField}
                  value={item.resit_fee || ""}
                  onChange={handleFieldChange(item.id, "resit_fee")}
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  error={errorData.hasOwnProperty("resit_fee")}
                  helperText={errorData.resit_fee}
                  InputProps={{
                    readOnly: props.readOnly,
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
            ))}
          </SwipeableViews>
        </React.Fragment>
      )}
    </div>
  );
}
