import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Select from "@material-ui/core/Select";
import IntegrationDownshift from "../../components/IntegrationDownshift";
import Autocomplete from "../../components/Autocomplete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Popover from "@material-ui/core/Popover";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "entity_type", numeric: false, label: "Entity", sortable: true },
  { id: "message", numeric: false, label: "Message", sortable: true },
  { id: "requested_by", numeric: false, label: "Requested By", sortable: true },
  {
    id: "requested_at",
    numeric: false,
    label: "Requested At",
    sortable: false,
  },
  { id: "status", numeric: false, label: "Status", sortable: false },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  formroot: {
    width: "100%",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(1),
    height: "24px",
  },
  avatar: {
    height: "24px",
    width: "24px",
    fontSize: "11px",
  },
}));

function ApprovalList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [approvals, setApprovals] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [config, setconfig] = useState({
    entityTypes: {},
    status: {},
  });
  const [approval, setApproval] = useState({});
  const [entity, setEntity] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stats, setStats] = useState([]);
  const [filterStats, setFilterStats] = useState();

  useEffect(() => {
    let filters = localStorage.getItem("filter.approvals");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setSearchUser(filters.searchUser);
      setFilterStats(filters.filterStats);
    }
    fetchApprovalStats();
    //fetchApprovals(filters);
  }, []);

  useEffect(() => {
    if (filterStats !== undefined) {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        searchUser: searchUser,
        filterStats: filterStats ? filterStats : [],
      };
      localStorage.setItem("filter.approvals", JSON.stringify(filters));
      fetchApprovals(filters);
    }
  }, [searchText, searchUser, page, rowsPerPage, orderBy, order, filterStats]);

  const fetchApprovalStats = async () => {
    setLoading(true);
    let resp = await axios.post(urls.getApprovalStats);
    if (resp.status === 200 && resp.data.error === false) {
      const stats = resp.data.rows;
      const statuses = stats.map((item) => item.status);
      setStats(stats);
      setconfig(resp.data.config);
      setLoading(false);
      if (filterStats && filterStats.length > 0) {
        const fstats = filterStats.filter((item) => statuses.indexOf(item) > 0);
        setFilterStats(fstats);
      }
    } else {
      props.onEnqueueSnackbar(resp.data.errorText, 3000);
      setLoading(false);
    }
  };

  const fetchApprovals = async (params) => {
    let resp = await axios.post(urls.getApprovals, params);
    if (resp.status === 200 && resp.data.error === false) {
      await setApprovals(resp.data.rows);
      setTotal(resp.data.total);
      setLoading(false);
    }
  };

  const showApproval = async (event, id) => {
    setApproval({});
    setAnchorEl(event.currentTarget);
    let resp = await axios.post(urls.getApproval(id));
    if (resp.status === 200 && resp.data.error === false) {
      setApproval({ ...resp.data.approval, entity: resp.data.entity });
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setPage(0);
  }, [searchText, searchUser, filterStats]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }
  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  /**
   * Update Select Status Code
   */
  function handleFilterStatChange(statusCode) {
    if (!filterStats) {
      setFilterStats([statusCode]);
    } else if (filterStats.includes(statusCode)) {
      setFilterStats(filterStats.filter((item) => item != statusCode));
    } else {
      setFilterStats([...filterStats, statusCode]);
    }
  }
  return (
    <Container>
      <div className={classes.chipRoot}>
        {stats.map((item) => (
          <Chip
            key={item.status}
            variant="outlined"
            size="small"
            avatar={
              <Avatar className={classes.avatar} size="small">
                {item.count}
              </Avatar>
            }
            label={config.status[item.status]}
            className={classes.chip}
            onClick={(e) => handleFilterStatChange(item.status)}
            deleteIcon={<DoneIcon />}
            color={
              filterStats && filterStats.includes(item.status)
                ? "primary"
                : "default"
            }
          />
        ))}
      </div>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" id="tableTitle">
                  Approval Requests
                </Typography>
              </Grid>

              <Grid item xs={6} sm={3}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="search-centre">{`Search by name,email,phone`}</InputLabel>
                    <Input
                      id="search-user"
                      value={searchUser}
                      onChange={(event) => setSearchUser(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchUser("")}
                          >
                            {searchUser.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="search-text">{`Search in message`}</InputLabel>
                    <Input
                      id="search-text"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchText("")}
                          >
                            {searchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? "none" : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              approvals.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={(event) => showApproval(event, item.id)}
                >
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>{config.entityTypes[item.entity_type]}</TableCell>
                  <TableCell>{item.message}</TableCell>
                  <TableCell>{item.user.name}</TableCell>
                  <TableCell>{item.requested_at}</TableCell>
                  <TableCell>{config.status[item.status]}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/approvals/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!loading && approvals.length === 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Popover
        id={"approval-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Approval Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {approval.entity &&
              Object.keys(approval.entity).map((key, index) => (
                <TableRow key={index}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{approval.entity[key]}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Popover>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApprovalList);
