import { connect } from 'react-redux';

const Gaurd = props => {
  if (
    props.authUser.permissions &&
    props.authUser.permissions.includes(props.check)
  ) {
    return props.children;
  } else {
    return null;
  }
};

const mapStateToProps = state => {
  return {
    authUser: state.authUser
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Gaurd);
