/* eslint-disable eqeqeq */
import { forwardRef } from "react";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import IntegrationDownshift from "../../components/IntegrationDownshift";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";
import Popover from "@material-ui/core/Popover";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";

import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import ViewColumn from "@material-ui/icons/ViewColumn";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "centre_id", numeric: false, label: "Centre", sortable: true },
  { id: "level", numeric: true, label: "Level", sortable: true },
  { id: "fee", numeric: true, label: "Fee", sortable: true, align: "right" },
  { id: "start_date", numeric: false, label: "Start Date", sortable: true },
  { id: "end_date", numeric: false, label: "End Date", sortable: true },
  { id: "status", numeric: false, label: "Status", sortable: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  formroot: {
    width: "100%",
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(1),
    height: "24px",
  },
  avatar: {
    height: "24px",
    width: "24px",
    fontSize: "11px",
    marginLeft: "0 !important",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  popoverTableHead: {
    background: theme.palette.grey[300],
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => (
    <Check {...props} ref={ref} size="small" />
  )),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} size="small" />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function AccountFee(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [annualFees, setAnnualFees] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [searchCentre, setSearchCentre] = useState("");
  const [centres, setCentres] = useState([]);
  const [searchCourse, setSearchCourse] = useState("");
  const [stats, setStats] = useState([]);
  const [filterStats, setFilterStats] = useState([]);
  const [filterLevels, setFilterLevels] = useState([]);
  const [annualFeeConfig, setAnnualFeeConfig] = useState({ statusCodes: {} });
  const [payments, setPayments] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    let filters = localStorage.getItem("filter.annualFees");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setSearchCentre(filters.searchCentre);
      setSearchCourse(filters.searchCourse);
      setFilterStats(filters.stats);
    }
    props.getCentreNames();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchText, filterStats, filterLevels]);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        searchCentre: searchCentre,
        searchCourse: searchCourse,
        filterStats: filterStats ? filterStats : [],
        filterLevels: filterLevels ? filterLevels : [],
      };
      await localStorage.setItem("filter.annualFees", JSON.stringify(filters));
      const fetchAnnualFeesStats = async () => {
        setLoading(true);
        let resp = await axios.post(urls.getannualFeestats);
        if (resp.status === 200 && resp.data.error === false) {
          setStats(resp.data.rows);
          setAnnualFeeConfig(resp.data.config);
          setLoading(false);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
          setLoading(false);
        }
      };
      const fetchAnnualFees = async (params) => {
        setLoading(true);
        let resp = await axios.post(urls.getannualFees, params);
        if (resp.status === 200 && resp.data.error === false) {
          await setAnnualFees(resp.data.rows);
          setTotal(resp.data.total);
          setLoading(false);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
          setLoading(false);
        }
      };
      fetchAnnualFeesStats();
      fetchAnnualFees(filters);
    })();
  }, [
    searchText,
    searchCentre,
    searchCourse,
    filterStats,
    filterLevels,
    page,
    rowsPerPage,
    orderBy,
    order,
    props,
  ]);

  useEffect(() => {
    if (props.centres.length > 0) {
      let centres = props.centres.map((item) => ({
        id: item.id,
        label: `${item.name} (${item.number})`,
      }));
      setCentres(centres);
    }
  }, [props.centres]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }

  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleDownshiftSelect(selectedId) {
    setSearchCentre(selectedId);
  }
  const selectedCentre = (id) => {
    let c = centres.filter((item) => item.id === id);
    return c.length ? c[0] : null;
  };

  /**
   * Update Selected Status Code and filter records
   */
  function handleFilterStatChange(statusCode) {
    if (!filterStats) {
      setFilterStats([statusCode]);
    } else if (filterStats.includes(statusCode)) {
      setFilterStats(filterStats.filter((item) => item != statusCode));
    } else {
      setFilterStats([...filterStats, statusCode]);
    }
  }
  /**
   * Update selected level and filter records
   */
  function handleFilterLevelChange(levelId) {
    if (!filterLevels) {
      setFilterLevels([levelId]);
    } else if (filterLevels.includes(levelId)) {
      setFilterLevels(filterLevels.filter((item) => item != levelId));
    } else {
      setFilterLevels([...filterLevels, levelId]);
    }
  }
  /**
   * Show payments on click of Row
   */
  const open = Boolean(anchorEl);
  const showPayments = (annualFee) => async (event) => {
    setAnchorEl(event.currentTarget);
    let resp = await axios.post(urls.getAnnualFeePayments(annualFee.id));
    if (resp.status === 200 && resp.data.error === false) {
      await setPayments(resp.data.rows);
    }
  };
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <Container>
      <div className={classes.chipRoot}>
        {stats.map((item) => (
          <Chip
            key={item.status}
            variant="outlined"
            size="small"
            avatar={
              <Avatar className={classes.avatar} size="small">
                {item.count}
              </Avatar>
            }
            label={annualFeeConfig.statusCodes[item.status]}
            className={classes.chip}
            onClick={(e) => handleFilterStatChange(item.status)}
            deleteIcon={<DoneIcon />}
            color={
              filterStats && filterStats.includes(item.status)
                ? "primary"
                : "default"
            }
          />
        ))}
      </div>
      <div className={classes.chipRoot}>
        {props.levels.map((item) => {
          return (
            item.id < 50 && (
              <Chip
                key={item.id}
                variant="outlined"
                size="small"
                label={item.name}
                className={classes.chip}
                onClick={(e) => handleFilterLevelChange(item.id)}
                deleteIcon={<DoneIcon />}
                color={
                  filterLevels && filterLevels.includes(item.id)
                    ? "primary"
                    : "default"
                }
              />
            )
          );
        })}
      </div>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={8}>
                <Typography variant="h6" id="tableTitle">
                  Annual Fees
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.search}>
                  <SearchIcon />
                  <IntegrationDownshift
                    label="&nbsp;"
                    placeholder="search in centre"
                    data={Object.values(centres)}
                    onSelect={(selected) => handleDownshiftSelect(selected.id)}
                    selectedItem={selectedCentre(searchCentre)}
                    onClear={() => setSearchCentre("")}
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.align ? row.align : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {annualFees.map((item) => (
              <TableRow
                hover
                key={item.id}
                onClick={showPayments(item)}
                selected={annualFees.id === item.id}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell>{`${item.centre_name} (${item.number})`}</TableCell>
                <TableCell align="left">{item.levels.name}</TableCell>
                <TableCell align="right">{`£${item.fee}`}</TableCell>
                <TableCell style={{ width: "140px" }}>
                  {item.start_date ? item.start_date : ""}
                </TableCell>
                <TableCell style={{ width: "140px" }}>
                  {item.end_date ? item.end_date : ""}
                </TableCell>
                <TableCell style={{ width: "140px" }}>
                  {annualFeeConfig
                    ? annualFeeConfig.statusCodes[item.status]
                    : ""}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="Edit"
                    onClick={() =>
                      props.history.push(
                        `/account/centre/${item.centre_id}/annual-fees`
                      )
                    }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {annualFees.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Popover
        id={"assessment-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper>
          <Table size="small">
            <TableHead className={classes.popoverTableHead}>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Invoice No</TableCell>
                <TableCell>Invoice Date</TableCell>
                <TableCell>Paid Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.invoice_no}</TableCell>
                  <TableCell>{item.invoice_date}</TableCell>
                  <TableCell>{item.paid_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Popover>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    centres: state.centres.rows,
    levels: state.common.levels,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCentreNames: () => dispatch(actions.getCentres()),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountFee);
