import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Gaurd from "../../../hoc/Gaurd";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import MomentUtils from "@date-io/moment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ViewFile from "../../../components/ViewFile";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import * as actions from "../../../store/actions";
import { urls } from "../../../common/urls";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  statusGroup: {
    flexDirection: "row",
  },
  legend: {
    fontSize: "0.75rem",
  },
  levelLabel: {
    width: 200,
  },
  uploadButton: {
    height: "30px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

function EditCentre(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({
    status: {},
    policyDocuments: [],
  });
  const [centre, setCentre] = useState({
    number: "",
    name: "",
    pay_before_media: 1,
    registered_at: "",
    po_required: 0,
    status: {},
    region_id: "",
    centre_statuses: [],
  });
  const [policies, setPolicies] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [reason, setReason] = useState("");
  const [errorData, setErrorData] = useState({});
  const [addressTab, setAddressTab] = React.useState(0);
  const [uploading, setUploading] = useState(false);
  const [address, setAddress] = useState({
    PRIMARY: {
      address: "",
      address_type: "PRIMARY",
      contact_name: "",
      email: "",
      lat: "",
      lng: "",
      city: "",
      phone: "",
      postcode: "",
    },
    ACCOUNT: {
      address: "",
      address_type: "ACCOUNT",
      contact_name: "",
      email: "",
      lat: "",
      lng: "",
      city: "",
      phone: "",
      postcode: "",
    },
    ALTERNATE: {
      address: "",
      address_type: "ALTERNATE",
      contact_name: "",
      email: "",
      lat: "",
      lng: "",
      city: "",
      phone: "",
      postcode: "",
    },
    doctypeControl: {
      width: "50%",
    },
  });

  const [levels, setLevels] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileItem, setFileItem] = useState({});

  // Get Qulification with Docs on Mount
  useEffect(() => {
    if (isNaN(props.centre.id)) {
    } else {
      fetchCentre();
      fetchCentrePolicies();
    }
    console.log("[EditCentre] Mounted ");
  }, []);

  useEffect(() => {
    console.log("[EditCentre] Updated ");
  });

  function fetchCentre() {
    axios
      .post(urls.getCentre(props.centre.id))
      .then(async (resp) => {
        if (resp.status == 200 && !resp.data.error) {
          let newStatus = resp.data.centre.centre_statuses.pop();
          setCentre({
            ...resp.data.centre,
            status: newStatus ? newStatus : {},
          });
          setAddress({ ...address, ...resp.data.address });
          let levels = resp.data.centre.centre_levels.map((item) => ({
            level_id: item.level_id,
            approved: item.approved,
          }));
          setLevels(levels);
          setConfig(resp.data.config);
        }
      })
      .catch((err) => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }

  function fetchCentrePolicies(deleteId = null) {
    axios
      .post(urls.getCentreDocuments(props.centre.id), { deleteId })
      .then(async (resp) => {
        if (resp.status == 200 && !resp.data.error) {
          setPolicies(resp.data.rows);
        }
      })
      .catch((err) => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }

  // handle form field change
  const handleChange = (name) => (e) => {
    if (name === "reason") {
      setReason(e.target.value);
    } else {
      setCentre({ ...centre, [name]: e.target.value });
    }
  };

  // Update Centre
  function handleSubmit(event) {
    event.preventDefault();
    let data = {
      centreObj: centre,
      newStatus,
      reason: reason,
    };
    axios
      .post(urls.updateCentre(props.centre.id), data)
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          fetchCentre();
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((error) => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  function handleAddressTabChange(event, newValue) {
    setAddressTab(newValue);
  }
  function handleAddressChange(e, name, type) {
    setAddress({
      ...address,
      [type]: { ...address[type], [name]: e.target.value },
    });
  }
  async function fetchGeoLocation() {
    try {
      await fetch(
        `https://api.postcodes.io/postcodes/${address.PRIMARY.postcode}`
      )
        .then(async (resp) => await resp.json())
        .then(async (resp) => {
          if (resp.status === 200) {
            let primaryAddress = address.PRIMARY;
            primaryAddress.lat = resp.result.latitude;
            primaryAddress.lng = resp.result.longitude;
            await setAddress({ ...address, PRIMARY: primaryAddress });
          }
        });
      return;
    } catch (err) {
      props.onEnqueueSnackbar(
        "Unable to fetch Latitude & Longitude",
        1000,
        "warning"
      );
      return;
    }
  }
  async function handleAddressSubmit(event) {
    event.preventDefault();
    if (address.PRIMARY.lat === null || address.PRIMARY.lat === "") {
      await fetchGeoLocation();
    }
    await axios
      .post(urls.updateCentreAddress(props.centre.id), { address })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.centre.id)) {
            props.history.push("/centres/list/");
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((error) => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  // handle Level Change
  const handleLevelChange = (levelId, levelName) => async (e) => {
    let checked = e.target.checked;
    if (props.centre.id) {
      axios
        .post(urls.updateCentreLevel(props.centre.id), {
          levelId,
          checked,
          levelName,
          centreName: `${centre.name} (${centre.number})`,
          showNotification: false,
        })
        .then(async (resp) => {
          let clevels = [];
          let respLevel = resp.data.level;
          if (resp.status === 200 && resp.data.error === false) {
            clevels = await levels.filter(
              (item) => item.level_id !== respLevel.level_id
            );
            if (checked) {
              clevels.push({ level_id: levelId, approved: respLevel.approved });
              setLevels(clevels);
            } else {
              setLevels(clevels);
            }
            props.onEnqueueSnackbar(resp.data.message, 3000, "success");
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((err) => props.onEnqueueSnackbar(err.message, 3000));
    } else {
      let clevels = [];
      clevels = await levels.filter((item) => item.level_id !== levelId);
      if (checked) {
        clevels.push({ level_id: levelId, approved: false });
        setLevels(clevels);
      } else {
        setLevels(clevels);
      }
    }
  };

  function handleDocumentTypeChange(event) {
    setSelectedDocType(event.target.value);
  }

  // Handle Media File Upload
  const handleFileUpload = async () => {
    if (props.uploader.uploading) {
      props.onEnqueueSnackbar(
        "Please wait for previous upload to finish",
        3000
      );
      return;
    }
    //let fileName = `${learnername.toString().split(' ').join('-')}`;
    let destination = `media/centre/${centre.number}/documents`;
    try {
      let data = {
        url: urls.updateCentreDocuments(centre.id),
        uploadType: "Media",
        id: centre.id,
        uid: Date.now(),
        location: props.history.location.pathname,
        destination: destination,
        documentType: selectedDocType,
      };
      props.uploadFile(data);
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000);
      setUploading(false);
    }
  };
  useEffect(() => {
    if (props.uploader.finished) {
      fetchCentrePolicies();
    }
  }, [props.uploader.finished]);

  const handleViewFile = (item) => {
    setOpenFileViewer(true);
    setFileItem(item);
  };

  const handleCloseFileViewer = () => {
    setOpenFileViewer(false);
    setFileItem({});
  };
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {centre.number
                    ? `Centre - ${centre.number}`
                    : `Add New Centre`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="centre-name"
                    label="Name"
                    className={classes.textField}
                    value={centre.name}
                    onChange={handleChange("name")}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("name")}
                    helperText={errorData.name}
                  />

                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="select-region"
                        select
                        label="Ofqual Region"
                        required={true}
                        className={classes.textField}
                        value={centre.region_id}
                        onChange={handleChange("region_id")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("region_id")}
                        helperText={errorData.region_id}
                      >
                        {props.levels &&
                          props.regions.map((region) => (
                            <MenuItem key={region.id} value={region.id}>
                              {region.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubmit}
                >
                  Update Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Tabs
                  value={addressTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleAddressTabChange}
                  aria-label="disabled tabs example"
                >
                  <Tab label="Primary" />
                  <Tab label="Alternate" />
                  <Tab label="Accounts" />
                </Tabs>
                <TabPanel value={addressTab} index={0}>
                  <form
                    className={classes.formContainer}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="primary-address"
                      label="Address"
                      required={true}
                      className={classes.textField}
                      value={address.PRIMARY.address || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "address", "PRIMARY")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("address")}
                      helperText={errorData.address}
                    />
                    <TextField
                      id="primary-city"
                      label="City"
                      required={true}
                      className={classes.textField}
                      value={address.PRIMARY.city || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "city", "PRIMARY")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("city")}
                      helperText={errorData.city}
                    />
                    <TextField
                      id="primary-postcode"
                      label="Post code"
                      required={true}
                      className={classes.textField}
                      value={address.PRIMARY.postcode || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "postcode", "PRIMARY")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("postcode")}
                      helperText={errorData.postcode}
                    />
                    <TextField
                      id="primary-name"
                      label="Contact"
                      required={true}
                      className={classes.textField}
                      value={address.PRIMARY.contact_name || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "contact_name", "PRIMARY")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("contact_name")}
                      helperText={errorData.contact_name}
                    />
                    <TextField
                      id="primary-email"
                      label="Email"
                      required={true}
                      type="email"
                      className={classes.textField}
                      value={address.PRIMARY.email || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "email", "PRIMARY")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("email")}
                      helperText={errorData.email}
                    />

                    <TextField
                      id="primary-phone"
                      label="Phone"
                      required={true}
                      className={classes.textField}
                      value={address.PRIMARY.phone || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "phone", "PRIMARY")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("phone")}
                      helperText={errorData.phone}
                    />
                  </form>
                </TabPanel>
                <TabPanel value={addressTab} index={1}>
                  <form
                    className={classes.formContainer}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="alternate-address"
                      label="Address"
                      className={classes.textField}
                      value={address.ALTERNATE.address || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "address", "ALTERNATE")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                    <TextField
                      id="alternate-city"
                      label="City"
                      required={true}
                      className={classes.textField}
                      value={address.ALTERNATE.city || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "city", "ALTERNATE")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("city")}
                      helperText={errorData.city}
                    />
                    <TextField
                      id="alternate-postcode"
                      label="Post code"
                      className={classes.textField}
                      value={address.ALTERNATE.postcode || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "postcode", "ALTERNATE")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                    <TextField
                      id="alternate-name"
                      label="Contact"
                      className={classes.textField}
                      value={address.ALTERNATE.contact_name || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "contact_name", "ALTERNATE")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                    <TextField
                      id="alternate-email"
                      label="Email"
                      type="email"
                      className={classes.textField}
                      value={address.ALTERNATE.email || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "email", "ALTERNATE")
                      }
                      margin="dense"
                      fullWidth={true}
                    />

                    <TextField
                      id="alternate-phone"
                      label="Phone"
                      className={classes.textField}
                      value={address.ALTERNATE.phone || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "phone", "ALTERNATE")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                  </form>
                </TabPanel>
                <TabPanel value={addressTab} index={2}>
                  <form
                    className={classes.formContainer}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="account-address"
                      label="Address"
                      className={classes.textField}
                      value={address.ACCOUNT.address || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "address", "ACCOUNT")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                    <TextField
                      id="account-city"
                      label="City"
                      required={true}
                      className={classes.textField}
                      value={address.ACCOUNT.city || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "city", "ACCOUNT")
                      }
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("city")}
                      helperText={errorData.city}
                    />
                    <TextField
                      id="account-postcode"
                      label="Post code"
                      className={classes.textField}
                      value={address.ACCOUNT.postcode || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "postcode", "ACCOUNT")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                    <TextField
                      id="account-name"
                      label="Contact"
                      className={classes.textField}
                      value={address.ACCOUNT.contact_name || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "contact_name", "ACCOUNT")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                    <TextField
                      id="acount-email"
                      label="Email"
                      type="email"
                      className={classes.textField}
                      value={address.ACCOUNT.email || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "email", "ACCOUNT")
                      }
                      margin="dense"
                      fullWidth={true}
                    />

                    <TextField
                      id="account-phone"
                      label="Phone"
                      className={classes.textField}
                      value={address.ACCOUNT.phone || ""}
                      onChange={(e) =>
                        handleAddressChange(e, "phone", "ACCOUNT")
                      }
                      margin="dense"
                      fullWidth={true}
                    />
                  </form>
                </TabPanel>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddressSubmit}
                >
                  Update Address
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Qualification Levels
                </Typography>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup row>
                    {props.levels &&
                      props.levels.map((level) => {
                        let clevel = levels.filter(
                          (item) => item.level_id === level.id
                        );
                        return (
                          level.name !== "ALL" && (
                            <FormControlLabel
                              className={classes.levelLabel}
                              key={level.id}
                              control={
                                <Checkbox
                                  checked={clevel.length > 0}
                                  onChange={handleLevelChange(
                                    level.id,
                                    level.name
                                  )}
                                  color={
                                    clevel[0] &&
                                    clevel[0].approved ===
                                      config.status.LEVEL_APPROVED
                                      ? "primary"
                                      : "secondary"
                                  }
                                />
                              }
                              label={level.name}
                            />
                          )
                        );
                      })}
                  </FormGroup>
                </FormControl>
              </CardContent>
            </Card>
            <Box>
              <Typography variant="caption">** Color of checkboxes</Typography>
              <div>
                <Checkbox color="secondary" checked={true} /> Level is Pending
                for Approval
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Policy and Procedure Documents
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <FormControl
                    className={classes.doctypeControl}
                    style={{ width: "50%" }}
                  >
                    <InputLabel htmlFor="age-simple">Document Type</InputLabel>
                    <Select
                      value={selectedDocType}
                      onChange={(event) => handleDocumentTypeChange(event)}
                      inputProps={{
                        name: "documentType",
                        id: "document-type-simple",
                      }}
                    >
                      {config.policyDocuments &&
                        Object.keys(config.policyDocuments).map((index) => (
                          <MenuItem key={index} value={index}>
                            {config.policyDocuments[index].title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Gaurd check="update_centre">
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.uploadButton}
                      onClick={() => handleFileUpload()}
                    >
                      Upload
                      <CloudUploadIcon className={classes.rightIcon} />
                    </Button>
                  </Gaurd>
                </Box>
                <Table size="small" aria-label="Policy Documents">
                  <TableHead>
                    <TableRow>
                      <TableCell>type</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {policies &&
                      policies.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {config.policyDocuments &&
                              config.policyDocuments[row.doc_type_id] &&
                              config.policyDocuments[row.doc_type_id].title}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              size="small"
                              color="primary"
                              className={classes.uploadButton}
                              onClick={() => handleViewFile(row)}
                            >
                              View
                            </Button>
                            <Button
                              size="small"
                              color="secondary"
                              className={classes.uploadButton}
                              onClick={() => fetchCentrePolicies(row.id)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            {openFileViewer && (
              <ViewFile
                item={fileItem}
                onClose={() => handleCloseFileViewer()}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    centre: state.authUser.centre,
    uploader: state.uploader,
    levels: state.common.levels,
    regions: state.common.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditCentre)
);
