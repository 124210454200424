import React from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Gaurd from "../../hoc/Gaurd";
import UserfulHelpLink from "../../components/UsefulHelpLink";
import { urls } from "../../common/urls";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  card: {
    maxWidth: 345,
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    textAlign: "center",
  },
}));

function PeopleIndex(props) {
  const classes = useStyles();
  const items = [
    {
      key: "teachers",
      name: "Teachers",
      link: "/people/teachers",
      permission: "view_teacher",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M5.5 22v-7.5H4V9c0-1.1.9-2 2-2h3c1.1 0 2 .9 2 2v5.5H9.5V22h-4zM18 22v-6h3l-2.54-7.63C18.18 7.55 17.42 7 16.56 7h-.12c-.86 0-1.63.55-1.9 1.37L12 16h3v6h3zM7.5 6c1.11 0 2-.89 2-2s-.89-2-2-2-2 .89-2 2 .89 2 2 2zm9 0c1.11 0 2-.89 2-2s-.89-2-2-2-2 .89-2 2 .89 2 2 2z" />
        </SvgIcon>
      ),
    },
    {
      key: "add-teacher",
      name: "Add New Teacher",
      link: "/people/teacher/create",
      permission: "create_teacher",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z" />
        </SvgIcon>
      ),
    },
    {
      key: "learners",
      name: "View Learners",
      link: "/people/learners",
      permission: "view_learner",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z" />
        </SvgIcon>
      ),
    },
    {
      key: "add-learner",
      name: "Add New Learner",
      link: "/people/learners/create",
      permission: "create_learner",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </SvgIcon>
      ),
    },
    {
      key: "examiners",
      name: "View Examiners",
      link: "/people/examiners",
      permission: "view_examiner",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z" />
        </SvgIcon>
      ),
    },
    {
      key: "add-examiner",
      name: "Add New Examiner",
      link: "/people/examiner/create",
      permission: "create_examiner",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </SvgIcon>
      ),
    },
  ];
  const [usefulLinks, setUsefulLinks] = React.useState([]);
  React.useEffect(() => {
    axios.get(urls.getUsefulLinks("people")).then((resp) => {
      if (resp.status === 200) {
        setUsefulLinks(resp.data.row);
      }
    });
  }, []);
  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={11}>
        <Grid container justify="center" spacing={2}>
          {items.map((item) => (
            <Grid key={item.key} item xs={6} sm={4} md={2}>
              <Gaurd check={item.permission}>
                <Card className={classes.card}>
                  <CardActionArea onClick={() => props.history.push(item.link)}>
                    <CardContent>
                      <div className={classes.iconWrapper}>{item.icon}</div>
                      <Typography component="h5" align="center">
                        {item.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Gaurd>
            </Grid>
          ))}
        </Grid>
        {usefulLinks.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <UserfulHelpLink helpLinks={usefulLinks} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default withRouter(PeopleIndex);
