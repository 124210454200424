import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utility';

const initialState = {
  rows: [],
  qualStatuses: {},
  error: null,
  errorText: '',
  loading: false
};

/* Get User */
const getQualificationsStart = (state, action) => {
  return updateObject(state, { error: null, errorText: '', loading: true });
};

const getQualificationsSuccess = (state, action) => {
  return updateObject(state, {
    rows: action.payload.qualifications,
    qualStatuses: action.payload.qualStatuses,
    count: action.payload.count,
    total: action.payload.total,
    offset: action.payload.offset,
    limit: action.payload.limit,
    orderBy: action.payload.orderBy,
    order: action.payload.order,
    error: action.payload.error,
    status: 200,
    errorText: action.payload.errorText,
    loading: false
  });
};

const getQualificationsFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.error,
    loading: false
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUALIFICATIONS_START:
      return getQualificationsStart(state, action);
    case actionTypes.GET_QUALIFICATIONS_SUCCESS:
      return getQualificationsSuccess(state, action);
    case actionTypes.GET_QUALIFICATIONS_FAIL:
      return getQualificationsFail(state, action);
    default:
      return state;
  }
};
