import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import UserfulHelpLink from "../../components/UsefulHelpLink";

import { urls } from "../../common/urls";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  card: {
    maxWidth: 345,
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    textAlign: "center",
  },
}));

function System(props) {
  const classes = useStyles();
  const items = [
    {
      key: "user",
      name: "User Management",
      link: "/system/users",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </SvgIcon>
      ),
    },
    {
      key: "add-user",
      name: "Add New User",
      link: "/system/users/create",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </SvgIcon>
      ),
    },
    {
      key: "roles",
      name: "Roles & Permissions",
      link: "/system/roles",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
        </SvgIcon>
      ),
    },
    {
      key: "settings",
      name: "App Settings",
      link: "",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z" />
        </SvgIcon>
      ),
    },
    // {
    //   key: 'regions',
    //   name: 'Regions',
    //   link: '',
    //   icon: (
    //     <SvgIcon color="primary" fontSize="large">
    //       <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    //     </SvgIcon>
    //   )
    // }
  ];
  const [usefulLinks, setUsefulLinks] = React.useState([]);
  React.useEffect(() => {
    axios.get(urls.getUsefulLinks("system")).then((resp) => {
      if (resp.status === 200) {
        setUsefulLinks(resp.data.row);
      }
    });
  }, []);
  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={11}>
        <Grid container justify="center" spacing={2}>
          {items.map((item) => (
            <Grid key={item.key} item xs={6} sm={4} md={2}>
              <Card className={classes.card}>
                <CardActionArea onClick={() => props.history.push(item.link)}>
                  <CardContent>
                    <div className={classes.iconWrapper}>{item.icon}</div>
                    <Typography component="h5" align="center">
                      {item.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        {usefulLinks.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <UserfulHelpLink helpLinks={usefulLinks} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default withRouter(System);
