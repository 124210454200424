import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import Select from '@material-ui/core/Select';

import * as actions from '../../store/actions';
import { urls } from '../../common/urls';
import axios from 'axios';
import moment from 'moment';

const headRows = [
  { id: 'id', numeric: false, label: 'ID', sortable: true },
  { id: 'name', numeric: false, label: 'Name', sortable: true },
  { id: 'description', numeric: false, label: 'Description', sortable: true },
  { id: 'level_id', numeric: false, label: 'Level', sortable: true },
  { id: 'year', numeric: false, label: 'Year', sortable: true },
  { id: 'is_active', numeric: false, label: 'Active', sortable: true }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  formroot: {
    width: '100%'
  },
  table: {
    minWidth: 700,
    marginTop: 20
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto',
    flexWrap: 'wrap'
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  searchField: {
    width: 240
  },
  inputField: {
    width: 220
  },
  fabAdd: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1)
  }
}));

function MediaGroupList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [mediaGroups, setMediaGroups] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [searchText, setSearchText] = useState('');
  const [filterLevel, setFilterLevel] = useState('');

  useEffect(() => {
    let filters = localStorage.getItem('filter.mediagroups');
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setFilterLevel(filters.filterLevel);
    }
  }, []);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        filterLevel: filterLevel
      };
      await localStorage.setItem('filter.mediagroups', JSON.stringify(filters));
      await fetchMediaGroups(filters);
    })();
  }, [searchText, page, rowsPerPage, orderBy, order, filterLevel]);

  const fetchMediaGroups = async params => {
    let resp = await axios.post(urls.getMediaGroups, params);
    if (resp.status === 200 && resp.data.error === false) {
      await setMediaGroups(resp.data.rows);
      setTotal(resp.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  function handleSorting(_orderBy) {
    setOrder(order == 'desc' ? 'asc' : 'desc');
    setOrderBy(_orderBy);
  }

  function handleLevelChange(event) {
    setFilterLevel(event.target.value ? parseInt(event.target.value) : '');
  }

  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <Container>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ alignItems: 'flex-end', display: 'flex' }}
              >
                <Typography variant="h6" id="tableTitle">
                  Media Groups
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="media-type-select">
                    Qualification Level
                  </InputLabel>
                  <Select
                    value={filterLevel ? filterLevel : ''}
                    onChange={handleLevelChange}
                    inputProps={{
                      name: 'media-level',
                      id: 'media-level-select'
                    }}
                  >
                    {props.levels &&
                      props.levels.map(level => (
                        <MenuItem key={level.id} value={level.id}>
                          {level.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <IconButton
                  aria-label="Clear Search"
                  onClick={() => setFilterLevel('')}
                  size="small"
                  className={classes.clearBtn}
                >
                  {filterLevel ? (
                    <ClearIcon fontSize="small" color="secondary" />
                  ) : null}
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.actions}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl>
                    <InputLabel htmlFor="seartch-text">{`Search in media group`}</InputLabel>
                    <Input
                      id="seartch-text"
                      value={searchText}
                      onChange={event => setSearchText(event.target.value)}
                      className={classes.inputField}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            size="small"
                            onClick={() => setSearchText('')}
                          >
                            {searchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map(row => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? 'none' : 'inherit'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              mediaGroups.map(item => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    {item.level ? item.level.name : item.level_id}
                  </TableCell>
                  <TableCell>
                    {item.year
                      ? moment(item.year, 'YYYY-MM-DD').format('YYYY')
                      : ''}
                  </TableCell>
                  <TableCell>{item.is_active ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/media-group/list/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!loading && mediaGroups.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Zoom
        className={classes.fabAdd}
        key="add"
        in={true}
        style={{ transitionDelay: '500ms' }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          className="classes.fab"
          color="secondary"
          onClick={() => props.history.push(`/media-group/create`)}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Container>
  );
}
const mapStateToProps = state => {
  return {
    levels: state.common.levels
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MediaGroupList);
