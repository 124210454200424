import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Button from '@material-ui/core/Button';
import AlertDialog from '../components/AlertDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TextField from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import lightGreen from '@material-ui/core/colors/lightGreen';

import { Document, Page, pdfjs } from 'react-pdf';
import { urls } from '../common/urls';
import * as actions from '../store/actions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  content: {
    paddingBottom: theme.spacing(1)
  }
}));

export default function ViewFile(props) {
  const classes = useStyles();
  let videoRef = React.createRef();
  const [openFileDialog, setOpenFileDialog] = React.useState('');
  const [loading, setLoading] = useState(true);
  const [openFileViewer, setOpenFileViewer] = React.useState(false);
  const [fileSingedUrl, setFileSingedUrl] = React.useState('');
  const [viewFile, setViewFile] = React.useState({});
  const [numPages, setNumPages] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      handleViewMediaFile();
      setLoading(false);
    };
    fetchData();
  }, [props.item]);

  // handle PDF Load
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  // Handle View Media file
  async function handleViewMediaFile() {
    setViewFile(props.item);
    await axios
      .post(urls.getSignedUrl, { path: props.item.path })
      .then(resp => {
        if (!resp.data.error) {
          setFileSingedUrl(resp.data.url);
          if (props.item.type === 'mp4') {
            videoRef.current.src = resp.data.url;
            videoRef.current.play();
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
    setOpenFileViewer(true);
  }

  // On Closing the Media File Dialog
  function onFileDialogExiting() {
    if (viewFile.type === 'mp4') {
      videoRef.current.pause();
      videoRef.current.src = '';
    }
    props.onClose();
    setViewFile({});
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'lg'}
        open={openFileViewer}
        keepMounted
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        onClose={() => setOpenFileViewer(false)}
        onExiting={onFileDialogExiting}
        aria-labelledby="file-viewer-slide-title"
        aria-describedby="file-viewer-slide-description"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="file-viewer-slide-title"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{viewFile.filename}</Typography>
            <div>
              <Link
                href={fileSingedUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  aria-label="close"
                  size="small"
                  color="primary"
                  className={classes.closeButton}
                >
                  <OpenInNewIcon size="small" />
                </IconButton>
              </Link>
              <IconButton
                aria-label="close"
                size="small"
                className={classes.closeButton}
                onClick={() => setOpenFileViewer(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        </DialogTitle>
        <DialogContent>
          {viewFile.type == 'mp4' && (
            <video
              ref={videoRef}
              src={fileSingedUrl}
              controls
              height="300"
              width="400"
              type="video/mp4"
            ></video>
          )}
          {['jpg', 'jpeg', 'png'].indexOf(viewFile.type) !== -1 && (
            <img src={fileSingedUrl} alt={viewFile.filename} width="100%" />
          )}
          {viewFile.type == 'pdf' && fileSingedUrl && (
            <Document
              file={fileSingedUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={700}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          )}
          {viewFile.type != 'pdf' &&
            viewFile.type != 'mp4' &&
            fileSingedUrl && <a href={fileSingedUrl}>Click To Download File</a>}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
