import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";

import * as actions from "../../store/actions";
import urls from "../../common/urls";

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "name", numeric: false, label: "Code", sortable: true },
  { id: "title", numeric: false, label: "Title", sortable: true },
  {
    id: "assessment_type",
    numeric: false,
    label: "Assessment Type",
    sortable: true,
  },
  {
    id: "short_desc",
    numeric: false,
    label: "Qualification Level",
    sortable: true,
  },
  {
    id: "year",
    numeric: false,
    label: "Year",
    sortable: true,
  },
  { id: "pass_grade", numeric: false, label: "Pass Grade", sortable: true },
  {
    id: "pass_grade_type",
    numeric: false,
    label: "Grade Type",
    sortable: true,
  },
  { id: "is_active", numeric: false, label: "Active", sortable: true },
  { id: "is_optional", numeric: false, label: "Optional", sortable: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    marginTop: 20,
  },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  searchField: {
    width: 240,
  },
  inputField: {
    width: 220,
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
}));
function ModuleList(props) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [filterAsstType, setFilterAsstType] = useState("");

  useEffect(() => {
    let filters = localStorage.getItem("filter.modules");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setFilterAsstType(filters.filterAsstType);
    }
  }, []);

  useEffect(() => {
    let filters = {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      order: order,
      searchText: searchText,
      filterAsstType: filterAsstType,
    };
    localStorage.setItem("filter.modules", JSON.stringify(filters));
    function getModulesData() {
      props.onGetModules(filters);
    }
    getModulesData();
  }, [searchText, page, rowsPerPage, orderBy, order, filterAsstType]);

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }

  function handleTypeChange(event) {
    setFilterAsstType(event.target.value ? parseInt(event.target.value) : "");
  }

  return (
    <Container>
      <Paper className={classes.root}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" id="tableTitle">
                Assessment Modules
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="asst-type-select">
                    Assessment Type
                  </InputLabel>
                  <Select
                    value={filterAsstType ? filterAsstType : ""}
                    onChange={handleTypeChange}
                    inputProps={{
                      name: "asst-type",
                      id: "asst-type-select",
                    }}
                  >
                    {props.assessmentTypes &&
                      props.assessmentTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <IconButton
                  aria-label="Clear Search"
                  onClick={() => setFilterAsstType("")}
                  size="small"
                  className={classes.clearBtn}
                >
                  {filterAsstType ? (
                    <ClearIcon fontSize="small" color="secondary" />
                  ) : null}
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className={classes.search}>
                <SearchIcon />
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="seartch-text">{`Search in Assessment Module`}</InputLabel>
                  <Input
                    id="seartch-text"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Clear Search"
                          style={{ marginRight: -12 }}
                          onClick={() => setSearchText("")}
                        >
                          {searchText.length > 0 ? (
                            <ClearIcon fontSize="small" color="secondary" />
                          ) : null}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? "none" : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.modules &&
              !props.modules.loading &&
              props.modules.rows.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>
                    {item.AssmtType ? item.AssmtType.name : ""}
                  </TableCell>
                  <TableCell>{item.level.short_desc}</TableCell>
                  <TableCell>{item.year}</TableCell>
                  <TableCell>{item.pass_grade}</TableCell>
                  <TableCell>{item.pass_grade_type}</TableCell>
                  <TableCell>{item.is_active ? "Yes" : "No"}</TableCell>
                  <TableCell>{item.is_optional ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/qualifications/modules/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!props.modules.loading && props.modules.total == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!props.modules.loading && props.modules.total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.modules.total}
            rowsPerPage={rowsPerPage}
            page={props.modules.total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={(event) =>
              setRowsPerPage(parseInt(event.target.value, 10))
            }
          />
        )}
      </Paper>
      <Zoom
        className={classes.fabAdd}
        key="add"
        in={true}
        style={{ transitionDelay: "500ms" }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          className="classes.fab"
          color="secondary"
          onClick={() => props.history.push(`/qualifications/modules/create`)}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    modules: state.modules,
    assessmentTypes: state.common.assessmentTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetModules: (params) => dispatch(actions.getModules(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModuleList);
