import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utility';

const initialState = {
  rows: [],
  loading: false,
  error: null,
  errorText: ''
};

const getNoticesStart = (state, action) => {
  return updateObject(state, { error: null, status: null, loading: true });
};

const getNoticesSuccess = (state = initialState, action) => {
  return updateObject(state, {
    alerts: action.payload.alerts,
    alertCount: action.payload.alertCount,
    announcements: action.payload.announcements,
    announcementCount: action.payload.announcementCount,
    total: action.payload.total,
    offset: action.payload.offset,
    limit: action.payload.limit,
    orderBy: action.payload.orderBy,
    order: action.payload.order,
    menuOptions: action.payload.menuOptions,
    error: false,
    errorText: '',
    loading: false
  });
};

const getNoticesFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.payload.errorText,
    status: action.payload.status,
    loading: false
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTICES_START:
      return getNoticesStart(state, action);
    case actionTypes.GET_NOTICES_SUCCESS:
      return getNoticesSuccess(state, action);
    case actionTypes.GET_NOTICES_FAIL:
      return getNoticesFail(state, action);
    default:
      return state;
  }
};
