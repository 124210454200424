import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as actions from '../../store/actions';
import { urls } from '../../common/urls';

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    padding: theme.spacing(1, 0, 0, 0)
  },
  container: {
    paddingTop: '100px',
    background: theme.palette.background.default,
    paddingBottom: theme.spacing(4)
  },
  title: {
    fontSize: 18
  },
  menu: {
    width: 200
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  button: {
    marginLeft: theme.spacing(2),
    position: 'relative'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  learnerList: {
    marginTop: theme.spacing(4)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function IssuedMedia(props) {
  const classes = useStyles();
  const [medias, setMedias] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  useEffect(() => {
    if (props.openIssuedMedia === true) {
      axios
        .post(urls.getCourseIssuedMedias(props.course.id), {
          course_id: props.course.id,
          showList: true
        })
        .then(resp => {
          if (resp.status === 200 && resp.data.error === false) {
            setMedias(resp.data.medias);
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        });
    }
  }, [props.openIssuedMedia]);

  const handleDownload = async id => {
    try {
      setDownloading(true);
      let resp = await axios.post(urls.getCourseIssuedMedias(props.course.id), {
        centreNo: props.course.centre.number,
        fileId: id
      });
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
        window.open(resp.data.url);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
      setDownloading(false);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  const handleSendEmail = async () => {
    try {
      setSendingEmail(true);
      let resp = await axios.post(urls.sendCourseMediaTo(props.course.id), {
        action: 'CENTRE_EMAIL'
      });
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
      setSendingEmail(false);
    } catch (error) {
      setSendingEmail(false);
    }
  };
  return (
    <Dialog
      fullScreen
      open={props.openIssuedMedia}
      onClose={props.onCloseIssuedMedia}
      TransitionComponent={Transition}
      className={classes.root}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.title}>
              Media Issued for Course #{props.course.id}
            </Typography>
          </Box>
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onCloseIssuedMedia}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={10}>
          <Grid container className={classes.learnerList}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Box pl={2} pr={2}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom>
                        Media
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <span className={classes.buttonWrapper}>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={classes.button}
                            disabled={sendingEmail}
                            onClick={handleSendEmail}
                          >
                            Mail Centre
                          </Button>
                          {sendingEmail && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="course media"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="left">File</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {medias.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">{row.file_path}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            edge="start"
                            color="primary"
                            onClick={() => handleDownload(row.id)}
                            aria-label="download"
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    mediaTypes: state.common.mediaTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IssuedMedia)
);
