export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

/* Common  */
export const GET_COMMON = 'GET_COMMON';
export const GET_COMMON_START = 'GET_COMMON_START';
export const GET_COMMON_SUCCESS = 'GET_COMMON_SUCCESS';
export const GET_COMMON_FAIL = 'GET_COMMON_FAIL';

export const GET_NOTICES = 'GET_NOTICES';
export const GET_NOTICES_START = 'GET_NOTICES_START';
export const GET_NOTICES_SUCCESS = 'GET_NOTICES_SUCCESS';
export const GET_NOTICES_FAIL = 'GET_NOTICES_FAIL';

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const GET_AUTH_USER = 'GET_AUTH_USER';
export const GET_AUTH_USER_START = 'GET_AUTH_USER_START';
export const GET_AUTH_USER_SUCCESS = 'GET_AUTH_USER_SUCCESS';
export const GET_AUTH_USER_FAIL = 'GET_AUTH_USER_FAIL';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

/** Live Exam */
export const AUTH_EXAM = 'AUTH_EXAM';
export const AUTH_EXAM_START = 'AUTH_EXAM_START';
export const AUTH_EXAM_SUCCESS = 'AUTH_EXAM_SUCCESS';
export const AUTH_EXAM_FAIL = 'AUTH_EXAM_FAIL';
export const AUTH_INITIATE_EXAM_LOGOUT = 'AUTH_INITIATE_EXAM_LOGOUT';
export const AUTH_EXAM_LOGOUT = 'AUTH_EXAM_LOGOUT';
export const AUTH_EXAM_CHECK_STATE = 'AUTH_EXAM_CHECK_STATE';
export const EXAM_ENDED = 'EXAM_ENDED';
export const EXAM_EXTENDED = 'EXAM_EXTENDED';

/* User */
export const GET_USER = 'GET_USER';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_START = 'UPDATE_USER_ROLE_START';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';

/* Roles & Permissions */
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_START = 'GET_PERMISSIONS_START';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL';

export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const UPDATE_PERMISSION_START = 'UPDATE_PERMISSION_START';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAIL = 'UPDATE_PERMISSION_FAIL';

/** Qualifications */
export const GET_QUALIFICATIONS = 'GET_QUALIFICATIONS';
export const GET_QUALIFICATIONS_START = 'GET_QUALIFICATIONS_START';
export const GET_QUALIFICATIONS_SUCCESS = 'GET_QUALIFICATIONS_SUCCESS';
export const GET_QUALIFICATIONS_FAIL = 'GET_QUALIFICATIONS_FAIL';

export const GET_MODULES = 'GET_MODULES';
export const GET_MODULES_START = 'GET_MODULES_START';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_FAIL = 'GET_MODULES_FAIL';

/** Uploader */
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOADING_CANCELLED = 'UPLOADING_CANCELLED';
export const UPLOADING_FINISHED = 'UPLOADING_FINISHED';

/** Centre */
export const GET_CENTRES = 'GET_CENTRES';
export const GET_CENTRES_SUCCESS = 'GET_CENTRES_SUCCESS';
export const GET_CENTRES_FAIL = 'GET_CENTRES_FAIL';
