export const urls = {
  common: '/api/common',
  verifyEmail: '/api/verify-email',
  getRegions: '/api/regions',
  getSignedUrl: '/api/signed/url',
  getUsefulLinks: id => `/api/useful-links/${id}`,
  dashboard: '/api/dashboard',
  dashboardAssessments: '/api/dashboard/assessments',
  dashboardApprovals: '/api/dashboard/approvals/pending',
  notices: '/api/notices',
  login: '/api/login',
  logout: '/api/logout',
  authUser: '/api/auth/user',
  users: '/api/system/users',
  getUser: id => `/api/system/user/${id}`,
  updateUser: id => `/api/system/user/${id}/update`,
  updateUserRole: id => `/api/system/user/${id}/update/role`,
  createUser: `/api/system/users/create`,
  storeUser: `/api/system/user/new/store`,
  getRoles: '/api/system/roles',

  getApprovals: '/api/approvals',
  getApproval: id => `/api/approvals/${id}`,
  getApprovalStats: `/api/approval/stats`,
  approveEntity: id => `/api/approvals/${id}/approve`,

  updatePermission: '/api/system/role/update/permission',
  getQualifications: '/api/qualifications/list',
  getQualification: id => `/api/qualifications/${id}`,
  updateQualification: id => `/api/qualifications/${id}/update`,
  createQualification: `/api/qualification/create`,
  createQualificationNewVersion: id => `/api/qualifications/${id}/new-version`,
  getQualificationDoc: id => `/api/qualifications/docs/${id}`,
  updateQualificationDoc: `/api/qualifications/update/docs`,
  uploadQualificationDoc: `/api/qualifications/upload/docs/`,
  removeQualificationDoc: id => `/api/qualifications/docs/${id}/remove`,
  getUnits: id => `/api/qualifications/${id}/units`,
  updateUnit: id => `/api/qualifications/${id}/units/update`,
  createUnit: id => `/api/qualifications/${id}/units/create`,
  removeUnit: id => `/api/qualifications/${id}/units/remove`,
  addModuleToUnit: id => `/api/qualifications/${id}/units/add/module`,
  removeModuleFromUnit: id => `/api/qualifications/${id}/units/remove/module`,
  getModules: '/api/qualification/modules',
  getModule: id => `/api/qualifications/modules/${id}`,
  updateModule: id => `/api/qualifications/modules/${id}/update`,
  createModule: `/api/qualifications/modules/create/new`,
  getModuleMarksheet: id => `/api/qualifications/modules/${id}/forms/marksheet`,

  getMedias: `/api/medias/list`,
  getMedia: id => `/api/medias/${id}`,
  updateMedia: `/api/media/update`,
  uploadMediaUrl: id => `/api/medias/${id}/upload/url`,
  uploadMediaFile: id => `/api/medias/${id}/upload/file`,
  updateMediaRegion: id => `/api/medias/${id}/update/region`,
  getMediaForms: id => `/api/media/forms/${id}`,
  deleteMediaFile: id => `/api/media/delete/file/${id}`,
  getMediaFile: id => `/api/media/get/file/${id}`,
  addMediaFormToDvd: id => `/api/medias/${id}/associate/form`,

  getMediaGroups: `/api/media-groups/list`,
  createMediaGroup: `/api/media-groups/create`,
  getMediaGroup: id => `/api/media-groups/${id}`,
  updateMediaGroup: id => `/api/media-group/${id}/update`,
  addMediaToMediaGroup: id => `/api/media-group/${id}/associate/media`,
  getGroupAssociatedMedia: id => `/api/media-group/${id}/associated-media`,
  getMediaGroupsByLevel: id => `/api/level/${id}/media-groups`,

  getCentreNames: `/api/centres/names`,
  getCentres: `/api/centres/list`,
  getCentre: id => `/api/centres/list/${id}`,
  getCentreRequest: id => `/api/request/centre/${id}`,
  updateCentre: id => `/api/centres/list/${id}/update`,
  updateCentreAddress: id => `/api/centres/list/${id}/update/address`,
  createCentre: `/api/centre/create`,
  associateTeacher: id => `/api/centres/list/${id}/associate/teacher`,

  updateCentreLevel: id => `/api/centres/list/${id}/update/level`,
  getCentreQualifications: id => `/api/centres/${id}/qualifications`,
  getCentreQualTeacher: id => `/api/centres/${id}/qualification/teachers`,
  getCentreCourses: id => `/api/centres/list/${id}/courses`,
  getCentreDocuments: id => `/api/centres/${id}/policy/documents`,
  updateCentreDocuments: id => `/api/centres/${id}/upload/documents`,
  requestCentreApproval: id => `/api/centres/${id}/request/approval`,

  getTeachers: `/api/teachers`,
  getTeacher: id => `/api/teachers/${id}`,
  updateTeacher: id => `/api/teachers/${id}/update`,
  createTeacher: `/api/teacher/create`,
  updateTeacherLevel: id => `/api/teachers/${id}/update/level`,
  approveTeacher: id => `/api/teachers/${id}/approve`,
  getTeacherDocuments: id => `/api/teachers/${id}/documents`,
  updateTeacherDocuments: id => `/api/teachers/${id}/upload/documents`,

  getExaminerList: `/api/examiners-list`,
  getExaminers: `/api/examiners`,
  getExaminer: id => `/api/examiners/${id}`,
  updateExaminer: id => `/api/examiners/${id}/update`,
  updateExaminerLevel: id => `/api/examiners/${id}/update/level`,
  updateFeeException: id => `/api/examiners/${id}/update/fee-exception`,
  deleteFeeException: id => `/api/examiners/${id}/delete/fee-exception`,

  getCourses: `/api/courses`,
  getCourseStats: `/api/courses/stats`,
  getCourse: id => `/api/courses/${id}`,
  createCourse: `/api/course/create`,
  updateCourse: id => `/api/courses/${id}/update`,
  getCourseConfig: `/api/course/config`,
  searchLearners: `/api/search/learners`,
  getCourseLearners: id => `/api/courses/${id}/registered-learners`,
  addCourseLearner: id => `/api/courses/${id}/add-learner`,
  removeCourseLearner: id => `/api/courses/${id}/remove-learner`,
  requestCourseApproval: id => `/api/courses/${id}/request-approval`,
  requestCourseReRegistration: id =>
    `/api/courses/${id}/request-reregistration`,
  approveCourse: id => `/api/courses/${id}/approve`,
  getCourseModules: id => `/api/courses/${id}/modules`,
  issueCourseMedia: id => `/api/courses/${id}/issue-media`,
  getCourseIssuedMedias: id => `/api/courses/${id}/issued-media`,
  getCourseLearnerMarks: id => `/api/courses/${id}/learner-marks`,
  sendCourseMediaTo: id => `/api/courses/${id}/issued-media/email`,

  getAssessments: `/api/assessments`,
  getAssessmentStats: `/api/assessments/stats`,
  getAssessment: id => `/api/assessments/${id}`,
  createAssessment: `/api/assessment/create`,
  getAssessmentConfig: `/api/assessment/config`,
  updateAssessment: id => `/api/assessments/${id}/update`,
  getAssessmentLearners: id => `/api/assessments/${id}/registered-learners`,
  addAssessmentLearner: id => `/api/assessments/${id}/add-learner`,
  removeAssessmentLearner: id => `/api/assessments/${id}/remove-learner`,
  requestAssessmentApproval: id => `/api/assessments/${id}/request-approval`,
  approveAssessment: id => `/api/assessments/${id}/approve`,
  issueAssessmentMedia: id => `/api/assessments/${id}/issue-media`,
  getAssessmentIssuedMedia: id => `/api/assessments/${id}/issued-media`,
  sendAssessmentMediaTo: id => `/api/assessments/${id}/issued-media/email`,
  updateAssessmentMarksheet: id => `/api/assessments/${id}/marksheet/update`,
  getAssessmentMark: id => `/api/assessments/${id}/marksheet/marks`,
  setLearnerAbsent: id => `/api/assessments/${id}/learner/absent`,
  updateLearnerMarks: id => `/api/assessments/${id}/learner/marks`,
  updateLearnerEvidence: id => `/api/assessments/${id}/learner/evidence/update`,
  getLearnerEvidences: id => `/api/assessments/${id}/learner/evidences`,
  getEvidenceFileSignedUrl: id =>
    `/api/assessments/${id}/learner/evidence/signedurl`,
  updateAssessmentAaf: id => `/api/assessments/${id}/upload/aaf`,
  getAafFileSignedUrl: id => `/api/assessments/${id}/aaf/signedurl`,
  evidenceComplete: id => `/api/assessments/${id}/evidence/complete`,
  updateAssessmentStatus: id => `/api/assessments/${id}/update/status`,

  getLearners: `/api/learners`,
  getLearner: id => `/api/learners/${id}`,
  updateLearner: id => `/api/learners/${id}/update`,
  submitRaRequest: id => `/api/learners/${id}/ra-request`,
  getRaEvidenceUrl: id => `/api/learner/ra-request/${id}/evidence-url`,
  createLearner: `/api/learner/create`,

  getannualFeestats: `/api/account/annual-fee/stats`,
  getannualFees: `/api/account/annual-fees`,
  getannualFee: id => `/api/account/centre/${id}/annual-fees`,
  updatePayment: id => `/api/account/centre/${id}/annual-fee/update`,
  //getannualFee: id => `/api/account/annual-fees/${id}`,
  getAnnualFeePayments: id => `/api/account/annual-fees/${id}/payments`,
  deletePayment: id => `/api/account/annual-fees/${id}/payments/delete`,

  getAssessmentFeestats: `/api/account/assessment-fee/stats`,
  getassessmentFees: `/api/account/assessment-fees`,
  getassessmentFee: id => `/api/account/assessment-fees/${id}`,
  updateAssessmentFeePayment: id =>
    `/api/account/assessment-fees/${id}/payment/update`,

  authLiveExam: `/api/live-assessment/auth`,
  validateLiveExamOtp: `/api/live-assessment/validate`,
  getLiveExamMediaSignedUrl: `/api/live-assessment/media/signedUrl`,
  extendExam: `/api/live-assessment/extend`
};
