import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";

import "date-fns";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import Autocomplete from "../../components/IntegrationDownshift";
import AlertDialog from "../../components/AlertDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
}));
const assessmentFields = {
  id: "",
  assessment_no: "",
  assessment_type: "",
  centre_id: "",
  course_id: "",
  qualification_id: "",
  unit_id: "",
  module_id: "",
  title: "",
  assessment_date: null,
  assessment_time: null,
  teacher_id: "",
  examiner_id: "",
  marker_examiner_id: "",
  marking_rate: "",
  marking_rate_type: 0,
  marking_due_date: null,
  approved_at: "",
  approved_by: "",
  is_resit: "",
  status: "",
};
function AssessmentApproval(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({});
  const [errorData, setErrorData] = useState(false);
  const [assessment, setAssessment] = useState(assessmentFields);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [centreNames, setCentreNames] = useState([]);
  const [course, setCourse] = useState([]);
  const [centreCourses, setCentreCourses] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [examiners, setExaminers] = useState([]);
  const [rateTypes, setRateTypes] = useState([]);
  const [openRejectedAlert, setOpenRejectedAlert] = React.useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchAssessment();
      await fetchCentreNames();
      setLoading(false);
    };
    fetchData();
  }, []);

  /** Fetch Assessment */
  function fetchAssessment() {
    axios
      .post(urls.getAssessment(props.approval.entity_id))
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          setAssessment(resp.data.assessment);
          setSteps(resp.data.steps);
          setRateTypes(resp.data.rateTypes);
          fetchCentreCourses(resp.data.assessment.centre_id);
          fetchCourseModules(resp.data.assessment.course_id);
          fetchExaminers(
            resp.data.assessment.qualification.level_id,
            resp.data.assessment.module_id
          );
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }
  /** Fetch Centres */
  function fetchCentreNames() {
    axios.get(urls.getCentreNames).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        const centres = await resp.data.centres.map((item) => ({
          id: item.id,
          label: `${item.name} (${item.number})`,
        }));
        setCentreNames(centres);
      }
    });
  }
  /** Fetch Courses for selected Centre */

  function fetchCentreCourses(centerId) {
    axios.get(urls.getCentreCourses(centerId)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setCentreCourses(resp.data.courses);
        if (resp.data.courses.length === 0) {
          props.onEnqueueSnackbar(
            "No courses available to book assessment ",
            3000
          );
        }
      }
    });
  }
  /** Fetch Module for selected  */
  function fetchCourseModules(courseId) {
    axios.post(urls.getCourseModules(courseId)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setCourseModules(resp.data.modules);
        if (resp.data.modules.length === 0) {
          props.onEnqueueSnackbar(
            "No assessment modules available to book assessment ",
            3000
          );
        }
      }
    });
  }
  /** Fetch Examiners */
  const fetchExaminers = async (levelId, moduleId) => {
    let resp = await axios.post(urls.getExaminerList, { levelId, moduleId });
    if (resp.status === 200 && resp.data.error === false) {
      await setExaminers(resp.data.rows);
    }
  };
  /** Handle Field Change */
  const handleChange = (name) => async (e) => {
    setAssessment({ ...assessment, [name]: e.target.value });
    delete errorData[name];
    if (name === "course_id") {
      fetchCourseModules(e.target.value);
      let course = centreCourses.find((c) => c.id === e.target.value);
      setCourse(course);
    }
  };
  /** get the selected centre */
  const selectedCentre = (id) => {
    let c = centreNames.filter((item) => item.id === id);
    return c.length ? c[0] : null;
  };
  /** handle Course field change */
  function handleCentreChange(id) {
    selectedCentre(id);
    setAssessment({ ...assessment, centre_id: id });
    delete errorData.centre_id;
    fetchCentreCourses(id);
  }
  /** handle Course field change */
  const handleMExaminerChange = async (e) => {
    let examinerArr = await examiners.filter(
      (item) => item.id === e.target.value
    );
    let feeException = examinerArr[0].examiner_fee_exceptions.filter(
      (item) =>
        item.level_id === assessment.qualification.level_id &&
        item.module_id === assessment.module_id
    );
    let { default_rate: marking_rate, default_rate_type: marking_rate_type } =
      examinerArr.length > 0 ? examinerArr[0] : {};
    if (feeException.length > 0) {
      marking_rate = feeException[0].rate;
      marking_rate_type = feeException[0].rate_type;
    }
    setAssessment({
      ...assessment,
      marker_examiner_id: e.target.value,
      marking_rate: marking_rate,
      marking_rate_type: marking_rate_type,
    });
    delete errorData.examiner_id;
  };
  /** Clear Centre Selection */
  function handleClearCentre() {
    setAssessment({
      ...assessment,
      centre_id: "",
      course_id: "",
      module_id: "",
    });
  }
  /** Handle Assessment Date Field Change */
  const handleDateChange = (name) => (e) => {
    setAssessment({
      ...assessment,
      [name]: name === "assessment_time" ? e : e.format("L"),
    });
    delete errorData[name];
  };
  // Update Assessment
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.updateAssessment(props.approval.entity_id), {
        assessment: assessment,
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.approval.entity_id) && resp.data.result) {
            props.history.push(`/courses/list/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }

  /**
   *  Send for Approval
   */
  async function hanleApproval(agree, approve) {
    setOpenApprovalAlert(false);
    if (agree && !approve && rejectionReason === "") {
      props.onEnqueueSnackbar("Provide rejection reason!!", 1000, "error");
      return;
    }
    setOpenRejectedAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.approveAssessment(props.approval.entity_id),
        { approvalId: props.approval.id, approve, rejectionReason }
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar("Success!!", 1000, "success");
        props.onUpdate();
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {props.approval.status === props.statusCodes.APPROVED && (
              <React.Fragment>
                <Chip color="primary" icon={<DoneIcon />} label="Approved" />
                <div>Approved by {props.approval.user.name}</div>
                <div>
                  Last Updated at{" "}
                  {props.approval.updatedAt
                    ? moment(props.approval.updatedAt).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : ""}
                </div>
              </React.Fragment>
            )}
            {props.approval.status === props.statusCodes.REJECTED && (
              <React.Fragment>
                <Chip color="secondary" label="Rejected" />
                <div>Rejected by {props.approval.user.name}</div>
                <div>
                  Last Updated at{" "}
                  {props.approval.updatedAt
                    ? moment(props.approval.updatedAt).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : ""}
                </div>
              </React.Fragment>
            )}

            <Box display="flex" justifyContent="flex-end">
              {props.approval.status < props.statusCodes.REJECTED && (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={assessment.status > 200}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    onClick={() => setOpenRejectedAlert(true)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={() => setOpenApprovalAlert(true)}
                  >
                    Approve
                  </Button>
                </React.Fragment>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot}>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography className={classes.title} color="textSecondary">
                  {`Assessment # ${assessment.assessment_no}`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="assessment-title"
                    label="Title"
                    className={classes.textField}
                    value={assessment.title}
                    onChange={handleChange("title")}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("title")}
                    helperText={errorData.title}
                  />
                  <Autocomplete
                    label="Centre"
                    placeholder="Search in Centre"
                    data={centreNames}
                    onSelect={(selected) => handleCentreChange(selected.id)}
                    onClear={() => handleClearCentre()}
                    selectedItem={selectedCentre(assessment.centre_id)}
                    error={errorData.hasOwnProperty("centre_id")}
                    helperText={errorData.centre_id}
                  />
                  <TextField
                    id="select-courses"
                    select
                    label="Course"
                    className={classes.textField}
                    value={assessment.course_id}
                    onChange={handleChange("course_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("course_id")}
                    helperText={errorData.course_id}
                  >
                    {centreCourses &&
                      centreCourses.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    id="select-modules"
                    select
                    label="Module"
                    className={classes.textField}
                    value={assessment.module_id}
                    onChange={handleChange("module_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("module_id")}
                    helperText={errorData.module_id}
                  >
                    {courseModules &&
                      courseModules.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.name} - ${item.title}`}
                        </MenuItem>
                      ))}
                  </TextField>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label="Assessment Date"
                          margin="normal"
                          format="DD/MM/YYYY"
                          value={assessment.assessment_date || null}
                          onChange={handleDateChange("assessment_date")}
                          error={errorData.hasOwnProperty("assessment_date")}
                          helperText={errorData.assessment_date}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Assessment Time"
                          value={assessment.assessment_time || null}
                          onChange={handleDateChange("assessment_time")}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          error={errorData.hasOwnProperty("assessment_date")}
                          helperText={errorData.assessment_date}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <TextField
                    id="assessment-address"
                    label="Address"
                    className={classes.textField}
                    value={assessment.address}
                    onChange={handleChange("address")}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("address")}
                    helperText={errorData.address}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="assessment-city"
                    label="City"
                    className={classes.textField}
                    value={assessment.city}
                    onChange={handleChange("city")}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("city")}
                    helperText={errorData.city}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="assessment-postcode"
                    label="Postcode"
                    className={classes.textField}
                    value={assessment.postcode}
                    onChange={handleChange("postcode")}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("postcode")}
                    helperText={errorData.postcode}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="select-teacher"
                    select
                    label="Teacher"
                    className={classes.textField}
                    value={assessment.teacher_id}
                    onChange={handleChange("teacher_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("teacher_id")}
                    helperText={errorData.teacher_id}
                    InputLabelProps={{ shrink: true }}
                  >
                    {centreCourses &&
                      centreCourses.map((item) =>
                        item.id === assessment.course_id && item.teacher ? (
                          <MenuItem
                            key={item.teacher.id}
                            value={item.teacher.id}
                          >
                            {item.teacher.name}
                          </MenuItem>
                        ) : null
                      )}
                  </TextField>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <TextField
                  id="select-examiner"
                  select
                  label="Examiner"
                  className={classes.textField}
                  value={assessment.examiner_id}
                  onChange={handleChange("examiner_id")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  readOnly
                  inputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  fullWidth={true}
                  error={errorData.hasOwnProperty("examiner_id")}
                  helperText={errorData.examiner_id}
                >
                  {examiners &&
                    examiners
                      .filter((e) => e.external_examiner)
                      .map((item) => {
                        if (item) {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                </TextField>
                <TextField
                  id="select-marking-examiner"
                  select
                  label="Marking Examiner"
                  className={classes.textField}
                  value={assessment.marker_examiner_id}
                  onChange={(e) => handleMExaminerChange(e)}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  readOnly
                  inputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  fullWidth={true}
                  error={errorData.hasOwnProperty("marker_examiner_id")}
                  helperText={errorData.marker_examiner_id}
                >
                  {examiners &&
                    examiners.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    label="Marking Due Date"
                    margin="normal"
                    fullWidth={true}
                    format="DD/MM/YYYY"
                    value={assessment.marking_due_date || null}
                    onChange={handleDateChange("marking_due_date")}
                    error={errorData.hasOwnProperty("marking_due_date")}
                    helperText={errorData.marking_due_date}
                    readOnly
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="assessment-rate"
                      label="Marking Rate"
                      type="number"
                      className={classes.textField}
                      value={assessment.marking_rate}
                      onChange={handleChange("marking_rate")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("marking_rate")}
                      helperText={errorData.marking_rate}
                      readOnly
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="select-modules"
                      select
                      label="Marking Rate Type"
                      className={classes.textField}
                      value={assessment.marking_rate_type}
                      onChange={handleChange("marking_rate_type")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("marking_rate_type")}
                      helperText={errorData.marking_rate_type}
                      readOnly
                      inputProps={{
                        readOnly: true,
                      }}
                    >
                      {rateTypes &&
                        rateTypes.map((item, index) => (
                          <MenuItem key={index} value={index}>
                            {item}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AlertDialog
          title="Approve Assessment"
          description="Autogenerated email will be sent to Centre with the assessment updates."
          open={openApprovalAlert}
          handleAlertClose={(agree) => hanleApproval(agree, true)}
        />
        <Dialog open={openRejectedAlert} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Confirm Assessment Rejection{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Provide a reason for rejection of this assessment. Centre will be
              notified with the rejection message on their registered email.
            </DialogContentText>
            <TextField
              id="rejection-reason"
              label="Reason for Rejection"
              multiline
              rowsMax="4"
              fullWidth={true}
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              className={classes.textField}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => hanleApproval(false, false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => hanleApproval(true, false)}
              color="secondary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    modules: state.common.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentApproval)
);
