import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import "date-fns";
import Gaurd from "../../../hoc/Gaurd";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ViewFile from "../../../components/ViewFile";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import * as actions from "../../../store/actions";
import { urls } from "../../../common/urls";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  doctypeControl: {
    width: "50%",
  },
  statusGroup: {
    flexDirection: "row",
  },
  legend: {
    fontSize: "0.75rem",
  },
  levelLabel: {
    width: 200,
  },
  uploadButton: {
    height: "30px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

function EditTeacher(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [teacher, setTeacher] = useState({
    id: "",
    name: "",
    secondary_phone: "",
    address: "",
    city: "",
    postcode: "",
    centres: [],
    user: {
      email: "",
      phone: "",
    },
    teacher_docs: [],
  });
  const [selectedDoc, setSelectedDoc] = useState("");
  const [documents, setDocuments] = useState("");
  const [errorData, setErrorData] = useState({});
  const [levels, setLevels] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState("");
  const [uploading, setUploading] = useState(false);
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileItem, setFileItem] = useState({});

  // Get Qulification with Docs on Mount
  useEffect(() => {
    fetchTeacher();
    props.getCentreNames();
    fetchTeacherDocuments();
  }, []);

  function fetchTeacher() {
    axios
      .post(urls.getTeacher(props.id))
      .then(async (resp) => {
        if (resp.status == 200 && !resp.data.error) {
          setTeacher(resp.data.teacher);
          setLevels(resp.data.teacher.centre_teacher_levels);
          if (resp.data.teacher.centres.length > 0) {
            setSelectedCentre(resp.data.teacher.centres[0].id);
          }
          setConfig(resp.data.config);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((err) => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }
  // handle form field change
  const handleChange = (name) => (e) => {
    if (name === "email" || name === "phone") {
      setTeacher({
        ...teacher,
        user: { ...teacher.user, [name]: e.target.value },
      });
    } else {
      setTeacher({ ...teacher, [name]: e.target.value });
    }
  };

  // Update Teacher
  function handleSubmit(event) {
    event.preventDefault();
    axios
      .post(urls.updateTeacher(props.id), teacher)
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.id) && resp.data.result) {
            props.history.push(`/teachers/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((error) => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }

  // handle Level Change
  const handleLevelChange = (levelId, levelName) => async (e) => {
    if (!selectedCentre) {
      props.onEnqueueSnackbar(
        "choose a centre to view approved levels",
        3000,
        "warning"
      );
      return;
    }
    let checked = e.target.checked;
    axios
      .post(urls.updateTeacherLevel(props.id), {
        levelId,
        centreId: selectedCentre,
        checked: checked,
        levelName,
        teacherName: `${teacher.name}`,
        showNotification: false,
      })
      .then(async (resp) => {
        let clevels = [];
        let respLevel = resp.data.level;
        if (resp.status === 200 && resp.data.error === false) {
          if (checked) {
            clevels = await teacher.centre_teacher_levels.filter(
              (item) => item.id !== respLevel.id
            );
            clevels.push(respLevel);
            setTeacher({ ...teacher, centre_teacher_levels: clevels });
          } else {
            clevels = await teacher.centre_teacher_levels.filter(
              (item) => item.id !== respLevel.id
            );
            setTeacher({ ...teacher, centre_teacher_levels: clevels });
          }
          props.onEnqueueSnackbar(resp.data.message, 3000, "success");
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((err) => props.onEnqueueSnackbar(err.message, 3000));
  };
  function handleCentreSelect(event) {
    setSelectedCentre(event.target.value);
  }

  function handleDocumentChange(event) {
    setSelectedDoc(event.target.value);
  }

  function fetchTeacherDocuments(deleteId = null) {
    axios
      .post(urls.getTeacherDocuments(props.id), { deleteId })
      .then(async (resp) => {
        if (resp.status == 200 && !resp.data.error) {
          setDocuments(resp.data.rows);
        }
      })
      .catch((err) => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }

  // Handle Media File Upload
  const handleFileUpload = async () => {
    if (props.uploader.uploading) {
      props.onEnqueueSnackbar(
        "Please wait for previous upload to finish",
        3000
      );
      return;
    }
    //let fileName = `${learnername.toString().split(' ').join('-')}`;
    let destination = `media/teacher/${teacher.id}/documents`;
    try {
      let data = {
        url: urls.updateTeacherDocuments(teacher.id),
        uploadType: "Media",
        id: teacher.id,
        uid: Date.now(),
        location: props.history.location.pathname,
        destination: destination,
        documentType: selectedDoc,
      };
      props.uploadFile(data);
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000);
      setUploading(false);
    }
  };
  useEffect(() => {
    if (props.uploader.finished) {
      fetchTeacherDocuments();
    }
  }, [props.uploader.finished]);

  const handleViewFile = (item) => {
    setOpenFileViewer(true);
    setFileItem(item);
  };

  const handleCloseFileViewer = () => {
    setOpenFileViewer(false);
    setFileItem({});
  };

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card>
                {loading && <LinearProgress color="secondary" />}
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {teacher.id ? `Teacher` : `Add New Teacher`}
                  </Typography>
                  <form
                    className={classes.formContainer}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="teacher-name"
                      label="Name"
                      className={classes.textField}
                      value={teacher.name || ""}
                      onChange={handleChange("name")}
                      margin="dense"
                      required={true}
                      fullWidth={true}
                      error={errorData.hasOwnProperty("name")}
                      helperText={errorData.name}
                    />
                    <TextField
                      id="teacher-email"
                      label="Email"
                      className={classes.textField}
                      value={teacher.user ? teacher.user.email : ""}
                      onChange={handleChange("email")}
                      margin="dense"
                      required={true}
                      fullWidth={true}
                      error={errorData.hasOwnProperty("email")}
                      helperText={
                        errorData.email || errorData.users_email_unique
                      }
                    />
                    <TextField
                      id="teacher-phone"
                      label="Phone"
                      className={classes.textField}
                      value={
                        teacher.user && teacher.user.phone
                          ? teacher.user.phone
                          : ""
                      }
                      onChange={handleChange("phone")}
                      margin="dense"
                      required={true}
                      fullWidth={true}
                      error={errorData.hasOwnProperty("phone")}
                      helperText={
                        errorData.phone || errorData.users_phone_unique
                      }
                    />
                    <TextField
                      id="secondary-phone"
                      label="Secondary Phone"
                      className={classes.textField}
                      value={teacher.secondary_phone || ""}
                      onChange={handleChange("secondary_phone")}
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("secondary_phone")}
                      helperText={errorData.secondary_phone}
                    />
                    <TextField
                      id="teacher-address"
                      label="Address"
                      className={classes.textField}
                      value={teacher.address || ""}
                      required={true}
                      onChange={handleChange("address")}
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("address")}
                      helperText={errorData.address}
                    />
                    <TextField
                      id="teacher-city"
                      label="City"
                      className={classes.textField}
                      value={teacher.city || ""}
                      required={true}
                      onChange={handleChange("city")}
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("city")}
                      helperText={errorData.city}
                    />
                    <TextField
                      id="teacher-postcode"
                      label="Postcode"
                      className={classes.textField}
                      value={teacher.postcode || ""}
                      required={true}
                      onChange={handleChange("postcode")}
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("postcode")}
                      helperText={errorData.postcode}
                    />
                  </form>
                </CardContent>
                <Gaurd check="update_teacher">
                  <CardActions>
                    {!isNaN(props.id) && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleSubmit}
                      >
                        Update Teacher
                      </Button>
                    )}
                  </CardActions>
                </Gaurd>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Approved Qualification Levels
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="centre-helper">Centre</InputLabel>
                        <Select
                          value={props.centre.id}
                          //onChange={handleCentreSelect}
                          inputProps={{
                            name: "centre",
                            id: "centre-select",
                          }}
                        >
                          {teacher.centres &&
                            teacher.centres.map((c) =>
                              props.centre.id === c.id ? (
                                <MenuItem key={c.id} value={c.id}>
                                  {c.name}
                                </MenuItem>
                              ) : null
                            )}
                        </Select>
                        <FormHelperText>
                          Some important helper text
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup row>
                          {props.levels &&
                            props.levels.map((level) => {
                              let tlevel =
                                teacher.centre_teacher_levels &&
                                teacher.centre_teacher_levels.filter(
                                  (item) =>
                                    item.level_id == level.id &&
                                    item.centre_id == selectedCentre
                                );
                              if (level.name !== "ALL") {
                                if (tlevel && tlevel.length > 0) {
                                  return (
                                    <FormControlLabel
                                      className={classes.levelLabel}
                                      key={level.id}
                                      control={
                                        <Checkbox
                                          checked={
                                            tlevel[0].approved ===
                                              config.LEVEL_APPROVED ||
                                            tlevel[0].approved ===
                                              config.LEVEL_PENDING_APPROVAL
                                              ? true
                                              : false
                                          }
                                          onChange={handleLevelChange(
                                            level.id,
                                            level.name
                                          )}
                                          color={
                                            tlevel[0].approved ===
                                            config.LEVEL_APPROVED
                                              ? "primary"
                                              : "secondary"
                                          }
                                        />
                                      }
                                      label={level.name}
                                    />
                                  );
                                } else {
                                  return (
                                    <FormControlLabel
                                      className={classes.levelLabel}
                                      key={level.id}
                                      control={
                                        <Checkbox
                                          checked={false}
                                          onChange={handleLevelChange(
                                            level.id,
                                            level.name
                                          )}
                                          color={"primary"}
                                        />
                                      }
                                      label={level.name}
                                    />
                                  );
                                }
                              }
                            })}
                        </FormGroup>
                      </FormControl>
                    </CardContent>
                  </Card>
                  <Box>
                    <Typography variant="caption">
                      ** Color of checkboxes
                    </Typography>
                    <div>
                      <Checkbox color="primary" checked={true} /> Level is
                      Approved
                      <Checkbox color="secondary" checked={true} /> Level is
                      Pending for Approval
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Teacher Documents
                      </Typography>
                      <Box display="flex" justifyContent="space-between">
                        <FormControl className={classes.doctypeControl}>
                          <InputLabel htmlFor="document-simple">
                            Document Type
                          </InputLabel>
                          <Select
                            value={selectedDoc}
                            onChange={(event) => handleDocumentChange(event)}
                            inputProps={{
                              name: "documentType",
                              id: "document-type-simple",
                            }}
                          >
                            {config.documents &&
                              Object.keys(config.documents).map((key) => (
                                <MenuItem key={key} value={key}>
                                  {config.documents[key].title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <Gaurd check="update_teacher">
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.uploadButton}
                            onClick={() => handleFileUpload()}
                          >
                            Upload
                            <CloudUploadIcon className={classes.rightIcon} />
                          </Button>
                        </Gaurd>
                      </Box>

                      <Table size="small" aria-label="Policy Documents">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell align="right">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {documents &&
                            documents.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                  {config.documents &&
                                    config.documents[row.doc_type_id].title}
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    size="small"
                                    color="primary"
                                    className={classes.uploadButton}
                                    onClick={() => handleViewFile(row)}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    size="small"
                                    color="secondary"
                                    className={classes.uploadButton}
                                    onClick={() =>
                                      fetchTeacherDocuments(row.id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openFileViewer && (
        <ViewFile item={fileItem} onClose={() => handleCloseFileViewer()} />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    uploader: state.uploader,
    levels: state.common.levels,
    centreNames: state.centres,
    centre: state.authUser.centre,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    getCentreNames: () => dispatch(actions.getCentres()),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditTeacher)
);
