import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utility';

const initialState = {
  row: {},
  roles: {},
  updatingRole: false,
  error: null,
  errorText: '',
  loading: false,
  updating: false
};

/* Get User */
const getUserStart = (state, action) => {
  return updateObject(state, { error: null, errorText: '', loading: true });
};

const getUserSuccess = (state, action) => {
  return updateObject(state, {
    row: action.payload.user,
    roles: action.payload.roles,
    error: false,
    errorText: '',
    loading: false
  });
};

const getUserFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.error,
    loading: false
  });
};

/* Update User Role  */
const updateUserRoleStart = (state, action) => {
  return updateObject(state, { error: null, errorText: '', updating: true });
};

const updateUserRoleSuccess = (state, action) => {
  return updateObject(state, {
    row: action.payload.user,
    roles: action.payload.roles,
    error: false,
    errorText: '',
    updating: false
  });
};

const updateUserRoleFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.error,
    updating: false
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_START:
      return getUserStart(state, action);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case actionTypes.UPDATE_USER_ROLE_START:
      return updateUserRoleStart(state, action);
    case actionTypes.UPDATE_USER_ROLE_SUCCESS:
      return updateUserRoleSuccess(state, action);
    case actionTypes.UPDATE_USER_ROLE_FAIL:
      return updateUserRoleFail(state, action);
    default:
      return state;
  }
};
