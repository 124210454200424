import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import ErrorPage from "../../../components/ErrorPage";

import { withStyles, createStyles, Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";

const styles = theme =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto"
    },
    table: {
      minWidth: 700,
      marginTop: 20
    },
    spacer: {
      flex: "1 1 100%"
    },
    actions: {
      color: theme.palette.text.secondary
    },
    title: {
      flex: "0 0 auto"
    },
    search: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end"
    },
    iconButton: {
      padding: 10
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4
    },
    searchField: {
      width: 240
    },
    inputField: {
      width: 220
    },
    fabAdd: {
      position: "fixed",
      right: theme.spacing(2),
      bottom: theme.spacing(2)
    }
  });

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "name", numeric: false, label: "Name", sortable: true },
  { id: "email", numeric: false, label: "Email", sortable: true },
  { id: "role", numeric: false, label: "Roles", sortable: false },
  { id: "action", numeric: false, label: "Action", sortable: false }
];

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      offset: 0,
      limit: 10,
      page: 0,
      orderBy: "id",
      order: "asc",
      searchText: "",
      anchorEl: null,
      setAnchorEl: "",
      filterIn: "users"
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleFilterClose = this.handleFilterClose.bind(this);
    this.handleFilterOpen = this.handleFilterOpen.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }
  componentDidMount() {
    const filters = localStorage.getItem("filter.users");
    this.setState(
      { anchorEl: null, setAnchorEl: "", ...JSON.parse(filters) },
      () => {
        this.loadUsers();
      }
    );
  }
  handleFilterUpdate() {
    let filters = Object.assign({}, this.state);
    delete filters.anchorEl;
    delete filters.setAnchorEl;
    localStorage.setItem("filter.users", JSON.stringify(filters));
  }
  loadUsers() {
    this.props.onGetUsers({
      offset: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      orderBy: this.state.orderBy,
      order: this.state.order,
      searchText: this.state.searchText,
      filterIn: this.state.filterIn
    });
  }
  async handleChangePage(event, newPage) {
    await this.setState({ page: newPage });
    this.loadUsers();
    this.handleFilterUpdate();
  }
  async handleChangeRowsPerPage(event) {
    await this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.loadUsers();
    this.handleFilterUpdate();
  }
  async handleSorting(orderBy) {
    await this.setState(state => {
      return {
        orderBy: orderBy,
        order: state.order == "desc" ? "asc" : "desc"
      };
    });
    this.loadUsers();
    this.handleFilterUpdate();
  }
  async handleSearchTextChange(event) {
    await this.setState({ searchText: event.target.value, offset: 0, page: 0 });
    this.loadUsers();
    this.handleFilterUpdate();
  }
  async handleClearSearch() {
    await this.setState({ searchText: "", offset: 0 });
    this.loadUsers();
    this.handleFilterUpdate();
  }
  handleFilterOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleFilterClose() {
    this.setState({ anchorEl: null });
  }
  async handleMenuItemClick(event, value) {
    await this.setState({ anchorEl: null, filterIn: value });
    this.loadUsers();
    this.handleFilterUpdate();
  }
  render() {
    const { users, classes } = this.props;
    if (users.error && users.status == 403) {
      return <ErrorPage error="403" message={users.errorText} />;
    }
    return (
      <Container>
        <Paper className={classes.root}>
          <Toolbar>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ alignItems: "flex-end", display: "flex" }}
              >
                <Typography variant="h6" id="tableTitle">
                  Users
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.actions}>
                  <div className={classes.search}>
                    <SearchIcon />
                    <FormControl>
                      <InputLabel htmlFor="seartch-text">{`Search in ${this.state.filterIn}`}</InputLabel>
                      <Input
                        id="seartch-text"
                        value={this.state.searchText}
                        onChange={event => this.handleSearchTextChange(event)}
                        className={classes.inputField}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Clear Search"
                              style={{ marginRight: -12 }}
                              onClick={this.handleClearSearch}
                            >
                              {this.state.searchText.length > 0 ? (
                                <ClearIcon fontSize="small" color="secondary" />
                              ) : null}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Tooltip title="Filter list" style={{ marginBottom: -8 }}>
                      <IconButton
                        aria-haspopup="true"
                        aria-controls="filter-menu"
                        aria-label="Filter list"
                        onClick={event => this.handleFilterOpen(event)}
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="filter-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={() => this.handleFilterClose(null)}
                    >
                      <MenuItem
                        selected={"users" === this.state.filterIn}
                        onClick={event =>
                          this.handleMenuItemClick(event, "users")
                        }
                      >
                        Search in user name, email
                      </MenuItem>
                      <MenuItem
                        selected={"centres" === this.state.filterIn}
                        onClick={event =>
                          this.handleMenuItemClick(event, "centres")
                        }
                      >
                        Search in centre
                      </MenuItem>
                      <MenuItem
                        selected={"roles" === this.state.filterIn}
                        onClick={event =>
                          this.handleMenuItemClick(event, "roles")
                        }
                      >
                        Search in roles
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {headRows.map(row => (
                    <TableCell
                      key={row.id}
                      align={row.numeric ? "right" : "left"}
                      padding={row.disablePadding ? "none" : "default"}
                      sortDirection={
                        this.state.orderBy === row.id ? this.state.order : false
                      }
                    >
                      {row.sortable ? (
                        <TableSortLabel
                          active={this.state.orderBy === row.id}
                          direction={this.state.order}
                          onClick={() => this.handleSorting(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      ) : (
                        row.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!users.loading &&
                  users.rows.map(user => (
                    <TableRow key={`user-${user.id}`}>
                      <TableCell component="th" scope="row">
                        {user.id}
                      </TableCell>
                      <TableCell>
                        {user.name}
                        <div>
                          {user.centres
                            .map(
                              centre =>
                                `centre: ${centre.name} (${centre.number})`
                            )
                            .join(", ")}
                        </div>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.roles.map(role => role.description).join(", ")}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="Edit"
                          onClick={() =>
                            this.props.history.push(`/system/users/${user.id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
          {!users.loading && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.total}
              rowsPerPage={this.state.rowsPerPage}
              page={users.total > 0 ? this.state.page : 0}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </Paper>
        <Zoom
          className={classes.fabAdd}
          key="add"
          in={true}
          style={{ transitionDelay: "500ms" }}
          unmountOnExit
        >
          <Fab
            aria-label="Add"
            className="classes.fab"
            color="secondary"
            onClick={() => this.props.history.push(`/system/users/create`)}
          >
            <AddIcon />
          </Fab>
        </Zoom>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUsers: params => dispatch(actions.getUsers(params))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Users))
);
