import React, { Component } from 'react';

import { createStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const styles = theme =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
      width: 400 + theme.spacing(4) * 2
    },
    cropButton: {
      display: 'flex',
      justifyContent: 'center'
    },
    input: {
      display: 'none'
    },
    button: {
      margin: theme.spacing(1)
    }
  });

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};
class ReactCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: '',
      cropResult: null,
      openModel: false,
      modalStyle: getModalStyle()
    };
    this.onChange = this.onChange.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleOnModalRender = this.handleOnModalRender.bind(this);
  }
  componentDidMount() {}
  componentWillUnmount() {
    if (this.img) {
      // Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
      this.cropper.destroy();
      delete this.img;
      delete this.cropper;
    }
  }

  onChange(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();

    reader.onload = () => {
      this.setState({ src: reader.result, openModel: true });
    };
    if (files[0]) {
      reader.readAsDataURL(files[0]);
      this.setState({ name: files[0].name, type: files[0].type });
    }
  }
  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    this.setState({
      cropResult: this.cropper.getCroppedCanvas().toDataURL(),
      openModel: false
    });
    this.props.onImageCrop(
      this.cropper.getCroppedCanvas().toDataURL(),
      this.state.name,
      this.state.type
    );
  }
  handleModalClose() {
    this.setState({ openModel: false });
  }
  handleModalOpen() {
    this.setState({ openModel: true });
  }
  handleOnModalRender() {
    const options = {
      viewMode: 2,
      aspectRatio: 1,
      minCropBoxWidth: 200,
      minCropBoxHeight: 200,
      minCanvasHeight: 400,
      minCanvasWidth: 400,
      minContainerWidth: 400,
      minContainerHeight: 400,
      cropBoxResizable: false,
      dragMode: 'move'
    };
    this.cropper = new Cropper(this.img, options);
  }
  render() {
    const { alt, classes } = this.props;
    return (
      <div style={{ textAlign: 'center' }}>
        <input
          type="file"
          name="img"
          accept="image/*"
          id="raised-button-file"
          onChange={this.onChange}
          className={classes.input}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            size="small"
            component="span"
            className={classes.button}
          >
            {this.props.btnText}
          </Button>
        </label>
        <div>
          <Modal
            aria-labelledby="img-cropper-model"
            aria-describedby="img-cropper-model"
            open={this.state.openModel}
            onRendered={this.handleOnModalRender}
            onClose={this.handleModalClose}
          >
            <div style={this.state.modalStyle} className={classes.paper}>
              <Typography variant="h6" id="modal-title">
                Crop Image
              </Typography>
              <div style={{ height: 400, width: '100%' }}>
                <img
                  id="image"
                  ref={img => (this.img = img)}
                  src={this.state.src}
                  alt={alt === undefined ? 'picture' : alt}
                />
              </div>
              <Box m={1} className={classes.cropButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.cropImage}
                >
                  CROP PHOTO
                </Button>
              </Box>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ReactCropper);
