/* eslint-disable eqeqeq */

import { forwardRef } from "react";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import IntegrationDownshift from "../../components/IntegrationDownshift";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";
import Popover from "@material-ui/core/Popover";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ViewColumn from "@material-ui/icons/ViewColumn";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";

const headRows = [
  {
    id: "id",
    numeric: false,
    label: "ID",
    sortable: true,
    orderTable: "assessment_fee",
  },
  {
    id: "assessment_no",
    numeric: true,
    label: "Asst No",
    sortable: true,
    orderTable: "assessment",
  },
  {
    id: "centre_id",
    numeric: false,
    label: "Centre",
    sortable: true,
    orderTable: "assessment",
  },
  {
    id: "course_id",
    numeric: true,
    label: "Course",
    sortable: true,
    orderTable: "assessment",
  },
  {
    id: "module_id",
    numeric: true,
    label: "Module",
    sortable: true,
    orderTable: "assessment",
  },
  {
    id: "learner_count",
    numeric: false,
    label: "Learners",
    sortable: true,
    orderTable: "assessment_fee",
  },
  {
    id: "fee_details",
    numeric: false,
    label: "Unit Learner Fee",
    sortable: false,
    orderTable: "assessment_fee",
  },
  {
    id: "total",
    numeric: false,
    label: "Total Fee",
    sortable: true,
    orderTable: "assessment_fee",
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
    sortable: true,
    orderTable: "assessment_fee",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  formroot: {
    width: "100%",
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(1),
    height: "24px",
  },
  avatar: {
    height: "24px",
    width: "24px",
    fontSize: "11px",
    marginLeft: "0 !important",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  popoverTableHead: {
    background: theme.palette.grey[300],
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => (
    <Check {...props} ref={ref} size="small" />
  )),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => (
    <EditIcon {...props} ref={ref} size="small" />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function AssessmentFees(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);
  const [assessmentFees, setAssessmentFees] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [orderTable, setOrderTable] = useState("assessment_fees");
  const [searchText, setSearchText] = useState("");
  const [searchCentre, setSearchCentre] = useState("");
  const [centres, setCentres] = useState([]);
  const [searchCourse, setSearchCourse] = useState("");
  const [stats, setStats] = useState([]);
  const [filterStats, setFilterStats] = useState([]);
  const [filterLevels, setFilterLevels] = useState([]);
  const [annualFeeConfig, setAnnualFeeConfig] = useState({ statusCodes: {} });
  const [payments, setPayments] = useState([]);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [errorData, setErrorData] = React.useState({});
  const [details, setDetails] = React.useState({});
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    let filters = localStorage.getItem("filter.assessmentFee");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setOrderTable(filters.orderTable);
      setSearchText(filters.searchText);
      setSearchCentre(filters.searchCentre);
      setSearchCourse(filters.searchCourse);
      setFilterStats(filters.stats);
    }
    props.getCentreNames();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchText, filterStats, filterLevels]);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderTable: orderTable,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        searchCentre: searchCentre,
        searchCourse: searchCourse,
        filterStats: filterStats ? filterStats : [],
        filterLevels: filterLevels ? filterLevels : [],
      };
      await localStorage.setItem(
        "filter.assessmentFee",
        JSON.stringify(filters)
      );
      const fetchAnnualFeesStats = async () => {
        setLoading(true);
        let resp = await axios.post(urls.getAssessmentFeestats);
        if (resp.status === 200 && resp.data.error === false) {
          setStats(resp.data.rows);
          setAnnualFeeConfig(resp.data.config);
          setLoading(false);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
          setLoading(false);
        }
      };
      const fetchAssessmentFees = async (params) => {
        setLoading(true);
        let resp = await axios.post(urls.getassessmentFees, params);
        if (resp.status === 200 && resp.data.error === false) {
          await setAssessmentFees(resp.data.rows);
          setTotal(resp.data.count);
          setLoading(false);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
          setLoading(false);
        }
      };
      fetchAnnualFeesStats();
      fetchAssessmentFees(filters);
    })();
  }, [
    searchText,
    searchCentre,
    searchCourse,
    filterStats,
    filterLevels,
    orderTable,
    page,
    rowsPerPage,
    orderBy,
    order,
    props,
    count,
  ]);

  useEffect(() => {
    if (props.centres.length > 0) {
      let centres = props.centres.map((item) => ({
        id: item.id,
        label: `${item.name} (${item.number})`,
      }));
      setCentres(centres);
    }
  }, [props.centres]);

  function handleSorting(_orderBy, orderTable) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
    setOrderTable(orderTable);
  }

  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleDownshiftSelect(selectedId) {
    setSearchCentre(selectedId);
  }
  const selectedCentre = (id) => {
    let c = centres.filter((item) => item.id === id);
    return c.length ? c[0] : null;
  };

  /**
   * Update Selected Status Code and filter records
   */
  function handleFilterStatChange(statusCode) {
    if (!filterStats) {
      setFilterStats([statusCode]);
    } else if (filterStats.includes(statusCode)) {
      setFilterStats(filterStats.filter((item) => item != statusCode));
    } else {
      setFilterStats([...filterStats, statusCode]);
    }
  }
  /**
   * Update selected level and filter records
   */
  function handleFilterLevelChange(levelId) {
    if (!filterLevels) {
      setFilterLevels([levelId]);
    } else if (filterLevels.includes(levelId)) {
      setFilterLevels(filterLevels.filter((item) => item != levelId));
    } else {
      setFilterLevels([...filterLevels, levelId]);
    }
  }

  /**
   * Show payments on click of Row
   */

  const showPayments = (assessmentFee) => async (event) => {
    setSelectedId(assessmentFee.id);
    let resp = await axios.post(urls.getassessmentFee(assessmentFee.id));
    if (resp.status === 200 && resp.data.error === false) {
      if (resp.data.fee.assessment_fee_payments.length > 0) {
        await setPayments(resp.data.fee.assessment_fee_payments);
      } else {
        await setPayments([{}]);
      }
      await setDetails(resp.data.fee.details);
      setOpenPayment(true);
    } else {
      props.onEnqueueSnackbar(resp.data.errorText, 3000, "error");
    }
  };

  function handleClose() {
    setOpenPayment(false);
    setEditId(null);
  }

  /** Handle Field Change */
  const handlePaymentChange = (id, field) => async (e) => {
    let paymentsArr = payments.map((item) => {
      if (item.id === id) {
        if (field == "invoice_date" || field == "paid_date") {
          item[field] = e ? e.format("L") : null;
        } else {
          item[field] = e.target.value;
        }
      }
      return item;
    });

    setPayments(paymentsArr);
    delete errorData[field];
  };
  const updatePayment = async (item) => {
    try {
      let payment = payments.filter((i) => i.id == item.id);
      let resp = await axios.post(
        urls.updateAssessmentFeePayment(item.assessment_fee_id),
        { payment: payment[0], selected: item.id }
      );
      if (resp.status === 200 && resp.data.error === false) {
        //await setPayments(resp.data.rows);
        setEditId(null);
        setOpenPayment(false);
        setCount((prev) => prev + 1);
        props.onEnqueueSnackbar("Success!!", 1000, "success");
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000, "error");
      }
    } catch (error) {
      props.onEnqueueSnackbar(error.message, 3000, "error");
    }
  };

  return (
    <Container>
      <div className={classes.chipRoot}>
        {stats.map((item) => (
          <Chip
            key={item.status}
            variant="outlined"
            size="small"
            avatar={
              <Avatar className={classes.avatar} size="small">
                {item.count}
              </Avatar>
            }
            label={annualFeeConfig.statusCodes[item.status]}
            className={classes.chip}
            onClick={(e) => handleFilterStatChange(item.status)}
            deleteIcon={<DoneIcon />}
            color={
              filterStats && filterStats.includes(item.status)
                ? "primary"
                : "default"
            }
          />
        ))}
      </div>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={3} md={4}>
                <Typography variant="h6" id="tableTitle">
                  Assessment Fees
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={4}>
                <div className={classes.search}>
                  <SearchIcon />
                  <IntegrationDownshift
                    label="&nbsp;"
                    placeholder="search in centre"
                    data={Object.values(centres)}
                    onSelect={(selected) => handleDownshiftSelect(selected.id)}
                    selectedItem={selectedCentre(searchCentre)}
                    onClear={() => setSearchCentre("")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={4}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="seartch-text">{`asst. no`}</InputLabel>
                    <Input
                      id="seartch-text"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchText("")}
                          >
                            {searchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.align ? row.align : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id, row.orderTable)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assessmentFees.map((item) => (
              <TableRow
                hover
                key={item.id}
                onClick={showPayments(item)}
                selected={item.id === selectedId}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell>
                  {get(item, "assessment.assessment_no", "")}
                </TableCell>
                <TableCell style={{ maxWidth: "200px" }}>{`${get(
                  item,
                  "assessment.centre.name",
                  ""
                )} (${get(item, "assessment.centre.number", "")})`}</TableCell>
                <TableCell style={{ maxWidth: "200px" }}>{`${get(
                  item,
                  "assessment.course.name",
                  ""
                )} (${get(item, "assessment.course_id", "")})`}</TableCell>
                <TableCell>{get(item, "assessment.module.name", "")}</TableCell>
                <TableCell align="left">{item.learner_count}</TableCell>
                <TableCell align="left">
                  {item.fee_details &&
                    Object.keys(item.fee_details).map((key) => (
                      <div>
                        {key} <strong>{`£${item.fee_details[key]}`}</strong>
                      </div>
                    ))}
                </TableCell>
                <TableCell align="right">{`£${item.total}`}</TableCell>
                <TableCell style={{ width: "140px" }}>
                  {annualFeeConfig
                    ? annualFeeConfig.statusCodes[item.status]
                    : ""}
                </TableCell>
              </TableRow>
            ))}
            {assessmentFees.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={10}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Dialog
        fullScreen={fullScreen}
        open={openPayment}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"md"}
      >
        <Paper>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Table size="small">
              <TableHead className={classes.popoverTableHead}>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Invoice Date</TableCell>
                  <TableCell>Paid Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {editId !== item.id ? (
                        item.amount
                      ) : (
                        <TextField
                          id="payment-amount"
                          label="Title"
                          className={classes.textField}
                          value={item.amount}
                          onChange={handlePaymentChange(item.id, "amount")}
                          margin="normal"
                          required={true}
                          fullWidth={true}
                          error={errorData.hasOwnProperty("title")}
                          helperText={errorData.title}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {editId !== item.id ? (
                        item.invoice_no
                      ) : (
                        <TextField
                          id="payment-invoice-no"
                          label="Invoice-no"
                          className={classes.textField}
                          value={item.invoice_no}
                          onChange={handlePaymentChange(item.id, "invoice_no")}
                          margin="normal"
                          required={true}
                          fullWidth={true}
                          error={errorData.hasOwnProperty("title")}
                          helperText={errorData.title}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {editId != item.id ? (
                        item.invoice_date
                      ) : (
                        <KeyboardDatePicker
                          clearable
                          label="Invoice Date"
                          margin="normal"
                          format="DD-MM-YYYY"
                          value={item.invoice_date || null}
                          onChange={handlePaymentChange(
                            item.id,
                            "invoice_date"
                          )}
                          error={errorData.hasOwnProperty("invoice_date")}
                          helperText={errorData.invoice_date}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {editId != item.id ? (
                        item.paid_date
                      ) : (
                        <KeyboardDatePicker
                          clearable
                          label="Paid Date"
                          margin="normal"
                          format="DD-MM-YYYY"
                          value={item.paid_date || null}
                          onChange={handlePaymentChange(item.id, "paid_date")}
                          error={errorData.hasOwnProperty("paid_date")}
                          helperText={errorData.paid_date}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {editId !== item.id ? (
                        <IconButton
                          aria-label="Edit"
                          onClick={() => setEditId(item.id)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="done"
                          onClick={() => updatePayment(item)}
                        >
                          <DoneIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </MuiPickersUtilsProvider>
        </Paper>
      </Dialog>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    centres: state.centres.rows,
    levels: state.common.levels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCentreNames: () => dispatch(actions.getCentres()),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentFees);
