import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import Autocomplete from "../../components/IntegrationDownshift";
import CourseStepper from "../../components/Course/CourseStepper";
import UnitTabs from "../../components/Course/UnitTabs";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  cardRoot: {
    overflow: "visible",
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

function CreateCourse(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({
    id: "",
    name: "",
    centre_id: "",
    qualification_id: "",
    teacher_id: "",
    start_date: null,
    end_date: null,
    active: "",
    progress: 0,
  });
  const [centreNames, setCentreNames] = useState([]);
  const [steps, setSteps] = useState({});
  const [qualifications, setQualifications] = useState([]);
  const [annualFees, setAnnualFees] = useState({});
  const [qualification, setQualification] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [units, setUnits] = useState([]);
  const [dates, setDates] = useState({
    start_date: moment().format("DD-MM-YYYY"),
    end_date: moment().format("DD-MM-YYYY"),
  });
  const [errorData, setErrorData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetchCentreNames();
      fetchCourseConfig();
    }
    fetchData();
  }, []);

  /** Set Selected Centre */
  useEffect(() => {
    if (props.currentRole && props.currentRole.name === "centre") {
      selectedCentre(props.centre.id);
      setCourse({ ...course, centre_id: props.centre.id });
    }
  }, [props.currentRole]);

  /** Fetch Course */
  function fetchCourseConfig() {
    axios.post(urls.getCourseConfig).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setSteps(resp.data.steps);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Query Centres */
  function fetchCentreNames() {
    axios.get(urls.getCentreNames).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        const centres = await resp.data.centres.map((item) => ({
          id: item.id,
          label: `${item.name} (${item.number})`,
        }));
        setCentreNames(centres);
      }
    });
  }
  /** Fetch Centre Qualifications */

  function fetchCentreQualifications(id) {
    if (!id) return;
    axios.get(urls.getCentreQualifications(id)).then(async (resp) => {
      console.log(resp);
      if (resp.status === 200 && !resp.data.error) {
        let annualFees = {};
        resp.data.annualFees.forEach(
          (item) =>
            (annualFees[item.level] = {
              start_date: item.start_date,
              end_date: item.end_date,
            })
        );
        setQualifications(resp.data.qualifications);
        setAnnualFees(annualFees);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Fetch Centre Teacher for Qualification  */

  function fetchCentreQualTeacher(id, levelId) {
    axios
      .post(urls.getCentreQualTeacher(id), {
        levelId: levelId,
      })
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          setTeachers(resp.data.teachers);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }

  /** Fetch Qualification Units */
  function fetchQulificationUnits(id) {
    axios.post(urls.getUnits(id)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setUnits(resp.data.rows);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }
  const selectedCentre = (id) => {
    let c = centreNames.filter((item) => item.id === id);
    return c.length ? c[0] : null;
  };
  /** Handle Field Change */
  const handleChange = (name) => async (e) => {
    setCourse({ ...course, [name]: e.target.value });
    delete errorData[name];
    if (name === "qualification_id") {
      let qual = await qualifications.filter(
        (item) => item.id === e.target.value
      );
      await setQualification(qual[0]);
      console.log(qual[0]);
      //fetchCentreQualTeacher(course.centre_id,qual[0].level_id);
      //fetchQulificationUnits(qual[0].id);
    }
  };
  /** Handle Field Change */
  const handleDateChange = (name) => (e) => {
    console.log(e);
    setDates({ ...dates, [name]: e });
    setCourse({ ...course, [name]: e.format("L") });
    delete errorData[name];
  };
  /** handle Course field change */
  function handleCentreChange(id) {
    console.log("id", id);
    selectedCentre(id);
    setCourse({ ...course, centre_id: id });
    delete errorData.centre_id;
  }
  /** Fetch Qualification on Centre Change */
  useEffect(() => {
    console.log(course);
    fetchCentreQualifications(course.centre_id);
  }, [course.centre_id]);

  /** Set Qualification on Qualification Change */
  useEffect(() => {
    if (course.qualification_id && qualifications.length) {
      let qual = qualifications.filter(
        (item) => item.id === course.qualification_id
      );
      setQualification(qual[0]);
    }
  }, [qualifications, course.qualification_id]);

  /** Set Centre Teachers and Qualification Units */
  useEffect(() => {
    if (qualification.level_id) {
      fetchCentreQualTeacher(course.centre_id, qualification.level_id);
      fetchQulificationUnits(qualification.id);
    }
  }, [qualification]);

  function handleClearCentre() {
    setCourse({ ...course, centre_id: "", qualification_id: "" });
    setQualifications([]);
    setAnnualFees({});
  }
  // Create Course
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.createCourse, { course, units })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/courses/list/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }

  /** handle Unit Change */
  function handleUnitChange(unitArr) {
    setUnits(unitArr);
  }

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CourseStepper steps={steps} activeStep={0} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot}>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography className={classes.title} color="textSecondary">
                  New Course Request
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="course-name"
                    label="Name"
                    className={classes.textField}
                    value={course.name}
                    onChange={handleChange("name")}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("name")}
                    helperText={errorData.name}
                  />
                  {props.currentRole.name !== "centre" && (
                    <Autocomplete
                      label="Centre"
                      placeholder="Search in Centre"
                      data={centreNames}
                      onSelect={(selected) => handleCentreChange(selected.id)}
                      onClear={() => handleClearCentre()}
                      selectedItem={selectedCentre(course.centre_id)}
                      error={errorData.hasOwnProperty("centre_id")}
                      helperText={errorData.centre_id}
                    />
                  )}
                  <TextField
                    id="select-qualifications"
                    select
                    label="Qualification Level"
                    className={classes.textField}
                    value={course.qualification_id}
                    onChange={handleChange("qualification_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("qualification_id")}
                    helperText={errorData.qualification_id}
                  >
                    {qualifications &&
                      qualifications.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.code} - {item.title}
                        </MenuItem>
                      ))}
                  </TextField>
                  <Typography variant="caption" display="block" gutterBottom>
                    Only Qualifications that have paid their Annual Fees and
                    have an Approved Teacher for the Qualification Level will be
                    shown in this list
                  </Typography>
                  {course.qualification_id && (
                    <p>{qualification.description}</p>
                  )}
                  <TextField
                    id="select-teacher"
                    select
                    label="Teacher"
                    className={classes.textField}
                    value={course.teacher_id}
                    onChange={handleChange("teacher_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("teacher_id")}
                    helperText={errorData.teacher_id}
                  >
                    {teachers &&
                      teachers.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="Start Date"
                      margin="normal"
                      fullWidth={true}
                      format="DD/MM/YYYY"
                      value={
                        course.start_date
                          ? moment(course.start_date).format("DD-MM-YYYY")
                          : null
                      }
                      onChange={handleDateChange("start_date")}
                      error={errorData.hasOwnProperty("start_date")}
                      helperText={errorData.start_date}
                    />
                  </MuiPickersUtilsProvider>
                  {qualification.version && (
                    <Typography variant="caption" display="block">
                      Start date of courses for this Qualification must be
                      between {annualFees[qualification.level_id].start_date}{" "}
                      and {annualFees[qualification.level_id].end_date}
                    </Typography>
                  )}
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="End Date"
                      margin="normal"
                      format="DD/MM/YYYY"
                      fullWidth={true}
                      value={
                        course.end_date
                          ? moment(course.end_date).format("DD-MM-YYYY")
                          : null
                      }
                      minDate={dates.start_date}
                      onChange={handleDateChange("end_date")}
                      error={errorData.hasOwnProperty("end_date")}
                      helperText={errorData.end_date}
                    />
                  </MuiPickersUtilsProvider>
                </form>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Create Course
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <UnitTabs
                units={units}
                onChange={(unitArr) => handleUnitChange(unitArr)}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    currentRole: state.authUser.currentRole,
    centre: state.authUser.centre,
    levels: state.common.levels,
    regions: state.common.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateCourse)
);
