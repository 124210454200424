import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../common/utility";

const initialState = {
  error: null,
  errorText: "",
  loading: false,
  isAuthenticated: false,
  authRedirectPath: "/assessment/live/instruction",
  examEnded: false,
  examExtended: false,
  data: {},
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  console.log(action);
  return updateObject(state, {
    error: action.error,
    errorText: action.errorText,
    isAuthenticated: true,
    data: action.exam,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    error: false,
    errorText: "",
  });
};

const examEnded = (state) => {
  return updateObject(state, {
    error: false,
    errorText: "",
    examEnded: true,
  });
};
const examExtended = (state) => {
  return updateObject(state, {
    error: false,
    errorText: "",
    examExtended: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_EXAM_START:
      return authStart(state, action);
    case actionTypes.AUTH_EXAM_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_EXAM_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_EXAM_LOGOUT:
      return authLogout(state, action);
    case actionTypes.EXAM_ENDED:
      return examEnded(state);
    case actionTypes.EXAM_EXTENDED:
      return examExtended(state);
    default:
      return state;
  }
};

export default reducer;
