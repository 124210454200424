import { put, call } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';
import { urls } from '../../common/urls';

/*
 * Get All Users
 */

export function* getRolesSaga(action) {
  yield put(actions.getRolesStart());
  try {
    const response = yield axios.post(urls.getRoles);
    if (response.status === 200 && !response.data.error) {
      yield put(actions.getRolesSuccess(response.data));
    } else {
      yield put(actions.getRolesFail(response.data));
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.getRolesFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}

export function* updatePermissionSaga(action) {
  yield put(actions.updatePermissionStart());
  try {
    const response = yield axios.post(urls.updatePermission, {
      roleId: action.payload.roleId,
      permissionIds: action.payload.permissionIds,
      attach: action.payload.attach
    });
    if (response.status === 200 && !response.data.error) {
      yield call(getRolesSaga);
      yield put(actions.updatePermissionSuccess(response.data));
    } else {
      yield put(actions.updatePermissionFail(response.data));
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.updatePermissionFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}
