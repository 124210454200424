import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Gaurd from "../../hoc/Gaurd";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

import UserfulHelpLink from "../../components/UsefulHelpLink";

import { urls } from "../../common/urls";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  card: {
    maxWidth: 345,
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    textAlign: "center",
  },
}));
function CentreIndex(props) {
  const classes = useStyles();
  const items = [
    {
      key: "Centres",
      name: "View Centres",
      link: "/centres/list",
      permission: "view_centre",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
        </SvgIcon>
      ),
    },
    {
      key: "add-centre",
      name: "Add New Centre",
      link: "/centres/create",
      permission: "create_centre",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" />
        </SvgIcon>
      ),
    },
  ];

  const [usefulLinks, setUsefulLinks] = React.useState([]);
  useEffect(() => {
    axios.get(urls.getUsefulLinks("centre")).then((resp) => {
      if (resp.status === 200) {
        setUsefulLinks(resp.data.row);
      }
    });
  }, []);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={11}>
        <Grid container justify="center" spacing={2}>
          {items.map((item) => (
            <Grid key={item.key} item xs={6} sm={4} md={2}>
              <Card className={classes.card}>
                <Gaurd check={item.permission}>
                  <CardActionArea onClick={() => props.history.push(item.link)}>
                    <CardContent>
                      <div className={classes.iconWrapper}>{item.icon}</div>
                      <Typography component="h5" align="center">
                        {item.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Gaurd>
              </Card>
            </Grid>
          ))}
        </Grid>
        {usefulLinks.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <UserfulHelpLink helpLinks={usefulLinks} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default withRouter(CentreIndex);
