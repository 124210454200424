import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "media_type_id", numeric: false, label: "Type", sortable: true },
  { id: "title", numeric: false, label: "Title", sortable: true },
  { id: "level_id", numeric: false, label: "Level", sortable: true },
  { id: "year", numeric: true, label: "Year", sortable: true },
  { id: "is_active", numeric: false, label: "Active", sortable: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  formroot: {
    width: "100%",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
}));

function MediaList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [medias, setMedias] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [filterMediaType, setFilterMediaType] = useState("");
  const [filterLevel, setFilterLevel] = useState("");
  const [isActual, setIsActual] = useState(false);
  const [isPractice, setIsPractice] = useState(false);
  const [isResit, setIsResit] = useState(false);

  useEffect(() => {
    let filters = localStorage.getItem("filter.medias");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setFilterMediaType(filters.filterMediaType);
      setFilterLevel(filters.filterLevel);
      setIsActual(filters.filterIsActual);
      setIsPractice(filters.filterIsPractice);
      setIsResit(filters.filterIsResit);
    }
  }, []);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        filterMediaType: filterMediaType,
        filterLevel: filterLevel,
        filterIsActual: isActual,
        filterIsPractice: isPractice,
        filterIsResit: isResit,
      };
      await localStorage.setItem("filter.medias", JSON.stringify(filters));
      await fetchMedias(filters);
    })();
  }, [
    searchText,
    page,
    rowsPerPage,
    orderBy,
    order,
    filterMediaType,
    filterLevel,
    isActual,
    isPractice,
    isResit,
  ]);

  const fetchMedias = async (params) => {
    let resp = await axios.post(urls.getMedias, params);
    if (resp.status === 200 && resp.data.error === false) {
      await setMedias(resp.data.rows);
      setTotal(resp.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }

  function handleLevelChange(event) {
    setFilterLevel(event.target.value ? parseInt(event.target.value) : "");
  }

  function handleTypeChange(event) {
    setFilterMediaType(event.target.value ? parseInt(event.target.value) : "");
  }
  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" id="tableTitle">
            Media
          </Typography>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isActual}
                        onChange={() => setIsActual((p) => !p)}
                        name="isActual"
                      />
                    }
                    label="Is Actual"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPractice}
                        onChange={() => setIsPractice((p) => !p)}
                        name="IsPractice"
                      />
                    }
                    label="Is Practice"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isResit}
                        onChange={() => setIsResit((p) => !p)}
                        name="IsResit"
                      />
                    }
                    label="Is Resit"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="media-type-select">
                      Media Type
                    </InputLabel>
                    <Select
                      value={filterMediaType ? filterMediaType : ""}
                      onChange={handleTypeChange}
                      inputProps={{
                        name: "media-type",
                        id: "media-type-select",
                      }}
                    >
                      {props.mediaTypes &&
                        props.mediaTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    aria-label="Clear Search"
                    onClick={() => setFilterMediaType("")}
                    size="small"
                    className={classes.clearBtn}
                  >
                    {filterMediaType ? (
                      <ClearIcon fontSize="small" color="secondary" />
                    ) : null}
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="media-type-select">
                      Qualification Level
                    </InputLabel>
                    <Select
                      value={filterLevel ? filterLevel : ""}
                      onChange={handleLevelChange}
                      inputProps={{
                        name: "media-level",
                        id: "media-level-select",
                      }}
                    >
                      {props.levels &&
                        props.levels.map((level) => (
                          <MenuItem key={level.id} value={level.id}>
                            {level.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    aria-label="Clear Search"
                    onClick={() => setFilterLevel("")}
                    size="small"
                    className={classes.clearBtn}
                  >
                    {filterLevel ? (
                      <ClearIcon fontSize="small" color="secondary" />
                    ) : null}
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="seartch-text">{`Search in media title`}</InputLabel>
                    <Input
                      id="seartch-text"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchText("")}
                          >
                            {searchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? "none" : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              medias.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>
                    {item.mediatypes
                      ? item.mediatypes.title
                      : item.media_type_id}
                  </TableCell>
                  <TableCell>
                    {item.title}
                    <Typography variant="caption" display="block" gutterBottom>
                      {item.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {item.level_id > 0
                      ? item.level.name
                      : item.level_id === 0
                      ? "ALL"
                      : ""}
                  </TableCell>
                  <TableCell>{item.year}</TableCell>
                  <TableCell>{item.is_active ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/media/list/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!loading && medias.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Zoom
        className={classes.fabAdd}
        key="add"
        in={true}
        style={{ transitionDelay: "500ms" }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          className="classes.fab"
          color="secondary"
          onClick={() => props.history.push(`/media/create`)}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    mediaTypes: state.common.mediaTypes,
    levels: state.common.levels,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MediaList);
