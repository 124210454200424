import * as actionTypes from './actionTypes';

export const uploadFile = payload => {
  return {
    type: actionTypes.UPLOAD_FILE,
    payload: payload
  };
};

export const uploadingCancelled = () => {
  return {
    type: actionTypes.UPLOADING_CANCELLED
  };
};
export const uploadingFinished = payload => {
  return {
    type: actionTypes.UPLOADING_FINISHED,
    payload: payload
  };
};
