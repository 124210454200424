import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

import UnitTabs from '../../components/Course/UnitTabs';
import AlertDialog from '../../components/AlertDialog';

import * as actions from '../../store/actions';
import { urls } from '../../common/urls';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  cardRoot: {
    overflow: 'visible'
  },
  container: {},
  title: {
    fontSize: 18
  },
  textField: {
    minWidth: 200
  },
  menu: {
    width: 200
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

function CourseApproval(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const [course, setCourse] = useState({
    id: '',
    name: '',
    centre_id: '',
    qualification_id: '',
    teacher_id: '',
    start_date: null,
    end_date: null,
    active: '',
    status: 100
  });
  const [centreNames, setCentreNames] = useState([]);
  const [steps, setSteps] = useState({});
  const [approvalStatus, setApprovalStatus] = useState({});
  const [qualifications, setQualifications] = useState([]);
  const [qualification, setQualification] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [units, setUnits] = useState([]);
  const [dates, setDates] = useState({
    start_date: moment(),
    end_date: moment()
  });
  const [errorData, setErrorData] = useState(false);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [openRejectedAlert, setOpenRejectedAlert] = React.useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetchCentreNames();
      await fetchCourse();
    }
    fetchData();
  }, []);

  /** Fetch Course */
  function fetchCourse() {
    axios.post(urls.getCourse(props.approval.entity_id)).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        setCourse(resp.data.course);
        setSteps(resp.data.steps);
        setApprovalStatus(resp.data.approvalStatus);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Query Centres */
  function fetchCentreNames() {
    axios.get(urls.getCentreNames).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        const centres = await resp.data.centres.map(item => ({
          id: item.id,
          label: `${item.name} (${item.number})`
        }));
        setCentreNames(centres);
      }
    });
  }
  /** Fetch Centre Qualifications */

  function fetchCentreQualifications(id) {
    axios.get(urls.getCentreQualifications(id)).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        setQualifications(resp.data.qualifications);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Fetch Centre Teacher for Qualification  */

  function fetchCentreQualTeacher(id, levelId) {
    axios
      .post(urls.getCentreQualTeacher(id), {
        levelId: levelId
      })
      .then(async resp => {
        if (resp.status === 200 && !resp.data.error) {
          setTeachers(resp.data.teachers);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }

  /** Fetch Qualification Units */
  function fetchQulificationUnits(id) {
    axios.post(urls.getUnits(id)).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        let cunits = resp.data.rows.map(item => {
          let unitObj = course.course_units.filter(
            cunit => cunit.unit_id === item.id
          );
          if (unitObj.length > 0) {
            return {
              ...item,
              learner_fee: unitObj[0].learner_fee,
              resit_fee: unitObj[0].resit_fee
            };
          }
          return item;
        });

        setUnits(cunits);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Handle Field Change */
  const handleChange = name => async e => {
    setCourse({ ...course, [name]: e.target.value });
    delete errorData[name];
    if (name === 'qualification_id') {
      let qual = await qualifications.filter(
        item => item.id === e.target.value
      );
      console.log(qual);
      await setQualification(qual[0]);
      //fetchCentreQualTeacher(course.centre_id,qual[0].level_id);
      //fetchQulificationUnits(qual[0].id);
    }
  };
  /** Handle Course Date Field Change */
  const handleDateChange = name => e => {
    setDates({ ...dates, [name]: e });
    setCourse({ ...course, [name]: e.format('L') });
    delete errorData[name];
  };

  /** Fetch Qualification on Centre Change */
  useEffect(() => {
    if (course.centre_id !== '') {
      fetchCentreQualifications(course.centre_id);
    }
  }, [course.centre_id]);

  /** Set Qualification on Qualification Change */
  useEffect(() => {
    if (course.qualification_id && qualifications.length) {
      let qual = qualifications.filter(
        item => item.id === course.qualification_id
      );
      setQualification(qual[0]);
    }
  }, [qualifications, course.qualification_id]);

  /** Set Centre Teachers and Qualification Units */
  useEffect(() => {
    if (qualification.level_id) {
      fetchCentreQualTeacher(course.centre_id, qualification.level_id);
      fetchQulificationUnits(qualification.id);
    }
  }, [qualification]);

  // Create Course
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.updateCourse(course.id), { course, units })
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar('Success!!', 1000, 'success');
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/courses/list/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar('Access Denied', 1000, 'warning');
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, 'error');
        }
      });
  }

  /** handle Unit Change */
  function handleUnitChange(unitArr) {
    setUnits(unitArr);
  }

  /**
   *  Handle Approval
   */
  async function handleApproval(agree, approve) {
    setOpenApprovalAlert(false);
    if (agree && !approve && rejectionReason === '') {
      props.onEnqueueSnackbar('Provide rejection reason!!', 1000, 'error');
      return;
    }
    setOpenRejectedAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.approveCourse(props.approval.entity_id),
        { approvalId: props.approval.id, approve, rejectionReason }
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
        props.onUpdate();
        fetchCourse();
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            {course.is_approved === props.statusCodes.APPROVED && (
              <React.Fragment>
                <Chip color="primary" icon={<DoneIcon />} label="Approved" />
                <div>Approved by {props.approval.user.name}</div>
                <div>Status updated at {props.approval.updated_at}</div>
              </React.Fragment>
            )}
            {course.is_approved === props.statusCodes.REJECTED && (
              <React.Fragment>
                <Chip color="secondary" label="Rejected" />
                <div>Rejected by {props.approval.user.name}</div>
                <div>Rejected at {course.approved_at}</div>
              </React.Fragment>
            )}
            <Box display="flex" justifyContent="flex-end">
              {course.is_approved === 0 && course.status === 110 && (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    onClick={() => setOpenRejectedAlert(true)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={() => setOpenApprovalAlert(true)}
                  >
                    Approve
                  </Button>
                </React.Fragment>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot}>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography className={classes.title} color="textSecondary">
                  Course
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="course-name"
                    label="Name"
                    className={classes.textField}
                    value={course.name}
                    onChange={handleChange('name')}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty('name')}
                    helperText={errorData.name}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  />
                  <TextField
                    id="select-Centre"
                    select
                    label="Centre"
                    className={classes.textField}
                    value={course.centre_id}
                    onChange={handleChange('centre_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('centre_id')}
                    helperText={errorData.centre_id}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  >
                    {centreNames &&
                      centreNames.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  {/* <Autocomplete
                    label="Centre"
                    placeholder="Search in Centre"
                    data={centreNames}
                    onSelect={selected => handleCentreChange(selected.id)}
                    onClear={() => handleClearCentre()}
                    selectedItem={selectedCentre(course.centre_id)}
                    error={errorData.hasOwnProperty("centre_id")}
                    helperText={errorData.centre_id}
                    InputProps={{
                      readOnly: course.status > 100,
                    }}
                  /> */}
                  <TextField
                    id="select-qualifications"
                    select
                    label="Qualification Level"
                    className={classes.textField}
                    value={course.qualification_id}
                    onChange={handleChange('qualification_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('qualification_id')}
                    helperText={errorData.qualification_id}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  >
                    {qualifications &&
                      qualifications.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.code} - {item.title}
                        </MenuItem>
                      ))}
                  </TextField>
                  {course.qualification_id && (
                    <p>{qualification.description}</p>
                  )}
                  <TextField
                    id="select-teacher"
                    select
                    label="Teacher"
                    className={classes.textField}
                    value={course.teacher_id}
                    onChange={handleChange('teacher_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('teacher_id')}
                    helperText={errorData.teacher_id}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  >
                    {teachers &&
                      teachers.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="Start Date"
                      margin="normal"
                      fullWidth={true}
                      format="DD/MM/YYYY"
                      value={course.start_date || null}
                      onChange={handleDateChange('start_date')}
                      error={errorData.hasOwnProperty('start_date')}
                      helperText={errorData.start_date}
                      InputProps={{
                        readOnly: course.status > 100
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="End Date"
                      margin="normal"
                      format="DD/MM/YYYY"
                      fullWidth={true}
                      value={course.end_date || null}
                      minDate={dates.start_date.add(1, 'days').toDate()}
                      onChange={handleDateChange('end_date')}
                      error={errorData.hasOwnProperty('end_date')}
                      helperText={errorData.end_date}
                      InputProps={{
                        readOnly: course.status > 100
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </form>
              </CardContent>
              <CardActions>
                {course.status === 110 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Update Course
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <UnitTabs
                units={units}
                onChange={unitArr => handleUnitChange(unitArr)}
              />
            </Paper>
          </Grid>
        </Grid>
        <AlertDialog
          title="Approve Course"
          description="Autogenerated email will be sent to Centre with the course updates."
          open={openApprovalAlert}
          handleAlertClose={agree => handleApproval(agree, true)}
        />
      </Grid>
      <Dialog open={openRejectedAlert} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Confirm Course Rejection{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Provide a reason for rejection of this course. Centre will be
            notified with the rejection message on their registered email.
          </DialogContentText>
          <TextField
            id="rejection-reason"
            label="Reason for Rejection"
            multiline
            rowsMax="4"
            fullWidth={true}
            value={rejectionReason}
            onChange={e => setRejectionReason(e.target.value)}
            className={classes.textField}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleApproval(false, false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleApproval(true, false)}
            color="secondary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    levels: state.common.levels,
    regions: state.common.regions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CourseApproval)
);
