import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../common/utility";

const initialState = {
  name: null,
  email: null,
  phone: null,
  profilePhoto: null,
  currentRole: null,
  currentCentre: null,
  roles: [],
  menus: [],
  error: null,
  errorText: "",
  loading: false,
};

const getAuthUserStart = (state, action) => {
  return updateObject(state, { error: null, errorText: "", loading: true });
};

const getAuthUserSuccess = (state, action) => {
  const { user, roles, sideBarMenus, menus, centre } = action.payload;
  return updateObject(state, {
    name: user.name,
    email: user.email,
    profilePhoto: user.profile_photo,
    phone: user.phone,
    currentRole: user.currentRole,
    permissions: user.permissions,
    currentCentre: user.entity,
    phone_verified_at: user.phone_verified_at,
    email_verified_at: user.email_verified_at,
    email_sent_at: user.email_sent_at,
    is_active: user.is_active,
    timezone: user.timezone,
    roles: roles,
    sideBarMenus: sideBarMenus,
    menus: menus,
    centre: centre,
    error: action.error,
    errorText: action.errorText,
    loading: false,
  });
};

const getAuthUserFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AUTH_USER_START:
      return getAuthUserStart(state, action);
    case actionTypes.GET_AUTH_USER_SUCCESS:
      return getAuthUserSuccess(state, action);
    case actionTypes.GET_AUTH_USER_FAIL:
      return getAuthUserFail(state, action);
    default:
      return state;
  }
};

export default reducer;
