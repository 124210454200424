import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Gaurd from "../../hoc/Gaurd";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import Zoom from "@material-ui/core/Zoom";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import IntegrationDownshift from "../../components/IntegrationDownshift";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";

const headRows = [
  { id: "id", numeric: false, label: "Id", sortable: true },
  { id: "name", numeric: false, label: "Name", sortable: true },
  { id: "centre", numeric: false, label: "Centre", sortable: true },
  { id: "start_date", numeric: false, label: "Start Date", sortable: true },
  { id: "end_date", numeric: false, label: "End Date", sortable: true },
  { id: "learner", numeric: false, label: "Learners", sortable: false },
  { id: "status", numeric: false, label: "Status", sortable: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  formroot: {
    width: "100%",
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(1),
    height: "24px",
  },
  avatar: {
    height: "24px",
    width: "24px",
    fontSize: "11px",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
  smCell: {
    width: "135px",
  },
  xsCell: {
    width: "130px",
  },
  xxsCell: {
    width: "90px",
  },
}));

function CourseList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [medias, setMedias] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [searchCentre, setSearchCentre] = useState("");
  const [centres, setCentres] = useState([]);
  const [stats, setStats] = useState([]);
  const [filterStats, setFilterStats] = useState();
  const [courseConfig, setCourseConfig] = useState({ steps: {} });
  useEffect(() => {
    let filters = localStorage.getItem("filter.courses");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setSearchCentre(filters.searchCentre);
      setFilterStats(filters.filterStats);
    }
    fetchCourseStats();
    props.getCentreNames();
  }, []);

  useEffect(() => {
    console.log("filterStats ", filterStats);
    if (filterStats !== undefined) {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        filterStats: filterStats ? filterStats : [],
        searchCentre: searchCentre,
      };
      localStorage.setItem("filter.courses", JSON.stringify(filters));
      fetchCourses(filters);
    }
  }, [
    searchText,
    searchCentre,
    page,
    filterStats,
    rowsPerPage,
    orderBy,
    order,
  ]);

  const fetchCourseStats = async () => {
    setLoading(true);
    let resp = await axios.post(urls.getCourseStats);
    if (resp.status === 200 && resp.data.error === false) {
      let statsObj = {};
      resp.data.rows.forEach((item) => {
        statsObj[item.status] = item.count;
      });
      setStats(statsObj);
      setCourseConfig(resp.data.courseConfig);
      setLoading(false);
    } else {
      props.onEnqueueSnackbar(resp.data.errorText, 3000);
      setLoading(false);
    }
  };

  const fetchCourses = async (params) => {
    let resp = await axios.post(urls.getCourses, params);
    if (resp.status === 200 && resp.data.error === false) {
      await setMedias(resp.data.rows);
      setTotal(resp.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  useEffect(() => {
    if (props.centres.length > 0) {
      let centres = props.centres.map((item) => ({
        id: item.id,
        label: `${item.name} (${item.number})`,
      }));
      setCentres(centres);
    }
  }, [props.centres]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }
  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleDownshiftSelect(selectedId) {
    setSearchCentre(selectedId);
  }
  /**
   * Update Select Status Code
   */
  function handleFilterStatChange(statusCode) {
    if (!filterStats) {
      setFilterStats([statusCode]);
    } else if (filterStats.includes(statusCode)) {
      setFilterStats(filterStats.filter((item) => item != statusCode));
    } else {
      setFilterStats([...filterStats, statusCode]);
    }
  }
  const selectedCentre = (id) => {
    let c = centres.filter((item) => item.id === id);
    return c.length ? c[0] : null;
  };
  return (
    <Container>
      <div className={classes.chipRoot}>
        {Object.keys(courseConfig.steps).map((key) => (
          <Chip
            key={key}
            variant="outlined"
            size="small"
            avatar={
              <Avatar className={classes.avatar} size="small">
                {stats[key] ? stats[key] : 0}
              </Avatar>
            }
            label={courseConfig.steps[key]}
            className={classes.chip}
            onClick={(e) => handleFilterStatChange(key)}
            deleteIcon={<DoneIcon />}
            color={
              filterStats && filterStats.includes(key) ? "primary" : "default"
            }
          />
        ))}
      </div>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1} alignItems="center">
              <Grid
                item
                xs={12}
                sm={
                  props.currentRole.name !== "centre" && !props.match.params.id
                    ? 6
                    : 9
                }
              >
                <Typography variant="h6" id="tableTitle">
                  Course
                </Typography>
              </Grid>
              {!props.match.params.id && props.currentRole.name !== "centre" && (
                <Grid item xs={6} sm={3}>
                  <div className={classes.search}>
                    <SearchIcon />
                    <IntegrationDownshift
                      label="&nbsp;"
                      placeholder="search in centre"
                      data={Object.values(centres)}
                      onSelect={(selected) =>
                        handleDownshiftSelect(selected.id)
                      }
                      selectedItem={selectedCentre(searchCentre)}
                      onClear={() => setSearchCentre("")}
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={3}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="seartch-text">{`Search in id, name`}</InputLabel>
                    <Input
                      id="seartch-text"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchText("")}
                          >
                            {searchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? "none" : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              medias.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {`${item.centre.name} (${item.centre.number})`}
                  </TableCell>
                  <TableCell className={classes.smCell}>
                    {item.start_date}
                  </TableCell>
                  <TableCell className={classes.smCell}>
                    {item.end_date}
                  </TableCell>
                  <TableCell className={classes.xxsCell}>
                    {item.course_learners.length}
                  </TableCell>
                  <TableCell className={classes.xxsCell}>
                    {courseConfig.steps[item.status]}
                  </TableCell>
                  <TableCell className={classes.xsCell}>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/courses/list/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    |
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(
                          `/courses/list/${item.id}/tracking-grid`
                        )
                      }
                    >
                      <PeopleOutlineIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!loading && medias.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Gaurd check="create_course">
        <Zoom
          className={classes.fabAdd}
          key="add"
          in={true}
          style={{ transitionDelay: "500ms" }}
          unmountOnExit
        >
          <Fab
            aria-label="Add"
            size="small"
            className="classes.fab"
            color="secondary"
            onClick={() => props.history.push(`/courses/create`)}
          >
            <AddIcon />
          </Fab>
        </Zoom>
      </Gaurd>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    centres: state.centres.rows,
    currentRole: state.authUser.currentRole,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCentreNames: () => dispatch(actions.getCentres()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
