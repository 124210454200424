import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import "date-fns";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import IntegrationDownshift from "../../components/IntegrationDownshift";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import AlertDialog from "../../components/AlertDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  doctypeControl: {
    width: "50%",
  },
  statusGroup: {
    flexDirection: "row",
  },
  legend: {
    fontSize: "0.75rem",
  },
  levelLabel: {
    width: 200,
  },
  uploadButton: {
    height: "30px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

function TeacherApproval(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [teacher, setTeacher] = useState({
    id: "",
    name: "",
    secondary_phone: "",
    address: "",
    city: "",
    postcode: "",
    centres: [],
    user: {
      email: "",
      phone: "",
    },
    teacher_docs: [],
  });
  const [selectedDocType, setSelectedDocType] = useState("");
  const [errorData, setErrorData] = useState({});
  const [levels, setLevels] = useState([]);
  const [approvedLevelIds, setApprovedLevelIds] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [openRejectedAlert, setOpenRejectedAlert] = React.useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileItem, setFileItem] = useState({});
  const [documentsReady, setDocumentsReady] = useState(false);

  // Get Qulification with Docs on Mount
  useEffect(() => {
    fetchTeacher();
    props.getCentreNames();
  }, []);

  function fetchTeacher() {
    axios.post(urls.getTeacher(props.approval.entity_id)).then((resp) => {
      if (resp.status == 200 && !resp.data.error && resp.data) {
        if (resp.data.teacher) {
          setTeacher(resp.data.teacher);
          setLevels(resp.data.teacher.centre_teacher_levels);
          let aLevels = resp.data.teacher.centre_teacher_levels.map((item) => {
            return item.approved === 1 ? item.id : null;
          });
          setApprovedLevelIds(aLevels);
          if (resp.data.teacher.centres.length > 0) {
            setSelectedCentre(resp.data.teacher.centres[0]);
          }
        }
        setConfig(resp.data.config);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }
  useEffect(() => {
    const documentTypeIds = teacher.teacher_docs.map((row) => row.doc_type_id);
    let pendingDocUpload = false;
    if (config.documents) {
      Object.keys(config.documents).forEach((key) => {
        if (!documentTypeIds.some((id) => id === key)) {
          pendingDocUpload = true;
        }
      });
      setDocumentsReady(!pendingDocUpload);
    }
  }, [config.documents]);

  // handle form field change
  const handleChange = (name) => (e) => {
    if (name === "email" || name === "phone") {
      setTeacher({
        ...teacher,
        user: { ...teacher.user, [name]: e.target.value },
      });
    } else {
      setTeacher({ ...teacher, [name]: e.target.value });
    }
  };

  // Update Teacher
  function handleSubmit(event) {
    event.preventDefault();
    axios
      .post(urls.updateTeacher(props.match.params.id), teacher)
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/teachers/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((error) => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }

  // handle Level Change
  const handleLevelChange = (levelId) => async (e) => {
    let checked = e.target.checked;
    if (checked) {
      setApprovedLevelIds([...approvedLevelIds, levelId]);
    } else {
      let aLevels = approvedLevelIds.filter((id) => id !== levelId);
      setApprovedLevelIds(aLevels);
    }
  };

  /**
   *  Handle Approval
   */
  async function handleApproval(agree, approve) {
    setOpenApprovalAlert(false);
    if (agree && !approve && rejectionReason === "") {
      props.onEnqueueSnackbar("Provide rejection reason!!", 1000, "error");
      return;
    }
    setOpenRejectedAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.approveTeacher(props.approval.entity_id),
        {
          approvalId: props.approval.id,
          approve,
          rejectionReason,
          approvedLevelIds: approvedLevelIds,
          centre: selectedCentre,
        }
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar("Success!!", 1000, "success");
        props.onUpdate();
        fetchTeacher();
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }
  const handleViewFile = (item) => {
    setOpenFileViewer(true);
    setFileItem(item);
  };
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid item xs={12}>
          {props.approval.status === props.statusCodes.APPROVED && (
            <React.Fragment>
              <Chip color="primary" icon={<DoneIcon />} label="Approved" />
              <div>Approved by {props.approval.user.name}</div>
              <div>Last updated at {props.approval.updated_at}</div>
            </React.Fragment>
          )}
          {props.approval.status === props.statusCodes.REJECTED && (
            <React.Fragment>
              <Chip color="secondary" label="Rejected" />
              <div>Rejected by {props.approval.user.name}</div>
              <div>Last updated at {props.approval.updated_at}</div>
            </React.Fragment>
          )}
          {!documentsReady && (
            <Alert severity="warning">
              <Typography>
                Teachers are required to provide at least one Document of each
                Type in order to be Approved by iBSL.
              </Typography>
              <Typography>
                Valid Document Types are - CV, Teaching Certificates, BSL
                Certificates and Assessor Certificates
              </Typography>
            </Alert>
          )}
          <br />
          <Box display="flex" justifyContent="flex-end" mb={2}>
            {props.approval.status < props.statusCodes.REJECTED && (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  disabled={props.approval.status >= props.statusCodes.REJECTED}
                  onClick={(e) => handleSubmit(e)}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={() => setOpenApprovalAlert(true)}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.button}
                  onClick={() => setOpenRejectedAlert(true)}
                >
                  Reject
                </Button>
              </React.Fragment>
            )}
          </Box>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {teacher.id ? `Teacher` : `Add New Teacher`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="teacher-name"
                    label="Name"
                    className={classes.textField}
                    value={teacher.name || ""}
                    onChange={handleChange("name")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("name")}
                    helperText={errorData.name}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                  <TextField
                    id="teacher-email"
                    label="Email"
                    className={classes.textField}
                    value={teacher.user ? teacher.user.email : ""}
                    onChange={handleChange("email")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("email")}
                    helperText={errorData.email || errorData.users_email_unique}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                  <TextField
                    id="teacher-phone"
                    label="Phone"
                    className={classes.textField}
                    value={
                      teacher.user && teacher.user.phone
                        ? teacher.user.phone
                        : ""
                    }
                    onChange={handleChange("phone")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("phone")}
                    helperText={errorData.phone || errorData.users_phone_unique}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                  <TextField
                    id="secondary-phone"
                    label="Secondary Phone"
                    className={classes.textField}
                    value={teacher.secondary_phone || ""}
                    onChange={handleChange("secondary_phone")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("secondary_phone")}
                    helperText={errorData.secondary_phone}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                  <TextField
                    id="teacher-address"
                    label="Address"
                    className={classes.textField}
                    value={teacher.address || ""}
                    required={true}
                    onChange={handleChange("address")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("address")}
                    helperText={errorData.address}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                  <TextField
                    id="teacher-city"
                    label="City"
                    className={classes.textField}
                    value={teacher.city || ""}
                    required={true}
                    onChange={handleChange("city")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("city")}
                    helperText={errorData.city}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                  <TextField
                    id="teacher-postcode"
                    label="Postcode"
                    className={classes.textField}
                    value={teacher.postcode || ""}
                    required={true}
                    onChange={handleChange("postcode")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("postcode")}
                    helperText={errorData.postcode}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </form>
              </CardContent>
              <CardActions>
                {!isNaN(props.match.params.id) && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Update Teacher
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Teacher Documents
                    </Typography>
                    <Table size="small" aria-label="Policy Documents">
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell align="right">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {teacher.teacher_docs &&
                          teacher.teacher_docs.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                {config.documents &&
                                  config.documents[row.doc_type_id].title}
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  size="small"
                                  color="primary"
                                  className={classes.uploadButton}
                                  onClick={() => handleViewFile(row)}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Approve Qualification Levels
                    </Typography>
                    <TextField
                      id="teacher-centre"
                      label="Centre"
                      className={classes.textField}
                      value={
                        `${selectedCentre.name} ${selectedCentre.number}` || ""
                      }
                      required={true}
                      onChange={handleChange("address")}
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("address")}
                      helperText={errorData.address}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <Box mt={2}>
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Level</TableCell>
                            <TableCell align="right">Approve</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.levels.map((level) => {
                            return (
                              levels &&
                              levels.map(
                                (item) =>
                                  level.id === item.level_id && (
                                    <TableRow key={item.level_id}>
                                      <TableCell component="th" scope="row">
                                        {level.name}
                                      </TableCell>
                                      <TableCell align="right">
                                        <Checkbox
                                          checked={
                                            approvedLevelIds.includes(item.id)
                                              ? true
                                              : false
                                          }
                                          onChange={handleLevelChange(item.id)}
                                          color={
                                            approvedLevelIds.includes(item.id)
                                              ? "primary"
                                              : "secondary"
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                              )
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </CardContent>
                </Card>
                <Box>
                  <Typography variant="caption">
                    ** Color of checkboxes
                  </Typography>
                  <div>
                    <Checkbox color="secondary" checked={true} /> Level is
                    Pending for Approval
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        title="Approve Teacher"
        description="Autogenerated email will be sent to Centre with the teacher updates."
        open={openApprovalAlert}
        handleAlertClose={(agree) => handleApproval(agree, true)}
      />
      <Dialog open={openRejectedAlert} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Confirm Teacher Rejection{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Provide a reason for rejection of this teacher. Centre will be
            notified with the rejection message on their registered email.
          </DialogContentText>
          <TextField
            id="rejection-reason"
            label="Reason for Rejection"
            multiline
            rowsMax="4"
            fullWidth={true}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            className={classes.textField}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleApproval(false, false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleApproval(true, false)}
            color="secondary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    centreNames: state.centres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCentreNames: () => dispatch(actions.getCentres()),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeacherApproval)
);
