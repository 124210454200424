import React from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { urls } from "../../common/urls";
import axios from "axios";

import UserfulHelpLink from "../../components/UsefulHelpLink";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  card: {
    maxWidth: 345,
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    textAlign: "center",
  },
}));
const usefulLinks = [
  {
    heading: "What is Annual Fee",
    content:
      "It is the fee charged for each qualification level for duration of 12 months ",
    link: "https://google.com",
  },
  {
    heading: "What is Assessment Fee",
    content: "It is the total of fee charged per learner for assessment",
    link: "https://google.com",
  },
];
function AccountIndex(props) {
  const classes = useStyles();
  const items = [
    {
      key: "annual-fees",
      name: "Annual Fees",
      link: "/account/annual-fees",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z" />
        </SvgIcon>
      ),
    },
    {
      key: "assessment-fee",
      name: "Assessment Fee",
      link: "/account/assessment-fees",
      icon: (
        <SvgIcon color="primary" fontSize="large">
          <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" />
        </SvgIcon>
      ),
    },
  ];
  const [usefulLinks, setUsefulLinks] = React.useState([]);
  React.useEffect(() => {
    axios.get(urls.getUsefulLinks("account")).then((resp) => {
      if (resp.status === 200) {
        setUsefulLinks(resp.data.row);
      }
    });
  }, []);
  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={11}>
        <Grid container justify="center" spacing={2}>
          {items.map((item) => (
            <Grid key={item.key} item xs={6} sm={4} md={2}>
              <Card className={classes.card}>
                <CardActionArea onClick={() => props.history.push(item.link)}>
                  <CardContent>
                    <div className={classes.iconWrapper}>{item.icon}</div>
                    <Typography component="h5" align="center">
                      {item.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        {usefulLinks.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <UserfulHelpLink helpLinks={usefulLinks} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default withRouter(AccountIndex);
