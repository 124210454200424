import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import MailIcon from "@material-ui/icons/Mail";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import EmailField from "../../../components/Common/Fields/EmailField";

import * as actions from "../../../store/actions";
import {
  updateObject,
  checkValidity,
  randomString,
} from "../../../common/utility";
import ReactCropper from "../../../components/ReactCropper";
import { FormControlLabel } from "@material-ui/core";

const styles = (theme) =>
  createStyles({
    modalPaper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: "none",
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
    content: {
      padding: theme.spacing(2),
    },
    TextField: {
      width: "100%",
    },
    bigAvatar: {
      margin: "10px auto",
      width: 120,
      height: 120,
      fontSize: 60,
    },
    button: {
      marginRight: theme.spacing(4),
    },
    loader: {
      minHeight: 400,
    },
    verificationIcon: {
      marginRight: theme.spacing(1),
      color: "#606060",
      fontSize: 20,
    },
  });
class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      cropResult: null,
      openAssignRoleModal: false,
      user: {},
      roles: [],
      loadingPhoto: true,
      sendingMail: false,
      mailSendStatus: "",
      otp: "",
      sendingOTP: false,
      otpSendStatus: "",
      otpDialogOpen: false,
      otpVerifiying: false,
      password: randomString(10),
      sendingPasswordMail: false,
      sendingPasswordMailStatus: "",
      passwordDialogOpen: false,
      sendPasswordInstruction: false,
      controls: {
        name: {
          value: "",
          validation: {
            required: true,
            minLength: 2,
          },
          valid: true,
          error: "",
          touched: false,
        },
        email: {
          value: "",
          validation: {
            required: true,
            isEmail: true,
          },
          valid: true,
          error: "",
          touched: false,
        },
        phone: {
          value: "",
          validation: {
            required: false,
          },
          valid: true,
          error: "",
          touched: false,
        },
        is_active: {
          value: false,
          validation: {
            required: false,
          },
          valid: true,
          error: "",
          touched: false,
        },
      },
    };
    this.imgRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRoleToggle = this.handleRoleToggle.bind(this);
    this.handleEmailConfirmationMail = this.handleEmailConfirmationMail.bind(
      this
    );
    this.handleMobileVerificationOtp = this.handleMobileVerificationOtp.bind(
      this
    );
    this.handleOtpDialogClose = this.handleOtpDialogClose.bind(this);
    this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
    this.handlePasswordDialogClose = this.handlePasswordDialogClose.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.onGetUser(id);
    axios.get(`api/system/user/${id}/photo`, {}).then((resp) => {
      if (resp.data.url) {
        this.setState({ loadingPhoto: false, src: resp.data.url });
      } else {
        this.setState({ loadingPhoto: false, src: "" });
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user.row != state.user) {
      const controls = Object.assign({}, state.controls);
      for (let key in controls) {
        controls[key].value = props.user.row[key] ? props.user.row[key] : "";
      }
      return {
        user: props.user.row,
        controls: controls,
      };
    }
    return null;
  }

  onImageCrop(resultImg, name, type) {
    let orginalImgurl = this.state.src;
    this.setState({
      src: resultImg,
      cropResult: resultImg,
      loadingPhoto: true,
    });
    fetch(resultImg)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], name, { type: type });
        let data = new FormData();
        const id = this.props.match.params.id;
        data.append("userId", 20);
        data.append("profile_photo", file);
        data.append("mimetype", type);
        data.append("originalname", name);
        //this.props.onUserUpdate(data);
        axios({
          method: "post",
          url: `/api/system/user/${id}/update/photo`,
          data: data,
          config: { headers: { "content-type": `multipart/form-data` } },
        }).then((resp) => {
          axios.get(`api/system/user/${id}/photo`, {}).then((resp) => {
            if (resp.data.error) {
              this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
              this.setState({ loadingPhoto: false, src: orginalImgurl });
            } else {
              this.props.onEnqueueSnackbar("Success!!", 3000, "success");
              this.setState({ loadingPhoto: false });
            }
          });
        });
      })
      .catch(() => {
        this.setState({ loadingPhoto: false, src: orginalImgurl });
      });
  }

  handleInputChange(event, controlName) {
    const eValue =
      controlName === "is_active" ? event.target.checked : event.target.value;

    let { isValid, errorText } = checkValidity(
      eValue,
      this.state.controls[controlName].validation,
      controlName
    );
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: eValue,
        valid: isValid,
        error: errorText,
        touched: true,
      }),
    });

    //const user = updateObject(this.state.user, {[controlName]: controlName === 'is_active' ? event.target.checked : event.target.value});
    this.setState({ controls: updatedControls });
  }
  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const controls = this.state.controls;
    const params = {
      userId: this.props.user.row.id,
    };
    for (let field in controls) {
      params[field] = controls[field].value;
    }
    const notValid = await Object.keys(controls).filter((field) => {
      let { isValid, errorText } = checkValidity(
        controls[field].value,
        this.state.controls[field].validation,
        field
      );
      controls[field].valid = isValid;
      controls[field].error = errorText;
      if (!isValid) return field;
    });
    if (!notValid.length) {
      this.props.onUserUpdate(params);
    }
  }

  handleRoleToggle(role, assign) {
    this.props.onRoleUpdate({
      userId: this.props.user.row.id,
      role: role,
      assign: !assign,
    });
  }
  /* Sending Email Confirmation Mail */
  handleEmailConfirmationMail() {
    this.setState({ sendingMail: true, mailSendStatus: "sending..." });
    let id = this.props.user.row.id;
    axios
      .post(`/api/system/user/${id}/email/confirmation`)
      .then((resp) => {
        if (resp.data.error) {
          this.setState({ sendingMail: true, mailSendStatus: "" });
          this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          this.setState({
            sendingMail: false,
            mailSendStatus: "Confirmation Mail Sent",
          });
        }
      })
      .catch((err) => {
        this.setState({ sendingMail: false, mailSendStatus: "" });
        this.props.onEnqueueSnackbar(err.message, 3000);
      });
  }

  /* Sending OTP for Mobile Verification */
  handleMobileVerificationOtp() {
    this.setState({
      sendingOTP: true,
      otpDialogOpen: true,
      otpSendStatus: "sending...",
    });
    let id = this.props.user.row.id;
    axios
      .post(`/api/system/user/${id}/phone/send/otp`)
      .then((resp) => {
        if (resp.data.error) {
          this.setState({ sendingOTP: true, otpSendStatus: "" });
          this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          this.setState({ sendingOTP: false, otpSendStatus: `OTP sent` });
        }
      })
      .catch((err) => {
        this.setState({ sendingOTP: false, otpSendStatus: "" });
        this.props.onEnqueueSnackbar(err.message, 3000);
      });
  }

  handleOtpDialogClose() {
    this.setState({ otpDialogOpen: false });
  }
  handleOtpSubmit() {
    let id = this.props.user.row.id;
    this.setState({ otpVerifiying: true });
    axios
      .post(`/api/system/user/${id}/phone/verify/otp`, { otp: this.state.otp })
      .then((resp) => {
        if (resp.data.error) {
          this.setState({ sendingOTP: true, otpSendStatus: "" });
          this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          this.setState({ sendingOTP: false, otpSendStatus: `OTP sent` });
        }
      })
      .catch((err) => {
        this.setState({ sendingOTP: false, otpSendStatus: "" });
        this.props.onEnqueueSnackbar(err.message, 3000);
      });
  }

  handlePasswordDialogClose() {
    this.setState({ passwordDialogOpen: false });
  }
  handlePasswordSubmit() {
    let id = this.props.user.row.id;
    this.setState({ sendingPasswordMail: true });
    axios
      .post(`/api/system/user/${id}/password/reset`, {
        password: this.state.password,
        sendPasswordInstruction: this.state.sendPasswordInstruction,
      })
      .then((resp) => {
        if (resp.data.error) {
          this.setState({ sendingPasswordMail: true });
          this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          this.setState({
            sendingPasswordMail: false,
            passwordDialogOpen: false,
          });
          this.props.onEnqueueSnackbar("Success!!", 1000, "success");
        }
      })
      .catch((err) => {
        this.setState({
          sendingPasswordMail: false,
          passwordDialogOpen: false,
        });
        this.props.onEnqueueSnackbar(err.message, 3000);
      });
  }

  render() {
    const { classes, updating } = this.props;
    const { row, roles } = this.props.user;

    const {
      user,
      controls,
      loadingPhoto,
      sendingMail,
      sendingOTP,
      otpVerifiying,
    } = this.state;

    if (this.props.user.loading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.verifStatus}
        >
          <CircularProgress className={classes.progress} color="secondary" />
        </Box>
      );
    }
    return (
      <Box>
        <CssBaseline />
        <Grid container justify="center" spacing={2}>
          <Grid item xs={10}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  {(updating ||
                    loadingPhoto ||
                    sendingMail ||
                    sendingOTP ||
                    otpVerifiying) && <LinearProgress />}
                  <div className={classes.content}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h5">
                          {user.name ? user.name : row.name}
                        </Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                          <MailIcon className={classes.verificationIcon} />
                          {row.emai_verified_at ? (
                            <Typography component="span" variant="body2">
                              Verified
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              variant="body2"
                              color="error"
                            >
                              Not Verified
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div>
                          <Avatar
                            ref={this.imgRef}
                            src={this.state.src}
                            className={classes.bigAvatar}
                          >
                            <AddAPhotoIcon fontSize="large" />
                          </Avatar>
                          {!loadingPhoto ? (
                            <ReactCropper
                              btnText={this.state.src ? "Replace" : "Upload"}
                              onImageCrop={(result, name, type) =>
                                this.onImageCrop(result, name, type)
                              }
                            />
                          ) : (
                            <Typography color="secondary" align="center">
                              Uploading Photo...
                            </Typography>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <TextField
                        id="user-name"
                        label="Name"
                        className={classes.TextField}
                        value={controls.name.value}
                        margin="normal"
                        onChange={(e) => this.handleInputChange(e, "name")}
                        error={
                          this.state.submitted &&
                          (!controls.name.valid || this.props.error)
                        }
                        helperText={
                          this.state.submitted &&
                          (controls.name.error || this.props.errorText)
                        }
                      />
                    </div>
                    <EmailField
                      data={{
                        userId: row.id,
                        email: row.email,
                        verifiedAt: row.email_verified_at,
                        emailSentAt: row.email_sent_at,
                      }}
                      onChange={(e) => this.handleInputChange(e, "email")}
                      onEnqueueSnackbar={this.props.onEnqueueSnackbar}
                    />
                    <TextField
                      id="user-phone"
                      label="Phone"
                      className={classes.TextField}
                      margin="normal"
                      value={controls.phone.value}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+44</InputAdornment>
                        ),
                      }}
                      onChange={(e) => this.handleInputChange(e, "phone")}
                      error={
                        this.state.submitted &&
                        (!controls.phone.valid || this.props.error)
                      }
                      helperText={
                        this.state.submitted &&
                        (controls.phone.error || this.props.errorText)
                      }
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(controls.is_active.value)}
                        color="primary"
                        name="is_active"
                        onChange={(e) => this.handleInputChange(e, "is_active")}
                      />
                    }
                    label="Is Active"
                    labelPlacement="start"
                  />
                  <Divider />
                  <Box className={classes.content}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleSubmit}
                      disabled={updating}
                    >
                      Submit
                    </Button>
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({ passwordDialogOpen: true })
                      }
                    >
                      Reset Password
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  <Box className={classes.content}>
                    <Typography variant="h6">Current Roles</Typography>
                    <Divider />
                    <List>
                      {roles.length > 0 &&
                        roles.map((role, index) => (
                          <ListItem
                            key={`role-${index}`}
                            alignItems="flex-start"
                          >
                            <ListItemText
                              primary={role.name.trim().toUpperCase()}
                              secondary={
                                role.name.trim() == "centre" &&
                                row.centres.map((c) => c.name).join(",")
                              }
                            />
                            <ListItemSecondaryAction>
                              <Switch
                                edge="end"
                                onChange={() =>
                                  this.handleRoleToggle(
                                    role.name.trim(),
                                    row.roles
                                      .map((r) => r.name)
                                      .indexOf(role.name) != -1
                                  )
                                }
                                checked={
                                  row.roles
                                    .map((r) => r.name)
                                    .indexOf(role.name) != -1
                                }
                                inputProps={{
                                  "aria-labelledby": `switch-list-label-${role.name.trim()}`,
                                }}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.otpDialogOpen}
          onClose={this.handleOtpDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Verify Mobile</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter OTP sent on your registered mobile number.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="OTP"
              type="text"
              onChange={(value) => this.setState({ otp: value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOtpDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleOtpSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.passwordDialogOpen}
          onClose={this.handlePasswordDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Password"
              type={this.state.showPassword ? "text" : "password"}
              value={this.state.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        this.setState((state) => ({
                          showPassword: !state.showPassword,
                        }))
                      }
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendPasswordInstruction}
                  onChange={(event) =>
                    this.setState({
                      sendPasswordInstruction: event.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label={`Email new password to user at ${controls.email.value}`}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handlePasswordDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handlePasswordSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    updating: state.user.updating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: (id) => dispatch(actions.getUser(id)),
    onUserUpdate: (params) => dispatch(actions.updateUser(params)),
    onRoleUpdate: (params) => dispatch(actions.updateUserRole(params)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditUser))
);
