import * as actionTypes from './actionTypes';

/*
 * Load All Users
 * Route : /system/users
 */
export const getUsers = params => {
  return {
    type: actionTypes.GET_USERS,
    payload: params
  };
};
export const getUsersStart = () => {
  return {
    type: actionTypes.GET_USERS_START
  };
};

export const getUsersSuccess = data => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    payload: data
  };
};

export const getUsersFail = payload => {
  return {
    type: actionTypes.GET_USERS_FAIL,
    payload
  };
};

/*
 * Load A specific User
 * Route : /system/users/:id
 */

export const getUser = params => {
  return {
    type: actionTypes.GET_USER,
    payload: params
  };
};

export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START
  };
};

export const getUserSuccess = data => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    payload: data
  };
};

export const getUserFail = error => {
  return {
    type: actionTypes.GET_USER_FAIL,
    payload: error
  };
};

/*
 * Update User
 */
export const updateUser = data => {
  return {
    type: actionTypes.UPDATE_USER,
    payload: data
  };
};

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START
  };
};

export const updateUserSuccess = () => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS
  };
};

export const updateUserFail = error => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    payload: error
  };
};

/* 
  Update Role for a given User
 */

export const updateUserRole = data => {
  return {
    type: actionTypes.UPDATE_USER_ROLE,
    payload: data
  };
};

export const updateUserRoleStart = () => {
  return {
    type: actionTypes.UPDATE_USER_ROLE_START
  };
};

export const updateUserRoleSuccess = data => {
  return {
    type: actionTypes.UPDATE_USER_ROLE_SUCCESS,
    payload: data
  };
};

export const updateUserRoleFail = error => {
  return {
    type: actionTypes.UPDATE_USER_ROLE_FAIL,
    error: error
  };
};
