import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { urls } from "../common/urls";
import * as actions from "../store/actions";
import MailIcon from "@material-ui/icons/Mail";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4, 2),
    margin: "auto",
    maxWidth: "786px",
    width: "90%",
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

function VerifyEmail(props) {
  const classes = useStyles();
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    handleEmailVerification();
  }, []);

  /* Sending Email Confirmation Mail */
  const handleEmailVerification = () => {
    setVerifying(true);
    axios
      .post(urls.verifyEmail, {
        code: props.match.params.id,
      })
      .then((resp) => {
        setVerifying(false);
        if (resp.status === 200 && resp.data.error) {
          setVerified(false);
          setError(resp.data.errorText);
          //props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          setUser(resp.data.data);
          setVerified(true);
          //props.onEnqueueSnackbar("Email Successfully Verified", 3000);
        }
      })
      .catch((err) => {
        setVerifying(false);
        setError(err.message);
        //props.onEnqueueSnackbar(err.message, 3000);
      });
  };
  return (
    <Box
      display="flex"
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "60vh" }}
    >
      <Paper alignItems="center" className={classes.paper}>
        <Box display="flex" justifyContent="center" mb={4}>
          <MailIcon style={{ fontSize: "3rem", color: green[500] }} />
        </Box>
        <Typography variant="h4" align="center">
          Hi, {user.name}
        </Typography>
        <br />
        <br />
        {verifying && (
          <Typography variant="body1" align="center">
            {`Please wait! Verifying your email ${user.email}`}
          </Typography>
        )}
        {!verifying && verified && (
          <Typography
            variant="body1"
            align="center"
            color={verified ? "textPrimary" : "error"}
          >
            {`Your email ${user.email} has been successfully verified`}
          </Typography>
        )}
        {!verifying && error && (
          <Typography
            variant="body1"
            align="center"
            className={classes.message}
            color="error"
          >
            {error}
          </Typography>
        )}
        <Box display="flex" mt={2} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.history.push("/")}
          >
            Back To Portal
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default withRouter(VerifyEmail);
