import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Select from "@material-ui/core/Select";
import IntegrationDownshift from "../../components/IntegrationDownshift";
import Autocomplete from "../../components/Autocomplete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";
import moment from "moment";

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "uln", numeric: false, label: "ULN/SCN", sortable: true },
  { id: "first_name", numeric: false, label: "FirstName", sortable: true },
  { id: "last_name", numeric: false, label: "LastName", sortable: true },
  { id: "gender", numeric: false, label: "Gender", sortable: true },
  { id: "dob", numeric: false, label: "DoB", sortable: true },
  { id: "email", numeric: false, label: "Email", sortable: false },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  formroot: {
    width: "100%",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
}));

function LearnerList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [learners, setLearners] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [searchUser, setSearchUser] = useState("");

  useEffect(() => {
    let filters = localStorage.getItem("filter.learners");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setSearchUser(filters.searchUser);
    }
  }, []);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        searchUser: searchUser,
      };
      await localStorage.setItem("filter.learners", JSON.stringify(filters));
      await fetchLearners(filters);
    })();
  }, [searchText, searchUser, page, rowsPerPage, orderBy, order]);

  const fetchLearners = async (params) => {
    let resp = await axios.post(urls.getLearners, params);
    if (resp.status === 200 && resp.data.error === false) {
      await setLearners(resp.data.rows);
      setTotal(resp.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }
  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <Container>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" id="tableTitle">
                  Learners
                </Typography>
              </Grid>

              <Grid item xs={6} sm={3}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="search-centre">{`Search in email, phone`}</InputLabel>
                    <Input
                      id="search-user"
                      value={searchUser}
                      onChange={(event) => setSearchUser(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchUser("")}
                          >
                            {searchUser.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="search-text">{`Search in uln, name`}</InputLabel>
                    <Input
                      id="search-text"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchText("")}
                          >
                            {searchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? "none" : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              learners.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>{item.uln}</TableCell>
                  <TableCell>{item.first_name}</TableCell>
                  <TableCell>{item.last_name}</TableCell>
                  <TableCell>
                    {item.gender === "M" ? "Male" : "Female"}
                  </TableCell>
                  <TableCell>
                    {item.dob
                      ? moment(item.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell>{item.user ? item.user.email : ""}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/people/learners/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!loading && learners.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Zoom
        className={classes.fabAdd}
        key="add"
        in={true}
        style={{ transitionDelay: "500ms" }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          className="classes.fab"
          color="secondary"
          onClick={() => props.history.push(`/people/learners/create`)}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(LearnerList);
