import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/styles';
import Gaurd from '../../hoc/Gaurd';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import MomentUtils from '@date-io/moment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ViewFile from '../../components/ViewFile';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import * as actions from '../../store/actions';
import { urls } from '../../common/urls';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

function Verify(props) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [verifiedAt, setVerifiedAt] = useState(null);
  const [error, setError] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [mailSent, setMailSent] = useState(null);

  /* Sending Email Confirmation Mail */
  const handleEmailConfirmationMail = e => {
    setVerifying(true);
    axios
      .post(
        `/api/system/user/${props.authUser.centre.user_id}/email/confirmation`
      )
      .then(resp => {
        setVerifying(false);
        if (resp.data.error) {
          setMailSent('');
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          setMailSent('Mail Sent');
        }
      })
      .catch(err => {
        setVerifying(false);
        setMailSent('Mail Sent');
        props.onEnqueueSnackbar(err.message, 3000);
      });
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={6}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} gutterBottom>
                  Email Verification
                </Typography>
                {!props.authUser.email_verified_at ? (
                  <React.Fragment>
                    <Typography variant="h5" component="h3">
                      Verify your Email address before proceeding to submit for
                      Approval
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      An email has been sent on your registered email address{' '}
                      {props.authUser.email}
                    </Typography>
                    <Typography variant="body2" component="p">
                      <br />
                    </Typography>
                  </React.Fragment>
                ) : (
                  <Typography variant="h5" component="h3">
                    Your Email address is verified. You can processed Further
                  </Typography>
                )}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleEmailConfirmationMail()}
                  disabled={verifying || mailSent}
                >
                  {!mailSent ? `Resent Verification Mail` : `${mailSent}`}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    centre: state.authUser.centre,
    authUser: state.authUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: data => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Verify));
