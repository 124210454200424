import * as actionTypes from './actionTypes';

/*
 * Load All Notices
 * Route : /notices
 */
export const getNotices = payload => {
  return {
    type: actionTypes.GET_NOTICES,
    payload
  };
};
export const getNoticesStart = () => {
  return {
    type: actionTypes.GET_NOTICES_START
  };
};

export const getNoticesSuccess = payload => {
  return {
    type: actionTypes.GET_NOTICES_SUCCESS,
    payload
  };
};

export const getNoticesFail = payload => {
  return {
    type: actionTypes.GET_NOTICES_FAIL,
    payload
  };
};
