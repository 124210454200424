import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utility';

const initialState = {
  rows: [],
  error: null,
  errorText: '',
  loading: false
};

const getCentresSuccess = (state = initialState, action) => {
  return updateObject(state, {
    rows: action.payload.centres,
    error: false,
    status: 200,
    errorText: '',
    loading: false
  });
};

const getCentresFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.payload.errorText,
    status: action.payload.status,
    loading: false
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CENTRES_SUCCESS:
      return getCentresSuccess(state, action);
    case actionTypes.GET_CENTRES_FAIL:
      return getCentresFail(state, action);
    default:
      return state;
  }
};
