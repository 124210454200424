import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import Aux from '../../hoc/Fragment';
import Logo from '../Logo';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 240,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const DrawerMenu = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    menuItems: props.menus,
    selectedMenuIndex: props.selectedMenuIndex,
    selectedSubMenuIndex: props.selectedSubMenuIndex
  });

  function handleListItemClick(event, menuIndex, subMenuIndex, link = null) {
    let menuItems = [...state.menuItems];
    if (state.selectedMenuIndex) {
      menuItems[state.selectedMenuIndex].open = false;
    }
    menuItems[menuIndex].open = true;
    if (link) {
      props.history.push(link);
      props.toggleDrawerState(event, false, menuIndex, subMenuIndex);
    }
    setState({
      menuItems,
      selectedMenuIndex: menuIndex,
      selectedSubMenuIndex: subMenuIndex
    });
  }
  return (
    <List
      dense
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Logo p={2} />
        </ListSubheader>
      }
      className={classes.root}
    >
      {state.menuItems.map((item, index) => (
        <Aux key={item.key}>
          <ListItem
            button
            selected={state.selectedMenuIndex === index}
            onClick={event =>
              handleListItemClick(
                event,
                index,
                null,
                item.submenu.length > 0 ? null : item.link
              )
            }
          >
            <ListItemText primary={item.label} />
            {item.submenu.length > 0 &&
              (state.selectedMenuIndex === index ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
          </ListItem>
          {item.submenu.length > 0 && (
            <Collapse
              in={state.selectedMenuIndex === index}
              timeout="auto"
              unmountOnExit
            >
              <List dense component="div" disablePadding>
                {item.submenu.map((submenu, subIndex) => (
                  <ListItem
                    button
                    className={classes.nested}
                    key={submenu.key}
                    selected={
                      state.selectedMenuIndex === index &&
                      state.selectedSubMenuIndex === subIndex
                    }
                    onClick={event =>
                      handleListItemClick(event, index, subIndex, submenu.link)
                    }
                  >
                    <ListItemText primary={submenu.label} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
          <Divider />
        </Aux>
      ))}
    </List>
  );
};
const mapStateToProps = state => {
  return {
    menus: state.authUser.menus
  };
};
export default connect(mapStateToProps)(withRouter(DrawerMenu));
