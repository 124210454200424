import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import Gaurd from "../../hoc/Gaurd";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import ViewFile from "../../components/ViewFile";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/styles";
import LearnerIcon from "@material-ui/icons/SupervisorAccount";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CircularProgress from "@material-ui/core/CircularProgress";

//import "date-fns";
import MomentUtils from "@date-io/moment";
//import { moment } from "../../services/utils";
import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import AssessmentStepper from "../../components/Assessment/AssessmentStepper";
import Autocomplete from "../../components/IntegrationDownshift";
import AlertDialog from "../../components/AlertDialog";
import RegisterLearner from "../../components/Assessment/RegisterLearner";
import IssuedMedia from "../../components/Assessment/IssuedMedia";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  mediafab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(10),
  },
}));
const assessmentFields = {
  id: "",
  assessment_no: "",
  assessment_type: "",
  centre_id: "",
  course_id: "",
  qualification_id: "",
  unit_id: "",
  module_id: "",
  title: "",
  assessment_date: null,
  assessment_time: null,
  teacher_id: "",
  examiner_id: "",
  marker_examiner_id: "",
  marking_rate: "",
  marking_rate_type: 0,
  marking_due_date: null,
  approved_at: "",
  approved_by: "",
  is_resit: "",
  status: "",
  assessment_media: [],
};
function AssessmentEdit(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({});
  const [errorData, setErrorData] = useState(false);
  const [assessment, setAssessment] = useState(assessmentFields);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [centreNames, setCentreNames] = useState([]);
  const [course, setCourse] = useState([]);
  const [centreCourses, setCentreCourses] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [openRegisterLearner, setOpenRegisterLearner] = useState(false);
  const [examiners, setExaminers] = useState([]);
  const [rateTypes, setRateTypes] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [issuing, setIssuing] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [openIssuedMedia, setOpenIssuedMedia] = useState(false);
  const [aafUploaded, setAafUploaded] = useState(false);
  const [centre, setCentre] = useState({ address: {}, levels: {}, config: {} });
  const [openMarkingAlert, setOpenMarkingAlert] = useState(false);
  const [markingExaminerId, setMarkingExaminerId] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchAssessment();
      await fetchCentreNames();
      setLoading(false);
    };
    fetchData();
  }, []);

  /** Fetch Assessment */
  function fetchAssessment() {
    axios.post(urls.getAssessment(props.match.params.id)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setAssessment(resp.data.assessment);
        setIssuing(resp.data.assessment.issuing_media_queued);
        setSteps(resp.data.steps);
        setRateTypes(resp.data.rateTypes);
        fetchCentreCourses(resp.data.assessment.centre_id);
        fetchCourseModules(resp.data.assessment.course_id);
        fetchExaminers(
          resp.data.assessment.qualification.level_id,
          resp.data.assessment.module_id
        );
        if (
          resp.data.assessment.status > props.asstStatus.REGISTERATION_STARTED
        ) {
          setReadOnly(true);
        }
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }
  /** Fetch Centres */
  function fetchCentreNames() {
    axios.get(urls.getCentreNames).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        const centres = await resp.data.centres.map((item) => ({
          id: item.id,
          label: `${item.name} (${item.number})`,
        }));
        setCentreNames(centres);
      }
    });
  }
  /** Fetch Courses for selected Centre */

  function fetchCentreCourses(centerId) {
    axios.get(urls.getCentreCourses(centerId)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setCentreCourses(resp.data.courses);
        if (resp.data.courses.length === 0) {
          props.onEnqueueSnackbar(
            "No courses available to book assessment ",
            3000
          );
        }
      }
    });
  }
  /** Fetch Module for selected  */
  function fetchCourseModules(courseId) {
    axios
      .post(urls.getCourseModules(courseId), {
        is_assessment: true,
        is_resit: assessment.is_resit,
      })
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          setCourseModules(resp.data.modules);
          if (resp.data.modules.length === 0) {
            props.onEnqueueSnackbar(
              "No assessment modules available to book assessment ",
              3000
            );
          }
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }
  /** Fetch Examiners */
  const fetchExaminers = async (levelId, moduleId) => {
    let resp = await axios.post(urls.getExaminerList, { levelId, moduleId });
    if (resp.status === 200 && resp.data.error === false) {
      setExaminers(resp.data.rows);
    }
  };
  /** Handle Field Change */
  const handleChange = (name) => async (e) => {
    setAssessment({ ...assessment, [name]: e.target.value });
    delete errorData[name];
    if (name === "course_id") {
      fetchCourseModules(e.target.value);
      let course = centreCourses.find((c) => c.id === e.target.value);
      setCourse(course);
    }
  };
  /** get the selected centre */
  const selectedCentre = (id) => {
    let c = centreNames.filter((item) => item.id === id);
    return c.length ? c[0] : null;
  };
  /** handle Course field change */
  function handleCentreChange(id) {
    selectedCentre(id);
    setAssessment({ ...assessment, centre_id: id });
    delete errorData.centre_id;
    fetchCentreCourses(id);
    fetchCentre(id);
  }
  /** handle Course field change */
  const handleMExaminerChange = async (e, agree = false) => {
    if (e) {
      setMarkingExaminerId(e.target.value);
    } else {
      setOpenMarkingAlert(false);
    }
    if (e && assessment.status >= props.asstStatus.MARKING_ALLOCATED) {
      setOpenMarkingAlert(true);
      return;
    }
    if (agree) {
      let examinerArr = await examiners.filter(
        (item) => item.id === markingExaminerId
      );
      let feeException = examinerArr[0].examiner_fee_exceptions.filter(
        (item) =>
          item.level_id === assessment.qualification.level_id &&
          item.module_id === assessment.module_id
      );
      let { default_rate: marking_rate, default_rate_type: marking_rate_type } =
        examinerArr.length > 0 ? examinerArr[0] : {};
      if (feeException.length > 0) {
        marking_rate = feeException[0].rate;
        marking_rate_type = feeException[0].rate_type;
      }
      setAssessment({
        ...assessment,
        marker_examiner_id: markingExaminerId,
        marking_rate: marking_rate,
        marking_rate_type: marking_rate_type,
      });
      delete errorData.examiner_id;
    }
  };
  /** Clear Centre Selection */
  function handleClearCentre() {
    setAssessment({
      ...assessment,
      centre_id: "",
      course_id: "",
      module_id: "",
    });
  }
  /** Handle Assessment Date Field Change */
  const handleDateChange = (name) => (e) => {
    setAssessment({
      ...assessment,
      [name]: e,
    });
    delete errorData[name];
  };
  // Update Assessment
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.updateAssessment(props.match.params.id), {
        assessment: assessment,
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          fetchAssessment();
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/courses/list/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  /** Handle  */
  const handleOpenRegisterLearner = () => {
    setOpenRegisterLearner(true);
  };
  /** Handle  */
  const handleCloseRegisterLearner = () => {
    setOpenRegisterLearner(false);
    fetchAssessment();
  };

  /** Handle  */
  const handleOpenIssuedMedia = () => {
    setOpenIssuedMedia(true);
  };
  /** Handle  */
  const handleCloseIssuedMedia = () => {
    setOpenIssuedMedia(false);
  };

  /**
   *  Send for Approval
   */

  async function hanleSendForApproval(agree) {
    setOpenApprovalAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.requestAssessmentApproval(props.match.params.id)
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar("Success!!", 1000, "success");
        fetchAssessment();
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }
  /** Handle Issue Media */
  const handleIssueMedia = () => {
    try {
      setIssuing(true);
      axios
        .post(urls.issueAssessmentMedia(props.match.params.id))
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            props.onEnqueueSnackbar("Success!!", 1000, "success");
            setIssuing(false);
            fetchAssessment();
          } else {
            setIssuing(false);
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((err) => {
          setIssuing(false);
          props.onEnqueueSnackbar(err.message, 3000);
        });
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000);
      setIssuing(false);
    }
  };
  /** Download Media */
  async function handleDownloadIssuedMedia() {
    try {
      setDownloading(true);
      let resp = await axios.post(
        urls.getAssessmentIssuedMedias(props.match.params.id)
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar("Success!!", 1000, "success");
        window.open(resp.data.url);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  }

  // Handle AAF File Upload
  const handleAafFileUpload = async () => {
    if (props.uploader.uploading) {
      props.onEnqueueSnackbar(
        "Please wait for previous upload to finish",
        3000
      );
      return;
    }
    let destination = `assessments/${assessment.id}/aaf`;
    try {
      let data = {
        url: urls.updateAssessmentAaf(assessment.id),
        uploadType: "Media",
        id: assessment.id,
        uid: Date.now(),
        location: props.history.location.pathname,
        destination: destination,
      };
      props.uploadFile(data);
      setAafUploaded(true);
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000);
    }
  };

  useEffect(() => {
    if (props.uploader.uploading == false && aafUploaded) {
      fetchAssessment();
    }
  }, [props.uploader.uploading, aafUploaded]);
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileItem, setFileItem] = useState({});
  const handleViewFile = (path) => {
    setOpenFileViewer(true);
    setFileItem({ path: path, type: path.split(".").pop() });
  };

  const handleCloseFileViewer = () => {
    setOpenFileViewer(false);
    setFileItem({});
  };

  function fetchCentre(id) {
    axios
      .post(urls.getCentre(id))
      .then(async (resp) => {
        if (resp.status == 200 && !resp.data.error) {
          let newStatus = resp.data.centre.centre_statuses.pop();
          let levels = resp.data.centre.centre_levels.map((item) => ({
            level_id: item.level_id,
            approved: item.approved,
          }));
          const centreAdd = resp.data.address ? resp.data.address.PRIMARY : {};
          setCentre({
            ...resp.data.centre,
            status: newStatus,
            address: resp.data.address ? resp.data.address.PRIMARY : {},
            levels: levels,
            config: resp.data.config,
          });
          setAssessment({
            ...assessment,
            centre_id: id,
            address: centreAdd.address,
            city: centreAdd.city,
            postcode: centreAdd.postcode,
          });
        }
      })
      .catch((err) => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container justify="center">
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AssessmentStepper
                steps={steps}
                activeStep={assessment.status}
                {...props}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Gaurd check="update_assessment">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={assessment.status > props.asstStatus.EXAMINED}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Update
                  </Button>
                  {issuing !== null && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={(e) => handleIssueMedia(e)}
                        disabled={
                          issuing ||
                          assessment.status < props.asstStatus.FEES_PAID ||
                          assessment.status > props.asstStatus.STARTED
                        }
                      >
                        {!issuing
                          ? assessment.status >= props.asstStatus.MEDIA_ISSUED
                            ? "ReIssue Media"
                            : "Issue Media"
                          : "Issue Media in Queue"}
                      </Button>
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={
                      assessment.status < props.asstStatus.COMPLETED ||
                      assessment.status >= props.asstStatus.EVIDENCE_COMPLETE ||
                      aafUploaded
                    }
                    onClick={() => handleAafFileUpload()}
                  >
                    {assessment.aaf_path ? "ReUpload AAF" : "Upload AAF"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={!assessment.aaf_path}
                    onClick={() => handleViewFile(assessment.aaf_path)}
                  >
                    View AAF
                  </Button>
                  {assessment.status === 110 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.button}
                      disabled={
                        assessment.status !==
                        props.asstStatus.REGISTERATION_STARTED
                      }
                      onClick={() => setOpenApprovalAlert(true)}
                    >
                      Send For Approval
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    disabled={assessment.status <= props.asstStatus.FEES_PAID}
                    onClick={() =>
                      props.history.push(
                        `/assessments/list/${assessment.id}/evidence`
                      )
                    }
                  >
                    Evidence
                  </Button>
                </Gaurd>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card className={classes.cardRoot}>
                {loading && <LinearProgress color="secondary" />}
                <CardContent>
                  <Typography className={classes.title} color="textSecondary">
                    {`Assessment # ${assessment.assessment_no}`}
                  </Typography>
                  <form
                    className={classes.formContainer}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="assessment-title"
                      label="Title"
                      className={classes.textField}
                      value={assessment.title}
                      onChange={handleChange("title")}
                      margin="normal"
                      required={true}
                      fullWidth={true}
                      error={errorData.hasOwnProperty("title")}
                      helperText={errorData.title}
                      InputProps={{
                        readOnly: assessment.status !== 100 ? readOnly : false,
                      }}
                    />
                    <Autocomplete
                      label="Centre"
                      placeholder="Search in Centre"
                      data={centreNames}
                      onSelect={(selected) => handleCentreChange(selected.id)}
                      onClear={() => handleClearCentre()}
                      selectedItem={selectedCentre(assessment.centre_id)}
                      error={errorData.hasOwnProperty("centre_id")}
                      helperText={errorData.centre_id}
                      InputProps={{
                        readOnly:
                          assessment.status !== props.asstStatus.DRAFT
                            ? readOnly
                            : false,
                      }}
                      disabled={assessment.status !== props.asstStatus.DRAFT}
                    />
                    <TextField
                      id="select-courses"
                      select
                      label="Course"
                      className={classes.textField}
                      value={assessment.course_id}
                      onChange={handleChange("course_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("course_id")}
                      helperText={errorData.course_id}
                      InputProps={{
                        readOnly:
                          assessment.status !== props.asstStatus.DRAFT
                            ? readOnly
                            : false,
                      }}
                    >
                      {centreCourses &&
                        centreCourses.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="select-modules"
                          select
                          label="Module"
                          className={classes.textField}
                          value={assessment.module_id}
                          onChange={handleChange("module_id")}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth={true}
                          error={errorData.hasOwnProperty("module_id")}
                          helperText={errorData.module_id}
                          InputProps={{
                            readOnly:
                              assessment.status !== props.asstStatus.DRAFT
                                ? readOnly
                                : false,
                          }}
                        >
                          {courseModules &&
                            courseModules.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {`${item.name} - ${item.title}`}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="assessment-type"
                          label="Assessed By"
                          className={classes.textField}
                          value={
                            assessment && assessment.AssessmentType
                              ? assessment.AssessmentType.name
                              : ""
                          }
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth={true}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                    {assessment.assessment_type === 3 && (
                      <TextField
                        id="select-teacher"
                        select
                        label="Teacher"
                        className={classes.textField}
                        value={assessment.teacher_id}
                        onChange={handleChange("teacher_id")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("teacher_id")}
                        helperText={errorData.teacher_id}
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      >
                        <MenuItem value={null}></MenuItem>
                        {centreCourses &&
                          centreCourses.map((item) =>
                            item.id === assessment.course_id ? (
                              <MenuItem
                                key={item.teacher.id}
                                value={item.teacher.id}
                              >
                                {item.teacher.name}
                              </MenuItem>
                            ) : (
                              ""
                            )
                          )}
                      </TextField>
                    )}

                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          label="Assessment Date"
                          margin="normal"
                          format="DD-MM-YYYY"
                          value={assessment.assessment_date || null}
                          onChange={handleDateChange("assessment_date")}
                          error={errorData.hasOwnProperty("assessment_date")}
                          helperText={errorData.assessment_date}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Assessment Time"
                          value={assessment.assessment_time || null}
                          onChange={handleDateChange("assessment_time")}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          error={errorData.hasOwnProperty("assessment_time")}
                          helperText={errorData.assessment_time}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      id="assessment-address"
                      label="Address"
                      InputLabelProps={{ shrink: true }}
                      className={classes.textField}
                      value={assessment.address}
                      onChange={handleChange("address")}
                      margin="normal"
                      required={true}
                      fullWidth={true}
                      error={errorData.hasOwnProperty("address")}
                      helperText={errorData.address}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <TextField
                      id="alternate-city"
                      label="City"
                      required={true}
                      className={classes.textField}
                      value={assessment.city || centre.address.city}
                      onChange={handleChange("city")}
                      margin="dense"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("city")}
                      helperText={errorData.city}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      id="assessment-postcode"
                      label="Postcode"
                      InputLabelProps={{ shrink: true }}
                      className={classes.textField}
                      value={assessment.postcode}
                      onChange={handleChange("postcode")}
                      margin="normal"
                      required={true}
                      fullWidth={true}
                      error={errorData.hasOwnProperty("postcode")}
                      helperText={errorData.postcode}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <TextField
                      id="select-teacher"
                      select
                      label="Teacher"
                      className={classes.textField}
                      value={assessment.teacher_id}
                      onChange={handleChange("teacher_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("teacher_id")}
                      helperText={errorData.teacher_id}
                    >
                      {centreCourses &&
                        centreCourses.map((item) =>
                          item.id === assessment.course_id ? (
                            <MenuItem
                              key={item.teacher.id}
                              value={item.teacher.id}
                            >
                              {item.teacher.name}
                            </MenuItem>
                          ) : null
                        )}
                    </TextField>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            {((assessment.assessment_type == 5 &&
              assessment.status >= props.asstStatus.COMPLETED) ||
              (assessment.assessment_type !== 5 &&
                assessment.status >= props.asstStatus.EVIDENCE_COMPLETE)) && (
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <TextField
                      id="select-examiner"
                      select
                      label="Examiner"
                      className={classes.textField}
                      value={assessment.examiner_id}
                      onChange={handleChange("examiner_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("examiner_id")}
                      helperText={errorData.examiner_id}
                    >
                      {examiners &&
                        examiners.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="select-marking-examiner"
                      select
                      label="Marking Examiner"
                      className={classes.textField}
                      value={assessment.marker_examiner_id}
                      onChange={(e) => handleMExaminerChange(e)}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("marker_examiner_id")}
                      helperText={errorData.marker_examiner_id}
                    >
                      {examiners &&
                        examiners
                          .filter((i) => i.external_examiner === 1)
                          .map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                    </TextField>
                    <KeyboardDatePicker
                      label="Marking Due Date"
                      margin="normal"
                      fullWidth={true}
                      format="DD-MM-YYYY"
                      value={assessment.marking_due_date || null}
                      onChange={handleDateChange("marking_due_date")}
                      error={errorData.hasOwnProperty("marking_due_date")}
                      helperText={errorData.marking_due_date}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="assessment-rate"
                          label="Marking Rate"
                          type="number"
                          className={classes.textField}
                          value={assessment.marking_rate}
                          onChange={handleChange("marking_rate")}
                          margin="normal"
                          fullWidth={true}
                          error={errorData.hasOwnProperty("marking_rate")}
                          helperText={errorData.marking_rate}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="select-modules"
                          select
                          label="Marking Rate Type"
                          className={classes.textField}
                          value={assessment.marking_rate_type}
                          onChange={handleChange("marking_rate_type")}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth={true}
                          error={errorData.hasOwnProperty("marking_rate_type")}
                          helperText={errorData.marking_rate_type}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        >
                          {rateTypes &&
                            rateTypes.map((item, index) => (
                              <MenuItem key={index} value={index}>
                                {item}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          {assessment.id && (
            <React.Fragment>
              <Gaurd check="update_assessment">
                <Zoom
                  className={classes.mediafab}
                  key="media"
                  in={true}
                  disabled={assessment.status < props.asstStatus.MEDIA_ISSUED}
                  style={{ transitionDelay: "500ms" }}
                  unmountOnExit
                >
                  <Tooltip title="Media" aria-label="Media" placement="left">
                    <Fab
                      aria-label="Media"
                      className="classes.mediaFab"
                      color="secondary"
                      onClick={handleOpenIssuedMedia}
                    >
                      <PermMediaIcon />
                    </Fab>
                  </Tooltip>
                </Zoom>
              </Gaurd>
              <Zoom
                className={classes.fab}
                key="add"
                in={true}
                style={{ transitionDelay: "500ms" }}
                unmountOnExit
              >
                <Tooltip
                  title="Learners"
                  aria-label="Learners"
                  placement="left"
                >
                  <Fab
                    aria-label="Learners"
                    className="classes.fab"
                    color="secondary"
                    onClick={handleOpenRegisterLearner}
                  >
                    <LearnerIcon />
                  </Fab>
                </Tooltip>
              </Zoom>
              <RegisterLearner
                openRegisterLearner={openRegisterLearner}
                onCloseRegisterLearner={handleCloseRegisterLearner}
                assessment={assessment}
              />
              <IssuedMedia
                openIssuedMedia={openIssuedMedia}
                onCloseIssuedMedia={handleCloseIssuedMedia}
                assessment={assessment}
              />
            </React.Fragment>
          )}
          <AlertDialog
            title="Send For Approval"
            description="You won't be able to make changes to assessment after sending for approval. Are you sure ?"
            open={openApprovalAlert}
            handleAlertClose={(agree) => hanleSendForApproval(agree)}
          />
          <AlertDialog
            title="ReOpen Marking"
            description="Are you sure you want to Reopen Marking?"
            open={openMarkingAlert}
            handleAlertClose={(agree) => handleMExaminerChange(null, agree)}
          />
          {openFileViewer && (
            <ViewFile item={fileItem} onClose={() => handleCloseFileViewer()} />
          )}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    uploader: state.uploader,
    levels: state.common.levels,
    modules: state.common.modules,
    asstStatus: state.common.assessmentStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentEdit)
);
