import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import Button from "@material-ui/core/Button";
import AlertDialog from "../../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";

import { urls } from "../../common/urls";
import * as actions from "../../store/actions";
const individualPassGrade = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  table: {
    "& td,th": {
      border: "1px solid #e0e0e0",
      fontSize: "13px",
    },
    "& .bg": {
      background: "#eaf1dd",
    },
  },
  noBorder: {
    border: "none",
  },
  label: {
    background: "#eaf1dd",
    "&.sm": {
      maxWidth: "60px",
    },
  },
  withoutLabel: {
    maxWidth: "50px",
  },
  bg: {
    background: "#eaf1dd",
  },
  cell: {
    borderRight: "1px solid #e0e0e0",
    backgroundColor: "#FFF",
  },
  radio: {
    padding: 0,
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  total: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  required: {
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '"*"',
      color: "red",
      top: "10px",
      right: "10px",
    },
  },
  chip: {
    marginRight: "10px",
    fontWeight: "900",
  },
}));

let dataTop = [];
const dataMiddle = {
  header: [
    [
      {
        label: "Skills",
        key: "skills",
        value: "",
        colSpan: 1,
        rowSpan: 2,
        class: ["bg"],
      },
      {
        label: "PC",
        key: "pc",
        value: "",
        colSpan: 1,
        rowSpan: 2,
        class: ["bg"],
      },
      {
        label: "Assessment Criteria",
        key: "assessment_criteria",
        value: "",
        colSpan: 1,
        rowSpan: 2,
        class: ["bg"],
      },
      {
        label: "ACHIEVED",
        key: "achieved",
        value: "",
        colSpan: 3,
        rowSpan: 1,
        align: "center",
        class: ["bg"],
      },
      {
        label: "NOT ACHIEVED",
        key: "not_achieved",
        value: "",
        colSpan: 2,
        rowSpan: 1,
        align: "center",
        class: [],
      },
      {
        label: "TOTAL",
        key: "total",
        value: "",
        colSpan: 1,
        rowSpan: 1,
        align: "center",
        class: [],
      },
    ],
    [
      {
        label: (
          <div>
            <div>5</div>
            <div>Excellent</div>
          </div>
        ),
        key: "achieved",
        value: "",
        colSpan: 1,
        rowSpan: 1,
        align: "center",
        class: ["bg"],
      },
      {
        label: (
          <div>
            <div>4</div>
            <div>Very Good</div>
          </div>
        ),
        key: "not_achieved",
        value: "",
        colSpan: 1,
        rowSpan: 1,
        align: "center",
        class: ["bg"],
      },
      {
        label: (
          <div>
            <div>3</div>
            <div>Good</div>
          </div>
        ),
        key: "good",
        value: "",
        colSpan: 1,
        rowSpan: 1,
        align: "center",
        class: ["bg"],
      },
      {
        label: (
          <div>
            <div>2</div>
            <div>Week</div>
          </div>
        ),
        key: "week",
        value: "",
        colSpan: 1,
        rowSpan: 1,
        align: "center",
        class: [],
      },
      {
        label: (
          <div>
            <div>1</div>
            <div>Very Poor</div>
          </div>
        ),
        key: "poor",
        value: "",
        colSpan: 1,
        rowSpan: 1,
        align: "center",
        class: [],
      },
      {
        label: "total_marks",
        key: "total_marks",
        value: "",
        colSpan: 1,
        rowSpan: 1,
        align: "center",
        class: ["total"],
      },
    ],
  ],
};
const Marksheet = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [updatingMarks, setUpdatingMarks] = useState(false);
  const [details, setDetails] = useState([]);
  const [fields, setFields] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [total, setTotal] = React.useState(null);
  const [pass, setPass] = React.useState(false);
  const [mandatory, setMandatory] = React.useState([]);
  const [scores, setScores] = useState({});
  const [marks, setMarks] = useState({});
  const [touched, setTouched] = React.useState(false);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [module, setModule] = useState({});

  useEffect(() => {
    dataTop = [
      [
        {
          label: "Centre No",
          key: "centre_no",
          value: props.assessment ? props.assessment.centre.number : "",
        },
        {
          label: "Assessment Ref",
          key: "assessment_number",
          value: props.assessment ? props.assessment.assessment_no : "",
        },
        {
          label: "Assessment Date",
          key: "assessment_date",
          value: props.assessment ? props.assessment.assessment_date : "",
        },
      ],
      [
        {
          label: "Learners Name",
          key: "learner_name",
          value: props.learner
            ? `${props.learner.first_name} ${props.learner.last_name}`
            : "",
          valSpan: 2,
        },
        {
          label: "ULN",
          key: "uln",
          value: props.learner ? props.learner.uln : "",
          valSpan: 2,
        },
      ],
    ];
    const fetchData = async () => {
      setLoading(true);
      await fetchMarksheet();
      if (props.assessment) {
        await getMarks();
      }

      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (props.match && !isNaN(props.match.params.id)) {
      axios.post(urls.getModule(props.match.params.id)).then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setModule(resp.data.module);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
    }
  }, []);

  /** Get Marks */
  function getMarks() {
    axios
      .post(urls.getAssessmentMark(props.assessment.id), {
        learner_id: props.learner.id,
      })
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          if (resp.data.rows.length > 0) {
            let scoreObj = {};
            resp.data.rows.forEach((item) => {
              scoreObj[item.marksheet_id] = item.teacher;
            });
            setScores(scoreObj);
          }
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }
  /** Fetch Centres */
  function fetchMarksheet() {
    axios
      .post(
        urls.getModuleMarksheet(
          props.assessment ? props.assessment.module_id : props.match.params.id
        )
      )
      .then(async (resp) => {
        if (
          resp.status === 200 &&
          !resp.data.error &&
          resp.data.rows.length > 0
        ) {
          let rows = resp.data.rows;
          setFields(rows);
          let records = [];
          let skills = [];
          let mandatory = [];
          rows.forEach((row) => {
            let required = parseInt(row.required);
            if (required) {
              mandatory.push(row.id);
            }
            if (skills.includes(row.skill)) {
              let record = records.filter((item) => item.skill === row.skill);
              if (record.length === 0) {
                record = {
                  skill: row.skill,
                  criterias: [
                    {
                      id: row.id,
                      pc: row.pc,
                      criteria: row.criteria.split(","),
                      required: required ? true : false,
                    },
                  ],
                };
                records.push(record);
              } else {
                record[0].criterias.push({
                  id: row.id,
                  pc: row.pc,
                  criteria: row.criteria.split(","),
                  required: required ? true : false,
                });
                skills.push(row.skill);
              }
            } else {
              let record = {
                skill: row.skill,
                criterias: [
                  {
                    id: row.id,
                    pc: row.pc,
                    criteria: row.criteria.split(","),
                    required: required ? true : false,
                  },
                ],
              };
              records.push(record);
              skills.push(row.skill);
            }
          });
          setDetails(records);
          setMandatory(mandatory);
        }
      });
  }

  const handleScoreChange = (args) => (e) => {
    setScores({ ...scores, [args.key]: args.score });
    setTouched(true);
  };
  useEffect(() => {
    let pass_grade;
    if (props.assessment) {
      pass_grade = props.assessment.module.pass_grade;
    } else {
      pass_grade = module.pass_grade;
    }
    if (Object.values(scores).length) {
      let t = Object.values(scores).reduce((a, c) => a + c);
      setTotal(t);
      let eq = mandatory.every((e) => scores[e] >= individualPassGrade);
      if (eq && t >= pass_grade) {
        setPass(true);
      } else {
        setPass(false);
      }
    }
  }, [scores]);

  /** Save Marks */
  const handleSaveMarks = () => {
    setUpdatingMarks(true);
    let ids = [];
    details.forEach((row) =>
      row.criterias.forEach((item) => ids.push(item.id))
    );
    console.log(ids);
    let eq = ids.every((e) =>
      Object.keys(scores)
        .map((i) => parseInt(i))
        .includes(e)
    );
    if (!eq) {
      setOpenApprovalAlert(true);
      setUpdatingMarks(false);
    } else {
      axios
        .post(urls.updateAssessmentMarksheet(props.assessment.id), {
          scores: scores,
          learner: props.learner,
        })
        .then(async (resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            props.onEnqueueSnackbar("Success!!", 1000, "success");
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
          setTouched(false);
          setUpdatingMarks(false);
        })
        .catch((err) => {
          props.onEnqueueSnackbar(err.message, 3000);
          setUpdatingMarks(false);
        });
    }
  };

  const handleAlertClose = () => {
    setOpenApprovalAlert(false);
  };
  return (
    <Container className={classes.root}>
      <section>
        <Table size="small" className={classes.table}>
          <TableBody>
            {dataTop.map((row, index) => (
              <TableRow key={index}>
                {row.map((item) => (
                  <React.Fragment key={item.key}>
                    <TableCell
                      className={clsx(classes.label, "sm", classes.cell)}
                    >
                      {item.label}
                    </TableCell>
                    <TableCell
                      className={clsx(classes.field, classes.cell)}
                      colSpan={item.valSpan}
                    >
                      {item.value}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
      <Box display="flex" justifyContent="flex-end" my={2}>
        <Chip
          className={classes.chip}
          label={pass ? "PASS" : "FAIL"}
          color={pass ? "primary" : "secondary"}
        />
        {props.assessment && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!touched || updatingMarks}
            onClick={handleSaveMarks}
          >
            {!updatingMarks ? (
              "SAVE"
            ) : (
              <CircularProgress size={18} disableShrink />
            )}
          </Button>
        )}
      </Box>
      <Paper>
        <section>
          <Table size="small" className={classes.table}>
            <TableHead>
              {dataMiddle.header.map((row, i) => (
                <TableRow key={i}>
                  {row.map((item, index) => (
                    <TableCell
                      key={index}
                      className={clsx(item.class)}
                      rowSpan={item.rowSpan}
                      colSpan={item.colSpan}
                      align={item.align}
                    >
                      {item.key === "total_marks" ? total : item.label}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {details.map((row, rIndex) =>
                row.criterias.map((item, index) => (
                  <TableRow key={`${rIndex}${index}`}>
                    {index == 0 && (
                      <TableCell
                        className={clsx(classes.cell)}
                        rowSpan={row.criterias.length}
                        align="center"
                      >
                        {row.skill}
                      </TableCell>
                    )}
                    <TableCell className={clsx(classes.cell)} align="center">
                      {item.pc}
                    </TableCell>
                    <TableCell
                      className={clsx(classes.cell, {
                        [classes.required]: item.required,
                      })}
                      align="left"
                    >
                      {item.criteria.map((c, i) => (
                        <div key={`c-${i}`}>{c}</div>
                      ))}
                    </TableCell>
                    {[5, 4, 3, 2, 1].map((score) => (
                      <TableCell
                        key={`score-${score}`}
                        className={clsx(classes.cell)}
                        align="center"
                      >
                        {" "}
                        <Radio
                          className={clsx(classes.radio)}
                          checked={scores[item.id] === score}
                          onChange={handleScoreChange({
                            key: item.id,
                            score: score,
                          })}
                          color="primary"
                          value={score}
                          name={`radio-button-${score}`}
                          inputProps={{ "aria-label": score }}
                          icon={
                            <RadioButtonUncheckedIcon
                              fontSize="small"
                              style={{ color: "#eee" }}
                            />
                          }
                          checkedIcon={
                            <RadioButtonCheckedIcon
                              fontSize="small"
                              color="primary"
                            />
                          }
                        />
                      </TableCell>
                    ))}
                    <TableCell className={clsx(classes.cell)} align={"center"}>
                      {scores[item.id]}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </section>
      </Paper>

      {/* {JSON.stringify(scores,null,2)}
      {JSON.stringify(marks,null,2)} */}
      <AlertDialog
        title="Error"
        description="Please mark all the Assessment Criteria's"
        open={openApprovalAlert}
        handleAlertClose={(agree) => handleAlertClose(agree)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Marksheet);
