import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TeacherEdit from "./TeacherEdit";
import TeacherCreate from "./TeacherCreate";
import * as actions from "../../../store/actions";
import { urls } from "../../../common/urls";
import axios from "axios";

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "name", numeric: false, label: "Name", sortable: true },
  { id: "email", numeric: false, label: "Email", sortable: true },
  { id: "centre", numeric: false, label: "Centre", sortable: false },
  { id: "phone", numeric: false, label: "Phone", sortable: false },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  formroot: {
    width: "100%",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function TeacherList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchCentre, setSearchCentre] = useState("");
  const [centres, setCentres] = useState([]);
  const [viewTeacherId, setViewTeacherId] = useState(null);

  useEffect(() => {
    let filters = localStorage.getItem("filter.teachers");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setSearchUser(filters.searchUser);
      setSearchCentre(filters.searchCentre);
    }
    props.getCentreNames();
  }, []);

  useEffect(() => {
    if (props.centres.length > 0) {
      let centres = props.centres.map((item) => ({
        id: item.id,
        label: `${item.name} (${item.number})`,
      }));
      setCentres(centres);
    }
  }, [props.centres]);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        searchUser: searchUser,
        searchCentre: searchCentre,
      };
      console.log(filters);
      await localStorage.setItem("filter.teachers", JSON.stringify(filters));
      await fetchTeachers(filters);
    })();
  }, [searchText, searchUser, searchCentre, page, rowsPerPage, orderBy, order]);

  const fetchTeachers = async (params) => {
    let resp = await axios.post(urls.getTeachers, params);
    if (resp.status === 200 && resp.data.error === false) {
      await setTeachers(resp.data.rows);
      setTotal(resp.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }
  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setViewTeacherId(null);
    setOpen(true);
  };

  const handleClose = async () => {
    console.log("onClose={handleClose}");
    setOpen(false);
    await fetchTeachers({});
  };
  const handleViewTeacher = (id) => {
    setViewTeacherId(id);
    setOpen(true);
  };

  const handleCloseModal = async (id) => {
    console.log("onClose", id);
    await fetchTeachers({});
    handleViewTeacher(id);
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" id="tableTitle">
            Teachers
          </Typography>
        </Toolbar>
        {!teachers.length ? (
          <Card>
            <CardContent>
              <Box display="flex" direction="row">
                <Typography variant="h6" className={classes.title}>
                  Add a New Teacher or Associate Existing Teacher
                </Typography>
                <Button color="secondary" onClick={handleClickOpen}>
                  Add Now
                </Button>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                {headRows.map((row) => (
                  <TableCell
                    key={row.id}
                    align={row.disablePadding ? "none" : "inherit"}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    {row.sortable ? (
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={() => handleSorting(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    ) : (
                      row.label
                    )}
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                teachers.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.id}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.user.email}</TableCell>
                    <TableCell>
                      {item.centres.length > 0 &&
                        item.centres.map((centre) => (
                          <div
                            key={centre.number}
                          >{`${centre.name} (${centre.number})`}</div>
                        ))}
                    </TableCell>
                    <TableCell>
                      {item.user.phone ? item.user.phone : item.secondary_phone}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => handleViewTeacher(item.id)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {!loading && teachers.length == 0 && (
                <TableRow>
                  <TableCell colSpan="9" align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Zoom
        className={classes.fabAdd}
        key="add"
        in={true}
        style={{ transitionDelay: "500ms" }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          className="classes.fab"
          color="secondary"
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <Typography variant="h6" className={classes.title}>
                {viewTeacherId ? "Update " : "Create"} Teacher
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box mt={5}>
          {viewTeacherId ? (
            <TeacherEdit id={viewTeacherId} />
          ) : (
            <TeacherCreate onClose={(id) => handleCloseModal(id)} />
          )}
        </Box>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    currentRole: state.authUser.currentRole,
    centres: state.centres.rows,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCentreNames: () => dispatch(actions.getCentres()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TeacherList);
