import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";

import CentreApproval from "./CentreApproval";
import TeacherApproval from "./TeacherApproval";
import CourseApproval from "./CourseApproval";
import AssessmentApproval from "./AssessmentApproval";

import CentreLevelApproval from "./CentreLevelApproval";

function ViewApproval(props) {
  const [approval, setApproval] = useState({});
  const [entityType, setEntityType] = useState(null);
  const [statusCodes, setStatusCodes] = useState({});
  useEffect(() => {
    getApproval();
  }, []);

  useEffect(() => {
    setEntityType(approval.entity_type);
  }, [approval]);
  const getApproval = async () => {
    let resp = await axios.post(urls.getApproval(props.match.params.id));
    if (resp.status === 200 && resp.data.error === false) {
      await setApproval({ ...resp.data.approval });
      await setStatusCodes({ ...resp.data.config.statusCodes });
    }
  };
  return (
    <React.Fragment>
      {entityType === 10 && (
        <CentreApproval
          approval={approval}
          statusCodes={statusCodes}
          onUpdate={() => getApproval()}
        />
      )}
      {entityType === 11 && (
        <CentreLevelApproval
          approval={approval}
          statusCodes={statusCodes}
          onUpdate={() => getApproval()}
        />
      )}
      {entityType === 20 && (
        <TeacherApproval
          approval={approval}
          statusCodes={statusCodes}
          onUpdate={() => getApproval()}
        />
      )}
      {entityType === 21 && (
        <TeacherApproval
          approval={approval}
          statusCodes={statusCodes}
          onUpdate={() => getApproval()}
        />
      )}
      {entityType === 30 && (
        <CourseApproval
          approval={approval}
          statusCodes={statusCodes}
          onUpdate={() => getApproval()}
        />
      )}
      {entityType === 40 && (
        <AssessmentApproval
          approval={approval}
          statusCodes={statusCodes}
          onUpdate={() => getApproval()}
        />
      )}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewApproval);
