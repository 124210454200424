import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Gaurd from "../../hoc/Gaurd";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";

const headRows = [
  { id: "number", numeric: false, label: "Number", sortable: true },
  { id: "name", numeric: false, label: "Name", sortable: true },
  { id: "postcode", numeric: false, label: "Postcode", sortable: false },
  { id: "region", numeric: false, label: "Region", sortable: false },
  { id: "courses", numeric: false, label: "Courses", sortable: false },
  { id: "status", numeric: false, label: "status", sortable: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  formroot: {
    width: "100%",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
  chip: {
    height: "24px",
    margin: theme.spacing(1),
  },
  avatar: {
    height: "24px",
    width: "24px",
    fontSize: "12px",
  },
  link: {
    cursor: "pointer",
  },
}));

function CentreList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [medias, setMedias] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [stats, setStats] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
  });

  useEffect(() => {
    let filters = localStorage.getItem("filter.centres");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setFilterStatus(filters.filterStatus);
    }
  }, []);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        filterStatus: filterStatus,
      };
      await localStorage.setItem("filter.centres", JSON.stringify(filters));
      await fetchCentres(filters);
    })();
  }, [searchText, page, rowsPerPage, orderBy, order, filterStatus]);

  const fetchCentres = async (params) => {
    let resp = await axios.post(urls.getCentres, params);
    if (resp.status === 200 && resp.data.error === false) {
      await setMedias(resp.data.rows);
      setTotal(resp.data.total);
      setStats(resp.data.stats);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }

  function handleFilterStatus(val) {
    setFilterStatus(val);
  }

  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  return (
    <Container>
      <div className={classes.chipRoot}>
        <Chip
          size="small"
          avatar={<Avatar className={classes.avatar}>{stats[1]}</Avatar>}
          label="Active"
          className={classes.chip}
          onClick={() => handleFilterStatus(1)}
          deleteIcon={<DoneIcon />}
          color={filterStatus === 1 ? "primary" : "default"}
        />
        <Chip
          size="small"
          avatar={<Avatar className={classes.avatar}>{stats[2] || 0}</Avatar>}
          label="Closed"
          className={classes.chip}
          onClick={() => handleFilterStatus(2)}
          color={filterStatus === 2 ? "primary" : "default"}
        />
        <Chip
          size="small"
          avatar={<Avatar className={classes.avatar}>{stats[0] || 0}</Avatar>}
          label="Pending"
          className={classes.chip}
          onClick={() => handleFilterStatus(0)}
          color={filterStatus === 0 ? "primary" : "default"}
        />
        <Chip
          size="small"
          avatar={<Avatar className={classes.avatar}>{stats[3] || 0}</Avatar>}
          label="On Hold"
          className={classes.chip}
          onClick={() => handleFilterStatus(3)}
          color={filterStatus === 3 ? "primary" : "default"}
        />
      </div>
      <Paper className={classes.root}>
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={9}>
                <Typography variant="h6" id="tableTitle">
                  Centre
                </Typography>
              </Grid>
              {props.currentRole.name !== "centre" && (
                <Grid item xs={12} sm={3}>
                  <div className={classes.search}>
                    <SearchIcon />
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="seartch-text">{`Search in name, number`}</InputLabel>
                      <Input
                        id="seartch-text"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Clear Search"
                              style={{ marginRight: -12 }}
                              onClick={() => setSearchText("")}
                            >
                              {searchText.length > 0 ? (
                                <ClearIcon fontSize="small" color="secondary" />
                              ) : null}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? "none" : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              medias.map((item) => (
                <TableRow key={item.number}>
                  <TableCell component="th" scope="row">
                    {item.number}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {item.centre_addresses
                      ? item.centre_addresses[0].postcode
                      : ""}
                  </TableCell>
                  <TableCell>{item.region ? item.region.name : ""}</TableCell>
                  <TableCell>
                    <Link
                      className={classes.link}
                      onClick={() =>
                        props.history.push(`/centres/list/${item.id}/courses`)
                      }
                    >
                      {item.courses.length}
                    </Link>
                  </TableCell>
                  <TableCell>{item.statusName}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/centres/list/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!loading && medias.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Gaurd check="create_centre">
        <Zoom
          className={classes.fabAdd}
          key="add"
          in={true}
          style={{ transitionDelay: "500ms" }}
          unmountOnExit
        >
          <Fab
            aria-label="Add"
            size="small"
            className="classes.fab"
            color="secondary"
            onClick={() => props.history.push(`/centres/create`)}
          >
            <AddIcon />
          </Fab>
        </Zoom>
      </Gaurd>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    mediaTypes: state.common.mediaTypes,
    levels: state.common.levels,
    regions: state.common.regions,
    currentRole: state.authUser.currentRole,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CentreList);
