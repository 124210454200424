export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkValidity = (value, rules, label) => {
  let isValid = true;
  let errorText = [];
  if (!rules) {
    return { isValid: true, errorText: '' };
  }

  if (rules.required) {
    if (!value) {
      isValid = false;
      errorText.push(`The ${label} field is required.`);
    }
  }

  if (rules.minLength) {
    isValid = value && value.length >= rules.minLength && isValid;
    if (!isValid)
      errorText.push(
        `The ${label} must be at least ${rules.minLength} characters`
      );
  }

  if (rules.maxLength) {
    isValid = value && value.length <= rules.maxLength && isValid;
    if (!isValid)
      errorText.push(
        `The ${label} must be less than ${rules.minLength} characters`
      );
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = value && pattern.test(value) && isValid;
    if (!isValid) errorText.push(`The ${label} must be a valid email address.`);
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = value && pattern.test(value) && isValid;
    if (!isValid) errorText.push(`The ${label} must be a number.`);
  }
  return { isValid, errorText: errorText.join(', ') };
};

export const randomString = length => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generateUUID = () => {
  let d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

/** Generate Range of Numbers */
export const range = (start = 0, end = 10) => {
  return Array.from({ length: end - start }, (v, k) => k + start);
};
