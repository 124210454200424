import * as actionTypes from './actionTypes';

/*
 * Load All Qualifications
 * Route : /qualifications
 */
export const getQualifications = payload => {
  return {
    type: actionTypes.GET_QUALIFICATIONS,
    payload
  };
};
export const getQualificationStart = () => {
  return {
    type: actionTypes.GET_QUALIFICATIONS_START
  };
};

export const getQualificationSuccess = payload => {
  return {
    type: actionTypes.GET_QUALIFICATIONS_SUCCESS,
    payload
  };
};

export const getQualificationFail = payload => {
  return {
    type: actionTypes.GET_QUALIFICATIONS_FAIL,
    payload
  };
};

/*
 * Load All Modules
 * Route : /qualifications/modules
 */
export const getModules = payload => {
  return {
    type: actionTypes.GET_MODULES,
    payload
  };
};
export const getModulesStart = () => {
  return {
    type: actionTypes.GET_MODULES_START
  };
};

export const getModulesSuccess = payload => {
  return {
    type: actionTypes.GET_MODULES_SUCCESS,
    payload
  };
};

export const getModulesFail = payload => {
  return {
    type: actionTypes.GET_MODULES_FAIL,
    payload
  };
};
