import { takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';

/* Common */
import { getCommonSaga } from './common';
import { getNoticesSaga } from './notices';

/* Auth */
import {
  authCheckStateSaga,
  authUserSaga,
  logoutSaga,
  getAuthUserSaga
} from './auth';

/* Auth Exam */
import { authExamSaga, logoutExamSaga, authCheckExamStateSaga } from './exam';

/* Users */
import {
  getUsersSaga,
  getUserSaga,
  updateUserRoleSaga,
  updateUserSaga
} from './users';
import { getRolesSaga, updatePermissionSaga } from './roles';

/* Qualifications */
import { getModulesSaga, getQualificationsSaga } from './qualifications';

/* Centre */
import { getCentresSaga } from './centre';

/* Get Common Saga */
export function* watchCommon() {
  yield takeEvery(actionTypes.GET_COMMON, getCommonSaga);
}

/* Get Notices Saga */
export function* watchNotices() {
  yield takeEvery(actionTypes.GET_NOTICES, getNoticesSaga);
}

/* Auth */
export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.GET_AUTH_USER, getAuthUserSaga)
  ]);
}

/* Auth Exam */
export function* watchAuthExam() {
  yield all([
    takeEvery(actionTypes.AUTH_EXAM, authExamSaga),
    takeEvery(actionTypes.AUTH_INITIATE_EXAM_LOGOUT, logoutExamSaga),
    takeEvery(actionTypes.AUTH_EXAM_CHECK_STATE, authCheckExamStateSaga)
  ]);
}
/* Users */
export function* watchUsers() {
  yield all([takeLatest(actionTypes.GET_USERS, getUsersSaga)]);
}

/* User */
export function* watchUser() {
  yield all([
    takeLatest(actionTypes.GET_USER, getUserSaga),
    takeLatest(actionTypes.UPDATE_USER_ROLE, updateUserRoleSaga),
    takeLatest(actionTypes.UPDATE_USER, updateUserSaga)
  ]);
}

/* Roles */
export function* watchRoles() {
  yield all([
    takeLatest(actionTypes.GET_ROLES, getRolesSaga),
    takeLatest(actionTypes.UPDATE_PERMISSION, updatePermissionSaga)
  ]);
}

/* Qualification */
export function* watchQualification() {
  yield all([
    takeLatest(actionTypes.GET_QUALIFICATIONS, getQualificationsSaga),
    takeLatest(actionTypes.GET_MODULES, getModulesSaga)
  ]);
}
/* Centre */

export function* watchCentre() {
  yield takeLatest(actionTypes.GET_CENTRES, getCentresSaga);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield fork(watchAuth);
  yield fork(watchAuthExam);
  yield fork(watchUsers);
  yield fork(watchUser);
  yield fork(watchRoles);
  yield fork(watchCommon);
  yield fork(watchNotices);
  yield fork(watchQualification);
  yield fork(watchCentre);
}
