import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../common/utility";

const initialState = {
  data: {},
  uploading: false,
  finished: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_FILE:
      return updateObject(state, {
        uploading: true,
        finished: null,
        data: action.payload,
      });
    case actionTypes.UPLOADING_CANCELLED:
      return updateObject(state, initialState);
    case actionTypes.UPLOADING_FINISHED:
      return updateObject(state, {
        data: {},
        uploading: false,
        finished: action.payload,
      });
    default:
      return state;
  }
};
