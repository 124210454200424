import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';
import { urls } from '../../common/urls';

/*
 * Get All Qualifications
 */

export function* getQualificationsSaga(action) {
  yield put(actions.getQualificationStart());
  try {
    const response = yield axios.post(urls.getQualifications, action.payload);
    if (response.status === 200 && !response.data.error) {
      yield put(actions.getQualificationSuccess(response.data));
    } else {
      yield put(actions.getQualificationFail(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: response.data.errorText || 'Network Request Failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: response.data.status == 403 ? 'warning' : 'error'
          }
        })
      );
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.getUsersFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}

/*
 * Get All Modules
 */

export function* getModulesSaga(action) {
  yield put(actions.getModulesStart());
  try {
    const response = yield axios.post(urls.getModules, action.payload);
    if (response.status === 200 && !response.data.error) {
      yield put(actions.getModulesSuccess(response.data));
    } else {
      yield put(actions.getModulesFail(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: response.data.errorText || 'Network Request Failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: response.data.status == 403 ? 'warning' : 'error'
          }
        })
      );
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.getUsersFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}
