import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import Button from "@material-ui/core/Button";
import AlertDialog from "../../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import TextField from "@material-ui/core/TextField";

import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import lightGreen from "@material-ui/core/colors/lightGreen";

import { urls } from "../../common/urls";
import * as actions from "../../store/actions";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8)
  },
  label: {
    background: "#eaf1dd",
    "&.sm": {
      maxWidth: "60px"
    }
  },
  withoutLabel: {
    maxWidth: "50px"
  }
}));

const ViewEvidence = props => {
  const classes = useStyles();
  let videoRef = React.createRef();
  const [loading, setLoading] = useState(true);
  const [evidences, setEvidences] = useState([]);
  const [openFileDialog, setOpenFileDialog] = React.useState("");
  const [openFileViewer, setOpenFileViewer] = React.useState(false);
  const [fileSingedUrl, setFileSingedUrl] = React.useState("");
  const [viewFile, setViewFile] = React.useState({});
  const [numPages, setNumPages] = React.useState(null);
  const [openViewFeedback, setOpenViewFeedback] = React.useState(false);
  const [feedbacks, setFeedbacks] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      getEvidences();
      setLoading(false);
    };
    fetchData();
  }, [props.item]);

  /** Get Learner Evidences */
  function getEvidences() {
    axios
      .post(urls.getLearnerEvidences(props.assessment.id), {
        learner_id: props.learner.id
      })
      .then(async resp => {
        if (resp.status === 200 && !resp.data.error) {
          if (resp.data.rows.length > 0) {
            setEvidences(resp.data.rows);
          }
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }
  // handle PDF Load
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  // Handle View Media file
  async function handleViewMediaFile(item) {
    setViewFile(item);
    await axios
      .get(urls.getEvidenceFileSignedUrl(item.id))
      .then(resp => {
        if (!resp.data.error) {
          setFileSingedUrl(resp.data.url);
          if (item.type === "mp4") {
            videoRef.current.src = resp.data.url;
            videoRef.current.play();
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
    setOpenFileViewer(true);
  }
  // On Closing the Media File Dialog
  function onFileDialogExiting() {
    if (viewFile.type === "mp4") {
      videoRef.current.pause();
      videoRef.current.src = "";
    }
    setViewFile({});
  }
  /** Feedback */
  const leaveFeedback = item => {
    setOpenViewFeedback(true);
  };
  /** Handle Close Feedback */
  const handleCloseFeedback = () => {
    setOpenViewFeedback(false);
  };
  const onFeedbackChange = () => {};
  return (
    <Container className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={11} sm={10}>
          <Paper>
            <Grid container justify="center" className={classes.container}>
              <Grid item xs={12} sm={6}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Centre</TableCell>
                      <TableCell>{`${props.assessment.centre.name} #${props.assessment.centre.number}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Course</TableCell>
                      <TableCell>{`${props.assessment.course.name} #${props.assessment.course.id}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assessment</TableCell>
                      <TableCell>{`${props.assessment.title} #${props.assessment.assessment_no}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Assmt Date</TableCell>
                      <TableCell>{props.assessment.assessment_date}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assmt Time</TableCell>
                      <TableCell>{props.assessment.assessment_time}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Module</TableCell>
                      <TableCell>{props.assessment.module.title}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Paper>
          <br />
          <Typography variant="h6" className={classes.title} align="center">
            {`${props.learner.first_name} ${props.learner.last_name}`}
          </Typography>
          <br />
          <Paper>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Feedback</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evidences.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>
                      {item.feedback}
                      {` | `}
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        onClick={() => leaveFeedback(item)}
                      >
                        Leave Feedback
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        onClick={() => handleViewMediaFile(item)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        maxWidth={"lg"}
        open={openFileViewer}
        keepMounted
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        onClose={() => setOpenFileViewer(false)}
        onExiting={onFileDialogExiting}
        aria-labelledby="file-viewer-slide-title"
        aria-describedby="file-viewer-slide-description"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="file-viewer-slide-title"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{viewFile.filename}</Typography>
            <div>
              <Link
                href={fileSingedUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  aria-label="close"
                  size="small"
                  color="primary"
                  className={classes.closeButton}
                >
                  <OpenInNewIcon size="small" />
                </IconButton>
              </Link>
              <IconButton
                aria-label="close"
                size="small"
                className={classes.closeButton}
                onClick={() => setOpenFileViewer(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        </DialogTitle>
        <DialogContent>
          {viewFile.type == "mp4" && (
            <video
              ref={videoRef}
              src={fileSingedUrl}
              controls
              height="300"
              width="400"
              type="video/mp4"
            ></video>
          )}
          {["jpg", "jpeg", "png"].indexOf(viewFile.type) !== -1 && (
            <img src={fileSingedUrl} alt={viewFile.filename} width="100%" />
          )}
          {viewFile.type == "pdf" && fileSingedUrl && (
            <Document
              file={fileSingedUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={700}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          )}
          {viewFile.type != "pdf" &&
            viewFile.type != "mp4" &&
            fileSingedUrl && <a href={fileSingedUrl}>Click To Download File</a>}
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openViewFeedback}
        keepMounted
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        onClose={() => setOpenViewFeedback(false)}
        onExiting={onFileDialogExiting}
        aria-labelledby="file-viewer-slide-title"
        aria-describedby="file-viewer-slide-description"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="file-viewer-slide-title"
        >
          Feedbacks
        </DialogTitle>
        <DialogContent>
          <TextField
            id="filled-feedback-static"
            label="Leave your feedback"
            multiline
            rows="4"
            defaultValue=""
            variant="filled"
            fullWidth
            onChange={onFeedbackChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFeedback} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseFeedback} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvidence);
