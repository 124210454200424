import Box from '@material-ui/core/Box';
import React from 'react';

const Logo = props => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="center" p={props.p}>
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/images/${props.src ||
            'ibsl-logo.png'}`}
          width={props.width || '150px'}
          alt="iBSL Logo"
        />
      </div>
    </Box>
  );
};

export default Logo;
