import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Paper, Divider } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import IntegrationDownshift from "../../components/IntegrationDownshift";
import AlertDialog from "../../components/AlertDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabroot: {
    minWidth: 80,
  },
  gridContainer: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  appbarPaper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  tabButton: {
    minWidth: 100,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  textField: {
    minWidth: 160,
  },
  inlineField: {
    marginRight: theme.spacing(2),
    minWidth: 200,
  },
  action: {
    marginTop: theme.spacing(4),
  },
  actionBtn: {
    marginRight: theme.spacing(2),
  },
}));
function Units(props) {
  const classes = useStyles();

  /* Set State  */
  const [qualification, setQualification] = useState({});
  const [unitIndex, setUnitIndex] = useState(0);
  const [units, setUnits] = useState([]);
  const [newUnit, setNewUnit] = useState({});
  const [modules, setModules] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [moduleIndex, setModuleIndex] = useState(0);
  const [mediaGroups, setMediaGroups] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [dialogError, setDialogError] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUnitAlert, setOpenUnitAlert] = React.useState(false);
  const [openModAlert, setOpenModAlert] = React.useState(false);

  useEffect(() => {
    props.onGetModules({
      offset: 0,
      limit: 1000,
      orderBy: "id",
      order: "asc",
      searchText: "",
      filterIn: "",
    });
    fetchUnits();
  }, []);

  useEffect(() => {
    if (units.length > 0) {
      setModules(units[unitIndex].modules);
      setModuleIndex(moduleIndex);
    }
  }, [unitIndex]);

  useEffect(() => {
    if (props.modules.rows.length > 0 && qualification.level_id) {
      let filteredModules = props.modules.rows
        .filter((item) => item.level_id === qualification.level_id)
        .map((item) => ({
          id: item.id,
          label: `${item.name} - ${item.title} ${
            item.year ? `(${item.year})` : ""
          }`,
        }));
      setFilterData(filteredModules);
    }
  }, [props.modules.loading, qualification]);

  const fetchUnits = async () => {
    let resp = await axios.post(urls.getUnits(props.match.params.id));
    if (resp.status === 200 && resp.data.error === false) {
      await setQualification(resp.data.qualification);
      if (resp.data.rows.length) {
        await setUnits(resp.data.rows);
        await setModules(resp.data.rows[0].modules);
        fetchMediaGroups(resp.data.qualification.level_id);
      } else {
        await setUnits([]);
        await setModules([]);
      }
    }
  };

  // Fetch Media Groups
  const fetchMediaGroups = (level_id) => {
    axios.post(urls.getMediaGroupsByLevel(level_id)).then((resp) => {
      if (resp.status === 200 && resp.data.error === false) {
        setMediaGroups(resp.data.rows);
      }
    });
  };

  // Handle Unit Tab change
  function handleUnitTabChange(newValue) {
    setUnitIndex(newValue);
  }

  // Handle Module Tab change
  function handleModuleTabChange(newValue) {
    setModuleIndex(newValue);
  }

  // Update state on unit field value change
  function handleUnitChange(event, field) {
    let unitObj = Object.assign({}, units[unitIndex]);
    unitObj[field] = event.target.value;
    let unitsArray = units.filter((item) => true);
    unitsArray[unitIndex] = unitObj;
    setUnits(unitsArray);
  }
  // Update state on module field value change
  function handleModuleChange(event, field) {
    let moduldObj = Object.assign({}, modules[moduleIndex]);
    moduldObj[field] = event.target.value;
    let moduleArray = modules.filter((item) => true);
    moduleArray[moduleIndex] = moduldObj;
    setModules(moduleArray);
  }

  // Submit Unit form
  async function handleUnitSubmit() {
    let resp = await axios.post(urls.updateUnit(props.match.params.id), {
      unit: units[unitIndex],
    });
    if (resp.status === 200 && resp.data.error === false) {
      await props.onEnqueueSnackbar("Success!!", 1000, "success");
    } else {
      await props.onEnqueueSnackbar(resp.data.errorText, 3000);
      await setErrorData(resp.data.errorData);
    }
  }
  // Submit Module form
  async function handleModuleSubmit() {
    let resp = await axios.post(urls.updateModule(props.match.params.id), {
      module: modules[moduleIndex],
    });
    if (resp.status === 200 && resp.data.error === false) {
      props.onEnqueueSnackbar("Success!!", 1000, "success");
    } else {
      props.onEnqueueSnackbar(resp.data.errorText, 1000, "error");
      await setErrorData(resp.data.errorData);
    }
  }

  // Handle Module Addition
  async function handleModuleAddition(id) {
    let resp = await axios.post(urls.addModuleToUnit(props.match.params.id), {
      module_id: id,
      unit_id: units[unitIndex].id,
    });
    if (resp.status === 200 && resp.data.error === false) {
      props.onEnqueueSnackbar("Success!!", 1000, "success");
      await fetchUnits();
      await setModuleIndex(modules.length);
    } else {
      await props.onEnqueueSnackbar(resp.data.errorText, 3000);
    }
  }
  // Handle Module Deletion
  async function handleModuleRemoval(agree) {
    setOpenModAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.removeModuleFromUnit(props.match.params.id),
        {
          module_id: modules[moduleIndex].id,
          unit_id: units[unitIndex].id,
        }
      );
      if (resp.status === 200 && resp.data.error === false) {
        await props.onEnqueueSnackbar("Success!!", 1000, "success");
        await setModuleIndex(0);
        await fetchUnits();
      } else {
        await props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }

  // Handle Unit Deletion
  async function handleUnitRemoval(agree) {
    setOpenUnitAlert(false);
    if (agree) {
      let resp = await axios.post(urls.removeUnit(props.match.params.id), {
        unit_id: units[unitIndex].id,
      });
      if (resp.status === 200 && resp.data.error === false) {
        await props.onEnqueueSnackbar("Success!!", 1000, "success");
        await setUnitIndex(0);
        await setModuleIndex(0);
        await fetchUnits();
      } else {
        await props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }

  async function handleNewUnitChange(event, field) {
    let unitObj = Object.assign({}, newUnit);
    unitObj[field] = event.target.value;
    setNewUnit(unitObj);
  }
  // Handle Unit Add
  async function handleUnitCreate() {
    let resp = await axios.post(urls.createUnit(props.match.params.id), {
      unit: newUnit,
    });
    if (resp.status === 200 && resp.data.error === false) {
      await props.onEnqueueSnackbar("Success!!", 1000, "success");
      setOpen(false);
      await fetchUnits();
      setUnitIndex(units.length);
    } else {
      await props.onEnqueueSnackbar(resp.data.errorText, 3000);
      await setDialogError(resp.data.errorData);
    }
  }
  // Handle Alert close
  function handleClickOpen() {
    setNewUnit({});
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Container className={classes.root} maxWidth="lg">
      <Paper square={true} elevation={6} className={classes.appbarPaper}>
        {units.length > 0 &&
          units.map((unit, index) => (
            <Button
              key={unit.id}
              color={unitIndex === index ? "primary" : "default"}
              className={classes.tabButton}
              onClick={() => handleUnitTabChange(index)}
            >
              {unit.unit_code}
            </Button>
          ))}
        <IconButton
          size="small"
          color="primary"
          aria-label="Add"
          onClick={handleClickOpen}
        >
          <AddIcon />
        </IconButton>
      </Paper>
      <Grid container className={classes.content} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            {units.length > 0 ? (
              <form noValidate autoComplete="off">
                {units[unitIndex].unit_id}
                <Box>
                  <TextField
                    id="unit-code"
                    label="Unit Code"
                    value={units[unitIndex].unit_code || ""}
                    onChange={(event) => (event, "unit_code")}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("unit_code")}
                    helperText={errorData.unit_code}
                  />
                  <TextField
                    id="unit-description"
                    label="Description"
                    value={units[unitIndex].unit_description || ""}
                    onChange={(event) =>
                      handleUnitChange(event, "unit_description")
                    }
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("unit_description")}
                    helperText={errorData.unit_description}
                  />
                  <TextField
                    id="unit-nan"
                    label="NAN"
                    value={units[unitIndex].nan || ""}
                    onChange={(event) => handleUnitChange(event, "nan")}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("nan")}
                    helperText={errorData.nan}
                  />
                  <TextField
                    id="unit-tqt"
                    label="Total Qualification Time"
                    value={units[unitIndex].tqt || ""}
                    onChange={(event) => handleUnitChange(event, "tqt")}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("tqt")}
                    helperText={errorData.tqt}
                  />
                  <TextField
                    id="learner_fee"
                    label="Learner Fee"
                    value={units[unitIndex].learner_fee || "0.00"}
                    onChange={(event) => handleUnitChange(event, "learner_fee")}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("learner_fee")}
                    helperText={errorData.learner_fee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="resit_fee"
                    label="Resit Fee"
                    value={units[unitIndex].resit_fee || "0.00"}
                    onChange={(event) => handleUnitChange(event, "resit_fee")}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("resit_fee")}
                    helperText={errorData.resit_fee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <div className={classes.action}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    onClick={handleUnitSubmit}
                  >
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.actionBtn}
                    onClick={() => setOpenUnitAlert(true)}
                  >
                    Remove
                  </Button>
                </div>
              </form>
            ) : (
              "No Unit Found. Start adding by clicking on Plus Icon"
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          {filterData.length > 0 && (
            <IntegrationDownshift
              label="Modules"
              placeholder="Search in module"
              data={filterData}
              onSelect={(selected) => handleModuleAddition(selected.id)}
              onClear={() => {}}
            />
          )}
          {modules.length > 0 && (
            <div>
              <Paper
                square={true}
                elevation={6}
                className={classes.appbarPaper}
              >
                {modules.map((module, index) => (
                  <Button
                    key={`module-${module.id}`}
                    color={moduleIndex === index ? "primary" : "default"}
                    className={classes.tabButton}
                    onClick={() => handleModuleTabChange(index)}
                  >
                    {module.name}
                  </Button>
                ))}
              </Paper>
              <Paper className={classes.paper}>
                <form noValidate autoComplete="off">
                  {modules.length > 0 && (
                    <Box>
                      <TextField
                        id="module-name"
                        label="Name"
                        value={modules[moduleIndex].name || ""}
                        onChange={(event) => handleModuleChange(event, "name")}
                        margin="dense"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("name")}
                        helperText={errorData.name}
                      />
                      <TextField
                        id="module-title"
                        label="Title"
                        value={modules[moduleIndex].title || ""}
                        onChange={(event) => handleModuleChange(event, "title")}
                        margin="dense"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("title")}
                        helperText={errorData.title}
                      />
                      <TextField
                        id="select-assmt-type"
                        select
                        label="Assessment Type"
                        className={classes.textField}
                        value={modules[moduleIndex].assessment_type || ""}
                        onChange={(event) =>
                          handleModuleChange(event, "assessment_type")
                        }
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("assessment_type")}
                        helperText={errorData.assessment_type}
                      >
                        {props.assessmentTypes &&
                          props.assessmentTypes.map((assessmentType) => (
                            <MenuItem
                              key={assessmentType.id}
                              value={assessmentType.id}
                            >
                              {assessmentType.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="module-passgrade"
                            label="Pass Grade"
                            className={classes.textField}
                            value={
                              modules[moduleIndex].pass_grade_type ==
                              "PASS-FAIL"
                                ? "Pass/ Fail"
                                : modules[moduleIndex].pass_grade
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {modules[moduleIndex].pass_grade_type ===
                                  "PERCENTAGE"
                                    ? "%"
                                    : ""}
                                </InputAdornment>
                              ),
                            }}
                            onChange={(event) =>
                              handleModuleChange(event, "pass_grade")
                            }
                            margin="normal"
                            disabled={
                              modules[moduleIndex].pass_grade_type ==
                              "PASS-FAIL"
                                ? true
                                : false
                            }
                            error={errorData.hasOwnProperty("pass_grade")}
                            helperText={errorData.pass_grade}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="select-optional"
                            select
                            label="Is Module Optional"
                            className={classes.inlineField}
                            value={modules[moduleIndex].is_optional || "0"}
                            onChange={(event) =>
                              handleModuleChange(event, "is_optional")
                            }
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            margin="normal"
                            error={errorData.hasOwnProperty("is_optional")}
                            helperText={errorData.is_optional}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                      <TextField
                        id="media-group"
                        select
                        label="Media Group"
                        className={classes.textField}
                        value={modules[moduleIndex].media_group_id || ""}
                        onChange={(event) =>
                          handleModuleChange(event, "media_group_id")
                        }
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("media_group_id")}
                        helperText={errorData.media_group_id}
                      >
                        {mediaGroups &&
                          mediaGroups.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                              {group.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Box>
                  )}
                  <div className={classes.action}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.actionBtn}
                      onClick={handleModuleSubmit}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.actionBtn}
                      onClick={() => setOpenModAlert(true)}
                    >
                      Remove
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add New Unit</DialogTitle>
        <DialogContent>
          <DialogContentText>{qualification.title}</DialogContentText>
          <form noValidate autoComplete="off">
            <Box>
              <TextField
                id="unit-code"
                label="Unit Code"
                value={newUnit.unit_code || ""}
                onChange={(event) => handleNewUnitChange(event, "unit_code")}
                margin="dense"
                fullWidth={true}
                error={dialogError.hasOwnProperty("unit_code")}
                helperText={dialogError.unit_code}
              />
              <TextField
                id="unit-description"
                label="Description"
                value={newUnit.unit_description || ""}
                onChange={(event) =>
                  handleNewUnitChange(event, "unit_description")
                }
                margin="dense"
                fullWidth={true}
                error={dialogError.hasOwnProperty("unit_description")}
                helperText={dialogError.unit_description}
              />
              <TextField
                id="unit-nan"
                label="Ofqual NAN"
                value={newUnit.nan || ""}
                onChange={(event) => handleNewUnitChange(event, "nan")}
                margin="dense"
                fullWidth={true}
                error={dialogError.hasOwnProperty("nan")}
                helperText={dialogError.nan}
              />
              <TextField
                id="unit-tqt"
                label="Total Qualification Time"
                value={newUnit.tqt || ""}
                onChange={(event) => handleNewUnitChange(event, "tqt")}
                margin="dense"
                fullWidth={true}
                error={dialogError.hasOwnProperty("tqt")}
                helperText={dialogError.tqt}
              />
              <TextField
                id="learner_fee"
                label="Learner Fee"
                value={newUnit.learner_fee || ""}
                onChange={(event) => handleNewUnitChange(event, "learner_fee")}
                margin="dense"
                fullWidth={true}
                error={dialogError.hasOwnProperty("learner_fee")}
                helperText={dialogError.learner_fee}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
              <TextField
                id="resit_fee"
                label="Resit Fee"
                value={newUnit.resit_fee || ""}
                onChange={(event) => handleNewUnitChange(event, "resit_fee")}
                margin="dense"
                fullWidth={true}
                error={dialogError.hasOwnProperty("resit_fee")}
                helperText={dialogError.resit_fee}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUnitCreate}
          >
            Add New Unit
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        title="Confirm Removal"
        description={
          openUnitAlert
            ? `Are you sure you want to remove Unit ${units[moduleIndex].unit_code}`
            : `Are you sure you want to remove this unit`
        }
        open={openUnitAlert}
        handleAlertClose={(agree) => handleUnitRemoval(agree)}
      />
      <AlertDialog
        title="Confirm Removal"
        description={
          openModAlert
            ? `Are you sure you want to remove Module ${modules[moduleIndex].name} from Unit ${units[unitIndex].unit_code}`
            : `Are you sure you want to remove this module`
        }
        open={openModAlert}
        handleAlertClose={(agree) => handleModuleRemoval(agree)}
      />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    assessmentTypes: state.common.assessmentTypes,
    modules: state.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetModules: (params) => dispatch(actions.getModules(params)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Units));
