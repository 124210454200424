import { put, call } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';
import { urls } from '../../common/urls';

/*
 * Get All Users
 */

export function* getCentresSaga(action) {
  try {
    const response = yield axios.get(urls.getCentreNames);
    if (response.status === 200 && !response.data.error) {
      yield put(actions.getCentresSuccess(response.data));
    } else {
      yield put(actions.getCentresFail(response.data));
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.getRolesFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}
