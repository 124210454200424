import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Gaurd from "../../hoc/Gaurd";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1, 2),
  },
  container: {
    paddingTop: "100px",
    background: theme.palette.background.default,
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },

  learnerList: {
    marginTop: theme.spacing(4),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RegisterLearner(props) {
  const classes = useStyles();

  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [learners, setLearners] = useState([]);
  const [courseLearners, setCourseLearners] = useState([]);
  const [learnerIds, setLearnerIds] = useState([]);
  const [courseLearnerIds, setCourseLearnerIds] = useState([]);

  useEffect(() => {
    if (props.openRegisterLearner === true) {
      fetchCourseLearners();
      fetchAssestLearners();
    }
  }, [props.openRegisterLearner]);

  /** Fetch Course */
  function fetchCourseLearners() {
    axios
      .post(urls.getCourseLearners(props.assessment.course_id), {
        is_assessment: true,
        is_resit: props.assessment.is_resit,
        module_id: props.assessment.module_id,
      })
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          console.log(resp.data.learners);
          setCourseLearners(resp.data.learners);
          let ids = resp.data.learners.map((item) => item.learner_id);
          setCourseLearnerIds(ids);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }

  /** Fetch Assessment */
  function fetchAssestLearners() {
    axios
      .post(urls.getAssessmentLearners(props.assessment.id))
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          setLearners(resp.data.learners);
          let ids = resp.data.learners.map((item) => item.learner_id);
          setLearnerIds(ids);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }

  /**
   * Handle Add Learner from Assessment
   */
  function handleAddLearner(ids) {
    setAdding(true);
    axios
      .post(urls.addAssessmentLearner(props.match.params.id), {
        learner_id: ids,
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          fetchAssestLearners();
          props.onEnqueueSnackbar(
            `Success!! Added the learner to assessment .`,
            2000,
            "success"
          );
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 5000);
        }
        setAdding(false);
      })
      .catch((error) => {
        setAdding(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  /**
   * Handle Add All Learners
   */
  const handleAddAllLearner = () => {
    const ids = courseLearners.map((item) => {
      if (!learnerIds.includes(item.learner.id)) {
        return item.learner_id;
      }
    });
    const resultIds = ids.filter((id) => id != null);
    handleAddLearner(resultIds);
  };
  /**
   * Handle Remove Learner from Assessment
   */
  function handleRemoveLearner(ids) {
    setRemoving(true);
    axios
      .post(urls.removeAssessmentLearner(props.match.params.id), {
        learner_id: ids,
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          fetchAssestLearners();
          props.onEnqueueSnackbar(
            `Success!! Remove the learner to assessment .`,
            2000,
            "success"
          );
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 5000);
        }
        setRemoving(false);
      })
      .catch((error) => {
        setRemoving(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  /**
   * Handle Remove All Learner
   */
  function handleRemoveAllLearner() {
    const learnerIds = learners.map((item) => item.learner_id);
    handleRemoveLearner(learnerIds);
  }

  return (
    <Dialog
      fullScreen
      open={props.openRegisterLearner}
      onClose={props.onCloseRegisterLearner}
      TransitionComponent={Transition}
      className={classes.root}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.title}>
              Register Learner on Assessment
            </Typography>
          </Box>
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onCloseRegisterLearner}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={10}>
          <Paper>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Centre</TableCell>
                      <TableCell>{`${props.assessment.centre.name} #${props.assessment.centre.number}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Course</TableCell>
                      <TableCell>{`${props.assessment.course.name}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assessment</TableCell>
                      <TableCell>{`${props.assessment.title} #${props.assessment.assessment_no}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Assmt Date</TableCell>
                      <TableCell>
                        {moment(props.assessment.assessment_date).format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assmt Time</TableCell>
                      <TableCell>
                        {moment(props.assessment.assessment_time).format(
                          "HH:mm"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Module</TableCell>
                      <TableCell>{props.assessment.module.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Paper>
          {courseLearners.length > 0 && (
            <Grid container className={classes.learnerList}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Learners Registered on Course
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Gaurd check="update_assessment">
                        {props.assessment.status < 120 && (
                          <Button
                            color="secondary"
                            className={classes.button}
                            onClick={() => handleAddAllLearner()}
                          >
                            Add All
                          </Button>
                        )}
                      </Gaurd>
                    </Grid>
                  </Grid>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>ULN</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">Gender</TableCell>
                        <TableCell align="center">Date Of Birth</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseLearners.map((row) => (
                        <TableRow key={row.learner.id}>
                          <TableCell component="th" scope="row">
                            {row.learner.uln}
                          </TableCell>
                          <TableCell align="left">{`${row.learner.first_name} ${row.learner.last_name}`}</TableCell>
                          <TableCell align="center">
                            {row.learner.gender === "M" ? "Male" : "Female"}
                          </TableCell>
                          <TableCell align="center">
                            {row.learner.dob
                              ? moment(row.learner.dob).format("DD-MM-YYYY")
                              : ""}
                          </TableCell>
                          <TableCell align="right">
                            <Gaurd check="update_assessment">
                              {props.assessment.status < 120 &&
                                !learnerIds.includes(row.learner.id) && (
                                  <Button
                                    color="primary"
                                    className={classes.button}
                                    onClick={() =>
                                      handleAddLearner(row.learner.id)
                                    }
                                  >
                                    ADD
                                  </Button>
                                )}
                            </Gaurd>
                            {props.assessment.status === 200 &&
                              "Waiting for Approval"}
                            {learnerIds.includes(row.learner.id) &&
                              "Already Registered"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          )}
          <Grid container className={classes.learnerList}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Learners Registered on Assessment #
                      {props.assessment.assessment_no}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Gaurd check="update_assessment">
                      {learners.length > 0 && props.assessment.status < 120 && (
                        <Button
                          color="secondary"
                          className={classes.button}
                          onClick={() => handleRemoveAllLearner()}
                        >
                          Remove All
                        </Button>
                      )}
                      {props.assessment.status === 200 &&
                        "Waiting for Approval"}
                    </Gaurd>
                  </Grid>
                </Grid>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ULN</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Gender</TableCell>
                      <TableCell align="center">Date Of Birth</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {learners.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.learner.uln}
                        </TableCell>
                        <TableCell align="left">{`${row.learner.first_name} ${row.learner.last_name}`}</TableCell>
                        <TableCell align="center">
                          {row.learner.gender === "M" ? "Male" : "Female"}
                        </TableCell>
                        <TableCell align="center">
                          {row.learner.dob
                            ? moment(row.learner.dob).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          <Gaurd check="update_assessment">
                            {props.assessment.status < 120 && (
                              <Button
                                color="secondary"
                                className={classes.button}
                                onClick={() =>
                                  handleRemoveLearner(row.learner_id)
                                }
                              >
                                REMOVE
                              </Button>
                            )}
                            {props.assessment.status === 200 &&
                              "Waiting for Approval"}
                          </Gaurd>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    regions: state.common.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterLearner)
);
