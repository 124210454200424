import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../common/utility";

const initialState = {
  assessmentTypes: [],
  assessmentStatus: {},
  levels: [],
  mediaTypes: [],
  qualificationGroups: [],
  regions: [],
  centreAdjustments: [],
  mediaAdjustments: [],
  modules: [],
  qualifications: [],
  ethnicities: [],
  noticesConfig: {
    menuOptions: [],
    categories: {},
  },
  loading: false,
  error: null,
  errorText: "",
};

const getCommonStart = (state, action) => {
  return updateObject(state, { error: null, status: null, loading: true });
};

const getCommonSuccess = (state = initialState, action) => {
  return updateObject(state, {
    assessmentTypes: action.payload.assessmentTypes,
    assessmentStatus: action.payload.assessmentStatus,
    levels: action.payload.levels,
    mediaTypes: action.payload.mediaTypes,
    qualificationGroups: action.payload.qualificationGroups,
    regions: action.payload.regions,
    centreAdjustments: action.payload.centreAdjustments,
    mediaAdjustments: action.payload.mediaAdjustments,
    modules: action.payload.modules,
    qualifications: action.payload.qualifications,
    ethnicities: action.payload.ethnicities,
    noticesConfig: action.payload.noticesConfig,
    error: false,
    status: 200,
    errorText: "",
    loading: false,
  });
};

const getCommonFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.payload.errorText,
    status: action.payload.status,
    loading: false,
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMMON_START:
      return getCommonStart(state, action);
    case actionTypes.GET_COMMON_SUCCESS:
      return getCommonSuccess(state, action);
    case actionTypes.GET_COMMON_FAIL:
      return getCommonFail(state, action);
    default:
      return state;
  }
};
