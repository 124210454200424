/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import IntegrationDownshift from '../../components/IntegrationDownshift';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import * as actions from '../../store/actions';
import { urls } from '../../common/urls';
import axios from 'axios';
import moment from 'moment';
const headRows = [
  { id: 'assessment_no', numeric: false, label: 'Asst No', sortable: true },
  { id: 'title', numeric: false, label: 'Title', sortable: true },
  { id: 'centre_id', numeric: false, label: 'Centre', sortable: true },
  { id: 'course_id', numeric: false, label: 'Course', sortable: true },
  { id: 'assessment_date', numeric: false, label: 'Date', sortable: true },
  { id: 'status', numeric: false, label: 'Status', sortable: true }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  formroot: {
    width: '100%'
  },
  chipRoot: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1),
    height: '24px'
  },
  avatar: {
    height: '24px',
    width: '24px',
    fontSize: '11px'
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  fabAdd: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1)
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#f9f9f9'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  tableRow: {
    cursor: 'pointer'
  }
}));
const CancelToken = axios.CancelToken;
let cancelAxios = [];

function AssessmentList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingPopover, setLoadingPopover] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [assessment, setAssessment] = useState({ assessment_learners: [] });
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('assessment_no');
  const [searchText, setSearchText] = useState('');
  const [courseSearchText, setCourseSearchText] = useState('');
  const [searchCentre, setSearchCentre] = useState('');
  const [centres, setCentres] = useState([]);
  const [searchCourse, setSearchCourse] = useState('');
  const [stats, setStats] = useState([]);
  const [filterStats, setFilterStats] = useState([]);
  const [filterResit, setFilterResit] = useState([]);

  const [asstConfig, setAsstConfig] = useState({ statusCodes: {} });
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    let filters = localStorage.getItem('filter.assessments');
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setCourseSearchText(filters.courseSearchText);
      setSearchCentre(filters.searchCentre);
      setSearchCourse(filters.searchCourse);
      setFilterStats(filters.filterStats);
      setFilterResit(filters.filterResit);
    }
    props.getCentreNames();
    fetchAssessmentStats();
    //fetchAssessments(filters);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchText, courseSearchText, filterStats]);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        courseSearchText: courseSearchText,
        searchCentre: searchCentre,
        searchCourse: searchCourse,
        filterStats: filterStats ? filterStats : [],
        filterResit: filterResit
      };
      await localStorage.setItem('filter.assessments', JSON.stringify(filters));
      fetchAssessments(filters);
    })();
  }, [
    searchText,
    courseSearchText,
    searchCentre,
    filterStats,
    filterResit,
    page,
    rowsPerPage,
    orderBy,
    order
  ]);

  const fetchAssessmentStats = async () => {
    let resp = await axios.post(urls.getAssessmentStats, {
      cancelToken: new CancelToken(function executor(c) {
        //cancelAxios.push(c);
      })
    });
    if (resp.status === 200 && resp.data.error === false) {
      setStats(resp.data.rows);
      setAsstConfig(resp.data.asstConfig);
    } else {
      props.onEnqueueSnackbar(resp.data.errorText, 3000);
    }
  };
  const fetchAssessments = async params => {
    setLoading(true);
    try {
      if (cancelAxios.length > 0) {
        cancelAxios.forEach(c => c());
      }
      let resp = await axios.post(urls.getAssessments, params, {
        cancelToken: new CancelToken(function executor(c) {
          cancelAxios.push(c);
        })
      });
      if (resp && resp.status === 200 && resp.data.error === false) {
        await setAssessments(resp.data.rows);
        setTotal(resp.data.total);
        setLoading(false);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.centres.length > 0) {
      let centres = props.centres.map(item => ({
        id: item.id,
        label: `${item.name} (${item.number})`
      }));
      setCentres(centres);
    }
  }, [props.centres]);

  function handleSorting(_orderBy) {
    setOrder(order == 'desc' ? 'asc' : 'desc');
    setOrderBy(_orderBy);
  }

  function handleRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleDownshiftSelect(selectedId) {
    setSearchCentre(selectedId);
  }
  const selectedCentre = id => {
    let c = centres.filter(item => item.id === id);
    return c.length ? c[0] : null;
  };
  const open = Boolean(anchorEl);
  const showAssessment = id => event => {
    setLoadingPopover(true);
    axios.post(urls.getAssessment(id)).then(resp => {
      if (resp.status === 200 && resp.data.error === false) {
        setAssessment(resp.data.assessment);
        setLoadingPopover(false);
      } else {
        setLoadingPopover(false);
      }
    });
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  /**
   * Update Select Status Code
   */
  function handleFilterStatChange(statusCode) {
    if (!filterStats) {
      setFilterStats([statusCode]);
    } else if (filterStats.includes(statusCode)) {
      setFilterStats(filterStats.filter(item => item != statusCode));
    } else {
      setFilterStats([...filterStats, statusCode]);
    }
  }

  return (
    <Container>
      <div className={classes.chipRoot}>
        {stats.map(item => (
          <Chip
            key={item.status}
            variant="outlined"
            size="small"
            avatar={
              <Avatar className={classes.avatar} size="small">
                {item.count}
              </Avatar>
            }
            label={asstConfig.statusCodes[item.status]}
            className={classes.chip}
            onClick={e => handleFilterStatChange(item.status)}
            deleteIcon={<DoneIcon />}
            color={
              filterStats && filterStats.includes(item.status)
                ? 'primary'
                : 'default'
            }
          />
        ))}
      </div>
      <Paper className={classes.root}>
        {loading && <LinearProgress color="secondary" />}
        <form className={classes.formroot} autoComplete="off">
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={2}>
                <Typography variant="h6" id="tableTitle">
                  Assessments
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterResit === true}
                      onChange={e => setFilterResit(e.target.checked)}
                      value="1"
                      color="primary"
                    />
                  }
                  label="Resit Only"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                {props.currentRole.name !== 'centre' && (
                  <div className={classes.search}>
                    <SearchIcon />
                    <IntegrationDownshift
                      label="&nbsp;"
                      placeholder="centre"
                      data={Object.values(centres)}
                      onSelect={selected => handleDownshiftSelect(selected.id)}
                      selectedItem={selectedCentre(searchCentre)}
                      onClear={() => setSearchCentre('')}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="seartch-course">{`course. name & id`}</InputLabel>
                    <Input
                      id="seartch-course"
                      value={courseSearchText}
                      onChange={event =>
                        setCourseSearchText(event.target.value)
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setCourseSearchText('')}
                          >
                            {courseSearchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.search}>
                  <SearchIcon />
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="seartch-text">{`asst. title & no`}</InputLabel>
                    <Input
                      id="seartch-text"
                      value={searchText}
                      onChange={event => setSearchText(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            style={{ marginRight: -12 }}
                            onClick={() => setSearchText('')}
                          >
                            {searchText.length > 0 ? (
                              <ClearIcon fontSize="small" color="secondary" />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </form>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map(row => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? 'none' : 'inherit'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.map(item => (
              <TableRow
                hover
                key={item.id}
                onClick={showAssessment(item.id)}
                selected={assessment.id === item.id}
                className={classes.tableRow}
              >
                <TableCell component="th" scope="row">
                  {item.assessment_no} {item.is_resit ? ' (R)' : ''}
                </TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                  {item.centre
                    ? `${item.centre.name} (${item.centre.number})`
                    : ''}
                </TableCell>
                <TableCell>
                  {item.course ? `${item.course.name} (${item.course_id})` : ''}
                </TableCell>
                <TableCell style={{ width: '120px' }}>
                  {item.assessment_date
                    ? moment(item.assessment_date).format('DD-MM-YYYY')
                    : ''}
                </TableCell>
                <TableCell>
                  {asstConfig ? asstConfig.statusCodes[item.status] : ''}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="Edit"
                    onClick={() =>
                      props.history.push(`/assessments/list/${item.id}`)
                    }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {assessments.length == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!loading && total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > 0 ? page : 0}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        )}
      </Paper>
      <Zoom
        className={classes.fabAdd}
        key="add"
        in={true}
        style={{ transitionDelay: '500ms' }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          size="small"
          className="classes.fab"
          color="secondary"
          onClick={() => props.history.push(`/assessments/create`)}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <Popover
        id={'assessment-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {loadingPopover ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
            width="316px"
            height="331px"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  {assessment.is_resit ? `Resit ` : ''}Assessment Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Assessment Type</TableCell>
                <TableCell>
                  {assessment.AssessmentType
                    ? assessment.AssessmentType.name
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Course</TableCell>
                <TableCell>
                  {assessment.course
                    ? `${assessment.course.name} (${assessment.course.id})`
                    : ''}
                </TableCell>
              </TableRow>
              {/* <TableRow>
              <TableCell>Unit</TableCell>
              <TableCell>
                {assessment.Unit ? assessment.Unit.unit_code : ''}
              </TableCell>
            </TableRow> */}
              <TableRow>
                <TableCell>Module</TableCell>
                <TableCell>
                  {assessment.module
                    ? `${assessment.module.name} ${assessment.module.title}`
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Learners</TableCell>
                <TableCell>
                  {assessment.assessment_learners !== undefined
                    ? `${assessment.assessment_learners.length} of ${
                        assessment.course
                          ? assessment.course.course_learners.length
                          : 'NA'
                      }`
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>
                  {assessment.assessment_date
                    ? moment(assessment.assessment_date).format('DD-MM-YYYY')
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>
                  {assessment.assessment_time
                    ? moment(assessment.assessment_time).format('HH:mm')
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teacher</TableCell>
                <TableCell>
                  {assessment.teacher ? assessment.teacher.name : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Examiner</TableCell>
                <TableCell>
                  {assessment.examiner ? assessment.examiner.name : ''}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Marking Examiner</TableCell>
                <TableCell>
                  {assessment.MarkingExaminer
                    ? assessment.MarkingExaminer.name
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Marking Due Date</TableCell>
                <TableCell>{assessment.marking_due_date}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Popover>
    </Container>
  );
}
const mapStateToProps = state => {
  return {
    centres: state.centres.rows,
    currentRole: state.authUser.currentRole
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCentreNames: () => dispatch(actions.getCentres()),
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList);
