import React from 'react';
import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';
import { urls } from '../../common/urls';

function createMarkup(html) {
  return { __html: html };
}
/*
 * Get All Users
 */

export function* getUsersSaga(action) {
  yield put(actions.getUsersStart());
  try {
    const response = yield axios.post(urls.users, action.payload);
    if (response.status === 200 && !response.data.error) {
      yield put(actions.getUsersSuccess(response.data));
    } else {
      yield put(actions.getUsersFail(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: response.data.errorText || 'Network Request Failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: response.data.status == 403 ? 'warning' : 'error'
          }
        })
      );
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.getUsersFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}

/**
 *  Get User by ID
 */

export function* getUserSaga(action) {
  yield put(actions.getUserStart());
  try {
    const response = yield axios.post(urls.getUser(action.payload), {});
    if (response.status === 200 && !response.data.error) {
      yield put(actions.getUserSuccess(response.data));
    } else {
      yield put(actions.getUserFail(response.data));
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.getUserFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}

/* Update User */

export function* updateUserSaga(action) {
  yield put(actions.updateUserStart());
  try {
    const response = yield axios.post(
      urls.updateUser(action.payload.userId),
      action.payload
    );
    if (response.status === 200 && !response.data.error) {
      yield put(actions.updateUserSuccess(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: 'Updated Successfully',
          options: { variant: 'success' }
        })
      );
    } else {
      yield put(actions.updateUserFail(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: response.data
            ? response.data.errorText
            : 'Network Request Failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.updateUserFail({
        status: error.response.status,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}

/*
  Update User Role 
  */

export function* updateUserRoleSaga(action) {
  yield put(actions.updateUserRoleStart());
  try {
    const response = yield axios.post(
      urls.updateUserRole(action.payload.userId),
      action.payload
    );
    if (response.status === 200 && !response.data.error) {
      yield put(actions.updateUserRoleSuccess(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: 'Successfully updated',
          options: { variant: 'success', autoHideDuration: 2000 }
        })
      );
    } else {
      yield put(actions.updateUserRoleFail(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: (
            <div
              dangerouslySetInnerHTML={createMarkup(response.data.errorText)}
            ></div>
          ),
          options: {
            action: true,
            autoHideDuration: 10000
          }
        })
      );
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          variant: error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.updateUserRoleFail({
        status: error.response.status,
        message: error.message || 'Network Request Failed'
      })
    );
  }
}
