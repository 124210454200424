import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import axios from "axios";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import Tooltip from "@material-ui/core/Tooltip";

import { green } from "@material-ui/core/colors";

import * as actions from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  textField: {
    width: "100%",
  },
}));

function EmailField(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [verifiedAt, setVerifiedAt] = useState(null);
  const [emailSentAt, setEmailSentAt] = useState(null);
  const [error, setError] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [mailSent, setMailSent] = useState(null);

  useEffect(() => {
    setEmail(props.data.email);
    if (props.data.verifiedAt) {
      setVerifiedAt(moment(props.data.verifiedAt).fromNow());
    } else {
      setVerifiedAt(props.data.verifiedAt);
    }
    if (props.data.emailSentAt) {
      setEmailSentAt(moment(props.data.emailSentAt).fromNow());
    }
  }, [props.data]);

  const handleChange = (e) => {
    setEmail(e.target.value);
    props.onChange(e);
  };

  /* Sending Email Confirmation Mail */
  const handleEmailConfirmationMail = (e) => {
    setVerifying(true);
    axios
      .post(`/api/system/user/${props.data.userId}/email/confirmation`)
      .then((resp) => {
        setVerifying(false);
        if (resp.data.error) {
          setMailSent("");
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          setMailSent("Mail Sent");
        }
      })
      .catch((err) => {
        setVerifying(false);
        setMailSent("Mail Sent");
        props.onEnqueueSnackbar(err.message, 3000);
      });
  };

  return (
    <FormControl
      className={clsx(classes.margin, classes.textField)}
      error={props.error}
    >
      <InputLabel htmlFor="email-field">Email</InputLabel>
      <Input
        id="email-field"
        type={"text"}
        value={email}
        onChange={handleChange}
        required={true}
        endAdornment={
          <InputAdornment position="end">
            {!verifiedAt ? (
              verifying ? (
                <CircularProgress size={16} thickness={5} />
              ) : (
                <Tooltip title={emailSentAt ? emailSentAt : "Verify Email"}>
                  <Button
                    color="secondary"
                    onClick={handleEmailConfirmationMail}
                  >
                    {mailSent || emailSentAt ? "ReSend" : "Verify"}
                  </Button>
                </Tooltip>
              )
            ) : (
              <React.Fragment>
                <IconButton aria-label="email verifification">
                  <Tooltip title={verifiedAt}>
                    <VerifiedUserOutlined style={{ color: green[500] }} />
                  </Tooltip>
                </IconButton>
                <Button color="secondary" onClick={handleEmailConfirmationMail}>
                  {mailSent ? "ReSend" : "ReVerify"}
                </Button>
              </React.Fragment>
            )}
          </InputAdornment>
        }
        aria-describedby="email-field-helper-text"
      />
      <FormHelperText id="email-field-helper-text">
        {props.helperText}
      </FormHelperText>
    </FormControl>
  );
}

export default EmailField;
