import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";

import moment from "moment";
import * as actions from "../../store/actions";
import urls from "../../common/urls";

const headRows = [
  { id: "id", numeric: false, label: "ID", sortable: true },
  { id: "code", numeric: false, label: "Code", sortable: true },
  { id: "title", numeric: false, label: "Title", sortable: true },
  { id: "level_id", numeric: false, label: "Level", sortable: true },
  { id: "version", numeric: false, label: "Version", sortable: true },
  { id: "qan", numeric: false, label: "QAN", sortable: true },
  { id: "is_active", numeric: false, label: "Status", sortable: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  table: {
    minWidth: 700,
    marginTop: 20,
  },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  searchField: {
    width: 240,
  },
  inputField: {
    width: 220,
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  chip: {
    height: "24px",
    margin: theme.spacing(1),
  },
  avatar: {
    height: "24px",
    width: "24px",
    fontSize: "12px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  clearBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  cellSm: {
    minWidth: "140px",
  },
}));
function QualificationList(props) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchText, setSearchText] = useState("");
  const [filterLevel, setFilterLevel] = useState("");
  const [filterStatus, setFilterStatus] = useState(1);

  useEffect(() => {
    let filters = localStorage.getItem("filter.qualifications");
    filters = JSON.parse(filters);
    if (filters) {
      setOrder(filters.order);
      setOrderBy(filters.orderBy);
      setSearchText(filters.searchText);
      setFilterLevel(filters.filterLevel);
      setFilterStatus(filters.filterStatus);
    }
  }, []);

  useEffect(() => {
    (async () => {
      let filters = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        order: order,
        searchText: searchText,
        filterLevel: filterLevel,
        filterStatus: filterStatus,
      };
      await localStorage.setItem(
        "filter.qualifications",
        JSON.stringify(filters)
      );
      await props.onGetQualifications(filters);
    })();
  }, [
    searchText,
    page,
    rowsPerPage,
    orderBy,
    order,
    filterLevel,
    filterStatus,
  ]);

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  function handleSorting(_orderBy) {
    setOrder(order == "desc" ? "asc" : "desc");
    setOrderBy(_orderBy);
  }

  function handleLevelChange(event) {
    setFilterLevel(event.target.value ? parseInt(event.target.value) : "");
    console.log(filterLevel);
  }

  function handleFilterStatus(status) {
    if (filterStatus === status) {
      setFilterStatus(null);
    } else {
      setFilterStatus(status);
    }
  }
  return (
    <Container>
      <div className={classes.chipRoot}>
        <Chip
          size="small"
          avatar={
            <Avatar className={classes.avatar}>
              {props.qualifications.qualStatuses.notActive}
            </Avatar>
          }
          label="Not Active"
          className={classes.chip}
          onClick={() => handleFilterStatus(0)}
          deleteIcon={<DoneIcon />}
          color={filterStatus === 0 ? "primary" : "default"}
        />
        <Chip
          size="small"
          avatar={
            <Avatar className={classes.avatar}>
              {props.qualifications.qualStatuses.pending}
            </Avatar>
          }
          label="Pending"
          className={classes.chip}
          onClick={() => handleFilterStatus(-1)}
          color={filterStatus === -1 ? "primary" : "default"}
        />
        <Chip
          size="small"
          avatar={
            <Avatar className={classes.avatar}>
              {props.qualifications.qualStatuses.active}
            </Avatar>
          }
          label="Active"
          className={classes.chip}
          onClick={() => handleFilterStatus(1)}
          color={filterStatus === 1 ? "primary" : "default"}
        />
      </div>
      <Paper className={classes.root}>
        <Toolbar>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ alignItems: "flex-end", display: "flex" }}
            >
              <Typography variant="h6" id="tableTitle">
                Qualifications
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="media-type-select">
                  Qualification Level
                </InputLabel>
                <Select
                  value={filterLevel ? filterLevel : ""}
                  onChange={handleLevelChange}
                  inputProps={{
                    name: "media-level",
                    id: "media-level-select",
                  }}
                >
                  {" "}
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.levels &&
                    props.levels.map((level) => (
                      <MenuItem key={level.id} value={level.id}>
                        {level.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <IconButton
                aria-label="Clear Search"
                onClick={() => setFilterLevel("")}
                size="small"
                className={classes.clearBtn}
              >
                {filterLevel ? (
                  <ClearIcon fontSize="small" color="secondary" />
                ) : null}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.actions}>
              <div className={classes.search}>
                <SearchIcon />
                <FormControl>
                  <InputLabel htmlFor="seartch-text">{`Search in code, title`}</InputLabel>
                  <Input
                    id="seartch-text"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    className={classes.inputField}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Clear Search"
                          size="small"
                          onClick={() => setSearchText("")}
                        >
                          {searchText.length > 0 ? (
                            <ClearIcon fontSize="small" color="secondary" />
                          ) : null}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
          </Grid>
        </Toolbar>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headRows.map((row) => (
                <TableCell
                  key={row.id}
                  align={row.disablePadding ? "none" : "inherit"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {row.sortable ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => handleSorting(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.qualifications &&
              !props.qualifications.loading &&
              props.qualifications.rows.map((item) => (
                <TableRow hover key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.level ? item.level.name : ""}</TableCell>
                  <TableCell>
                    {item.version
                      ? moment(item.version, "YYYY-MM-DD").format("MMM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell>{item.qan}</TableCell>
                  <TableCell>
                    {item.is_active === 1
                      ? "Active"
                      : item.is_active === -1
                      ? "Pending"
                      : "Not Active"}
                  </TableCell>
                  <TableCell className={classes.cellSm}>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        props.history.push(`/qualifications/list/${item.id}`)
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <Button
                      color="primary"
                      onClick={() =>
                        props.history.push(
                          `/qualifications/list/${item.id}/units`
                        )
                      }
                    >
                      Units
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {!props.qualifications.loading && props.qualifications.total == 0 && (
              <TableRow>
                <TableCell colSpan="9" align="center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!props.qualifications.loading && props.qualifications.total > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.qualifications.total}
            rowsPerPage={rowsPerPage}
            page={props.qualifications.total > 0 ? page : 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={(event) =>
              setRowsPerPage(parseInt(event.target.value, 10))
            }
          />
        )}
      </Paper>
      <Zoom
        className={classes.fabAdd}
        key="add"
        in={true}
        style={{ transitionDelay: "500ms" }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          className="classes.fab"
          color="secondary"
          onClick={() => props.history.push(`/qualifications/create`)}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    qualifications: state.qualifications,
    levels: state.common.levels,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetQualifications: (params) =>
      dispatch(actions.getQualifications(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QualificationList);
