import * as actionTypes from './actionTypes';

/*
 * Load All Modules
 * Route : /common
 */
export const getCommon = () => {
  return {
    type: actionTypes.GET_COMMON
  };
};
export const getCommonStart = () => {
  return {
    type: actionTypes.GET_COMMON_START
  };
};

export const getCommonSuccess = payload => {
  return {
    type: actionTypes.GET_COMMON_SUCCESS,
    payload
  };
};

export const getCommonFail = payload => {
  return {
    type: actionTypes.GET_COMMON_FAIL,
    payload
  };
};

export const getCentres = () => {
  return {
    type: actionTypes.GET_CENTRES
  };
};

export const getCentresSuccess = payload => {
  return {
    type: actionTypes.GET_CENTRES_SUCCESS,
    payload
  };
};

export const getCentresFail = payload => {
  return {
    type: actionTypes.GET_CENTRES_FAIL,
    payload
  };
};
