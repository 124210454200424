import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { emphasize, withStyles, makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import MenuIcon from "@material-ui/icons/Menu";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import HomeIcon from "@material-ui/icons/Home";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import Popover from "@material-ui/core/Popover";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";

import DrawerMenu from "./DrawerMenu";
import * as actions from "../../store/actions";
import {
  subscribeToRoom,
  subscribeToPrivateChannel,
  updateNotice,
} from "../../services/socket";
import Logo from "../Logo";

/* Dialog to show Role Selector  */
const useDialogStyles = makeStyles((theme) => ({
  dialogTitle: {
    minWidth: 250,
  },
}));

function RoleDialog(props) {
  const classes = useDialogStyles();
  const { onClose, selectedName, selectedDescription, roles, ...other } = props;

  function handleClose() {
    onClose(selectedName, selectedDescription);
  }

  function handleListItemClick(name, description) {
    onClose(name, description);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      {...other}
    >
      <DialogTitle className={classes.dialogTitle} id="simple-dialog-title">
        SWITCH YOUR ROLE
      </DialogTitle>
      <List>
        {roles.length > 0 &&
          roles.map((role) => (
            <ListItem
              button
              selected={role.name === selectedName}
              onClick={() => handleListItemClick(role.name, role.description)}
              key={role.name}
            >
              <ListItemText primary={role.description} />
              {role.name === selectedName && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Selected">
                    <CheckCircleIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
}

/* App BAR
 * Top BAR on the page
 */

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  breadcrumbRoot: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  grow: {
    flexGrow: 1,
    marginBottom: theme.spacing(5),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    //display: 'none',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'block',
    // },
    marginRight: theme.spacing(4),
  },
  roleButton: {
    margin: theme.spacing(1),
    color: "#fff",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  rootList: {
    width: "100%",
    maxWidth: 360,
  },
  listAvatar: {
    minWidth: "36px",
  },
  paperNotice: {
    width: "360px",
  },
  padding: {
    padding: theme.spacing(0, 1),
  },
  contentWrapper: {
    paddingTop: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 48;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
function TopAppBar(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    left: false,
    selectedMenuIndex: null,
    selectedSubMenuIndex: null,
  });
  const [anchorEl, setAnchorEl] = useState();
  const [roleSwitchOpen, setRoleSwitchOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [currentRoleText, setCurrentRoleText] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose(e, link = null) {
    setAnchorEl(null);
    handleMobileMenuClose();
    if (link) {
      props.history.push(link);
    }
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleLogout() {
    setAnchorEl(null);
    handleMobileMenuClose();
    props.onLogout();
  }
  function handleBreadcumbClick(event, link) {
    event.preventDefault();
    props.history.push(link);
  }
  function makeBreadcrumbLink(index) {
    return pathArr.slice(0, index + 1).join("/");
  }

  function handleSwitchRoleOpen() {
    setRoleSwitchOpen(true);
  }
  const handleSwitchRoleClose = async (name, description) => {
    setRoleSwitchOpen(false);
    setCurrentRole(name);
    setCurrentRoleText(description);
    await localStorage.setItem("current_role", name);
    props.onRoleSwitch();
  };

  /**
   * Handle Notice State
   */

  const [noticeTabValue, setNoticeTabValue] = useState(0);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [noticeEl, setNoticeEl] = useState(null);
  const noticeOpen = Boolean(noticeEl);
  const [noticeFilters, setNoticeFilters] = useState({
    offset: 0,
    limit: 10,
    orderBy: "created_at",
    order: "desc",
    searchText: "",
  });
  const [noticeOptEl, setNoticeOptEl] = useState(null);
  const noticeOptOpen = Boolean(noticeOptEl);

  function handleNoticeOpen(event) {
    setNoticeEl(event.currentTarget);
  }

  function handleNoticeClose() {
    setNoticeEl(null);
  }

  function handleNoticeOptOpen(event, id) {
    setNoticeOptEl(event.currentTarget);
    setSelectedNotice(id);
  }

  function handleNoticeOptClose() {
    setNoticeOptEl(null);
  }

  function handleNoticeUpdate(e, actionName, showRead = false) {
    setNoticeOptEl(null);
    let noticeId = showRead ? null : selectedNotice;
    updateNotice(
      props.currentRole.role_user.user_id,
      noticeId,
      actionName,
      showRead
    );
  }

  function handleNoticeTabChange(event, newValue) {
    setNoticeTabValue(newValue);
  }

  function handleNoticeTabChangeIndex(index) {
    setNoticeTabValue(index);
  }

  const renderUserMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={(e) => handleMenuClose(e)}
    >
      <MenuItem onClick={(e) => handleMenuClose(e, "/profile")}>
        Profile
      </MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  const toggleDrawerLinkClick = (event, side, open, menuIndex, subIndex) => {
    setState({
      ...state,
      [side]: open,
      selectedMenuIndex: menuIndex,
      selectedSubMenuIndex: subIndex,
    });
  };
  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <DrawerMenu
        toggleDrawerState={(event, openDrawer, menuIndex, subIndex) =>
          toggleDrawerLinkClick(event, side, openDrawer, menuIndex, subIndex)
        }
        selectedMenuIndex={state.selectedMenuIndex}
        selectedSubMenuIndex={state.selectedSubMenuIndex}
      />
    </div>
  );
  let pathArr = props.location.pathname.split("/");
  const isRestrictedArr = ["verify-email"].filter((item) =>
    pathArr.includes(item)
  );
  if (!isRestrictedArr.length) {
    pathArr = pathArr.filter((p) => p !== "");
  } else {
    pathArr = [];
  }

  //Subscribe to Notices Room , and Private Channel
  useEffect(() => {
    if (props.currentRole) {
      subscribeToRoom(props.currentRole.name, (data) => {
        if (data === "updated") {
          props.getNotices(noticeFilters);
        }
      });
      if (props.currentRole.role_user) {
        props.getNotices(noticeFilters);
        console.log(props.currentRole.role_user.user_id);
        subscribeToPrivateChannel(
          props.currentRole.role_user.user_id,
          (data) => {
            if (data === "updated") {
              props.getNotices(noticeFilters);
            }
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!props.loading && props.authUser.roles.length > 0) {
      if (!props.currentRole && !roleSwitchOpen) {
        setRoleSwitchOpen(true);
      } else if (props.currentRole) {
        setCurrentRole(props.currentRole.name);
        setCurrentRoleText(props.currentRole.description);
      }
    }
  });

  return (
    <div className={classes.grow}>
      <ElevationScroll {...props}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
              <Logo p={0} width="70px" src="ibsl-logo-white.png" />
            </Typography>
            <div className={classes.sectionDesktop}>
              {props.authUser.menus &&
                props.authUser.menus.map((item) => (
                  <Button
                    key={item.key}
                    color="inherit"
                    onClick={() => props.history.push(item.link)}
                  >
                    {item.label}
                  </Button>
                ))}
            </div>
            <div className={classes.grow} />
            <div>
              <Button
                className={classes.roleButton}
                onClick={handleSwitchRoleOpen}
              >
                {currentRoleText || `Select Role`} <ArrowDropDownIcon />
              </Button>
              {!props.authUser.loading && (
                <RoleDialog
                  roles={props.authUser.roles}
                  selectedName={currentRole}
                  selectedDescription={currentRoleText}
                  open={roleSwitchOpen}
                  onClose={handleSwitchRoleClose}
                />
              )}
            </div>
            <IconButton color="inherit" onClick={handleNoticeOpen}>
              <Badge badgeContent={props.notices.total} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <div className={classes.sectionDesktop}>
              <IconButton
                edge="end"
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          <Paper elevation={0} className={classes.breadcrumbRoot}>
            <Breadcrumbs aria-label="Breadcrumb">
              <StyledBreadcrumb
                component="a"
                href="#"
                label="Home"
                avatar={
                  <Avatar className={classes.avatar}>
                    <HomeIcon />
                  </Avatar>
                }
                onClick={(event) => handleBreadcumbClick(event, "/")}
              />
              {pathArr.length > 0 &&
                pathArr.map((path, index) => (
                  <StyledBreadcrumb
                    key={path}
                    href="#"
                    label={path}
                    onClick={(event) =>
                      handleBreadcumbClick(
                        event,
                        `/${makeBreadcrumbLink(index)}`
                      )
                    }
                  />
                ))}
            </Breadcrumbs>
            <Button
              size="small"
              className={classes.button}
              onClick={() => props.history.goBack()}
            >
              <ChevronLeft />
              Back
            </Button>
          </Paper>
        </AppBar>
      </ElevationScroll>
      {renderUserMenu}
      {renderMobileMenu}
      <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {sideList("left")}
      </SwipeableDrawer>

      <Popover
        id="notice-popover"
        open={noticeOpen}
        anchorEl={noticeEl}
        onClose={handleNoticeClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper className={classes.paperNotice}>
          <Tabs
            value={noticeTabValue}
            onChange={handleNoticeTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label={
                <Badge
                  className={classes.padding}
                  color="secondary"
                  badgeContent={props.notices.alertCount}
                >
                  Alerts
                </Badge>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Badge
                  className={classes.padding}
                  color="secondary"
                  badgeContent={props.notices.announcementCount}
                >
                  Announcements
                </Badge>
              }
              {...a11yProps(1)}
            />
          </Tabs>
          <Divider />
          <SwipeableViews
            axis="x"
            index={noticeTabValue}
            onChangeIndex={handleNoticeTabChangeIndex}
          >
            <TabPanel value={noticeTabValue} index={0}>
              <Menu
                id="long-menu"
                anchorEl={noticeOptEl}
                keepMounted
                open={noticeOptOpen}
                onClose={handleNoticeOptClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: 200,
                  },
                }}
              >
                {props.noticesConfig.menuOptions &&
                  props.noticesConfig.menuOptions.map((option) => (
                    <MenuItem
                      key={option.action}
                      onClick={(e) => handleNoticeUpdate(e, option.action)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
              </Menu>
              {props.notices.alertCount > 0 ? (
                <React.Fragment>
                  <List className={classes.rootList} dense={true}>
                    {props.notices.alerts.map((item) => (
                      <ListItem key={item.id}>
                        <ListItemText
                          primary={
                            props.noticesConfig.categories[item.category]
                          }
                          secondary={
                            <React.Fragment>
                              {item.message}.{" "}
                              <i>{moment(item.createdAt).fromNow()}</i>
                            </React.Fragment>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            size="small"
                            aria-label="menu"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleNoticeOptOpen(e, item.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <Box>
                    <Button
                      color="primary"
                      size="small"
                      onClick={(e) => handleNoticeUpdate(e, "READ-ALL", false)}
                    >
                      Mark As Read
                    </Button>
                    <Button
                      color="secondary"
                      size="small"
                      onClick={(e) =>
                        handleNoticeUpdate(e, "DELETE-ALL", false)
                      }
                    >
                      Delete All
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                  p={4}
                >
                  <Typography variant="h6">No Alerts Available</Typography>
                </Box>
              )}
            </TabPanel>
            <TabPanel value={noticeTabValue} index={1}>
              {props.notices.announcementCount > 0 && (
                <List className={classes.rootList} dense={true}>
                  {props.notices.announcements.map(
                    (item) =>
                      item.role && (
                        <ListItem key={item.id}>
                          <ListItemText
                            primary={
                              props.noticesConfig.categories[item.category]
                            }
                            secondary={
                              <React.Fragment>
                                {item.message}.{" "}
                                <i>{moment(item.createdAt).fromNow()}</i>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      )
                  )}
                </List>
              )}
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </Popover>
      <div className={classes.contentWrapper}>{props.children}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    loading: state.authUser.loading,
    currentRole: state.authUser.currentRole || null,
    authUser: state.authUser,
    noticesConfig: state.common.noticesConfig,
    notices: state.notices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
    onRoleSwitch: () => dispatch(actions.getAuthUser()),
    getNotices: (filters) => dispatch(actions.getNotices(filters)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopAppBar));
