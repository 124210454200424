import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import CentreEdit from './Centre/CentreEdit';
import TeacherList from './Teachers/TeacherList';
import Verify from './Verify';
import Finish from './Finish';

import * as actions from '../../store/actions/index';
import { urls } from '../../common/urls';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return ['Centre Details', 'Teacher Details', 'Verify Email', 'Submit'];
}

function getStepContent(step, centre) {
  switch (step) {
    case 0:
      return <CentreEdit centre={centre} />;
    case 1:
      return <TeacherList centre={centre} />;
    case 2:
      return <Verify centre={centre} />;
    default:
      return <Finish centre={centre} />;
  }
}

function CentreRegistration(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => {
      if (prevActiveStep + 1 == 4) {
        sendForApproval();
      }
      return prevActiveStep + 1;
    });
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const sendForApproval = () => {
    axios
      .post(urls.requestCentreApproval(props.centre.id))
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          props.onEnqueueSnackbar('Successfully sent for Approval', 3000);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch(error => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar('Access Denied', 1000, 'warning');
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, 'error');
        }
      });
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box display="flex" height={'100%'}>
      <Container>
        <Box display="flex" mb={3}>
          <Typography variant="h5">CENTRE REGISTRATION</Typography>
        </Box>

        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <Grid
              container
              spacing={2}
              justify="center"
              style={{ marginTop: '24px' }}
            >
              <Grid item xs={12} sm={6}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} gutterBottom>
                      Done
                    </Typography>
                    <Typography variant="h5" component="h3">
                      All steps completed - you&apos;re finished
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <div>
              <Box display="flex" my={2} justifyContent="flex-end">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
              {getStepContent(activeStep)}
            </div>
          )}
        </div>
      </Container>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    levels: state.common.levels,
    regions: state.common.regions,
    centre: state.authUser.centre
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CentreRegistration);
