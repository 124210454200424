import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { withStyles, makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Box from "@material-ui/core/Box";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import NavigationIcon from "@material-ui/icons/Navigation";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  container: {},
  title: {
    fontSize: 18
  },
  textField: {
    minWidth: 200
  },
  menu: {
    width: 200
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(4),
    bottom: theme.spacing(4)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const mediaInGroupText = (
  <React.Fragment>
    {
      "If the Entire Group is to be Assessed together (eg: Receptive Skills)  - "
    }
    <em>{`leave this blank`}</em>
    <br />
    {`if each Learner is Assessed in turn, then enter "1"`}
    <br />
    {`if this is a Live Observed Assessment where Learners are observed in groups of up to 3 then enter "3"`}
    <br />
  </React.Fragment>
);
const minLeadTimeText = `Minimum number of days before the Assessment Date that it can be requested by the Centre`;
const mediaIssuedHours = `The number of hours before the Assessment Date/Time that Media will be Automatically Issued - 672 hours is 28 days in advance`;

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

function EditModule(props) {
  const classes = useStyles();
  const [mediaGroups, setMediaGroups] = useState([]);
  const [module, setModule] = useState({
    id: null,
    name: "",
    title: "",
    description: "",
    is_active: "",
    is_allow_media: null,
    is_ccs_issued: null,
    is_optional: "",
    level_id: "",
    media_group_id: "",
    pass_grade: "",
    pass_grade_type: "",
    year: null
  });
  const [errorData, setErrorData] = useState({});
  useEffect(() => {
    if (!isNaN(props.match.params.id)) {
      axios.post(urls.getModule(props.match.params.id)).then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setModule(resp.data.module);
          fetchMediaGroups(resp.data.module.level_id);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
    }
  }, []);
  const fetchMediaGroups = level_id => {
    axios.post(urls.getMediaGroupsByLevel(level_id)).then(resp => {
      if (resp.status === 200 && resp.data.error === false) {
        setMediaGroups(resp.data.rows);
      }
    });
  };

  const handleChange = (name, radio = false) => event => {
    if (name === "level_id") {
      fetchMediaGroups(event.target.value);
    }
    if (radio) {
      setModule({ ...module, [name]: event.target.checked });
    } else {
      if (name === "year") {
        setModule({ ...module, [name]: event.format("YYYY") });
      } else {
        setModule({ ...module, [name]: event.target.value });
      }
    }
  };
  const handleSubmit = event => {
    event.preventDefault();
    let reqUrl = isNaN(props.match.params.id)
      ? urls.createModule
      : urls.updateModule(props.match.params.id);
    axios
      .post(reqUrl, { module })
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.match.params.id)) {
            props.history.push("/qualifications/modules");
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch(error => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  };
  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Assessment Module
          </Typography>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="module-name"
              label="Name"
              className={classes.textField}
              value={module.name}
              onChange={handleChange("name")}
              margin="normal"
              fullWidth={true}
              error={errorData.hasOwnProperty("name")}
              helperText={errorData.name}
            />
            <TextField
              id="module-title"
              label="Title"
              className={classes.textField}
              value={module.title}
              onChange={handleChange("title")}
              margin="normal"
              fullWidth={true}
              error={errorData.hasOwnProperty("title")}
              helperText={errorData.title}
            />
            <TextField
              id="select-level"
              select
              label="Qualification Level"
              className={classes.textField}
              value={module.level_id}
              onChange={handleChange("level_id")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              fullWidth={true}
              error={errorData.hasOwnProperty("level_id")}
              helperText={errorData.level_id}
            >
              {props.levels &&
                props.levels.map(level => (
                  <MenuItem key={level.id} value={level.id}>
                    {level.short_desc}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              id="select-assmt-type"
              select
              label="Assessment Type"
              className={classes.textField}
              value={module.assessment_type || ""}
              onChange={handleChange("assessment_type")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              fullWidth={true}
              error={errorData.hasOwnProperty("assessment_type")}
              helperText={errorData.assessment_type}
            >
              {props.assessmentTypes &&
                props.assessmentTypes.map(assessmentType => (
                  <MenuItem key={assessmentType.id} value={assessmentType.id}>
                    {assessmentType.name}
                  </MenuItem>
                ))}
            </TextField>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="select-active"
                  select
                  label="Grade Type"
                  className={classes.textField}
                  value={module.pass_grade_type || ""}
                  onChange={handleChange("pass_grade_type")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  error={errorData.hasOwnProperty("pass_grade_type")}
                  helperText={errorData.pass_grade_type}
                >
                  <MenuItem value="NUMBER">Number</MenuItem>
                  <MenuItem value="PERCENTAGE">Percentage</MenuItem>
                  <MenuItem value="PASS-FAIL">Pass or Fail</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="module-passgrade"
                  label="Pass Grade"
                  className={classes.textField}
                  value={
                    module.pass_grade_type == "PASS-FAIL"
                      ? ""
                      : module.pass_grade
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {module.pass_grade_type === "PERCENTAGE" ? "%" : ""}
                      </InputAdornment>
                    )
                  }}
                  onChange={handleChange("pass_grade")}
                  margin="normal"
                  disabled={
                    module.pass_grade_type == "PASS-FAIL" ? true : false
                  }
                  error={errorData.hasOwnProperty("pass_grade")}
                  helperText={errorData.pass_grade}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <HtmlTooltip title={minLeadTimeText}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="module-mpt"
                    label="Minimum Lead Time"
                    className={classes.textField}
                    value={module.mlt}
                    onChange={handleChange("mlt")}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">days</InputAdornment>
                      )
                    }}
                    error={errorData.hasOwnProperty("mlt")}
                    helperText={errorData.mlt}
                  />
                </HtmlTooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <HtmlTooltip title={mediaIssuedHours}>
                  <TextField
                    id="module-mih"
                    label="Media Issued Hours"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={module.media_issued_hours}
                    onChange={handleChange("media_issued_hours")}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">hours</InputAdornment>
                      )
                    }}
                    error={errorData.hasOwnProperty("media_issued_hours")}
                    helperText={errorData.media_issued_hours}
                  />
                </HtmlTooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  id="select-active"
                  control={
                    <Switch
                      checked={module.is_optional}
                      onChange={handleChange("is_optional", true)}
                      value={1}
                    />
                  }
                  label="Is Module Optional"
                  error={errorData.hasOwnProperty("is_optional")}
                  helperText={errorData.is_optional}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  id="select-active"
                  control={
                    <Switch
                      checked={module.is_active}
                      onChange={handleChange("is_active", true)}
                      value={1}
                    />
                  }
                  label="Module Active"
                  error={errorData.hasOwnProperty("is_active")}
                  helperText={errorData.is_active}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <HtmlTooltip title={mediaInGroupText}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="module-duration"
                    label="No of Learners Assessed at a time"
                    className={classes.textField}
                    value={module.media_in_group}
                    onChange={handleChange("media_in_group")}
                    margin="normal"
                    error={errorData.hasOwnProperty("media_in_group")}
                    helperText={errorData.media_in_group}
                  />
                </HtmlTooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="module-duration"
                  label="Media Duration"
                  className={classes.textField}
                  value={module.duration_mins}
                  onChange={handleChange("duration_mins")}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">mins</InputAdornment>
                    )
                  }}
                  error={errorData.hasOwnProperty("duration_mins")}
                  helperText={errorData.duration_mins}
                />
              </Grid>
            </Grid>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                views={["year"]}
                label="Year"
                margin="normal"
                value={module.year ? `${module.year}` : null}
                onChange={handleChange("year")}
              />
            </MuiPickersUtilsProvider>
            <TextField
              id="module-media-group"
              select
              label="Media Group"
              className={classes.textField}
              value={module.media_group_id}
              onChange={handleChange("media_group_id")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              fullWidth={true}
              error={errorData.hasOwnProperty("media_group_id")}
              helperText={errorData.media_group_id}
            >
              {mediaGroups &&
                mediaGroups.map(group => (
                  <MenuItem key={group.id} value={group.id}>
                    {`${group.name} ${group.year ? `(${group.year})` : ""}`}
                  </MenuItem>
                ))}
            </TextField>
          </form>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {isNaN(props.match.params.id) ? "Create Module" : "Update Module"}
          </Button>
        </CardActions>
      </Card>
      {!isNaN(props.match.params.id) && module.marksheetCount > 0 && (
        <Zoom
          className={classes.fabAdd}
          key="add"
          in={true}
          style={{ transitionDelay: "500ms" }}
          unmountOnExit
        >
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            aria-label="add"
            className={classes.margin}
            onClick={() =>
              props.history.push(
                `/qualifications/modules/${props.match.params.id}/marksheet`
              )
            }
          >
            <AddIcon className={classes.extendedIcon} />
            Marksheet
          </Fab>
        </Zoom>
      )}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    modules: state.modules,
    assessmentTypes: state.common.assessmentTypes,
    levels: state.common.levels
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditModule)
);
