/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AlertDialog from "../../components/AlertDialog";
import Pink from "@material-ui/core/colors/pink";
import LighttGreen from "@material-ui/core/colors/lightGreen";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import axios from "axios";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  title: {
    fontSize: 14,
  },
  actionIcon: {
    cursor: "pointer",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  currentRowUnpaid: {
    backgroundColor: Pink[100],
  },
  currentRowPaid: {
    backgroundColor: LighttGreen[100],
  },
  cardRoot: {
    paddingBottom: 0,
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
    height: "24px",
  },
}));

function AccountFeeEdit(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [statusCodes, setStatusCodes] = useState({
    centre: {},
    annualFee: {},
  });
  const paymentObj = { invoice_no: "", invoice_date: null, paid_date: null };
  const [annualFee, setAnnualFee] = useState({});
  const [annualFees, setAnnualFees] = useState([]);
  const [annualFeesHistory, setAnnualFeesHistory] = useState([]);
  const [filteredFeesHistory, setFilteredFeesHistory] = useState([]);
  const [payment, setPayment] = useState(paymentObj);
  const [centre, setCentre] = useState({});
  const [centreLevels, setCentreLevels] = useState([]);
  const [asstPage, setAsstPage] = React.useState(0);
  const [totalPaid, setTotalPaid] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [errorData, setErrorData] = React.useState({});
  const [dialogTitle, setDialogTitle] = React.useState(
    "Update Payment Details"
  );
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [filterLevels, setFilterLevels] = useState([]);

  useEffect(() => {
    (async () => {
      fetchAnnualFees();
    })();
  }, []);

  const fetchAnnualFees = async (params) => {
    setLoading(true);
    let resp = await axios.post(
      urls.getannualFee(props.match.params.id),
      params
    );
    if (resp.status === 200 && resp.data.error === false) {
      setLoading(false);

      const { config, annualFeeRecords, feeHistory, centre } = resp.data.rows;
      const { centre_levels, ...centreRecords } = centre;
      const centreLevels = centre_levels.map((item) => {
        let results = feeHistory.filter(
          (row) => row.id == annualFeeRecords[item.level_id]
        );
        return results
          ? { ...item, annualFee: results[0] }
          : { ...item, annualFee: {} };
      });
      setAnnualFeesHistory(feeHistory);
      setFilteredFeesHistory(feeHistory);
      setCentre(centreRecords);
      setCentreLevels(centreLevels);
      setStatusCodes(config);
      console.log(centreLevels);
    } else {
      props.onEnqueueSnackbar(resp.data.errorText, 3000);
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  /**
   * Handle Payment update  TextField & DateField
   * */
  const handleChange = (name) => async (e) => {
    setPayment({ ...payment, [name]: e.target.value });
    delete errorData[name];
  };
  const handlePaymentChange = () => (e) => {
    setOpen(true);
    setDialogTitle("Update Payment Details");
  };

  function handlePaymentDelete({ annualFeeId, paymentId }, agree) {
    setOpenApprovalAlert(false);
    if (agree) {
      setLoading(true);
      axios
        .post(urls.deletePayment(annualFeeId), { paymentId: paymentId })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setErrorData({});
            fetchAnnualFees();
            setOpen(false);
            props.onEnqueueSnackbar("Success!!", 1000, "success");
          } else {
            if (resp.data.errorData) {
              setErrorData(resp.data.errorData);
            }
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (403 === error.response.status) {
            props.onEnqueueSnackbar("Access Denied", 1000, "warning");
          } else {
            props.onEnqueueSnackbar(
              error.response.data.errorText,
              3000,
              "error"
            );
          }
        });
    }
  }
  /** handle Date change in Dialog */
  const handleDateChange = (name) => (e) => {
    setPayment({
      ...payment,
      [name]: e.format("YYYY-MM-DD"),
    });
    delete errorData[name];
  };

  /** Submit Payment changes */
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.updatePayment(props.match.params.id), {
        payment,
        selected,
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          fetchAnnualFees();
          setOpen(false);
          props.onEnqueueSnackbar("Success!!", 1000, "success");
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  //** Select all from checkbox */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = centreLevels.map((n) => n.annualFee.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  //** Handle Select Individual */
  const handleCheckBoxSelect = (event, id) => {
    if (selected.indexOf(id) === -1) {
      setSelected([...selected, id]);
    } else {
      const ids = selected.filter((item) => item !== id);
      setSelected(ids);
    }
  };
  /**
   * Update selected level and filter records
   */
  async function handleFilterLevelChange(levelId) {
    let selectedIds;
    if (!filterLevels) {
      selectedIds = [levelId];
    } else if (filterLevels.includes(levelId)) {
      selectedIds = await filterLevels.filter((item) => item != levelId);
    } else {
      selectedIds = [...filterLevels, levelId];
    }
    setFilterLevels(selectedIds);
    const feeHistory = annualFeesHistory.filter((item) =>
      selectedIds.includes(item.level)
    );
    if (selectedIds.length > 0) {
      setFilteredFeesHistory(feeHistory);
    } else {
      setFilteredFeesHistory(annualFeesHistory);
    }
  }
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.button}
          >
            Remind Centre
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handlePaymentChange()}
          >
            Update Invoice
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Paper>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Centre Details
                </Typography>
                <Table className={classes.table} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Centre</TableCell>
                      <TableCell>{centre.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Centre No</TableCell>
                      <TableCell>{centre.number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Registered At</TableCell>
                      <TableCell>{centre.registered_at}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>{statusCodes.centre[centre.status]}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Annual Fees
            </Typography>
            <Paper className={classes.cardRoot}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Level</TableCell>
                    <TableCell>Fee</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < centreLevels.length
                        }
                        checked={
                          selected.length === Object.keys(centreLevels).length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{ "aria-label": "select all levels" }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {centreLevels &&
                    centreLevels.map((item) => (
                      <TableRow
                        key={item.id}
                        className={clsx({
                          [classes.currentRowPaid]: item.status > 120,
                          [classes.currentRowUnpaid]:
                            item.annualFee &&
                            (item.annualFee.status < 130 ||
                              moment().isAfter(
                                moment(item.annualFee.end_date, "DD-MM-YYYY")
                              )),
                        })}
                      >
                        <TableCell>{item.level.name}</TableCell>
                        <TableCell align="right">
                          {item.annualFee ? item.annualFee.fee : ""}
                        </TableCell>
                        <TableCell>
                          {item.annualFee ? item.annualFee.start_date : ""}
                        </TableCell>
                        <TableCell>
                          {item.annualFee ? item.annualFee.end_date : ""}
                        </TableCell>
                        <TableCell>
                          {item.annualFee
                            ? statusCodes.annualFee[item.annualFee.status]
                            : ""}
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              item.annualFee &&
                              selected.indexOf(item.annualFee.id) !== -1
                            }
                            inputProps={{ "aria-labelledby": item.id }}
                            onClick={(e) =>
                              handleCheckBoxSelect(e, item.annualFee.id)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              History
            </Typography>
            <div className={classes.chipRoot}>
              {props.levels.map((item) => {
                return (
                  item.id < 50 && (
                    <Chip
                      key={item.id}
                      variant="outlined"
                      size="small"
                      label={item.name}
                      className={classes.chip}
                      onClick={(e) => handleFilterLevelChange(item.id)}
                      deleteIcon={<DoneIcon />}
                      color={
                        filterLevels && filterLevels.includes(item.id)
                          ? "primary"
                          : "default"
                      }
                    />
                  )
                );
              })}
            </div>
            <Paper className={classes.cardRoot}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Level</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Fee</TableCell>
                    <TableCell>Invoice No</TableCell>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>Paid Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredFeesHistory &&
                    filteredFeesHistory
                      .slice(asstPage * 10, asstPage * 10 + 10)
                      .map((item) => (
                        <TableRow
                          key={item.id}
                          className={clsx({
                            [classes.currentRowPaid]:
                              item.id === annualFee.id && item.status > 120,
                            [classes.currentRowUnpaid]:
                              item.id === annualFee.id && item.status < 130,
                          })}
                        >
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.levels.name}</TableCell>
                          <TableCell>{item.start_date}</TableCell>
                          <TableCell>{item.end_date}</TableCell>
                          <TableCell align="right">
                            {item.annual_fee_payments.map((fee, index) => (
                              <div key={index}>{fee.amount}</div>
                            ))}
                          </TableCell>
                          <TableCell>
                            {item.annual_fee_payments.map((fee, index) => (
                              <div key={index}>{fee.invoice_no}</div>
                            ))}
                          </TableCell>
                          <TableCell>
                            {item.annual_fee_payments.map((fee, index) => (
                              <div key={index}>{fee.invoice_date}</div>
                            ))}
                          </TableCell>
                          <TableCell>
                            {item.annual_fee_payments.map((fee, index) => (
                              <div key={index}>{fee.paid_date}</div>
                            ))}
                          </TableCell>
                          <TableCell>
                            {statusCodes.annualFee[item.status]}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      colSpan={9}
                      count={annualFeesHistory && annualFeesHistory.length}
                      rowsPerPage={10}
                      page={asstPage}
                      onChangePage={(e, newPage) => setAsstPage(newPage)}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        title="Delete Payment"
        description="Are you sure you want to delete the payment detail."
        open={Boolean(openApprovalAlert)}
        handleAlertClose={(agree) =>
          handlePaymentDelete({ ...openApprovalAlert }, agree)
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Annual Fee</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogTitle}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="invoice_no"
            value={payment.invoice_no}
            onChange={handleChange("invoice_no")}
            label="Invoice No"
            type="text"
            fullWidth
          />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              fullWidth
              label="Invoice Date"
              margin="normal"
              format="DD-MM-YYYY"
              value={payment.invoice_date || null}
              onChange={handleDateChange("invoice_date")}
              error={errorData.hasOwnProperty("invoice_date")}
              helperText={errorData.invoice_date}
            />
            <DatePicker
              fullWidth
              label="Payment Date"
              margin="normal"
              format="DD-MM-YYYY"
              value={payment.paid_date || null}
              onChange={handleDateChange("paid_date")}
              error={errorData.hasOwnProperty("paid_date")}
              helperText={errorData.paid_date}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary" disabled={loading}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    centres: state.centres.rows,
    levels: state.common.levels,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCentreNames: () => dispatch(actions.getCentres()),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountFeeEdit);
