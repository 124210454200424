import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./store/sagas/root";
import axios from "axios";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";

import { SnackbarProvider } from "notistack";

import "./index.css";
import ErrorBoundary from "./ErrorBoundary";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./store/reducers/root";
var moment = require("moment-timezone");
moment.tz.setDefault("Europe/London");

const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(async (request) => {
  request.baseURL = API_URL;
  const token = await localStorage.getItem("token");
  const currentrole = await localStorage.getItem("current_role");
  if (token != null) {
    request.headers.Authorization = `Bearer ${token}`;
    request.headers.currentrole = currentrole;
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      localStorage.removeItem("userId");
      store.dispatch({ type: "AUTH_LOGOUT" });
    } else if (403 === error.response.status) {
      error.message = "Access Denied";
    }
    return Promise.reject(error);
  }
);

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
const theme = createMuiTheme({
  palette: {
    primary: blue,
    background: {
      default: "#f5f5f5",
    },
  },
});

const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
