import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

import {
  subscribeToRoom,
  subscribeToPrivateChannel,
  updateNotice
} from '../../services/socket';

import {
  VictoryTheme,
  VictoryChart,
  VictoryBar,
  VictoryZoomContainer,
  VictoryTooltip,
  VictoryLegend,
  VictoryPie
} from 'victory';
import * as actions from '../../store/actions';
import { urls } from '../../common/urls';

const CancelToken = axios.CancelToken;
let cancelAxios = [];

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  avatarCount: {
    height: 24,
    width: 24,
    fontSize: '1rem',
    display: 'inline-flex'
  },
  paper: {
    padding: theme.spacing(2)
  },
  listItem: {
    paddingLeft: 0,
    fontSize: 1
  },
  paperStat: {
    height: 80,
    padding: theme.spacing(2)
  },
  control: {
    padding: theme.spacing(2)
  },
  chip: {
    height: '22px',
    marginLeft: '5px'
  }
});
const colors = [
  'rgba(255, 99, 132, 0.8)',
  'rgba(54, 162, 235, 0.8)',
  'rgba(255, 206, 86, 0.8)',
  'rgba(75, 192, 192, 0.8)',
  'rgba(153, 102, 255, 0.8)',
  'rgba(255, 159, 64, 0.8)'
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counts: [],
      asstData: [],
      asstPieData: [],
      asstFilters: {
        minDate: moment()
          .subtract(15, 'days')
          .format(),
        maxDate: moment()
          .add(15, 'days')
          .format(),
        type: null,
        onlyResit: false,
        range: 'DAILY',
        onlyAssessment: false
      },
      asstLoading: true,
      approvalPieData: [],
      approvalLoading: true,
      noticeTabValue: 0,
      selectedNotice: 0,
      noticeOpen: false,
      noticeEl: null,
      noticeOptEl: null,
      noticeFilters: {
        offset: 0,
        limit: 10,
        orderBy: 'created_at',
        order: 'desc',
        searchText: ''
      }
    };
    this.handleAsstFilter = this.handleAsstFilter.bind(this);
    this.handleNoticeOpen = this.handleNoticeOpen.bind(this);
    this.handleNoticeClose = this.handleNoticeClose.bind(this);
    this.handleNoticeOptOpen = this.handleNoticeOptOpen.bind(this);
    this.handleNoticeOptClose = this.handleNoticeOptClose.bind(this);
    this.handleNoticeUpdate = this.handleNoticeUpdate.bind(this);
    this.handleNoticeTabChange = this.handleNoticeTabChange.bind(this);
    this.handleNoticeTabChangeIndex = this.handleNoticeTabChangeIndex.bind(
      this
    );
    this.handleNoticeTabChangeIndex = this.handleNoticeTabChangeIndex.bind(
      this
    );
  }

  componentDidMount() {
    this.fetchCounts();
    this.fetchAssessmentStats();
    this.fetchPendingApprovals();

    return () => {
      cancelAxios.entries(c => c());
    };
  }
  /**
   * Handle Notice State
   */

  handleNoticeOpen(event) {
    this.setState({ noticeEl: event.currentTarget });
  }

  handleNoticeClose() {
    this.setState({ noticeEl: null });
  }

  handleNoticeOptOpen(event, id) {
    this.setState({ noticeOptEl: event.currentTarget });
    this.setState({ selectedNotice: id });
  }

  handleNoticeOptClose() {
    this.setState({ noticeOptEl: null });
  }

  handleNoticeUpdate(e, actionName, showRead = false) {
    this.setState({ noticeOptEl: null });
    this.setState({ noticeOptEl: null });
    let noticeId = showRead ? null : this.state.selectedNotice;
    updateNotice(
      this.props.currentRole.role_user.user_id,
      noticeId,
      actionName,
      showRead
    );
  }

  handleNoticeTabChange(event, newValue) {
    this.setState({ noticeTabValue: newValue });
  }

  handleNoticeTabChangeIndex(index) {
    this.setState({ noticeTabValue: index });
  }
  fetchCounts() {
    try {
      axios
        .get(urls.dashboard, {
          cancelToken: new CancelToken(function executor(c) {
            cancelAxios.push(c);
          })
        })
        .then(resp => {
          if (resp.status === 200 && !resp.data.error) {
            this.setState({ counts: resp.data.counts });
          } else {
            this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  async fetchAssessmentStats() {
    try {
      this.setState({ asstLoading: true });
      await axios
        .post(
          urls.dashboardAssessments,
          { ...this.state.asstFilters },
          {
            cancelToken: new CancelToken(function executor(c) {
              cancelAxios.push(c);
            })
          }
        )
        .then(async resp => {
          if (resp.status === 200 && !resp.data.error) {
            let result1 = await resp.data.stats[0].map(item => ({
              date: moment(item.date).toDate(),
              count: item.count
            }));
            this.setState({ asstData: result1 });
            let result2 = await resp.data.stats[1].map(item => ({
              x: resp.data.statusCodes[item.status],
              y: item.count
            }));
            this.setState({ asstPieData: result2 });
          } else {
            this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .finally(() => {
          this.setState({ asstLoading: false });
        });
    } catch (error) {}
  }
  async fetchPendingApprovals() {
    try {
      this.setState({ approvalLoading: true });
      await axios
        .get(urls.dashboardApprovals, {
          cancelToken: new CancelToken(function executor(c) {
            cancelAxios.push(c);
          })
        })
        .then(async resp => {
          if (resp.status === 200 && !resp.data.error) {
            let result = await resp.data.result.map(item => ({
              x: resp.data.entityCodes[item.entity_type],
              y: item.count
            }));
            this.setState({ approvalPieData: result });
          } else {
            this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .finally(() => {
          this.setState({ approvalLoading: false });
        });
    } catch (error) {}
  }
  handleZoom(domain) {
    this.setState({ selectedDomain: domain });
  }

  handleBrush(domain) {
    this.setState({ zoomDomain: domain });
  }

  async handleAsstFilter(range) {
    let asstFilters = await Object.assign({}, this.state.asstFilters);
    asstFilters.range = range;
    switch (range) {
      case '1_YEAR':
        asstFilters.minDate = await moment()
          .subtract(365, 'days')
          .format();
        break;
      case '30_DAYS':
        asstFilters.minDate = await moment()
          .subtract(30, 'days')
          .format();
        break;
      case 'DAILY':
        asstFilters.minDate = await moment()
          .subtract(15, 'days')
          .format();
        asstFilters.maxDate = await moment()
          .add(15, 'days')
          .format();
        break;
      case 'NEXT_30':
        asstFilters.minDate = await moment().format();
        asstFilters.maxDate = await moment()
          .add(30, 'days')
          .format();
        break;
      case 'RESITS_ONLY':
        asstFilters.onlyResit = !asstFilters.onlyResit;
        break;
      default:
        asstFilters.minDate = await moment()
          .subtract(365 * 3, 'days')
          .format();
        break;
    }
    this.setState({ asstFilters, asstLoading: true });
    this.fetchAssessmentStats();
  }

  render() {
    const { classes } = this.props;
    const {
      counts,
      asstData,
      asstLoading,
      asstFilters,
      approvalLoading
    } = this.state;
    return (
      <Container maxWidth="lg">
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={4} sm={2}>
            <Paper className={classes.paperStat}>
              <Typography variant="h5" component="h2">
                {counts.user}
              </Typography>
              <div> Users</div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Paper className={classes.paperStat}>
              <Typography variant="h5" component="h2">
                {counts.centre}
              </Typography>
              <div> Centres</div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Paper className={classes.paperStat}>
              <Typography variant="h5" component="h2">
                {counts.teacher}
              </Typography>
              <div>Teachers</div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Paper className={classes.paperStat}>
              <Typography variant="h5" component="h2">
                {counts.courses}
              </Typography>
              <div>Courses</div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Paper className={classes.paperStat}>
              <Typography variant="h5" component="h2">
                {counts.assessment}
              </Typography>
              <div>Assessments</div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Paper className={classes.paperStat}>
              <Typography variant="h5" component="h2">
                {counts.approvals}
              </Typography>
              <div>Approvals</div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper>
              <Box pt={2} pl={2}>
                <Typography variant="body2" color="textSecondary">
                  Approvals Pending
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={'200px'} height={'240px'}>
                  <VictoryLegend
                    x={10}
                    y={20}
                    width={'150px'}
                    height={'230px'}
                    title="Status"
                    centerTitle
                    orientation="vertical"
                    gutter={20}
                    labels={({ datum }) => datum.y}
                    style={{ title: { fontSize: 20 } }}
                    data={this.state.approvalPieData.map((item, i) => ({
                      name: `${item.x} (${item.y})`,
                      symbol: { fill: colors[i] }
                    }))}
                  />
                </Box>
                <Box width={'260px'} height={'260px'}>
                  <VictoryPie
                    colorScale={colors}
                    padAngle={1}
                    innerRadius={75}
                    labels={({ datum }) => `${datum.x} ${datum.y}`}
                    labelRadius={({ innerRadius }) => innerRadius + 22}
                    data={this.state.approvalPieData}
                    style={{ labels: { fontSize: 24, fontWeight: 700 } }}
                    labelComponent={
                      <VictoryTooltip
                        flyoutStyle={{
                          fontSize: 16,
                          fontWeight: 700,
                          fill: 'rgba(255,255,255,0.8)',
                          stroke: 'tomato'
                        }}
                      />
                    }
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onMouseOver: () => {
                            return [
                              {
                                target: 'data',
                                mutation: ({ style }) => {
                                  return style.fill
                                    ? null
                                    : { style: { fill: 'gold' } };
                                }
                              },
                              {
                                target: 'labels',
                                mutation: () => ({ active: true })
                              }
                            ];
                          },
                          onMouseOut: () => {
                            return [
                              {
                                target: 'data',
                                mutation: () => {}
                              },
                              {
                                target: 'labels',
                                mutation: () => ({ active: false })
                              }
                            ];
                          }
                        }
                      }
                    ]}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.paper} style={{ height: '300px' }}>
              <Typography fontWeight="fontWeightBold" variant="h6">
                Alerts:
              </Typography>
              {this.props.notices.alertCount > 0 ? (
                <React.Fragment>
                  <List className={classes.rootList} dense={true}>
                    {this.props.notices.alerts.map((item, index) => {
                      if (index < 3) {
                        return (
                          <ListItem key={item.id} className={classes.listItem}>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  {item.message}.{' '}
                                  <i>{moment(item.createdAt).fromNow()}</i>
                                </React.Fragment>
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                size="small"
                                aria-label="menu"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={e =>
                                  this.handleNoticeOptOpen(e, item.id)
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      }
                    })}
                  </List>
                  <Divider />
                  <Box>
                    <Button
                      color="primary"
                      size="small"
                      onClick={e =>
                        this.handleNoticeUpdate(e, 'READ-ALL', false)
                      }
                    >
                      Mark As Read
                    </Button>
                    <Button
                      color="secondary"
                      size="small"
                      onClick={e =>
                        this.handleNoticeUpdate(e, 'DELETE-ALL', false)
                      }
                    >
                      Delete All
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                  p={4}
                >
                  <Typography variant="h6">No Alerts Available</Typography>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Paper>
              <Box
                pt={2}
                pl={2}
                pr={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="h2">
                  Assessments
                </Typography>
                <Chip
                  size="small"
                  label="ReSits Only"
                  className={classes.chip}
                  color={asstFilters.onlyResit ? 'primary' : 'default'}
                  disabled={asstLoading}
                  onClick={() => this.handleAsstFilter('RESITS_ONLY')}
                />
              </Box>
              <Box pl={2} pt={2}>
                <Chip
                  size="small"
                  label="Last 1 Year"
                  className={classes.chip}
                  color={asstFilters.range === '1_YEAR' ? 'primary' : 'default'}
                  disabled={asstLoading}
                  onClick={() => this.handleAsstFilter('1_YEAR')}
                />
                <Chip
                  size="small"
                  label="Last 30 days"
                  className={classes.chip}
                  color={
                    asstFilters.range === '30_DAYS' ? 'primary' : 'default'
                  }
                  disabled={asstLoading}
                  onClick={() => this.handleAsstFilter('30_DAYS')}
                />
                <Chip
                  size="small"
                  label="Daily"
                  className={classes.chip}
                  color={asstFilters.range === 'DAILY' ? 'primary' : 'default'}
                  disabled={asstLoading}
                  onClick={() => this.handleAsstFilter('DAILY')}
                />
                <Chip
                  size="small"
                  label="Next 30 days"
                  className={classes.chip}
                  color={
                    asstFilters.range === 'NEXT_30' ? 'primary' : 'default'
                  }
                  disabled={asstLoading}
                  onClick={() => this.handleAsstFilter('NEXT_30')}
                />
              </Box>
              <Box
                height={'300px'}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {!asstLoading ? (
                  <VictoryChart
                    theme={VictoryTheme.material}
                    responsive={true}
                    height={300}
                    width={600}
                    domainPadding={{ x: 15 }}
                    scale={{ x: 'time' }}
                    // domainPadding will add space to each side of VictoryBar to
                    // prevent it from overlapping the axis
                    containerComponent={
                      <VictoryZoomContainer
                        responsive={false}
                        zoomDimension="x"
                        zoomDomain={this.state.zoomDomain}
                        onZoomDomainChange={this.handleZoom.bind(this)}
                      />
                    }
                  >
                    <VictoryBar
                      barRatio={0.8}
                      barWidth={10}
                      labels={({ datum }) => datum.count}
                      style={{ data: { fill: '#f4511e' } }}
                      data={asstData}
                      x="date"
                      y="count"
                    ></VictoryBar>
                  </VictoryChart>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Paper>
              <Box pt={2} pl={2}>
                <Typography variant="body2" color="textSecondary">
                  {moment(this.state.asstFilters.minDate).format('LL')} -{' '}
                  {moment(this.state.asstFilters.maxDate).format('LL')}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={'200px'} height={'300px'}>
                  <VictoryLegend
                    x={10}
                    y={20}
                    width={'200px'}
                    height={'300px'}
                    title="Status"
                    centerTitle
                    orientation="vertical"
                    gutter={20}
                    labels={({ datum }) => datum.y}
                    style={{ title: { fontSize: 20 } }}
                    data={this.state.asstPieData.map((item, i) => ({
                      name: `${item.x} (${item.y})`,
                      symbol: { fill: colors[i] }
                    }))}
                  />
                </Box>
                <Box width={'400px'} height={'370px'} mt={'-20px'}>
                  <VictoryPie
                    colorScale={colors}
                    padAngle={1}
                    style={{ labels: { fontSize: 16, fontWeight: 700 } }}
                    labelComponent={
                      <VictoryTooltip
                        flyoutStyle={{
                          fontSize: 16,
                          fontWeight: 700,
                          fill: 'rgba(255,255,255,0.8)',
                          stroke: 'tomato'
                        }}
                      />
                    }
                    innerRadius={75}
                    labels={({ datum }) => `${datum.x} (${datum.y})`}
                    labelRadius={({ innerRadius }) => innerRadius + 20}
                    data={this.state.asstPieData}
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onMouseOver: () => {
                            return [
                              {
                                target: 'data',
                                mutation: ({ style }) => {
                                  return style.fill
                                    ? null
                                    : { style: { fill: 'gold' } };
                                }
                              },
                              {
                                target: 'labels',
                                mutation: () => ({ active: true })
                              }
                            ];
                          },
                          onMouseOut: () => {
                            return [
                              {
                                target: 'data',
                                mutation: () => {}
                              },
                              {
                                target: 'labels',
                                mutation: () => ({ active: false })
                              }
                            ];
                          }
                        }
                      }
                    ]}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    role: state.authUser.currentRole,
    levels: state.common.levels,
    modules: state.common.modules,
    noticesConfig: state.common.noticesConfig,
    notices: state.notices
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotices: filters => dispatch(actions.getNotices(filters)),
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Dashboard)
  )
);
