import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import IntegrationDownshift from "../../components/IntegrationDownshift";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  uploadInput: {
    display: "none",
  },
  button: {
    marginRight: theme.spacing(2),
  },
  dialogTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#efefef",
  },
  regionLabel: {
    width: 200,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  overflowCard: {
    marginBottom: theme.spacing(2),
    overflow: "visible",
  },
  viewButton: {
    margin: 0,
    padding: 0,
    minWidth: 50,
  },
  listItem: {
    borderLeft: `${theme.spacing(1)}px solid`,
  },
  nested: {
    marginLeft: theme.spacing(2),
    borderLeft: `${theme.spacing(1)}px solid`,
    "& .MuiTypography-body1": {
      fontSize: ".875rem",
    },
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

function EditMediaGroup(props) {
  const classes = useStyles();
  const [mediaGroup, setMediaGroup] = useState({
    id: null,
    name: "",
    description: "",
    level_id: "",
    is_active: 1,
    year: null,
  });
  const [updating, setUpdating] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [filterMedia, setFilterMedia] = useState({});
  const [mediaForms, setMediaForms] = React.useState([]);
  const [pmediaForms, setPmediaForms] = React.useState([]);
  const [rmediaForms, setRmediaForms] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState("");

  useEffect(() => {
    if (!isNaN(props.match.params.id)) {
      // Get Media Group
      axios.post(urls.getMediaGroup(props.match.params.id)).then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setMediaGroup(resp.data.mediaGroup);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
      // Get Associated Media for Group
      axios
        .post(urls.getGroupAssociatedMedia(props.match.params.id))
        .then(async (resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            let media = await resp.data.rows
              .filter((item) => item.type === "A")
              .map((item) => item.media_id);
            let pmedia = await resp.data.rows
              .filter((item) => item.type === "P")
              .map((item) => item.media_id);
            let rmedia = await resp.data.rows
              .filter((item) => item.type === "R")
              .map((item) => item.media_id);
            setMediaForms(media);
            setPmediaForms(pmedia);
            setRmediaForms(rmedia);
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (mediaGroup.level_id) {
      axios
        .post(urls.getMediaForms(mediaGroup.level_id), {
          nonDvd: false,
          skipMediaType: [7],
        })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setFilterMedia(resp.data.rows);
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        });
    }
  }, [mediaGroup.level_id]);

  const handleChange = (name) => (event) => {
    if (name === "year") {
      setMediaGroup({ ...mediaGroup, [name]: event.format("YYYY") });
    } else {
      setMediaGroup({ ...mediaGroup, [name]: event.target.value });
    }
  };

  // Update Media Group
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setUpdating(true);
      let url = !isNaN(props.match.params.id)
        ? urls.updateMediaGroup(props.match.params.id)
        : urls.createMediaGroup;
      axios
        .post(url, { mediaGroup, id: props.match.params.id })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setErrorData({});
            if (resp.data.created) {
              props.onEnqueueSnackbar(
                "Successfully created the media group",
                3000,
                "success"
              );
              props.history.push(
                `/media-group/list/${resp.data.mediaGroup.id}`
              );
              setUpdating(false);
            } else {
              props.onEnqueueSnackbar(
                "Successfully updated the media group",
                3000,
                "success"
              );
              setUpdating(false);
            }
          } else {
            if (resp.data.errorData) {
              setErrorData(resp.data.errorData);
            }
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
            setUpdating(false);
          }
        })
        .catch((error) => {
          props.onEnqueueSnackbar(error.message, 3000, "error");
          setUpdating(false);
        });
    } catch (error) {
      props.onEnqueueSnackbar(error.message, 3000, "error");
      setUpdating(false);
    }
  };

  // Handle Media Group Media Association
  async function handleMediaAssociation(media_id, attach, type) {
    if (!attach) {
      setOpenDeleteDialog(false);
    }
    let resp = await axios.post(
      urls.addMediaToMediaGroup(props.match.params.id),
      {
        attach: attach,
        media_id: media_id,
        type: type || deleteType,
      }
    );
    if (resp.status === 200 && resp.data.error === false) {
      props.onEnqueueSnackbar("Success!!", 1000, "success");
      if (attach) {
        switch (type) {
          case "P":
            setPmediaForms([...pmediaForms, resp.data.row.media_id]);
            break;
          case "R":
            setRmediaForms([...rmediaForms, resp.data.row.media_id]);
            break;
          default:
            setMediaForms([...mediaForms, resp.data.row.media_id]);
            break;
        }
      } else {
        switch (type || deleteType) {
          case "P":
            if (pmediaForms.indexOf(media_id) !== -1) {
              let forms = [...pmediaForms];
              await forms.splice(forms.indexOf(media_id), 1);
              setPmediaForms(forms);
            }
            break;
          case "R":
            if (rmediaForms.indexOf(media_id) !== -1) {
              let forms = [...rmediaForms];
              await forms.splice(forms.indexOf(media_id), 1);
              setRmediaForms(forms);
            }
            break;
          default:
            if (mediaForms.indexOf(media_id) !== -1) {
              let forms = [...mediaForms];
              await forms.splice(forms.indexOf(media_id), 1);
              setMediaForms(forms);
            }
            break;
        }
        setDeleteType("");
      }
    } else {
      await props.onEnqueueSnackbar(resp.data.errorText, 3000);
      setDeleteType("");
    }
  }
  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} sm={10}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                {updating && <LinearProgress color="secondary" />}
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Media Group
                  </Typography>
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="media-name"
                      label="Name"
                      className={classes.textField}
                      value={mediaGroup.name}
                      onChange={handleChange("name")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("name")}
                      helperText={errorData.name}
                    />
                    <TextField
                      id="media-descriptions"
                      label="Description"
                      className={classes.textField}
                      value={mediaGroup.description}
                      onChange={handleChange("description")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("description")}
                      helperText={errorData.description}
                    />
                    <TextField
                      id="select-level"
                      select
                      label="Qualification Level"
                      className={classes.textField}
                      value={mediaGroup.level_id}
                      onChange={handleChange("level_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("level_id")}
                      helperText={errorData.level_id}
                    >
                      {props.levels &&
                        props.levels.map((level) => (
                          <MenuItem key={level.id} value={level.id}>
                            {level.short_desc}
                          </MenuItem>
                        ))}
                    </TextField>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            views={["year"]}
                            label="Year"
                            margin="normal"
                            value={
                              mediaGroup.year ? `${mediaGroup.year}` : null
                            }
                            onChange={handleChange("year")}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="select-active"
                          select
                          label="Media Active"
                          value={mediaGroup.is_active}
                          fullWidth={true}
                          onChange={handleChange("is_active")}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          error={errorData.hasOwnProperty("is_active")}
                          helperText={errorData.is_active}
                        >
                          <MenuItem value="1">Yes</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                    disabled={updating}
                  >
                    {isNaN(props.match.params.id)
                      ? "Create Media Group"
                      : "Update Media Group"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            {!isNaN(props.match.params.id) && (
              <React.Fragment>
                <Grid item xs={12} md={6}>
                  {mediaGroup.level_id && (
                    <Card className={classes.overflowCard}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {`Media`}
                        </Typography>
                        {filterMedia && (
                          <IntegrationDownshift
                            label="Assoicate Forms"
                            placeholder="Search in Media Forms"
                            data={Object.values(filterMedia)}
                            onSelect={(selected) =>
                              handleMediaAssociation(selected.id, true, "A")
                            }
                            itemCount={12}
                            onClear={() => null}
                          />
                        )}
                        <div>
                          <List dense={true}>
                            {mediaForms.length > 0 &&
                              mediaForms.map((mediaId) => {
                                if (filterMedia[mediaId]) {
                                  let item = filterMedia[mediaId];
                                  return (
                                    <React.Fragment key={item.id}>
                                      <ListItem
                                        className={classes.listItem}
                                        style={{ borderColor: item.bgcolor }}
                                      >
                                        <ListItemText
                                          primary={item.title}
                                          secondary={item.secondary}
                                        />
                                        <ListItemSecondaryAction
                                          className={classes.listIcon}
                                        >
                                          <React.Fragment>
                                            {item.regionCount}
                                            <Button
                                              color="primary"
                                              size="small"
                                              variant="text"
                                              className={classes.viewButton}
                                              onClick={() =>
                                                props.history.push(
                                                  `/media/list/${item.id}`
                                                )
                                              }
                                            >
                                              view
                                            </Button>
                                            <IconButton
                                              edge="end"
                                              size="small"
                                              aria-label="delete"
                                              onClick={() => {
                                                setOpenDeleteDialog(item.id);
                                                setDeleteType("A");
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </React.Fragment>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <Divider />
                                      <List component="div" disablePadding>
                                        {filterMedia[mediaId].associatedMedias
                                          .length > 0 &&
                                          filterMedia[
                                            mediaId
                                          ].associatedMedias.map((amediaId) => {
                                            if (filterMedia[amediaId]) {
                                              let aItem = filterMedia[amediaId];
                                              return (
                                                <React.Fragment
                                                  key={`a` + aItem.media_id}
                                                >
                                                  <ListItem
                                                    className={classes.nested}
                                                    style={{
                                                      borderColor:
                                                        aItem.bgcolor,
                                                    }}
                                                  >
                                                    <ListItemText
                                                      primary={aItem.title}
                                                      secondary={
                                                        aItem.secondary
                                                      }
                                                    />
                                                    <ListItemSecondaryAction
                                                      className={
                                                        classes.listIcon
                                                      }
                                                    >
                                                      <React.Fragment>
                                                        {aItem.regionCount}
                                                        <Button
                                                          color="primary"
                                                          size="small"
                                                          variant="text"
                                                          className={
                                                            classes.viewButton
                                                          }
                                                          onClick={() =>
                                                            props.history.push(
                                                              `/media/list/${aItem.id}`
                                                            )
                                                          }
                                                        >
                                                          view
                                                        </Button>
                                                      </React.Fragment>
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <Divider />
                                                </React.Fragment>
                                              );
                                            }
                                          })}
                                      </List>
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </List>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {mediaGroup.level_id && (
                    <Card className={classes.overflowCard}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {`Practice Media`}
                        </Typography>
                        {filterMedia && (
                          <IntegrationDownshift
                            label="Assoicate Forms"
                            placeholder="Search in Media Forms"
                            data={Object.values(filterMedia)}
                            onSelect={(selected) =>
                              handleMediaAssociation(selected.id, true, "P")
                            }
                            itemCount={12}
                          />
                        )}
                        <div>
                          <List dense={true}>
                            {pmediaForms.length > 0 &&
                              pmediaForms.map((mediaId) => {
                                if (filterMedia[mediaId]) {
                                  let item = filterMedia[mediaId];
                                  return (
                                    <React.Fragment key={item.id}>
                                      <ListItem
                                        className={classes.listItem}
                                        style={{ borderColor: item.bgcolor }}
                                      >
                                        <ListItemText
                                          primary={item.title}
                                          secondary={item.secondary}
                                        />
                                        <ListItemSecondaryAction
                                          className={classes.listIcon}
                                        >
                                          <React.Fragment>
                                            {item.regionCount}
                                            <Button
                                              color="primary"
                                              size="small"
                                              variant="text"
                                              className={classes.viewButton}
                                              onClick={() =>
                                                props.history.push(
                                                  `/media/list/${item.id}`
                                                )
                                              }
                                            >
                                              view
                                            </Button>
                                            <IconButton
                                              edge="end"
                                              size="small"
                                              aria-label="delete"
                                              onClick={() => {
                                                setOpenDeleteDialog(item.id);
                                                setDeleteType("P");
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </React.Fragment>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <Divider />
                                      <List component="div" disablePadding>
                                        {filterMedia[mediaId].associatedMedias
                                          .length > 0 &&
                                          filterMedia[
                                            mediaId
                                          ].associatedMedias.map((amediaId) => {
                                            if (filterMedia[amediaId]) {
                                              let aItem = filterMedia[amediaId];
                                              return (
                                                <React.Fragment
                                                  key={`a` + aItem.media_id}
                                                >
                                                  <ListItem
                                                    className={classes.nested}
                                                    style={{
                                                      borderColor:
                                                        aItem.bgcolor,
                                                    }}
                                                  >
                                                    <ListItemText
                                                      primary={aItem.title}
                                                      secondary={
                                                        aItem.secondary
                                                      }
                                                    />
                                                    <ListItemSecondaryAction
                                                      className={
                                                        classes.listIcon
                                                      }
                                                    >
                                                      <React.Fragment>
                                                        {aItem.regionCount}
                                                        <Button
                                                          color="primary"
                                                          size="small"
                                                          variant="text"
                                                          className={
                                                            classes.viewButton
                                                          }
                                                          onClick={() =>
                                                            props.history.push(
                                                              `/media/list/${aItem.id}`
                                                            )
                                                          }
                                                        >
                                                          view
                                                        </Button>
                                                      </React.Fragment>
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <Divider />
                                                </React.Fragment>
                                              );
                                            }
                                          })}
                                      </List>
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </List>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {mediaGroup.level_id && (
                    <Card className={classes.overflowCard}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {`Resit Media`}
                        </Typography>
                        {filterMedia && (
                          <IntegrationDownshift
                            label="Assoicate Forms"
                            placeholder="Search in Media Forms"
                            data={Object.values(filterMedia)}
                            onSelect={(selected) =>
                              handleMediaAssociation(selected.id, true, "R")
                            }
                            itemCount={12}
                          />
                        )}
                        <div>
                          <List dense={true}>
                            {rmediaForms.length > 0 &&
                              rmediaForms.map((mediaId) => {
                                if (filterMedia[mediaId]) {
                                  let item = filterMedia[mediaId];
                                  return (
                                    <React.Fragment key={item.id}>
                                      <ListItem
                                        className={classes.listItem}
                                        style={{ borderColor: item.bgcolor }}
                                      >
                                        <ListItemText
                                          primary={item.title}
                                          secondary={item.secondary}
                                        />
                                        <ListItemSecondaryAction
                                          className={classes.listIcon}
                                        >
                                          <React.Fragment>
                                            {item.regionCount}
                                            <Button
                                              color="primary"
                                              size="small"
                                              variant="text"
                                              className={classes.viewButton}
                                              onClick={() =>
                                                props.history.push(
                                                  `/media/list/${item.id}`
                                                )
                                              }
                                            >
                                              view
                                            </Button>
                                            <IconButton
                                              edge="end"
                                              size="small"
                                              aria-label="delete"
                                              onClick={() => {
                                                setOpenDeleteDialog(item.id);
                                                setDeleteType("R");
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </React.Fragment>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <Divider />
                                      <List component="div" disablePadding>
                                        {filterMedia[mediaId].associatedMedias
                                          .length > 0 &&
                                          filterMedia[
                                            mediaId
                                          ].associatedMedias.map((amediaId) => {
                                            if (filterMedia[amediaId]) {
                                              let aItem = filterMedia[amediaId];
                                              return (
                                                <React.Fragment
                                                  key={`a` + aItem.media_id}
                                                >
                                                  <ListItem
                                                    className={classes.nested}
                                                    style={{
                                                      borderColor:
                                                        aItem.bgcolor,
                                                    }}
                                                  >
                                                    <ListItemText
                                                      primary={aItem.title}
                                                      secondary={
                                                        aItem.secondary
                                                      }
                                                    />
                                                    <ListItemSecondaryAction
                                                      className={
                                                        classes.listIcon
                                                      }
                                                    >
                                                      <React.Fragment>
                                                        {aItem.regionCount}
                                                        <Button
                                                          color="primary"
                                                          size="small"
                                                          variant="text"
                                                          className={
                                                            classes.viewButton
                                                          }
                                                          onClick={() =>
                                                            props.history.push(
                                                              `/media/list/${aItem.id}`
                                                            )
                                                          }
                                                        >
                                                          view
                                                        </Button>
                                                      </React.Fragment>
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <Divider />
                                                </React.Fragment>
                                              );
                                            }
                                          })}
                                      </List>
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </List>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          {!isNaN(props.match.params.id) &&
            props.mediaTypes.length > 0 &&
            props.mediaTypes.map((item, index) => (
              <Chip
                key={index}
                label={item.title}
                size="small"
                className={classes.chip}
                style={{ backgroundColor: item.bgcolor, color: item.color }}
              />
            ))}
        </Grid>
      </Grid>
      <Dialog
        open={parseInt(openDeleteDialog) > 0 ? true : false}
        keepMounted
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirm Deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to remove this Media `}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleMediaAssociation(openDeleteDialog, false)}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    mediaTypes: state.common.mediaTypes,
    levels: state.common.levels,
    regions: state.common.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditMediaGroup)
);
