/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import "date-fns";
import Gaurd from "../../hoc/Gaurd";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MomentUtils from "@date-io/moment";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CloseIcon from "@material-ui/icons/Close";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import EmailField from "../../components/Common/Fields/EmailField";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  statusGroup: {
    flexDirection: "row",
  },
  legend: {
    fontSize: "0.75rem",
  },
  levelLabel: {
    width: 200,
  },
  cardSpacing: {
    marginBottom: theme.spacing(2),
  },
  exceptionRowLevel: {
    marginTop: theme.spacing(2),
    minWidth: "100px",
    width: "100%",
    maxWidth: "200px",
  },
  exceptionRowModule: {
    marginTop: theme.spacing(2),
    minWidth: "100px",
    width: "100%",
    maxWidth: "200px",
  },
  exceptionRowRate: {
    marginTop: theme.spacing(2),
    width: "80px",
  },
  exceptionRowType: {
    marginTop: theme.spacing(2),
    width: "100px",
  },
  progress: {
    verticalAlign: "middle",
  },
  boxTextField: {
    minWidth: "50%",
  },
  fileInput: {
    display: "none",
  },
  evidenceTable: {
    marginTop: theme.spacing(2),
  },
}));

const raRequestObj = {
  ra_type: "",
  centre_adjustment: null,
  media_adjustment: null,
  details: "",
  evidenceType: "",
  evidences: [],
  from: "",
  to: "",
};

function LearnerEdit(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [requestingRa, setRequestingRa] = useState(false);
  const [config, setConfig] = useState({
    RA: {
      DISABILITIES: {},
      REQUIRED: {},
      TYPES: {},
      EVIDENCE_TYPES: [],
    },
  });
  const [learner, setLearner] = useState({
    id: "",
    name: "",
    secondary_phone: "",
    dob: null,
    hearing: "",
    ethnicity_id: "",
    address: "",
    city: "",
    postcode: "",
    ra_required: "",
    user: {
      email: "",
    },
    levels: [],
    learner_ras: [],
  });

  const [errorData, setErrorData] = useState({});
  const [raRequest, setRaRequest] = useState(raRequestObj);
  const [openFileViewer, setOpenFileViewer] = React.useState(false);
  const [viewFile, setViewFile] = React.useState({});
  const [fileSingedUrl, setFileSingedUrl] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);

  useEffect(() => {
    console.log("[LearnerEdit] mounted");
    fetchLearner();
  }, []);

  useEffect(() => {
    console.log("[LearnerEdit] updated");
  });
  /** Fetch Learner */
  function fetchLearner() {
    axios
      .post(urls.getLearner(props.match.params.id))
      .then(async (resp) => {
        if (resp.status == 200 && !resp.data.error) {
          const { ...learnerObj } = resp.data.learner;
          setLearner(learnerObj);
          setConfig(resp.data.config);
        }
      })
      .catch((err) => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }

  // handle form field change
  const handleChange = (name) => (e) => {
    if (name === "email" || name === "phone") {
      setLearner({
        ...learner,
        user: { ...learner.user, [name]: e.target.value },
      });
    } else {
      setLearner({ ...learner, [name]: e.target.value });
    }
  };
  /** Handle DOB Change */
  const handleDobChange = (name) => (e) => {
    setLearner({ ...learner, [name]: e });
  };

  // Update Learner
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.updateLearner(props.match.params.id), learner)
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          fetchLearner();
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/learners/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  // New Ra Request
  const handleNewRaRequest = (name) => (e) => {
    setRaRequest({ ...raRequest, [name]: e.target.value });
  };
  const handleRaDateChange = (name) => (e) => {
    setRaRequest({ ...raRequest, [name]: e });
  };

  // Evidence File Input change handler
  const onChangeFileHandler = (e) => {
    let evidences = raRequest.evidences.slice(0);
    if (e.target.files && e.target.files.length > 0) {
      evidences.push({
        type: raRequest.evidenceType,
        file: e.target.files[0],
      });
      setRaRequest({ ...raRequest, evidences: evidences });
    }
  };
  // Remove Evidence File by index
  const handleEvidenceRemove = (index) => (e) => {
    let evidences = raRequest.evidences.slice(0);
    evidences.splice(index);
    setRaRequest({ ...raRequest, evidences: evidences });
  };
  const handleRaRequestSubmit = () => (e) => {
    e.preventDefault();
    setRequestingRa(true);
    const data = new FormData();
    data.append("ra_type", raRequest.ra_type);
    data.append("details", raRequest.details);
    data.append("centre_adjustment", raRequest.centre_adjustment);
    data.append("media_adjustment", raRequest.media_adjustment);
    data.append("from", raRequest.from);
    data.append("to", raRequest.to);
    raRequest.evidences.forEach((item) => {
      data.append("file", item.file);
      data.append("fileTypes[]", item.type);
    });
    axios
      .post(urls.submitRaRequest(props.match.params.id), data)
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          fetchLearner();
          setRaRequest(raRequestObj);
          props.onEnqueueSnackbar("Success!!", 1000, "success");
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setRequestingRa(false);
      })
      .catch((error) => {
        setRequestingRa(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  };

  // Get File signed url of the evidence file from S3 on View
  const getEvidenceFile = async (id, filename) => {
    let resp = await axios.post(urls.getRaEvidenceUrl(id), {
      learner_id: props.match.params.id,
      filename: filename,
    });
    //window.open(resp.data.url, '_blank');
    setViewFile({
      filetype: filename.split(".").pop(),
      filename: filename,
    });
    setFileSingedUrl(resp.data.url);
    setOpenFileViewer(true);
  };
  // On Closing the Media File Dialog
  function onFileDialogExiting() {
    setViewFile({});
  }

  // handle PDF Load
  function onDocumentLoadSuccess({ numPages }) {
    console.log(numPages);
    setNumPages(numPages);
  }
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {learner.id ? `Learner` : `Add New Learner`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="learner-uln"
                    label="ULN"
                    className={classes.textField}
                    value={learner.uln || ""}
                    onChange={handleChange("uln")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("uln")}
                    helperText={errorData.uln}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="learner-fname"
                        label="FirstName"
                        className={classes.textField}
                        value={learner.first_name || ""}
                        onChange={handleChange("first_name")}
                        margin="dense"
                        required={true}
                        fullWidth={true}
                        error={errorData.hasOwnProperty("first_name")}
                        helperText={errorData.first_name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="learner-;name"
                        label="LastName"
                        className={classes.textField}
                        value={learner.last_name || ""}
                        onChange={handleChange("last_name")}
                        margin="dense"
                        required={true}
                        fullWidth={true}
                        error={errorData.hasOwnProperty("last_name")}
                        helperText={errorData.last_name}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="select-gender"
                        select
                        label="Gender"
                        value={learner.gender || ""}
                        fullWidth={true}
                        onChange={handleChange("gender")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="dense"
                        error={errorData.hasOwnProperty("gender")}
                        helperText={errorData.gender}
                      >
                        <MenuItem value="M">Male</MenuItem>
                        <MenuItem value="F">Female</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          label="DOB"
                          margin="dense"
                          fullWidth={true}
                          format="DD/MM/YYYY"
                          value={learner.dob || null}
                          onChange={handleDobChange("dob")}
                          error={errorData.hasOwnProperty("dob")}
                          helperText={errorData.start_date}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="select-hearing"
                        select
                        label="Hearing"
                        value={learner.hearing || ""}
                        fullWidth={true}
                        onChange={handleChange("hearing")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="dense"
                        error={errorData.hasOwnProperty("hearing")}
                        helperText={errorData.hearing}
                      >
                        {config.RA &&
                          Object.keys(config.RA.DISABILITIES).map((key) => (
                            <MenuItem key={key} value={key}>
                              {config.RA.DISABILITIES[key]}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="select-ethnicity"
                        select
                        label="Ethnicity"
                        value={learner.ethnicity_id || ""}
                        fullWidth={true}
                        onChange={handleChange("ethnicity_id")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="dense"
                        error={errorData.hasOwnProperty("ethnicity_id")}
                        helperText={errorData.ethnicity_id}
                      >
                        {props.ethnicities &&
                          props.ethnicities.map((ethnicity) => (
                            <MenuItem key={ethnicity.id} value={ethnicity.id}>
                              {ethnicity.description}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <EmailField
                    required={true}
                    data={{
                      userId: learner.user_id,
                      email: learner.user ? learner.user.email : "",
                      verifiedAt: learner.user
                        ? learner.user.email_verified_at
                        : "",
                      emailSentAt: learner.user
                        ? learner.user.email_sent_at
                        : "",
                    }}
                    onChange={handleChange("email")}
                    onEnqueueSnackbar={props.onEnqueueSnackbar}
                    helperText={errorData.email}
                    error={errorData.hasOwnProperty("email")}
                  />
                  {/* <TextField
                    id="learner-email"
                    label="Email"
                    className={classes.textField}
                    value={(learner.user && learner.user.email) || ""}
                    onChange={handleChange("email")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("email")}
                    helperText={errorData.email || errorData.users_email_unique}
                  /> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="learner-phone"
                        label="Phone"
                        className={classes.textField}
                        value={
                          learner.user && learner.user.phone
                            ? learner.user.phone
                            : ""
                        }
                        onChange={handleChange("phone")}
                        margin="dense"
                        required={true}
                        fullWidth={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +44
                            </InputAdornment>
                          ),
                        }}
                        error={errorData.hasOwnProperty("phone")}
                        helperText={
                          errorData.phone || errorData.users_phone_unique
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="secondary-phone"
                        label="Secondary Phone"
                        className={classes.textField}
                        value={learner.secondary_phone || ""}
                        onChange={handleChange("secondary_phone")}
                        margin="dense"
                        fullWidth={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +44
                            </InputAdornment>
                          ),
                        }}
                        error={errorData.hasOwnProperty("secondary_phone")}
                        helperText={errorData.secondary_phone}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    id="learner-address"
                    label="Address"
                    className={classes.textField}
                    value={learner.address || ""}
                    required={true}
                    onChange={handleChange("address")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("address")}
                    helperText={errorData.address}
                  />
                  <TextField
                    id="learner-city"
                    label="City"
                    className={classes.textField}
                    value={learner.city || ""}
                    required={true}
                    onChange={handleChange("city")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("city")}
                    helperText={errorData.city}
                  />
                  <TextField
                    id="learner-postcode"
                    label="Postcode"
                    className={classes.textField}
                    value={learner.postcode || ""}
                    required={true}
                    onChange={handleChange("postcode")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("postcode")}
                    helperText={errorData.postcode}
                  />
                  <TextField
                    id="select-ra-required"
                    select
                    label="Reasonable Adjustment Required"
                    value={learner.ra_required}
                    fullWidth={true}
                    onChange={handleChange("ra_required")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                    error={errorData.hasOwnProperty("ra_required")}
                    helperText={errorData.ra_required}
                  >
                    {config.RA &&
                      Object.keys(config.RA.REQUIRED).map((key) => (
                        <MenuItem key={key} value={key}>
                          {config.RA.REQUIRED[key]}
                        </MenuItem>
                      ))}
                  </TextField>
                </form>
              </CardContent>
              <CardActions>
                {!isNaN(props.match.params.id) && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Update Learner
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          {learner.ra_required == 1 && (
            <Grid item xs={6}>
              <Card>
                {requestingRa && <LinearProgress color="secondary" />}
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    New RA Details
                  </Typography>
                  <form
                    className={classes.formContainer}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="select-ra_type"
                      select
                      label="RA Type"
                      value={raRequest.ra_type}
                      fullWidth={true}
                      onChange={handleNewRaRequest("ra_type")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="dense"
                      error={errorData.hasOwnProperty("ra_type")}
                      helperText={errorData.ra_type}
                    >
                      {config.RA &&
                        Object.keys(config.RA.TYPES).map((key) => (
                          <MenuItem key={key} value={key}>
                            {config.RA.TYPES[key]}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="select-centre-adjustment"
                      select
                      label="Centre Adjustment"
                      value={raRequest.centre_adjustment}
                      fullWidth={true}
                      onChange={handleNewRaRequest("centre_adjustment")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="dense"
                      error={errorData.hasOwnProperty("centre_adjustment")}
                      helperText={errorData.centre_adjustment}
                    >
                      {props.centreAdjustments &&
                        props.centreAdjustments.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.access_arrangements}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="select-media-adjustment"
                      select
                      label="Media Adjustment"
                      value={raRequest.media_adjustment}
                      fullWidth={true}
                      onChange={handleNewRaRequest("media_adjustment")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="dense"
                      error={errorData.hasOwnProperty("media_adjustment")}
                      helperText={errorData.media_adjustment}
                    >
                      {props.mediaAdjustments &&
                        props.mediaAdjustments.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.modified_papers}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="standard-ra-details"
                      label="Details of Reasonable Adjustment"
                      multiline
                      rowsMax="4"
                      value={raRequest.details}
                      fullWidth={true}
                      onChange={handleNewRaRequest("details")}
                      className={classes.textField}
                      margin="dense"
                    />
                    <Box
                      display="flex"
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      <TextField
                        id="select-evidence-type"
                        select
                        label="Evidence Type"
                        value={raRequest.evidenceType}
                        className={classes.boxTextField}
                        onChange={handleNewRaRequest("evidenceType")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="dense"
                        error={errorData.hasOwnProperty("evidenceType")}
                        helperText={errorData.evidenceType}
                      >
                        {config.RA &&
                          config.RA.EVIDENCE_TYPES.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </TextField>
                      <div>
                        <input
                          accept="image/*,application/pdf"
                          className={classes.fileInput}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => onChangeFileHandler(e)}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            component="span"
                            className={classes.button}
                            disabled={!raRequest.evidenceType}
                          >
                            Select File
                          </Button>
                        </label>
                      </div>
                    </Box>
                    {raRequest.evidences.length > 0 && (
                      <Table className={classes.evidenceTable} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Evidence Type</TableCell>
                            <TableCell>File Name</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {raRequest.evidences.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.type}</TableCell>
                              <TableCell>{item.file.name}</TableCell>
                              <TableCell>
                                <IconButton
                                  className={classes.button}
                                  aria-label="delete"
                                  color="secondary"
                                  size="small"
                                  onClick={handleEvidenceRemove(index)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                    {raRequest.ra_type == 1 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              label="From"
                              margin="dense"
                              fullWidth={true}
                              format="DD/MM/YYYY"
                              value={raRequest.from || null}
                              onChange={handleRaDateChange("from")}
                              error={errorData.hasOwnProperty("from")}
                              helperText={errorData.from}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              label="To"
                              margin="dense"
                              fullWidth={true}
                              format="DD/MM/YYYY"
                              value={raRequest.to || null}
                              onChange={handleRaDateChange("to")}
                              error={errorData.hasOwnProperty("to")}
                              helperText={errorData.to}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    )}
                  </form>
                </CardContent>
                <CardActions>
                  {!isNaN(props.match.params.id) && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={requestingRa}
                      onClick={handleRaRequestSubmit()}
                    >
                      Submit RA Request
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              RA Histroy
            </Typography>
            <Paper>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="right">Ra Type</TableCell>
                    <TableCell align="right">Details</TableCell>
                    <TableCell align="right">Centre Adjustment</TableCell>
                    <TableCell align="right">Media Adjustment</TableCell>
                    <TableCell align="right">From-To</TableCell>
                    <TableCell align="right">Evidences</TableCell>
                    <TableCell align="right">Approved</TableCell>
                    <TableCell align="right">Request Dt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {learner.learner_ras &&
                    learner.learner_ras.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="right">
                          {config.RA.TYPES[row.ra_type]}
                        </TableCell>
                        <TableCell align="right">
                          {props.centreAdjustments.map((ca) =>
                            ca.id === row.centre_adjustment ? (
                              <span>{ca.access_arrangements}</span>
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {props.mediaAdjustments.map((ma) =>
                            ma.id === row.media_adjustment ? (
                              <span>{ma.modified_papers}</span>
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="right">{row.details}</TableCell>
                        <TableCell align="right">
                          {row.ra_type === 0
                            ? `${row.from ? row.from : ""} - ${
                                row.to ? row.to : ""
                              }`
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {row.evidence_path.map((item, index) => (
                            <Button
                              key={index}
                              color="primary"
                              size="small"
                              className={classes.buttonLink}
                              onClick={() =>
                                getEvidenceFile(row.id, item.filename)
                              }
                            >
                              {item.type}
                            </Button>
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          {row.is_approved === 1 ? "Yes" : "NO"}
                        </TableCell>
                        <TableCell align="right">
                          {moment(row.createdAt).format("DD-MM-YYYY")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        maxWidth={"lg"}
        open={openFileViewer}
        keepMounted
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        onClose={() => setOpenFileViewer(false)}
        onExiting={onFileDialogExiting}
        aria-labelledby="file-viewer-slide-title"
        aria-describedby="file-viewer-slide-description"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="file-viewer-slide-title"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{viewFile.filename}</Typography>
            <div>
              <Link
                href={fileSingedUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  aria-label="close"
                  size="small"
                  color="primary"
                  className={classes.closeButton}
                >
                  <OpenInNewIcon size="small" />
                </IconButton>
              </Link>
              <IconButton
                aria-label="close"
                size="small"
                className={classes.closeButton}
                onClick={() => setOpenFileViewer(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        </DialogTitle>
        <DialogContent>
          {["jpg", "jpeg", "png"].indexOf(viewFile.filetype) !== -1 && (
            <img src={fileSingedUrl} alt={viewFile.filename} width="100%" />
          )}
          {viewFile.filetype == "pdf" && fileSingedUrl && (
            <Document
              file={fileSingedUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={700}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    modules: state.common.modules,
    centreAdjustments: state.common.centreAdjustments,
    mediaAdjustments: state.common.mediaAdjustments,
    ethnicities: state.common.ethnicities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LearnerEdit)
);
