import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { createStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import * as actions from "../store/actions";
import { urls } from "../common/urls";

const styles = (theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
    progressbar: {
      width: "300px",
      marginRight: theme.spacing(1),
    },
    uploadInput: {
      display: "none",
    },
    dialogTitle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      backgroundColor: "#efefef",
    },
  });
class Uploader extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      uploading: false,
      open: false,
      openDialog: false,
      message: false,
      completed: 0,
      openFileDialog: false,
      data: {},
      accept: "image/*,.doc,.docx,.pdf,.mp4,.mov",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFileDialogOpen = this.handleFileDialogOpen.bind(this);
  }
  static getDerivedStateFromProps(props, state) {
    console.log("uploader");
    if (props.uploader.uploading == true && state.uploading == false) {
      return { ...state, openFileDialog: true };
    }
    return state;
  }

  handleClick() {
    this.setState({ open: true });
  }

  handleClose(event, reason) {
    this.props.cancelUpload();
    this.setState({ open: false, openDialog: false, uploading: false });
    //xhr.abort();
  }
  handleDialogOpen(val) {
    this.setState({ openDialog: val });
  }
  handleFileDialogOpen(val) {
    this.props.finishUpload(null);
    this.setState({ openFileDialog: val });
  }

  // Handle Media File Upload
  handleFileUpload(e, ra_category_id) {
    e.preventDefault();
    if (e.target.files.length > 0) {
      try {
        let file = e.target.files[0];
        let ext = file.name.split(".").pop();
        let filename =
          this.props.uploader.data.filename ||
          file.name
            .split(".")
            .slice(0, -1)
            .join(".");
        let data = {
          ...this.props.uploader.data,
          filename: filename,
          ext: ext,
        };
        this.setState({ uploading: true, open: true, message: filename });
        axios
          .post(urls.uploadMediaUrl(data.id), data)
          .then((resp) => {
            this.setState({ openFileDialog: false });
            if (resp.data.error) {
              console.log(resp.data);
            } else {
              var xhr = new XMLHttpRequest();
              xhr.withCredentials = true;
              xhr.upload.addEventListener("progress", (evt) => {
                if (evt.lengthComputable) {
                  let percentComplete = Math.round(
                    (evt.loaded / evt.total) * 100
                  );
                  this.setState({ completed: percentComplete });
                }
              });
              xhr.addEventListener("load", (evt) => {
                this.props.onEnqueueSnackbar(
                  "Successfully Uploaded the file",
                  3000,
                  "success"
                );
                axios
                  .post(data.url, {
                    ...data,
                    destFileName: resp.data.destFileName,
                    filename: data.filename,
                    title: data.filename,
                    ext: ext,
                    ra_category_id: data.ra_category_id,
                  })
                  .then(async (resp) => {
                    await this.props.finishUpload(data.uid);
                    this.setState({ uploading: false, open: false });
                    this.props.onEnqueueSnackbar(
                      "Record Updated successfully",
                      3000,
                      "success"
                    );
                    if (
                      this.props.history.location.pathname ===
                      this.props.uploader.data.location
                    ) {
                      this.props.history.push(
                        this.props.history.location.pathname
                      );
                    }
                  })
                  .catch((err) => {
                    throw err;
                  });
              });
              xhr.addEventListener("error", (err) => {
                throw err;
              });

              xhr.open("PUT", resp.data.url, true);
              xhr.setRequestHeader("Content-Type", file.type);
              xhr.send(file);
            }
          })
          .catch((err) => {
            throw err;
          });
      } catch (err) {
        this.props.onEnqueueSnackbar(err.message, 3000);
        this.setState({ uploading: false, open: false });
      }
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.open}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <React.Fragment>
              <div>{this.state.message}</div>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                alignContent="space-between"
              >
                <LinearProgress
                  className={classes.progressbar}
                  variant="determinate"
                  value={this.state.completed}
                />
                <span>{`${this.state.completed}%`}</span>
              </Box>
            </React.Fragment>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={() => this.handleDialogOpen(true)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Dialog
          open={this.state.openFileDialog}
          keepMounted
          fullWidth={true}
          maxWidth="xs"
          style={{ zIndex: 2000 }}
          onClose={() => this.setState({ openFileDialog: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"FILE UPLOADER"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Choose the file to upload
            </DialogContentText>
            <input
              accept={
                this.props.uploader.data.accept &&
                this.props.uploader.data.accept.length > 0
                  ? this.props.uploader.data.accept
                  : this.state.accept
              }
              className={classes.uploadInput}
              id="contained-button-file"
              type="file"
              onChange={(e) => this.handleFileUpload(e, null)}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                size="small"
                className={classes.button}
                disabled={this.state.uploading}
              >
                {"Choose file"}
              </Button>
            </label>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleFileDialogOpen(false)}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDialog}
          onClose={() => this.handleDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel current upload ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleDialogOpen(false)}
              color="primary"
            >
              Disagree
            </Button>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uploader: state.uploader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelUpload: () => dispatch(actions.uploadingCancelled()),
    finishUpload: (uid) => dispatch(actions.uploadingFinished(uid)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Uploader))
);
