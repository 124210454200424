import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ErrorPage from '../../components/ErrorPage';

const styles = theme =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  });
class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      permissions: [],
      groups: [],
      selectedRole: 1,
      selectedGroup: '',
      selectedPermissions: []
    };
    this.handleRolechange = this.handleRolechange.bind(this);
    this.handleGroupchange = this.handleGroupchange.bind(this);
    this.handlePermissionchange = this.handlePermissionchange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }
  componentDidMount() {
    this.props.onGetRoles();
  }
  static getDerivedStateFromProps(prop, state) {
    if (
      !prop.roles.loading &&
      prop.roles.rows.length > 0 &&
      state.permissions.length === 0
    ) {
      let selectedRole = prop.roles.rows[0];
      let selectedPermissions = selectedRole.permissions.map(p => p.id);
      let groups = prop.roles.permissions.map(p => p.group);
      groups = groups.filter((g, i, arr) => arr.indexOf(g) == i);
      return {
        ...state,
        selectedRole: selectedRole.id,
        permissions: prop.roles.permissions,
        groups: groups,
        selectedPermissions: selectedPermissions
      };
    }
    return null;
  }
  handleRolechange(e, roleId) {
    let selectedRole = this.props.roles.rows.filter(
      role => role.id === roleId
    )[0];
    let selectedPermissions = selectedRole.permissions.map(p => p.id);
    this.setState({
      selectedRole: roleId,
      selectedPermissions: selectedPermissions
    });
  }
  handleGroupchange(e, name) {
    let permissions = this.props.roles.permissions.filter(
      permission => permission.group == name
    );
    let selectedRole = this.props.roles.rows.filter(
      role => role.id === this.state.selectedRole
    )[0];
    let selectedPermissions = selectedRole
      ? selectedRole.permissions.map(p => p.id)
      : [];
    this.setState({
      selectedGroup: name,
      permissions: permissions,
      selectedPermissions: selectedPermissions
    });
  }
  handlePermissionchange(e, permissionId) {
    let selectedPermissions = this.state.selectedPermissions;
    let currentIndex = this.state.selectedPermissions.indexOf(permissionId);
    if (currentIndex == -1) {
      selectedPermissions.push(permissionId);
      this.props.onPermissionUpdate(
        this.state.selectedRole,
        [permissionId],
        true
      );
    } else {
      selectedPermissions.splice(currentIndex, 1);
      this.props.onPermissionUpdate(
        this.state.selectedRole,
        [permissionId],
        false
      );
    }
    this.setState({ selectedPermissions });
  }
  handleSelectAll(selectAll) {
    let permissionIds = this.state.permissions.map(p => p.id);
    this.props.onPermissionUpdate(
      this.state.selectedRole,
      permissionIds,
      selectAll
    );
    this.setState({ selectedPermissions: selectAll ? permissionIds : [] });
  }

  render() {
    const { classes, roles } = this.props;
    const {
      selectedRole,
      selectedGroup,
      selectedPermissions,
      permissions,
      groups
    } = this.state;
    let selectedPermisionsInGroup =
      permissions.length &&
      permissions.reduce(
        (a, c) => (selectedPermissions.includes(c.id) ? (a = a + 1) : a),
        0
      );
    if (roles.error && roles.status == 403) {
      return (
        <div>
          <ErrorPage error="403" message={roles.errorText} />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={3}>
                <Paper className={classes.paper}>
                  <Typography variant="h5" align="left">
                    Roles
                  </Typography>
                  <Divider />
                  <List component="nav" aria-label="Main mailbox folders">
                    {roles.rows &&
                      roles.rows.map(role => (
                        <ListItem
                          button
                          key={role.name}
                          selected={selectedRole == role.id}
                          onClick={event =>
                            this.handleRolechange(event, role.id)
                          }
                        >
                          <ListItemText primary={`${role.description}`} />
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Paper className={classes.paper}>
                  <Typography variant="h5" align="left">
                    Permission Groups
                  </Typography>
                  <Divider />
                  <List component="nav" aria-label="Main mailbox folders">
                    {groups &&
                      groups.map(group => (
                        <ListItem
                          button
                          key={group}
                          selected={selectedGroup === group}
                          onClick={event =>
                            this.handleGroupchange(event, group)
                          }
                        >
                          <ListItemText primary={`${group}`} />
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <Paper className={classes.paper}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    pl={selectedGroup ? 3 : 0}
                  >
                    {selectedGroup && (
                      <FormControlLabel
                        value="Select All"
                        control={
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            checked={
                              permissions.length == selectedPermisionsInGroup
                            }
                            inputProps={{
                              'aria-labelledby': 'all_permissions'
                            }}
                            onClick={() =>
                              this.handleSelectAll(
                                !(
                                  permissions.length ==
                                  selectedPermisionsInGroup
                                )
                              )
                            }
                          />
                        }
                        label="Select All"
                        labelPlacement="end"
                      />
                    )}
                    <Typography variant="h5" align="left">
                      Permissions
                    </Typography>
                  </Box>
                  <Divider />
                  <List component="nav" aria-label="Main mailbox folders">
                    {permissions.length > 0 &&
                      permissions.map(permission => (
                        <ListItem
                          button
                          dense
                          key={permission.name}
                          onClick={event =>
                            this.handlePermissionchange(event, permission.id)
                          }
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selectedPermissions.includes(
                                permission.id
                              )}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                'aria-labelledby': permission.name
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={`${permission.description}`} />
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    roles: state.roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetRoles: () => dispatch(actions.getRoles()),
    onPermissionUpdate: (roleId, permissionIds, attach) =>
      dispatch(actions.updatePermission({ roleId, permissionIds, attach }))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Roles))
);
