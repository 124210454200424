import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserfulHelpLink from "../../components/UsefulHelpLink";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  card: {
    maxWidth: 345,
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    textAlign: "center",
  },
  isPass: {
    color: theme.palette.primary.main,
  },
  isFail: {
    color: theme.palette.secondary.main,
  },
  markCell: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
}));

function TrackingGrid(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [level, setLevel] = useState({});
  const [modules, setModules] = useState([]);
  const [marks, setMarks] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [course, setCourse] = useState({
    id: "",
    name: "",
    centre_id: "",
    qualification_id: "",
    teacher_id: "",
    start_date: null,
    end_date: null,
    active: "",
    status: 100,
    centre: {},
    qualification: {
      level: {},
    },
    course_learners: [],
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingPopover, setLoadingPopover] = useState(false);
  const [assessment, setAssessment] = useState({ assessment_learners: [] });
  const [asstStatus, setAsstStatus] = useState({});

  useEffect(() => {
    async function fetchData() {
      await fetchCourse();
      await fetchMarks();
      await fetchCourseModules(props.match.params.id);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const moduleArr = props.modules.filter((m) => m.level_id === level.id);
    setModules(moduleArr);
  }, [level, props.modules]);

  /** Fetch Course */
  function fetchCourse() {
    axios.post(urls.getCourse(props.match.params.id)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setCourse(resp.data.course);
        setLevel(resp.data.course.qualification.level);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }
  const showAssessment = (id) => (event) => {
    setLoadingPopover(true);
    if (id === undefined) {
      return;
    }
    axios.post(urls.getAssessment(id)).then((resp) => {
      if (resp.status === 200 && resp.data.error === false) {
        setAssessment(resp.data.assessment);
        setLoadingPopover(false);
      } else {
        setLoadingPopover(false);
      }
    });
    setAnchorEl(event.currentTarget);
  };

  /** Fetch Module for selected  */
  function fetchCourseModules(courseId) {
    axios.post(urls.getCourseModules(courseId)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setCourseModules(resp.data.modules);
        if (resp.data.modules.length === 0) {
          props.onEnqueueSnackbar(
            "No assessment modules available to book assessment ",
            3000
          );
        }
      }
    });
  }
  /** Fetch Course */
  function fetchMarks() {
    axios
      .post(urls.getCourseLearnerMarks(props.match.params.id))
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          let rows = resp.data.rows;
          console.log(rows);
          setAsstStatus(resp.data.asstConfig);
          let data = {};
          if (rows.length > 0) {
            rows.forEach((row) => {
              row.assessment_learners.forEach((learner) => {
                if (!data[learner.learner_id]) {
                  data[learner.learner_id] = {
                    [row.module_id]: [],
                  };
                }
                if (!data[learner.learner_id][row.module_id]) {
                  data[learner.learner_id][row.module_id] = [];
                }
                data[learner.learner_id][row.module_id].push({
                  is_pass: learner.is_pass,
                  is_absent: learner.is_absent,
                  marks: learner.marks,
                  assessment_id: learner.assessment_id,
                  assessment_no: row.assessment_no,
                  is_resit: row.is_resit,
                  module_id: row.module_id,
                  assessment_date: row.assessment_date,
                  status: row.status,
                });
              });
            });
          }
          setMarks(data);
          console.log(data);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }
  const open = Boolean(anchorEl);
  function handleClose() {
    setAnchorEl(null);
  }
  const showMarks = (item) => {
    if (item.is_absent === 1) {
      return "Absent";
    } else {
      if (item.is_pass === 1) {
        return `${item.marks} (Pass)`;
      } else if (item.is_pass === 0) {
        return `${item.marks} (Fail)`;
      } else {
        return ``;
      }
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={11}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Centre</TableCell>
                    <TableCell align="center">Course Name</TableCell>
                    <TableCell align="center">Qualifcation</TableCell>
                    <TableCell align="center">Level</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{`${course.centre.name} (${course.centre.number})`}</TableCell>
                    <TableCell align="center">{course.name}</TableCell>
                    <TableCell align="center">{`${
                      course.qualification.title
                    } (${
                      course.qualification.version
                        ? moment(
                            course.qualification.version,
                            "YYYY-MM-DD"
                          ).format("MMM-YYYY")
                        : ""
                    })`}</TableCell>
                    <TableCell align="center">
                      {course.qualification.level.name}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              {!loading && (
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>ULN</TableCell>
                      {courseModules.map((m) => (
                        <TableCell key={m.id} align="center">
                          <Typography
                            variant="subtitle2"
                            fontWeight="fontWeightBold"
                          >
                            {m.name}
                          </Typography>
                          <Typography variant="caption">{m.title}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {course.course_learners.length > 0 &&
                      course.course_learners.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{`${row.learner.first_name} ${row.learner.last_name}`}</TableCell>
                          <TableCell>{row.learner.uln}</TableCell>
                          {courseModules.map((m) => {
                            let obj =
                              marks[row.learner.id] &&
                              marks[row.learner.id][m.id]
                                ? marks[row.learner.id][m.id]
                                : [];
                            return (
                              <TableCell
                                align="center"
                                className={classes.markCell}
                              >
                                {obj.map((item) => (
                                  <div
                                    onClick={showAssessment(item.assessment_id)}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      className={clsx({
                                        [classes.isPass]: item.is_pass,
                                        [classes.isFail]: !item.is_pass,
                                      })}
                                    >
                                      {showMarks(item)}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      display="block"
                                    >
                                      {item.assessment_no}{" "}
                                      {item.is_resit ? " (R)" : ""}
                                      {asstStatus[item.status]}
                                    </Typography>
                                  </div>
                                ))}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Popover
        id={"assessment-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {loadingPopover ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
            width="316px"
            height="331px"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  {assessment.is_resit ? `Resit ` : ""}Assessment Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Assessment</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() =>
                      props.history.push(`/assessments/list/${assessment.id}`)
                    }
                  >
                    {assessment.assessment_no}
                  </Button>{" "}
                  {asstStatus[assessment.status]}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>
                  {assessment.assessment_date
                    ? moment(assessment.assessment_date).format("DD-MM-YYYY")
                    : ""}{" "}
                  {assessment.assessment_time
                    ? moment(assessment.assessment_time).format("HH:mm")
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Assessment Type</TableCell>
                <TableCell>
                  {assessment.AssessmentType
                    ? assessment.AssessmentType.name
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Course</TableCell>
                <TableCell>
                  {assessment.course ? assessment.course.name : ""}
                </TableCell>
              </TableRow>
              {/* <TableRow>
              <TableCell>Unit</TableCell>
              <TableCell>
                {assessment.Unit ? assessment.Unit.unit_code : ''}
              </TableCell>
            </TableRow> */}
              <TableRow>
                <TableCell>Teacher</TableCell>
                <TableCell>
                  {assessment.teacher ? assessment.teacher.name : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Examiner</TableCell>
                <TableCell>
                  {assessment.examiner ? assessment.examiner.name : ""}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Marking Examiner</TableCell>
                <TableCell>
                  {assessment.MarkingExaminer
                    ? assessment.MarkingExaminer.name
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Marking Due Date</TableCell>
                <TableCell>
                  {assessment.marking_due_date
                    ? moment(assessment.marking_due_date).format("DD-MM-YYYY")
                    : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Popover>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    centres: state.centres.rows,
    levels: state.common.levels,
    modules: state.common.modules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrackingGrid);
