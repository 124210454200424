import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import "date-fns";
import Gaurd from "../../hoc/Gaurd";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "../../components/IntegrationDownshift";
import red from "@material-ui/core/colors/red";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  container: {},
  title: {
    fontSize: 18
  },
  textField: {
    minWidth: 200
  },
  menu: {
    width: 200
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  statusGroup: {
    flexDirection: "row"
  },
  legend: {
    fontSize: "0.75rem"
  },
  levelLabel: {
    width: 200
  },
  error: {
    color: red[500]
  }
}));

function EditTeacher(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [teacher, setTeacher] = useState({
    teacher_id: "",
    name: "",
    secondary_phone: "",
    address: "",
    city: "",
    postcode: "",
    centres: [],
    user: {}
  });
  const [errorData, setErrorData] = useState({});
  const [teacherLevels, setTeacherLevels] = useState([]);
  const [centreNames, setCentreNames] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState(null);
  const [feeNotPaidLevels, setFeeNotPaidLevels] = useState([]);
  // Get Qulification with Docs on Mount
  useEffect(() => {
    fetchCentreNames();
  }, []);
  useEffect(() => {
    if (props.currentRole && props.currentRole.name === "centre") {
      setSelectedCentre(props.centre.id);
    }
  }, [props.currentRole]);

  function fetchCentreNames() {
    axios.get(urls.getCentreNames).then(resp => {
      if (resp.status === 200 && !resp.data.error) {
        const centres = resp.data.centres.map(item => ({
          id: item.id,
          label: `${item.name} (${item.number})`
        }));
        setCentreNames(centres);
      }
    });
  }

  // handle form field change
  const handleChange = name => e => {
    if (name === "email" || name === "phone") {
      setTeacher({
        ...teacher,
        user: { ...teacher.user, [name]: e.target.value }
      });
    } else {
      setTeacher({ ...teacher, [name]: e.target.value });
    }
  };

  // Update Teacher
  function handleSubmit(event) {
    setLoading(true);
    setFeeNotPaidLevels([]);
    event.preventDefault();
    axios
      .post(urls.createTeacher, {
        teacher,
        teacherLevels,
        centreId: selectedCentre,
        registration: true
      })
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          props.history.push(`/people/teachers/${resp.data.result}`);
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          if (resp.data.feeNotPaidLevels) {
            setFeeNotPaidLevels(resp.data.feeNotPaidLevels);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }

  // handle Level Change
  async function handleLevelChange(e, levelId) {
    if (!selectedCentre) {
      props.onEnqueueSnackbar(
        "choose a centre to view approved levels",
        3000,
        "warning"
      );
      return;
    }
    if (e.target.checked) {
      console.log(levelId);
      setTeacherLevels([...teacherLevels, levelId]);
    } else {
      let selectedLevels = await teacherLevels.filter(item => item !== levelId);
      setTeacherLevels(selectedLevels);
    }
  }

  /** handle Course field change */
  function handleCentreChange(id) {
    delete errorData.centre_id;
    console.log(id);
    setSelectedCentre(id);
  }

  /** Clear Centre Selection */
  function handleClearCentre() {
    setSelectedCentre(null);
  }

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Box display="flex" flexDirection="row-reverse" mb={2}>
          <Gaurd check="create_teacher">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}
            >
              Next
            </Button>
          </Gaurd>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {teacher.teacher_id ? `Teacher` : `Add New Teacher`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="teacher-name"
                    label="Name"
                    className={classes.textField}
                    value={teacher.name || ""}
                    onChange={handleChange("name")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("name")}
                    helperText={errorData.name}
                  />
                  <TextField
                    id="teacher-email"
                    label="Email"
                    className={classes.textField}
                    value={teacher.user.email || ""}
                    onChange={handleChange("email")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("email")}
                    helperText={errorData.email}
                  />
                  <TextField
                    id="teacher-phone"
                    label="Phone"
                    className={classes.textField}
                    value={teacher.user.phone || ""}
                    onChange={handleChange("phone")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+44</InputAdornment>
                      )
                    }}
                    error={errorData.hasOwnProperty("phone")}
                    helperText={errorData.phone}
                  />
                  <TextField
                    id="secondary-phone"
                    label="Secondary Phone"
                    className={classes.textField}
                    value={teacher.secondary_phone || ""}
                    onChange={handleChange("secondary_phone")}
                    margin="dense"
                    fullWidth={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+44</InputAdornment>
                      )
                    }}
                    error={errorData.hasOwnProperty("secondary_phone")}
                    helperText={errorData.secondary_phone}
                  />
                  <TextField
                    id="teacher-address"
                    label="Address"
                    className={classes.textField}
                    value={teacher.address || ""}
                    required={true}
                    onChange={handleChange("address")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("address")}
                    helperText={errorData.address}
                  />
                  <TextField
                    id="teacher-city"
                    label="City"
                    className={classes.textField}
                    value={teacher.city || ""}
                    required={true}
                    onChange={handleChange("city")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("city")}
                    helperText={errorData.city}
                  />
                  <TextField
                    id="teacher-postcode"
                    label="Postcode"
                    className={classes.textField}
                    value={teacher.postcode || ""}
                    required={true}
                    onChange={handleChange("postcode")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("postcode")}
                    helperText={errorData.postcode}
                  />
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Qualification Levels
                </Typography>
                {props.currentRole.name !== "centre" && (
                  <Autocomplete
                    label="Centre"
                    placeholder="Search in Centre"
                    data={centreNames}
                    onSelect={selected => handleCentreChange(selected.id)}
                    onClear={() => handleClearCentre()}
                    selectedItem={selectedCentre}
                    error={errorData.hasOwnProperty("centre_id")}
                    helperText={errorData.centre_id}
                  />
                )}
                {errorData.hasOwnProperty("centre") && (
                  <Typography
                    variant="caption"
                    display="block"
                    className={classes.error}
                  >
                    {errorData.centre}
                  </Typography>
                )}
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup row>
                    {props.levels &&
                      props.levels.map(
                        level =>
                          level.name !== "ALL" && (
                            <FormControlLabel
                              className={classes.levelLabel}
                              key={level.id}
                              control={
                                <Checkbox
                                  checked={
                                    teacherLevels.indexOf(level.id) !== -1
                                  }
                                  onChange={e => handleLevelChange(e, level.id)}
                                  color={"secondary"}
                                />
                              }
                              label={`${level.name}`}
                            />
                          )
                      )}
                  </FormGroup>
                </FormControl>
              </CardContent>
            </Card>
            <Box>
              <Typography variant="caption">** Colour of checkboxes</Typography>
              <div>
                <Checkbox color="primary" checked={true} /> Level is Approved
                <Checkbox color="secondary" checked={true} /> Level is pending
                for Approval
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    levels: state.common.levels,
    centre: state.authUser.centre,
    currentRole: state.authUser.currentRole
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditTeacher)
);
