import React from 'react';
import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';
import { urls } from '../../common/urls';

/*
 * Get Notices
 */
export function* getNoticesSaga(action) {
  yield put(actions.getNoticesStart());
  try {
    const response = yield axios.post(urls.notices, action.payload);
    if (response.status === 200 && !response.data.error) {
      yield put(actions.getNoticesSuccess(response.data));
    } else {
      yield put(actions.getNoticesFail(response.data));
      yield put(
        actions.enqueueSnackbar({
          message: response.data.errorText || 'Network Request Failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: response.data.status == 403 ? 'warning' : 'error'
          }
        })
      );
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.message || 'Network Request Failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant:
            error.response && error.response.status == 403 ? 'warning' : 'error'
        }
      })
    );
    yield put(
      actions.getNoticesFail({
        status: error.response ? error.response.status : 404,
        errorText: error.message || 'Network Request Failed'
      })
    );
  }
}
