import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import "date-fns";
import Gaurd from "../../hoc/Gaurd";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailField from "../../components/Common/Fields/EmailField";
import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  statusGroup: {
    flexDirection: "row",
  },
  legend: {
    fontSize: "0.75rem",
  },
  levelLabel: {
    width: 200,
  },
  cardSpacing: {
    marginBottom: theme.spacing(2),
  },
  exceptionRowLevel: {
    marginTop: theme.spacing(2),
    minWidth: "100px",
    width: "100%",
    maxWidth: "200px",
  },
  exceptionRowModule: {
    marginTop: theme.spacing(2),
    minWidth: "100px",
    width: "100%",
    maxWidth: "200px",
  },
  exceptionRowRate: {
    marginTop: theme.spacing(2),
    width: "80px",
  },
  exceptionRowType: {
    marginTop: theme.spacing(2),
    width: "100px",
  },
  progress: {
    verticalAlign: "middle",
  },
}));

function ExaminerEdit(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [examiner, setExaminer] = useState({
    id: "",
    name: "",
    secondary_phone: "",
    address: "",
    city: "",
    postcode: "",
    user: {},
    levels: [],
  });
  const [feeExceptions, setFeeExceptions] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [levels, setLevels] = useState([]);
  const [pendingDone, setPendingDone] = useState([]);
  const [updatingFee, setUpdatingFee] = useState(0);

  useEffect(() => {
    console.log("[ExaminerEdit] mounted");
    fetchExaminer();
  }, []);

  useEffect(() => {
    console.log("[ExaminerEdit] updated");
  });

  function fetchExaminer() {
    axios.post(urls.getExaminer(props.match.params.id)).then(async (resp) => {
      if (resp.status == 200 && !resp.data.error) {
        const {
          examiner_fee_exceptions,
          examiner_levels,
          ...examinerObj
        } = resp.data.examiner;
        setExaminer(examinerObj);
        setLevels(examiner_levels);
        setFeeExceptions(examiner_fee_exceptions);
        setConfig(resp.data.config);
      }
    });
  }

  // handle form field change
  const handleChange = (name) => (e) => {
    if (name === "email" || name === "phone") {
      setExaminer({
        ...examiner,
        user: { ...examiner.user, [name]: e.target.value },
      });
    } else {
      setExaminer({ ...examiner, [name]: e.target.value });
    }
  };

  const handleSwtichChange = (name) => (e, checked) => {
    setExaminer({ ...examiner, [name]: checked === true ? 1 : 0 });
  };

  // Update Examiner
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.updateExaminer(props.match.params.id), examiner)
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/examiners/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }

  // handle Level Change
  const handleLevelChange = (levelId) => async (e) => {
    let checked = e.target.checked;
    axios
      .post(urls.updateExaminerLevel(props.match.params.id), {
        levelId,
        checked: checked,
      })
      .then(async (resp) => {
        let clevels = [];
        let respLevel = resp.data.level;
        if (resp.status === 200 && resp.data.error === false) {
          if (checked) {
            clevels = await levels.filter((item) => item.id !== respLevel.id);
            clevels.push(respLevel);
            setLevels(clevels);
          } else {
            clevels = await levels.filter((item) => item.id !== respLevel.id);
            setLevels(clevels);
          }
          props.onEnqueueSnackbar(resp.data.message, 3000, "success");
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((err) => props.onEnqueueSnackbar(err.message, 3000));
  };
  /**
   * Buld Dropdown Menu for Module in Fee Exception Rows
   * Diable Module for which fee exception has already been provided
   */
  const feeModuleMenu = (levelId) => {
    let moduleIds = feeExceptions.map((item) =>
      item.level_id === levelId ? item.module_id : false
    );
    let menuItems = props.modules.map((m) => {
      if (m.level_id === levelId) {
        return (
          <MenuItem
            key={m.id}
            value={m.id}
            disabled={moduleIds.indexOf(m.id) !== -1}
          >
            {m.name}
          </MenuItem>
        );
      }
    });
    return menuItems;
  };
  /**
   * Update feeExceptions state
   * @param {*} name
   * @param {*} val
   */
  const handleExceptionChange = (name, val) => (e) => {
    let exceptions = feeExceptions.map((item) => {
      if (item.id === val) {
        setPendingDone([...pendingDone, item.id]);
        return { ...item, [name]: e.target.value };
      }
      return item;
    });
    setFeeExceptions(exceptions);
  };
  /**
   * Add new Row to Fee Exceptions
   */
  const handleExceptionAddRow = () => {
    let exceptions = [...feeExceptions];
    exceptions.push({
      id: `x${exceptions.length + 1}`,
      examiner_id: examiner.id,
      levelId: "",
      module_id: "",
      rate: "",
      rate_type: "PER_LEARNER",
    });
    setFeeExceptions(exceptions);
  };

  /**
   * Persist fee exception row to database
   * @param {*} row
   */
  const handleExceptionUpdate = (row) => {
    setUpdatingFee(row.id);
    axios
      .post(urls.updateFeeException(props.match.params.id), {
        exception: row,
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          let pendings = pendingDone.filter((val) => val !== row.id);
          setPendingDone(pendings);
          let exceptions = feeExceptions.map((item) => {
            if (item.id === resp.data.row) {
              return resp.data.row;
            }
            return item;
          });
          setFeeExceptions(exceptions);
          props.onEnqueueSnackbar("Updated Successfully", 3000, "success");
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setUpdatingFee(0);
      })
      .catch((err) => {
        setUpdatingFee(0);
        props.onEnqueueSnackbar(err.message, 3000);
      });
  };

  /**
   * Delete Fee Exception Row
   * @param {*} rowId
   */
  const handleExceptionDelete = (rowId) => {
    let exceptionItem = feeExceptions.filter((item) => item.id === rowId);
    axios
      .post(urls.deleteFeeException(props.match.params.id), {
        exception: exceptionItem[0],
      })
      .then(async (resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          let pendings = pendingDone.filter((val) => val !== rowId);
          setPendingDone(pendings);
          let exceptions = await feeExceptions.filter(
            (item) => item.id !== rowId
          );
          setFeeExceptions(exceptions);
          props.onEnqueueSnackbar("Deleted Successfully", 3000, "success");
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  };
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {examiner.id ? `Examiner` : `Add New Examiner`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="examiner-name"
                    label="Name"
                    className={classes.textField}
                    value={examiner.name || ""}
                    onChange={handleChange("name")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("name")}
                    helperText={errorData.name}
                  />
                  <EmailField
                    required={true}
                    data={{
                      userId: examiner.user_id,
                      email: examiner.user.email,
                      verifiedAt: examiner.user.email_verified_at,
                      emailSentAt: examiner.user
                        ? examiner.user.email_sent_at
                        : "",
                    }}
                    onChange={handleChange("email")}
                    onEnqueueSnackbar={props.onEnqueueSnackbar}
                    helperText={errorData.email}
                    error={errorData.hasOwnProperty("email")}
                  />
                  {/* <TextField
                    id="examiner-email"
                    label="Email"
                    className={classes.textField}
                    value={examiner.user.email || ''}
                    onChange={handleChange('email')}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty('email')}
                    helperText={errorData.email || errorData.users_email_unique}
                  /> */}
                  <TextField
                    id="examiner-phone"
                    label="Phone"
                    className={classes.textField}
                    value={examiner.user.phone || ""}
                    onChange={handleChange("phone")}
                    margin="dense"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty("phone")}
                    helperText={errorData.phone || errorData.users_phone_unique}
                  />
                  <TextField
                    id="secondary-phone"
                    label="Secondary Phone"
                    className={classes.textField}
                    value={examiner.secondary_phone || ""}
                    onChange={handleChange("secondary_phone")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("secondary_phone")}
                    helperText={errorData.secondary_phone}
                  />
                  <TextField
                    id="examiner-address"
                    label="Address"
                    className={classes.textField}
                    value={examiner.address || ""}
                    required={true}
                    onChange={handleChange("address")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("address")}
                    helperText={errorData.address}
                  />
                  <TextField
                    id="examiner-city"
                    label="City"
                    className={classes.textField}
                    value={examiner.city || ""}
                    required={true}
                    onChange={handleChange("city")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("city")}
                    helperText={errorData.city}
                  />
                  <TextField
                    id="examiner-postcode"
                    label="Postcode"
                    className={classes.textField}
                    value={examiner.postcode || ""}
                    required={true}
                    onChange={handleChange("postcode")}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty("postcode")}
                    helperText={errorData.postcode}
                  />
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      value="1"
                      control={
                        <Switch
                          color="primary"
                          checked={examiner.external_examiner === 1}
                          onChange={handleSwtichChange("external_examiner")}
                        />
                      }
                      label="External Examiner"
                      labelPlacement="end"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      value="1"
                      control={
                        <Switch
                          color="primary"
                          checked={examiner.marking_examiner === 1}
                          onChange={handleSwtichChange("marking_examiner")}
                        />
                      }
                      label="Marking Examiner"
                      labelPlacement="end"
                    />
                  </FormControl>
                  {examiner.marking_examiner === 1 && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="default-rate"
                          label="Default Rate"
                          className={classes.textField}
                          value={examiner.default_rate || ""}
                          required={true}
                          type="number"
                          onChange={handleChange("default_rate")}
                          margin="dense"
                          fullWidth={true}
                          error={errorData.hasOwnProperty("default_rate")}
                          helperText={errorData.default_rate}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="default-rate-type"
                          select
                          label="Default Rate Type"
                          className={classes.textField}
                          value={examiner.default_rate_type || "PER_LEARNER"}
                          onChange={handleChange("default_rate_type")}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="dense"
                        >
                          <MenuItem key="PER_LEARNER" value="PER_LEARNER">
                            / LEARNER
                          </MenuItem>
                          <MenuItem key="PER_HOUR" value="PER_HOUR">
                            / Hour
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  )}
                </form>
              </CardContent>
              <CardActions>
                {!isNaN(props.match.params.id) && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Update Examiner
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Approved Qualification Levels
                </Typography>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup row>
                    {props.levels &&
                      props.levels.map((level) => {
                        let elevel =
                          levels &&
                          levels.filter((item) => item.level_id === level.id);
                        if (level.name !== "ALL") {
                          if (elevel && elevel.length > 0) {
                            return (
                              <FormControlLabel
                                className={classes.levelLabel}
                                key={level.id}
                                control={
                                  <Checkbox
                                    checked={
                                      elevel[0].approved ===
                                        config.LEVEL_APPROVED ||
                                      elevel[0].approved ===
                                        config.LEVEL_PENDING_APPROVAL
                                        ? true
                                        : false
                                    }
                                    onChange={handleLevelChange(level.id)}
                                    color={
                                      elevel[0].approved ===
                                      config.LEVEL_APPROVED
                                        ? "primary"
                                        : "secondary"
                                    }
                                  />
                                }
                                label={level.name}
                              />
                            );
                          } else {
                            return (
                              <FormControlLabel
                                className={classes.levelLabel}
                                key={level.id}
                                control={
                                  <Checkbox
                                    checked={false}
                                    onChange={handleLevelChange(level.id)}
                                    color={"primary"}
                                  />
                                }
                                label={level.name}
                              />
                            );
                          }
                        }
                      })}
                  </FormGroup>
                </FormControl>
              </CardContent>
            </Card>
            <Box>
              <Typography variant="caption">** Color of checkboxes</Typography>
              <div>
                <Checkbox color="primary" checked={true} /> Level is Approved
                <Checkbox color="secondary" checked={true} /> Level is Pending
                for Approval
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.cardSpacing}>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Marking Fee Exceptions
                  </Typography>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleExceptionAddRow()}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Level</TableCell>
                      <TableCell>Module</TableCell>
                      <TableCell>Rate</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feeExceptions.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <FormControl>
                            <Select
                              value={row.level_id || ""}
                              onChange={handleExceptionChange(
                                "level_id",
                                row.id
                              )}
                              displayEmpty
                              name="exception-level"
                              className={classes.exceptionRowLevel}
                            >
                              {""}
                              {levels.map(
                                (item) =>
                                  item.approved === 1 && (
                                    <MenuItem
                                      key={item.level_id}
                                      value={item.level_id}
                                    >
                                      {item.level.name}
                                    </MenuItem>
                                  )
                              )}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            <Select
                              value={row.module_id || ""}
                              onChange={handleExceptionChange(
                                "module_id",
                                row.id
                              )}
                              displayEmpty
                              name="exception-module"
                              className={classes.exceptionRowModule}
                            >
                              {" "}
                              {feeModuleMenu(row.level_id)}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            id="ex-rate"
                            value={row.rate || ""}
                            required={true}
                            type="number"
                            onChange={handleExceptionChange("rate", row.id)}
                            margin="dense"
                            className={classes.exceptionRowRate}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            <Select
                              value={row.rate_type || "PER_LEARNER"}
                              onChange={handleExceptionChange(
                                "rate_type",
                                row.id
                              )}
                              displayEmpty
                              name="exception-rate-type"
                              className={classes.exceptionRowType}
                            >
                              {" "}
                              <MenuItem key="PER_LEARNER" value="PER_LEARNER">
                                Learner
                              </MenuItem>
                              <MenuItem key="PER_HOUR" value="PER_HOUR">
                                Hour
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="right">
                          {pendingDone.indexOf(row.id) !== -1 &&
                            updatingFee == row.id && (
                              <CircularProgress
                                size={20}
                                className={classes.progress}
                              />
                            )}
                          {pendingDone.indexOf(row.id) !== -1 && !updatingFee && (
                            <IconButton
                              className={classes.button}
                              aria-label="done"
                              onClick={() => handleExceptionUpdate({ ...row })}
                            >
                              <DoneIcon />
                            </IconButton>
                          )}
                          <IconButton
                            className={classes.button}
                            aria-label="done"
                            onClick={() => handleExceptionDelete(row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    modules: state.common.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExaminerEdit)
);
