import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import IntegrationDownshift from "../../components/IntegrationDownshift";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {},
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  uploadInput: {
    display: "none",
  },
  button: {
    marginRight: theme.spacing(2),
  },
  dialogTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#efefef",
  },
  regionLabel: {
    width: 200,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  overflowCard: {
    marginBottom: theme.spacing(2),
    overflow: "visible",
  },
  listItem: {
    borderLeft: `${theme.spacing(1)}px solid`,
  },
  listIcon: {
    right: 0,
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

function EditMedia(props) {
  const classes = useStyles();
  let videoRef = React.createRef();
  const [media, setMedia] = useState({
    id: null,
    media_type_id: "",
    title: "",
    description: "",
    level_id: "",
    is_active: "",
    generate_pdf: "",
    mediaFile: {},
    media_files: [],
    year: "",
    is_practice: "",
    is_actual: "",
    is_resit: "",
  });
  const [regions, setRegions] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [filterMedia, setFilterMedia] = useState({});
  const [openFileDialog, setOpenFileDialog] = React.useState("");
  const [openFileViewer, setOpenFileViewer] = React.useState(false);
  const [fileSingedUrl, setFileSingedUrl] = React.useState("");
  const [uploadTitle, setUploadTitle] = React.useState("Form/Video Clip");
  const [dvdForms, setDvdForms] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [raForm, setRaForm] = React.useState({
    modifiedPapers: "",
    name: "",
  });
  const [viewFile, setViewFile] = React.useState({});
  const [numPages, setNumPages] = React.useState(null);
  const [errorData, setErrorData] = useState({});
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!isNaN(props.match.params.id)) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (props.uploader.finished != null) {
      fetchData();
    }
  }, [props.uploader.finished]);

  function fetchData() {
    axios.post(urls.getMedia(props.match.params.id)).then((resp) => {
      if (resp.status === 200 && resp.data.error === false) {
        setMedia({ ...resp.data.media });
        setDvdForms(resp.data.dvdForms);
        let regions = resp.data.media.regions.map((item) => item.id);
        setRegions(regions);
        setUploadTitle(resp.data.media.mediatypes.title);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }
  useEffect(() => {
    if (media.level_id) {
      axios
        .post(urls.getMediaForms(media.level_id), {
          nonDvd: true,
          skipMediaType: [7],
        })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setFilterMedia(resp.data.rows);
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        });
    }
  }, [media.level_id]);

  /**
   * Set All regions Selected on a Create Media Page
   * props.match.params.id is null
   */
  useEffect(() => {
    if (isNaN(props.match.params.id) && props.regions.length > 0) {
      let regions = props.regions.map((item) => item.id);
      setRegions(regions);
    }
  }, [props.regions]);

  // Handle Form Change
  const handleChange = (name) => async (event) => {
    let value = "";
    if (["is_practice", "is_resit", "is_actual"].indexOf(name) !== -1) {
      value = event.target.checked;
    } else if (name === "year") {
      console.log(event.format("YYYY"));
      value = event.format("YYYY");
    } else {
      value = event.target.value;
    }

    let mediaObj = { ...media, [name]: value };
    setMedia({ ...mediaObj });
    if (name === "media_type_id" && event.target.value) {
      let mediaTypeArr = await props.mediaTypes.filter(
        (mt) => mt.id === event.target.value
      );

      if (mediaTypeArr.length > 0) {
        setUploadTitle(mediaTypeArr[0].title);
      }
    }
  };

  const handleMediaAdjustmentChange = (event, field) => {
    if (field == "modified_papers") {
      let mediaAdjustment = props.mediaAdjustments.filter(
        (item) => item.id == event.target.value
      );
      setRaForm({
        modifiedPapers: event.target.value,
        name: `${media.id} ${event.target.value} ${media.title} ${mediaAdjustment[0].modified_papers}`,
      });
    } else {
      setRaForm({ ...raForm, name: event.target.value });
    }
  };
  // Update Media Details
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setUpdating(true);
      axios
        .post(urls.updateMedia, {
          media,
          id: props.match.params.id,
          regions: props.match.params.id ? [] : regions,
        })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setErrorData({});
            if (isNaN(props.match.params.id)) {
              props.onEnqueueSnackbar(
                "Successfully created the media",
                3000,
                "success"
              );
              props.history.push(`/media/list/${resp.data.media.id}`);
              setUpdating(false);
            } else {
              props.onEnqueueSnackbar(
                "Successfully updated the media",
                3000,
                "success"
              );
              setUpdating(false);
            }
          } else {
            if (resp.data.errorData) {
              setErrorData(resp.data.errorData);
            }
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
            setUpdating(false);
          }
        })
        .catch((error) => {
          props.onEnqueueSnackbar(error.message, 3000, "error");
          setUpdating(false);
        });
    } catch (error) {
      props.onEnqueueSnackbar(error.message, 3000, "error");
      setUpdating(false);
    }
  };

  // Handle Media File Upload
  function handleFileUpload(ra_category_id) {
    if (props.uploader.uploading) {
      props.onEnqueueSnackbar(
        "Please wait for previous upload to finish",
        3000
      );
      return;
    }
    let fileName = filename;
    if (ra_category_id !== null) {
      fileName = raForm.name;
      if (raForm.name == "") {
        setErrorData({ ...errorData, rafilename: "Please enter the filename" });
        return;
      }
    }
    let mediaFile = media.media_files.filter(
      (item) => item.ra_category_id == ra_category_id
    );
    fileName = fileName
      ? `${fileName
          .toString()
          .split(" ")
          .join("-")}`
      : `${media.description
          .toString()
          .split(" ")
          .join("-")}`;

    try {
      let data = {
        url: urls.uploadMediaFile(props.match.params.id),
        uploadType: "Media",
        filename: fileName,
        id: props.match.params.id,
        level: media.level.name,
        ra_category_id: ra_category_id,
        uid: Date.now(),
        location: props.history.location.pathname,
        destination: `media/templates/${media.level.name}`,
        accept: ".doc,.docx,.mp4",
      };
      props.uploadFile(data);
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000);
      setUploading(false);
    }
  }

  // Handle Media File Delete
  function handleFileDelete() {
    setUploading(true);
    let fileId = openFileDialog;
    setOpenFileDialog("");
    axios
      .post(urls.deleteMediaFile(fileId))
      .then((resp) => {
        if (resp.data.error) {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          setFileSingedUrl("");
          fetchData();
          props.onEnqueueSnackbar(
            "Successfully deleted the file",
            3000,
            "success"
          );
        }
      })
      .catch((err) => props.onEnqueueSnackbar(err.message, 3000))
      .finally(() => setUploading(false));
  }

  // Handle View Media file
  async function handleViewMediaFile(ra_category_id) {
    let mediaFile = media.media_files.filter(
      (item) => item.ra_category_id == ra_category_id
    );
    setViewFile(mediaFile[0]);
    if (fileSingedUrl == "") {
      await axios
        .get(urls.getMediaFile(mediaFile[0].id))
        .then((resp) => {
          if (!resp.data.error) {
            setFileSingedUrl(resp.data.url);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (videoRef.current && mediaFile[0].filetype === "mp4") {
        videoRef.current.src = fileSingedUrl;
        videoRef.current.play();
      }
    }
    setOpenFileViewer(true);
  }
  // On Closing the Media File Dialog
  function onFileDialogExiting(ra_category_id) {
    if (viewFile.filetype === "mp4") {
      videoRef.current.pause();
      videoRef.current.src = "";
    }
    setViewFile({});
  }

  // Handle Check All
  const handleCheckAllChange = (name) => async (event) => {
    if (event.target.checked) {
      let checkedIds = await props.regions.map((item) => item.id);
      await setRegions(checkedIds);
    } else {
      await setRegions([]);
    }
    setCount((c) => c + 1);
  };

  // handle Region Change
  const handleRegionChange = (regionId) => async (e) => {
    if (!e.target.checked) {
      let checkedIds = await regions.filter((id) => id !== regionId);
      await setRegions(checkedIds);
    } else {
      await setRegions([...regions, regionId]);
    }
    setCount((c) => c + 1);
  };

  useEffect(() => {
    if (props.match.params.id && count > 0) {
      axios
        .post(urls.updateMediaRegion(props.match.params.id), {
          regions,
          all: props.regions.map((item) => item.id),
        })
        .then((resp) => {
          if (!resp.data.error) {
            props.onEnqueueSnackbar("Success!!", 3000, "success");
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((err) => props.onEnqueueSnackbar(err.message, 3000));
    }
  }, [count]);

  // handle PDF Load
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // Handle Media Association for DVD's
  async function handleMediaAssociation(media_id, attach) {
    if (!attach) {
      setOpenDeleteDialog(false);
    }
    let resp = await axios.post(urls.addMediaFormToDvd(props.match.params.id), {
      attach: attach,
      media_id: media_id,
    });
    if (resp.status === 200 && resp.data.error === false) {
      props.onEnqueueSnackbar("Success!!", 1000, "success");
      if (attach) {
        setDvdForms([...dvdForms, resp.data.row.media_id]);
      } else {
        if (dvdForms.indexOf(media_id) != -1) {
          let forms = [...dvdForms];
          await forms.splice(forms.indexOf(media_id), 1);
          setDvdForms(forms);
        }
      }
    } else {
      await props.onEnqueueSnackbar(resp.data.errorText, 3000);
    }
  }
  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} sm={10}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={classes.card}>
                {updating && <LinearProgress color="secondary" />}
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Media
                  </Typography>
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="media-title"
                      label="Title"
                      className={classes.textField}
                      value={media.title}
                      onChange={handleChange("title")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("title")}
                      helperText={errorData.title}
                    />
                    <TextField
                      id="media-descriptions"
                      label="Description"
                      className={classes.textField}
                      value={media.description}
                      onChange={handleChange("description")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("description")}
                      helperText={errorData.description}
                    />
                    <TextField
                      id="select-level"
                      select
                      label="Qualification Level"
                      className={classes.textField}
                      value={media.level_id}
                      onChange={handleChange("level_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("level_id")}
                      helperText={errorData.level_id}
                    >
                      {props.levels &&
                        props.levels.map((level) => (
                          <MenuItem key={level.id} value={level.id}>
                            {level.short_desc}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="select-media-type"
                      select
                      label="Media Type"
                      className={classes.textField}
                      value={media.media_type_id || ""}
                      onChange={handleChange("media_type_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("media_type_id")}
                      helperText={errorData.media_type_id}
                    >
                      {props.mediaTypes &&
                        props.mediaTypes.map((mediaTypes) => (
                          <MenuItem key={mediaTypes.id} value={mediaTypes.id}>
                            {mediaTypes.title}
                          </MenuItem>
                        ))}
                    </TextField>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="select-active"
                          select
                          label="Media Active"
                          value={media.is_active}
                          fullWidth={true}
                          onChange={handleChange("is_active")}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          error={errorData.hasOwnProperty("is_active")}
                          helperText={errorData.is_active}
                        >
                          <MenuItem value="1">Yes</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        </TextField>
                      </Grid>
                      {[1, 2, 10].indexOf(media.media_type_id) === -1 && (
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="select-pdf"
                            select
                            label="Generate as PDF"
                            value={media.generate_pdf}
                            fullWidth={true}
                            onChange={handleChange("generate_pdf")}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            margin="normal"
                            error={errorData.hasOwnProperty("generate_pdf")}
                            helperText={errorData.is_active}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            views={["year"]}
                            label="Year"
                            margin="normal"
                            value={media.year ? `${media.year}` : null}
                            onChange={handleChange("year")}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={media.is_practice}
                            onChange={handleChange("is_practice")}
                            name="is_practice"
                            color="primary"
                          />
                        }
                        label="Is Practice"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={media.is_actual}
                            onChange={handleChange("is_actual")}
                            name="is_actual"
                            color="primary"
                          />
                        }
                        label="Is Actual"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={media.is_resit}
                            onChange={handleChange("is_resit")}
                            name="is_resit"
                            color="primary"
                          />
                        }
                        label="Is Resit"
                      />
                    </FormGroup>
                  </form>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                    disabled={updating}
                  >
                    {isNaN(props.match.params.id)
                      ? "Create Media"
                      : "Update Media"}
                  </Button>
                </CardActions>
              </Card>
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Media Regions
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={regions.length == props.regions.length}
                          onChange={handleCheckAllChange("checkAll")}
                          value="checkAll"
                          color="secondary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                      }
                      label="All"
                    />
                  </Box>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup row>
                      {props.regions &&
                        props.regions.map((region) => (
                          <FormControlLabel
                            className={classes.regionLabel}
                            key={region.name}
                            control={
                              <Checkbox
                                checked={regions.indexOf(region.id) !== -1}
                                onChange={handleRegionChange(region.id)}
                                color="primary"
                              />
                            }
                            label={region.name}
                          />
                        ))}
                    </FormGroup>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              {props.match.params.id && (
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {`Upload ${uploadTitle}`}
                    </Typography>
                    <TextField
                      id="media-filename"
                      label="Filename"
                      className={classes.textField}
                      value={filename}
                      onChange={(e) => setFilename(e.target.value)}
                      margin="normal"
                      fullWidth={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setFilename(`${media.id} ${media.title}`)
                              }
                            >
                              <CopyIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errorData.hasOwnProperty("filename")}
                      helperText={errorData.filename}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      size="small"
                      className={classes.button}
                      disabled={uploading}
                      onClick={() => handleFileUpload(null)}
                    >
                      {"Upload file"}
                    </Button>
                    <List component="nav" aria-label="main media list">
                      {media.media_files.map((file) => {
                        if (
                          file.ra_category_id === null ||
                          !file.ra_category_id
                        ) {
                          return (
                            <ListItem key={file.id} button>
                              <ListItemText
                                primary={file.filename}
                                secondary={
                                  file.ra_category ? file.ra_category.name : ""
                                }
                              />
                              <ListItemSecondaryAction>
                                <Tooltip title={`${file.createdAt}`}>
                                  <Button
                                    className={classes.button}
                                    color="primary"
                                    size="small"
                                    onClick={() => handleViewMediaFile(null)}
                                  >
                                    View
                                  </Button>
                                </Tooltip>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => setOpenFileDialog(file.id)}
                                >
                                  <DeleteIcon size="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        }
                      })}
                    </List>
                  </CardContent>
                </Card>
              )}
              {[1, 2, 10].indexOf(media.media_type_id) !== -1 && (
                <Card className={classes.overflowCard}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {`Forms for ${uploadTitle}`}
                    </Typography>
                    {filterMedia && (
                      <IntegrationDownshift
                        label="Assoicate Forms"
                        placeholder="Search in Media Forms"
                        data={Object.values(filterMedia)}
                        onSelect={(selected) =>
                          handleMediaAssociation(selected.id, true)
                        }
                      />
                    )}
                    <div>
                      <List dense={true}>
                        {dvdForms.length > 0 &&
                          dvdForms.map((dvdFormsId) => {
                            if (filterMedia[dvdFormsId]) {
                              let item = filterMedia[dvdFormsId];
                              return (
                                <React.Fragment key={item.id}>
                                  <ListItem
                                    className={classes.listItem}
                                    style={{ borderColor: item.bgcolor }}
                                  >
                                    <ListItemText
                                      primary={item.title}
                                      secondary={item.label}
                                    />
                                    <ListItemSecondaryAction
                                      className={classes.listIcon}
                                    >
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() =>
                                          setOpenDeleteDialog(item.id)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  <Divider />
                                </React.Fragment>
                              );
                            }
                          })}
                      </List>
                    </div>
                  </CardContent>
                </Card>
              )}
              {props.mediaAdjustments && (
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {`Forms For Reasonable Adjustments`}
                    </Typography>
                    <TextField
                      id="select-ra-type"
                      select
                      label="Reasonable Adjustment Categories"
                      className={classes.textField}
                      value={raForm.modifiedPapers || ""}
                      onChange={(e) =>
                        handleMediaAdjustmentChange(e, "modified_papers")
                      }
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                    >
                      {props.mediaAdjustments.map((adjustment) => (
                        <MenuItem key={adjustment.id} value={adjustment.id}>
                          {adjustment.modified_papers}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="ra-media-filename"
                      label="Filename"
                      className={classes.textField}
                      value={raForm.name}
                      onChange={(e) =>
                        handleMediaAdjustmentChange(e, "filename")
                      }
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("rafilename")}
                      helperText={errorData.rafilename}
                    />
                  </CardContent>
                  <CardActions>
                    {raForm.modifiedPapers && (
                      <Button
                        variant="contained"
                        component="span"
                        size="small"
                        className={classes.button}
                        disabled={uploading}
                        onClick={() => handleFileUpload(raForm.modifiedPapers)}
                      >
                        {"Upload file"}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              )}
              <Card className={classes.card}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {`List of Reasonable Adjustment Media`}
                  </Typography>
                  <List component="nav" aria-label="main mailbox folders" dense>
                    {media.media_files.map((file) => {
                      if (file.ra_category_id != null) {
                        return (
                          <React.Fragment key={file.id}>
                            <ListItem button>
                              <ListItemText
                                primary={file.filename}
                                secondary={
                                  file.ra_category && file.ra_category.name
                                }
                              />
                              <ListItemSecondaryAction>
                                <Tooltip title={`${file.createdAt}`}>
                                  <Button
                                    className={classes.button}
                                    color="primary"
                                    size="small"
                                    onClick={() =>
                                      handleViewMediaFile(file.ra_category_id)
                                    }
                                  >
                                    View
                                  </Button>
                                </Tooltip>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => setOpenFileDialog(file.id)}
                                >
                                  <DeleteIcon size="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        );
                      }
                    })}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box my={2}>
            {!isNaN(props.match.params.id) &&
              props.mediaTypes.length > 0 &&
              props.mediaTypes.map((item) => (
                <Chip
                  key={item.id}
                  label={item.title}
                  size="small"
                  className={classes.chip}
                  style={{ backgroundColor: item.bgcolor, color: item.color }}
                />
              ))}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openFileDialog != ""}
        keepMounted
        onClose={() => setOpenFileDialog("")}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {`Confirm Deletion`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this file.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFileDialog("")} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFileDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={parseInt(openDeleteDialog) > 0 ? true : false}
        keepMounted
        onClose={() => setOpenDeleteDialog("")}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirm Deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to remove this Media Form from ${uploadTitle}`}
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleMediaAssociation(openDeleteDialog, false)}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={openFileViewer}
        keepMounted
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        onClose={() => setOpenFileViewer(false)}
        onExiting={onFileDialogExiting}
        aria-labelledby="file-viewer-slide-title"
        aria-describedby="file-viewer-slide-description"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="file-viewer-slide-title"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{viewFile.filename}</Typography>
            <div>
              <Link
                href={fileSingedUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  aria-label="close"
                  size="small"
                  color="primary"
                  className={classes.closeButton}
                >
                  <OpenInNewIcon size="small" />
                </IconButton>
              </Link>
              <IconButton
                aria-label="close"
                size="small"
                className={classes.closeButton}
                onClick={() => setOpenFileViewer(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        </DialogTitle>
        <DialogContent>
          {viewFile.filetype == "mp4" && (
            <video
              ref={videoRef}
              src={fileSingedUrl}
              controls
              height="300"
              width="400"
              type="video/mp4"
            ></video>
          )}
          {["jpg", "jpeg", "png"].indexOf(viewFile.filetype) !== -1 && (
            <img src={fileSingedUrl} alt={viewFile.filename} width="100%" />
          )}
          {viewFile.filetype == "pdf" && fileSingedUrl && (
            <Document
              file={fileSingedUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={700}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    mediaTypes: state.common.mediaTypes,
    levels: state.common.levels,
    regions: state.common.regions,
    mediaAdjustments: state.common.mediaAdjustments,
    uploader: state.uploader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditMedia)
);
