import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import AddNewLearner from "./AddNewLearner";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1, 2),
  },
  tablePaper: {
    padding: 0,
  },
  container: {
    paddingTop: "100px",
    background: theme.palette.background.default,
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontSize: 18,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  learnerList: {
    marginTop: theme.spacing(4),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RegisterLearner(props) {
  const classes = useStyles();

  const [searching, setSearching] = useState(false);
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [learners, setLearners] = useState([]);
  const [learnerIds, setLearnerIds] = useState([]);

  const [fields, setFields] = useState({
    uln: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
  });
  const [searchResults, setSearchResults] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [openRegisterNewLearner, setOpenRegisterNewLearner] = useState(false);

  useEffect(() => {
    fetchLearners();
    console.log("[RegisterLearner] Did mount");
  }, []);

  /** Fetch Course */
  function fetchLearners() {
    axios.post(urls.getCourseLearners(props.course.id)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setLearners(resp.data.learners);
        let ids = resp.data.learners.map((item) => item.learner_id);
        setLearnerIds(ids);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** handle Search Field Change */
  const handleSearchFieldChange = (name) => (e) => {
    if (name === "dob") {
      setFields({ ...fields, [name]: e ? moment(e).format("YYYY-MM-DD") : "" });
    } else {
      setFields({ ...fields, [name]: e.target.value });
    }

    let errors = { ...errorData };
    delete errors[name];
    setErrorData(errors);
  };

  /** Search Learners */
  function searchLearners() {
    let errors = {};
    if (!fields.uln) {
      if (!fields.first_name) {
        errors.first_name = "First name is required if ULN is not present";
      }
      if (!fields.last_name) {
        errors.last_name = "Last name is required if ULN is not present";
      }
      if (!fields.dob) {
        errors.dob = "Dob is required if ULN is not present";
      }
      setErrorData(errors);
    } else if (fields.uln) {
      setErrorData({});
    }

    if (!Object.keys(errorData).length) {
      setSearching(true);
      axios
        .post(urls.searchLearners, { fields })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setErrorData({});
            setSearchResults(resp.data.results);
            if (resp.data.results.length === 0) {
              props.onEnqueueSnackbar(
                "No Result found matching your search.",
                3000,
                "warning"
              );
            } else {
              props.onEnqueueSnackbar(
                `Success!! Found ${resp.data.results.length} results.`,
                2000,
                "success"
              );
            }
          } else {
            if (resp.data.errorData) {
              setErrorData(resp.data.errorData);
            }
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
          setSearching(false);
        })
        .catch((error) => {
          setSearching(false);
          if (403 === error.response.status) {
            props.onEnqueueSnackbar("Access Denied", 1000, "warning");
          } else {
            props.onEnqueueSnackbar(
              error.response.data.errorText,
              3000,
              "error"
            );
          }
        });
    }
  }

  /**
   * Handle Add Learner from Course
   */
  function handleAddLearner(id) {
    setAdding(true);
    axios
      .post(urls.addCourseLearner(props.match.params.id), { learner_id: id })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          fetchLearners();
          let results = searchResults.filter((item) => item.id !== id);
          setSearchResults(results);
          props.onEnqueueSnackbar(
            `Success!! Added the learner to course .`,
            2000,
            "success"
          );
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 5000);
        }
        setAdding(false);
      })
      .catch((error) => {
        setAdding(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  /**
   * Handle Remove Learner from Course
   */
  function handleRemoveLearner(id) {
    setRemoving(true);
    axios
      .post(urls.removeCourseLearner(props.match.params.id), { learner_id: id })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          fetchLearners();
          props.onEnqueueSnackbar(
            `Success!! Remove the learner to course .`,
            2000,
            "success"
          );
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 5000);
        }
        setRemoving(false);
      })
      .catch((error) => {
        setRemoving(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }
  /** Handle  */
  function handleOpenRegisterNewLearner() {
    setOpenRegisterNewLearner(true);
  }

  /** Handle  */
  function handleCloseRegisterNewLearner(e, id = null) {
    setOpenRegisterNewLearner(false);
    if (id) {
      handleAddLearner(id);
    }
  }

  const status = props.course.status;
  console.log(props.course);
  if (openRegisterNewLearner) {
    return (
      <AddNewLearner
        openRegisterNewLearner={openRegisterNewLearner}
        onCloseRegisterNewLearner={(e, id) =>
          handleCloseRegisterNewLearner(e, id)
        }
        course={props.course}
      />
    );
  }
  return (
    <Dialog
      fullScreen
      open={props.openRegisterLearner}
      onClose={props.onCloseRegisterLearner}
      TransitionComponent={Transition}
      className={classes.root}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.title}>
              Register Learners on Course
            </Typography>
          </Box>
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onCloseRegisterLearner}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={10}>
          {status === 100 && (
            <Grid container>
              <Grid item xs={12}>
                <Card className={classes.card}>
                  {searching && <LinearProgress color="secondary" />}
                  <CardContent>
                    <Box>
                      <TextField
                        id="field-uln"
                        label="Search in ULN"
                        className={classes.textField}
                        value={fields.uln}
                        onChange={handleSearchFieldChange("uln")}
                        margin="dense"
                        error={errorData.hasOwnProperty("uln")}
                        helperText={errorData.uln}
                      />
                      <TextField
                        id="field-first-name"
                        label="First Name"
                        className={classes.textField}
                        value={fields.first_name}
                        onChange={handleSearchFieldChange("first_name")}
                        margin="dense"
                        error={errorData.hasOwnProperty("first_name")}
                        helperText={errorData.first_name}
                      />
                      <TextField
                        id="field-last-name"
                        label="Last Name"
                        className={classes.textField}
                        value={fields.last_name}
                        onChange={handleSearchFieldChange("last_name")}
                        margin="dense"
                        error={errorData.hasOwnProperty("last_name")}
                        helperText={errorData.last_name}
                      />
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          label="Date of Birth"
                          margin="dense"
                          format="DD-MM-YYYY"
                          value={fields.dob || null}
                          onChange={handleSearchFieldChange("dob")}
                          error={errorData.hasOwnProperty("dob")}
                          helperText={errorData.dob}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => searchLearners()}
                          >
                            Search for a Learner in the iBSL Database
                          </Button>
                        </div>
                        <Typography variant="caption" color="textSecondary">
                          ** Search by ULN or FirstName, LastName and Date of
                          Birth
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleOpenRegisterNewLearner}
                        >
                          Add new Learner to iBSL Database
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          )}

          {searchResults.length > 0 && (
            <Grid container className={classes.learnerList}>
              <Grid item xs={12}>
                <Paper className={classes.tablePaper}>
                  <Box pt={2} px={2}>
                    <Typography variant="h6" gutterBottom>
                      Search Result
                    </Typography>
                  </Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>ULN</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">Gender</TableCell>
                        <TableCell align="center">Date Of Birth</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchResults.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.uln}
                          </TableCell>
                          <TableCell align="left">{`${row.first_name} ${row.last_name}`}</TableCell>
                          <TableCell align="center">
                            {row.gender === "M" ? "Male" : "Female"}
                          </TableCell>
                          <TableCell align="center">
                            {row.dob
                              ? moment(row.dob).format("DD-MM-YYYY")
                              : ""}
                          </TableCell>
                          <TableCell align="right">
                            {learnerIds.includes(row.id) ? (
                              "Already Registered"
                            ) : (
                              <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => handleAddLearner(row.id)}
                              >
                                ADD
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          )}
          <Grid container className={classes.learnerList}>
            <Grid item xs={12}>
              <Paper className={classes.tablePaper}>
                <Box pt={2} px={2}>
                  <Typography variant="h6">
                    Learners Registered on Course: {props.course.name} (
                    {props.course.id})
                  </Typography>
                  <Typography variant="body2">
                    Centre:{" "}
                    {`${props.course.centre.name} (${props.course.centre.number})`}
                  </Typography>
                  <Typography variant="body2">
                    Qualification: {props.course.qualification.title}
                  </Typography>
                </Box>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>ULN</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Gender</TableCell>
                      <TableCell align="center">Date Of Birth</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {learners.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.learner.uln}
                        </TableCell>
                        <TableCell align="left">{`${row.learner.first_name} ${row.learner.last_name}`}</TableCell>
                        <TableCell align="center">
                          {row.learner.gender === "M" ? "Male" : "Female"}
                        </TableCell>
                        <TableCell align="center">
                          {row.learner.dob
                            ? moment(row.learner.dob).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {status < 120 && (
                            <Button
                              color="secondary"
                              className={classes.button}
                              onClick={() =>
                                handleRemoveLearner(row.learner_id)
                              }
                            >
                              REMOVE
                            </Button>
                          )}
                          {status === 130 && "Waiting for Approval"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    regions: state.common.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterLearner)
);
