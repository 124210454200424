import { put, call } from "redux-saga/effects";
import * as actions from "../actions/index";
import axios from "axios";
import { urls } from "../../common/urls";

/*
 * Authenticate User by OTP
 */
export function* authExamSaga(action) {
  yield put(actions.authExamStart());
  const authData = { otp: action.otp };
  try {
    const response = yield axios.post(urls.validateLiveExamOtp, authData);
    if (response.status === 200 && !response.data.error) {
      const expirationTime = yield new Date(response.data.expirationTime);
      yield localStorage.setItem("examToken", response.data.token);
      yield localStorage.setItem("examExpirationTime", expirationTime);
      yield put(actions.authExamSuccess(response.data));
      // Call saga to get Authenticated user data;
    } else {
      yield put(
        actions.enqueueSnackbar({
          message: response.data.errorText,
          options: {
            key: new Date().getTime() + Math.random(),
          },
        })
      );
      yield put(actions.authExamFail(response.data.errorText));
    }
  } catch (error) {
    yield put(
      actions.enqueueSnackbar({
        message: error.response.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    yield put(actions.authFail(error.response.data.message));
  }
}

/*
 * Logout Authenticated User
 */
export function* logoutExamSaga(action) {
  try {
    const token = yield localStorage.getItem("examToken");
    if (token) {
      yield axios.post(urls.exitExam);
    }
    yield call([localStorage, "removeItem"], "examToken");
    yield call([localStorage, "removeItem"], "examExpirationTime");
  } catch (e) {
    console.log(e);
  }
  yield put(actions.logoutExamSucceed());
}

/*
 * Check if user is Authenticated for EXAM
 */

export function* authCheckExamStateSaga(action) {
  console.log("Called authCheckExamStateSaga");
  const token = yield localStorage.getItem("examToken");
  if (!token) {
    yield put(actions.logoutExam());
  } else {
    const expirationTime = yield new Date(
      localStorage.getItem("examExpirationTime")
    );
    if (expirationTime <= new Date()) {
      yield put(
        actions.enqueueSnackbar({
          message: "Session Expired",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
          },
        })
      );
      yield put(actions.logoutExam());
    } else {
      const response = yield axios.post(urls.authLiveExam, { token });
      if (response.status === 200 && !response.data.error) {
        yield put(
          actions.authExamSuccess({
            error: null,
            errorText: "",
            isAuthenticated: true,
            exam: response.data.exam,
          })
        );
      }
    }
  }
}
