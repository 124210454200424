import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import Table from "@material-ui/core/Table";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlertDialog from "../../components/AlertDialog";
import { urls } from "../../common/urls";
import * as actions from "../../store/actions";
import CheckIcon from "@material-ui/icons/Check";
import NavigationIcon from "@material-ui/icons/Navigation";
import Marksheet from "./Marksheet";
import MarksheetEE from "./MarksheetEE";
import ViewEvidence from "./ViewEvidence";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eee",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiDialog-paperFullScreen": {
      backgroundColor: "#f5f5f5",
    },
  },
  btnGap: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    position: "relative",
  },
  button: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: "400",
  },
  viewEvidenceRoot: {
    background: theme.palette.background.default,
  },
  historyTable: {
    marginBottom: theme.spacing(3),
    background: "#fff",
  },
  dialogPaper: {
    background: theme.palette.background.default,
    height: 400,
    width: "100%",
    minWidth: "320px",
    padding: theme.spacing(2, 3),
    overflowY: "scroll",
  },
}));

function Evidence(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [assessment, setAssessment] = useState({
    centre: { name: "", number: "" },
    course: { id: "", name: "" },
    module: { title: "" },
  });
  const [learners, setLearners] = useState([]);
  const [learner, setLearner] = useState({});
  const [uploading, setUploading] = useState(false);
  const [openMarksheet, setOpenMarksheet] = useState(false);
  const [openMarksheetEE, setOpenMarksheetEE] = useState(false);
  const [openEvidenceUpload, setOpenEvidenceUpload] = useState(false);
  const [filename, setFilename] = React.useState("");
  const [marksheetReq, setMarksheetReq] = React.useState("");
  const [touched, setTouched] = React.useState([]);
  const [evidenceCount, setEvidenceCount] = React.useState({});
  const [can, setCan] = React.useState({
    mark: false,
    setEvidenceComplete: false,
  });
  const [history, setHistory] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    markingCompleted: false,
  });

  useEffect(() => {
    fetchAssessment();
    fetchEvidenceCount();
  }, []);

  // Handle Media File Upload
  const handleFileUpload = (row) => {
    if (props.uploader.uploading) {
      props.onEnqueueSnackbar(
        "Please wait for previous upload to finish",
        3000
      );
      return;
    }
    let learnername = `${row.learner.first_name} ${row.learner.last_name}`;
    //let fileName = `${learnername.toString().split(' ').join('-')}`;
    let destination = `media/centre/${assessment.centre.number}/media/assessment/${assessment.assessment_no}/evidences`;
    try {
      let data = {
        url: urls.updateLearnerEvidence(assessment.id),
        uploadType: "Media",
        id: assessment.id,
        uid: Date.now(),
        location: props.history.location.pathname,
        destination: destination,
        learner_id: row.learner.id,
        assessment_id: assessment.id,
        assessment_no: assessment.assessment_no,
        accept:
          "image/*,.doc,.docx,.pdf,.avi,.flv,.3gp,.3gpp,.mp4,.mov,.mpeg4,.mks,.mkv,.m3u8,.ts,.wmv",
      };
      props.uploadFile(data);
    } catch (err) {
      props.onEnqueueSnackbar(err.message, 3000);
      setUploading(false);
    }
  };
  /** Fetch Assessment */
  async function fetchAssessment() {
    axios.post(urls.getAssessment(props.match.params.id)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setAssessment(resp.data.assessment);
        fetchMarksheet(resp.data.assessment.module_id);
        fetchAssestLearners(resp.data.assessment.id);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Fetch Assessment Learners */
  function fetchAssestLearners(id) {
    axios.post(urls.getAssessmentLearners(id)).then(async (resp) => {
      if (resp.status === 200 && !resp.data.error) {
        setLearners(resp.data.learners);
        if (resp.data.history) {
          setHistory(resp.data.history);
        }
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }
  /** Fetch Evidence Count*/
  function fetchEvidenceCount() {
    axios
      .post(urls.getLearnerEvidences(props.match.params.id))
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          if (resp.data.rows.length > 0) {
            const evidenceCountObj = {};
            await resp.data.rows.forEach((item) => {
              evidenceCountObj[item.learner_id] = item.count;
            });
            setEvidenceCount(evidenceCountObj);
          }
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }
  /** Handle Show Marksheet */
  function handleShowMarksheet(learner) {
    setLearner(learner);
    setOpenMarksheet(true);
  }
  /** handle Dialog Close for Marksheet */
  const handleMarksheetClose = () => {
    fetchAssestLearners(assessment.id);
    setOpenMarksheet(false);
  };

  /** Handle Show Marksheet EE */
  const handleShowMarksheetEE = () => {
    setOpenMarksheetEE(true);
  };
  /** handle Dialog Close for Marksheet EE*/
  const handleMarksheetEEClose = () => {
    fetchAssestLearners(assessment.id);
    setOpenMarksheetEE(false);
  };
  const handleChangeAbsent = (learnerId) => (event) => {
    if (assessment.status < props.asstStatus.AAF_UPLOADED) {
      props.onEnqueueSnackbar("AAF Not Uploaded", 3000);
      return;
    }
    if (assessment.status >= props.asstStatus.MARKED) {
      return;
    }
    const isAbsent = event.target.checked ? 1 : 0;
    axios
      .post(urls.setLearnerAbsent(assessment.id), {
        learner_id: learnerId,
        absent: isAbsent,
      })
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          let learnerArr = learners.map((item) => {
            if (item.learner.id === learnerId) {
              item.is_absent = isAbsent;
            }
            return item;
          });
          setLearners(learnerArr);
          props.onEnqueueSnackbar("Success!!", 1000, "success");
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  };

  /**
   * Handle File Upload Evidence
   */
  const handleShowEvidenceUpload = (learner) => {
    setLearner(learner);
    setOpenEvidenceUpload(true);
  };
  const handleEvidenceUloadClose = () => {
    fetchAssestLearners(assessment.id);
    setOpenEvidenceUpload(false);
  };
  /** Fetch media marksheet forms */
  function fetchMarksheet(module_id) {
    axios.post(urls.getModuleMarksheet(module_id)).then(async (resp) => {
      if (
        resp.status === 200 &&
        !resp.data.error &&
        resp.data.rows.length > 0
      ) {
        setMarksheetReq(resp.data.rows.length > 0);
      }
    });
  }

  /**
   * Handle Learner Mark Change
   */
  const handleMarkChange = (id, isRadio) => (e) => {
    if (assessment.status < props.asstStatus.EVIDENCE_COMPLETE) {
      props.onEnqueueSnackbar(
        "Assessment should be set to Evidence Complete before marking",
        5000
      );
      return;
    }
    if (assessment.status < props.asstStatus.MARKING_ALLOCATED) {
      props.onEnqueueSnackbar("Marking Examiner has not been allocated", 3000);
      return;
    }
    if (assessment.status >= props.asstStatus.MARKED) {
      return;
    }
    const ids = touched.filter((i) => i !== id);
    ids.push(id);
    setTouched(ids);
    const passgrade = assessment.module.pass_grade;
    const learnerArr = learners.map((item) => {
      if (item.id === id) {
        if (isRadio) {
          const is_pass = parseInt(e.target.value);
          return { ...item, is_pass: parseInt(e.target.value), marks: null };
        } else {
          const marks = parseInt(e.target.value);
          const marksValue =
            marks >= 0 && marks <= 100 ? marks : marks > 100 ? 100 : 0;
          const is_pass = marksValue >= passgrade ? 1 : 0;
          return { ...item, is_pass: is_pass, marks: marksValue };
        }
      }
      return item;
    });
    setLearners(learnerArr);
  };
  /**
   * Save Marks to Database
   */

  const saveMarksToDb = (row) => {
    axios
      .post(urls.updateLearnerMarks(row.id), {
        data: row,
      })
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          const ids = touched.filter((i) => i !== row.id);
          setTouched(ids);
          props.onEnqueueSnackbar("Success!!", 1000, "success");
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  };

  /**
   * Handle Evidence Complete
   */
  const handleEvidenceComplete = (reopon, agree = false) => {
    axios
      .post(urls.evidenceComplete(assessment.id), {
        reopen: reopon,
      })
      .then(async (resp) => {
        if (resp.status === 200 && !resp.data.error) {
          props.onEnqueueSnackbar("Success!!", 1000, "success");
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  };
  /**
   * Handle MArking Complete
   */
  const handleMarkingComplete = (agree) => {
    setOpenAlert((prev) => ({ ...prev, markingCompleted: false }));
    if (agree) {
      axios
        .post(urls.updateAssessmentStatus(assessment.id), {
          reopen: false,
          status: "MARKING_COMPLETED",
        })
        .then(async (resp) => {
          if (resp.status === 200 && !resp.data.error) {
            props.onEnqueueSnackbar("Success!!", 1000, "success");
            setAssessment({ ...assessment, status: resp.data.status });
            fetchAssestLearners(assessment.id);
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((err) => {
          props.onEnqueueSnackbar(err.message, 3000);
        });
    }
  };

  useEffect(() => {
    let tmp1 = false;
    let tmp2 = false;
    if (assessment.status < props.asstStatus.EVIDENCE_COMPLETE) {
      tmp1 = true;
    }
    if (
      assessment.status >= props.asstStatus.MARKING_ALLOCATED &&
      assessment.status < props.asstStatus.MARKED
    ) {
      tmp2 = true;
    }
    setCan({ ...can, setEvidenceComplete: tmp1, mark: tmp2 });
  }, [assessment.status]);

  const handleMarkingHistory = () => {
    setShowHistory((prev) => !prev);
  };
  return (
    <div>
      <Grid container justify="center">
        <Grid xs={12} sm={10} item>
          <Paper>
            <Grid container justify="center" className={classes.container}>
              <Grid item xs={12} sm={6}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Centre</TableCell>
                      <TableCell>{`${assessment.centre.name} #${assessment.centre.number}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Course</TableCell>
                      <TableCell>{`${assessment.course.name} #${assessment.course.id}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assessment</TableCell>
                      <TableCell>{`${assessment.title} #${assessment.assessment_no}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Assmt Date</TableCell>
                      <TableCell>{assessment.assessment_date}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assmt Time</TableCell>
                      <TableCell>{assessment.assessment_time}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Module</TableCell>
                      <TableCell>{assessment.module.title}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Paper>
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-end"
                my={2}
                mr={"-8px"}
              >
                {assessment.AssessmentType &&
                  assessment.AssessmentType.id === 5 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.btnGap}
                      onClick={handleShowMarksheetEE}
                    >
                      EE Marksheet
                    </Button>
                  )}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.btnGap}
                  disabled={!can.setEvidenceComplete}
                  onClick={() => handleEvidenceComplete(false)}
                >
                  Evidence Complete
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.btnGap}
                  disabled={assessment.status < 200 || assessment.status >= 220}
                  onClick={() => handleEvidenceComplete(true)}
                >
                  Reopen Evidence Upload
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    assessment.status < props.asstStatus.MARKING_ALLOCATED ||
                    assessment.status >= props.asstStatus.MARKED
                  }
                  className={classes.btnGap}
                  onClick={() =>
                    setOpenAlert((prev) => ({
                      ...prev,
                      markingCompleted: true,
                    }))
                  }
                >
                  Marking Complete
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.btnGap}
                  onClick={handleMarkingHistory}
                >
                  Marking History
                </Button>
              </Box>
              <Paper>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ULN</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Gender</TableCell>
                      <TableCell align="center">Date Of Birth</TableCell>
                      <TableCell align="center">Absent</TableCell>
                      <TableCell align="center">Marksheet</TableCell>
                      <TableCell align="center">Action</TableCell>
                      <TableCell align="center">Evidence</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {learners.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.learner.uln}
                        </TableCell>
                        <TableCell align="left">{`${row.learner.first_name} ${row.learner.last_name}`}</TableCell>
                        <TableCell align="center">
                          {row.learner.gender === "M" ? "Male" : "Female"}
                        </TableCell>
                        <TableCell align="center">
                          {row.learner.dob
                            ? moment(row.learner.dob).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            checked={row.is_absent ? true : false}
                            size="small"
                            onChange={handleChangeAbsent(row.learner.id)}
                            value="1"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {marksheetReq && (
                            <IconButton
                              color="primary"
                              aria-label="add marks"
                              size="small"
                              onClick={() => handleShowMarksheet(row.learner)}
                            >
                              {!row.is_absent &&
                                (row.marks ? (
                                  <EditIcon size="small" />
                                ) : (
                                  <AddCircleIcon size="small" />
                                ))}
                            </IconButton>
                          )}
                          {!marksheetReq && (
                            <div>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="gender"
                                  name={`mark${row.id}`}
                                  value={
                                    row.is_pass !== null ? row.is_pass : ""
                                  }
                                  onChange={handleMarkChange(row.id, true)}
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={
                                      <Radio color="primary" size="small" />
                                    }
                                    label="Pass"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio size="small" />}
                                    label="Fail"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <TextField
                                type="number"
                                className={classes.textField}
                                value={row.marks !== null ? row.marks : ""}
                                onChange={handleMarkChange(row.id)}
                                inputProps={{
                                  min: "0",
                                  max: "100",
                                  step: "1",
                                  readOnly: !can.mark,
                                }}
                              />
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {touched.indexOf(row.id) !== -1 && can.mark && (
                            <Button
                              className={classes.button}
                              color="secondary"
                              onClick={() => saveMarksToDb(row)}
                            >
                              Save
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            aria-label="add marks"
                            size="small"
                            onClick={() =>
                              handleShowEvidenceUpload(row.learner)
                            }
                          >
                            {!row.is_absent ? (
                              <PermMediaIcon size="small" />
                            ) : (
                              ""
                            )}
                          </IconButton>
                          <IconButton
                            color="primary"
                            aria-label="add marks"
                            size="small"
                            onClick={() => handleFileUpload(row)}
                          >
                            {!row.is_absent ? (
                              <React.Fragment>
                                {assessment.status <
                                  props.asstStatus.EVIDENCE_COMPLETE && (
                                  <AddCircleIcon size="small" />
                                )}
                                {`(${
                                  evidenceCount[row.learner.id]
                                    ? evidenceCount[row.learner.id]
                                    : 0
                                })`}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openMarksheetEE && (
        <Dialog
          fullScreen
          open={openMarksheetEE}
          onClose={handleMarksheetEEClose}
          className={classes.dialog}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Typography variant="h6" className={classes.title}>
                    {assessment.qualification.code} EE Marksheet
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h6"
                    className={classes.title}
                    align="center"
                  >
                    {assessment.qualification.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
              </Grid>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleMarksheetEEClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <MarksheetEE assessment={assessment} learners={learners} />
        </Dialog>
      )}
      {openMarksheet && (
        <Dialog
          fullScreen
          open={openMarksheet}
          onClose={handleMarksheetClose}
          className={classes.dialog}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Typography variant="h6" className={classes.title}>
                    {assessment.title} Marksheet
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h6"
                    className={classes.title}
                    align="center"
                  >
                    {assessment.qualification.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
              </Grid>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleMarksheetClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Marksheet assessment={assessment} learner={learner} />
        </Dialog>
      )}
      {openEvidenceUpload && (
        <Dialog
          fullScreen
          open={openEvidenceUpload}
          onClose={handleEvidenceUloadClose}
          className={classes.paperFullScreen}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Typography variant="h6" className={classes.title}>
                    Evidence
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={3}></Grid>
              </Grid>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleEvidenceUloadClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <ViewEvidence assessment={assessment} learner={learner} />
        </Dialog>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={showHistory}
        onClose={handleMarkingHistory}
        maxWidth={"md"}
        className={classes.dialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Marking History"}
        </DialogTitle>
        <Paper className={classes.dialogPaper}>
          {!Object.keys(history).length && (
            <Box>
              <Typography variant="subtitle1">No Record Found</Typography>
            </Box>
          )}
          {Object.values(history).map((row) => (
            <React.Fragment>
              <Typography variant="subtitle1">
                Marked By <strong>{row.marked_by}</strong> ({row.role}) at{" "}
                <strong>{row.marked_at}</strong>
              </Typography>
              <Table
                className={classes.historyTable}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Learner Id</StyledTableCell>
                    <StyledTableCell>ULN</StyledTableCell>
                    <StyledTableCell align="right">Name</StyledTableCell>
                    <StyledTableCell align="right">Is Absent</StyledTableCell>
                    <StyledTableCell align="right">Marks</StyledTableCell>
                    <StyledTableCell align="right">Is Pass</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row &&
                    row.data.map((data) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {data.learner_id}
                        </TableCell>
                        <TableCell scope="row">{data.uln}</TableCell>
                        <TableCell align="right">{`${data.first_name} ${data.last_name}`}</TableCell>
                        <TableCell align="right">
                          {data.is_absent === 1 ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="right">{data.marks}</TableCell>
                        <TableCell align="right">
                          {data.is_pass === 1 ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </React.Fragment>
          ))}
        </Paper>
      </Dialog>
      <AlertDialog
        title="Marking Complete"
        description="Once assessment has been set to Marking Complete, you will not be allowed to make any further change in marking. Are you sure you want to submit. ?"
        open={openAlert.markingCompleted}
        handleAlertClose={(agree) => handleMarkingComplete(agree)}
      />
      <AlertDialog
        title="ReOpen Evidence Upload"
        description="Are you sure you want to Reopen Evidence Upload?"
        open={openAlert.evidenceUpload}
        handleAlertClose={(agree) => handleEvidenceComplete(null, agree)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    uploader: state.uploader,
    asstStatus: state.common.assessmentStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Evidence);
