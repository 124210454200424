import * as actionTypes from './actionTypes';

/*
 * Load All ROLES
 * Route : /system/roles
 */
export const getRoles = () => {
  return {
    type: actionTypes.GET_ROLES
  };
};

export const getRolesStart = () => {
  return {
    type: actionTypes.GET_ROLES_START
  };
};

export const getRolesSuccess = payload => {
  return {
    type: actionTypes.GET_ROLES_SUCCESS,
    payload: payload
  };
};

export const getRolesFail = payload => {
  return {
    type: actionTypes.GET_ROLES_FAIL,
    payload: payload
  };
};

/*
 * Load All Permissions
 * Route : /system/permissions
 */
export const updatePermission = data => {
  return {
    type: actionTypes.UPDATE_PERMISSION,
    payload: data
  };
};

export const updatePermissionStart = () => {
  return {
    type: actionTypes.UPDATE_PERMISSION_START
  };
};

export const updatePermissionSuccess = data => {
  return {
    type: actionTypes.UPDATE_PERMISSION_SUCCESS,
    payload: data
  };
};

export const updatePermissionFail = payload => {
  return {
    type: actionTypes.UPDATE_PERMISSION_FAIL,
    payload
  };
};
