import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import EmailField from "../../components/Common/Fields/EmailField";
import PhoneField from "../../components/Common/Fields/PhoneField";

import * as actions from "../../store/actions";
import {
  updateObject,
  checkValidity,
  randomString,
} from "../../common/utility";
import ReactCropper from "../../components/ReactCropper";
import { FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function Profile(props) {
  const [user, setUser] = useState({});
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [src, setSrc] = useState("");
  const [cropResult, setCropResult] = useState("");
  const [password, setPassword] = useState("");
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [sendPasswordInstruction, setSendPasswordInstruction] = useState(0);
  const [sendingPasswordMail, setSendingPasswordMail] = useState(false);
  const imgRef = useRef(null);
  useEffect(() => {
    setUser({
      userId: props.auth.userId,
      name: props.authUser.name,
      email: props.authUser.email,
      phone: props.authUser.phone,
      profilePhoto: props.authUser.profilePhoto,
      phone_verified_at: props.authUser.phone_verified_at,
      email_verified_at: props.authUser.email_verified_at,
      email_sent_at: props.authUser.email_sent_at,
      timezone: props.authUser.timezone,
      loading: props.authUser.loading,
    });
    setSrc(props.authUser.profilePhoto);
    axios.get(`api/system/user/${props.auth.userId}/photo`, {}).then((resp) => {
      if (resp.data.url) {
        setLoadingPhoto(false);
        setSrc(resp.data.url);
      } else {
        setLoadingPhoto(false);
        setSrc("");
      }
    });
  }, [props.auth, props.authUser]);

  const classes = useStyles();

  // handle form field change
  const handleChange = (name) => (e) => {
    setUser({ ...user, [name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onUserUpdate(user);
  };
  /**
   * Handle Image Crop
   */
  const onImageCrop = (resultImg, name, type) => {
    let orginalImgurl = src;
    setSrc(resultImg);
    setCropResult(resultImg);
    setLoadingPhoto(true);
    fetch(resultImg)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], name, { type: type });
        let data = new FormData();
        const id = user.userId;
        data.append("userId", 20);
        data.append("profile_photo", file);
        data.append("mimetype", type);
        data.append("originalname", name);
        //this.props.onUserUpdate(data);
        axios({
          method: "post",
          url: `/api/system/user/${id}/update/photo`,
          data: data,
          config: { headers: { "content-type": `multipart/form-data` } },
        }).then((resp) => {
          axios.get(`api/system/user/${id}/photo`, {}).then((resp) => {
            if (resp.data.error) {
              props.onEnqueueSnackbar(resp.data.errorText, 3000);
              setLoadingPhoto(false);
              setSrc(orginalImgurl);
            } else {
              props.onEnqueueSnackbar("Success!!", 3000, "success");
              setLoadingPhoto(false);
            }
          });
        });
      })
      .catch(() => {
        setLoadingPhoto(false);
        setSrc(orginalImgurl);
      });
  };

  const handlePasswordSubmit = () => {
    let id = user.userId;
    setSendingPasswordMail(true);
    axios
      .post(`/api/system/user/${id}/password/reset`, {
        password: password,
        sendPasswordInstruction: sendPasswordInstruction,
      })
      .then((resp) => {
        if (resp.data.error) {
          setSendingPasswordMail(true);
          this.props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          setSendingPasswordMail(false);
          setPasswordDialogOpen(false);
          props.onEnqueueSnackbar("Success!!", 1000, "success");
        }
      })
      .catch((err) => {
        setSendingPasswordMail(false);
        setPasswordDialogOpen(false);
        props.onEnqueueSnackbar(err.message, 3000);
      });
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="h4" component="h4">
          My Profile
        </Typography>
        <Paper className={classes.paper}>
          <Box
            display="flex"
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Avatar
              ref={imgRef}
              variant="square"
              src={src}
              className={classes.large}
            >
              <AddAPhotoIcon fontSize="large" />
            </Avatar>
            {!loadingPhoto ? (
              <ReactCropper
                btnText={src ? "Replace" : "Upload"}
                onImageCrop={(result, name, type) =>
                  onImageCrop(result, name, type)
                }
              />
            ) : (
              <Typography color="secondary" align="center">
                Uploading Photo...
              </Typography>
            )}
          </Box>
          <Box>
            <TextField
              id="user-name"
              label="Name"
              className={classes.textField}
              value={user.name}
              onChange={handleChange("name")}
              margin="normal"
              required={true}
              fullWidth={true}
              error={errorData.hasOwnProperty("name")}
              helperText={errorData.name}
            />
            <EmailField
              data={{
                userId: user.userId,
                email: user.email,
                verifiedAt: user.email_verified_at,
                emailSentAt: user.email_sent_at,
              }}
              onChange={handleChange("email")}
              onEnqueueSnackbar={props.onEnqueueSnackbar}
            />
            <TextField
              id="user-phone"
              label="Phone"
              className={classes.textField}
              value={user.phone}
              onChange={handleChange("phone")}
              margin="normal"
              fullWidth={true}
              error={errorData.hasOwnProperty("phone")}
              helperText={errorData.phone}
            />
            {/* <PhoneField
              data={{
                userId: user.userId,
                phone: user.phone,
                verifiedAt: user.phone_verified_at,
              }}
              onChange={handleChange("phone")}
              onEnqueueSnackbar={props.onEnqueueSnackbar}
            /> */}
          </Box>
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => handleSubmit(e)}
            >
              Update Details
            </Button>
            <Button color="primary" onClick={() => setPasswordDialogOpen(true)}>
              Reset Password
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Dialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Password"
            type={showPassword ? "text" : "password"}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sendPasswordInstruction}
                onChange={(e) => setSendPasswordInstruction(e.target.checked)}
                color="primary"
              />
            }
            label={`Email new password to user at ${user.email}`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    authUser: state.authUser,
    updating: state.user.updating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserUpdate: (params) => dispatch(actions.updateUser(params)),
    onRoleUpdate: (params) => dispatch(actions.updateUserRole(params)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
