import * as actionTypes from "./actionTypes";

export const authExam = (otp) => {
  return {
    type: actionTypes.AUTH_EXAM,
    otp,
  };
};
export const authExamStart = () => {
  return {
    type: actionTypes.AUTH_EXAM_START,
  };
};

export const authExamSuccess = (data) => {
  return {
    type: actionTypes.AUTH_EXAM_SUCCESS,
    error: data.error,
    exam: data.exam,
    errorText: data.errorText,
    isAuthenticated: true,
  };
};
export const authExamFail = (error) => {
  return {
    type: actionTypes.AUTH_EXAM_FAIL,
    error: error,
  };
};

export const logoutExam = () => {
  return {
    type: actionTypes.AUTH_INITIATE_EXAM_LOGOUT,
  };
};

export const logoutExamSucceed = () => {
  return {
    type: actionTypes.AUTH_EXAM_LOGOUT,
  };
};

export const authExamCheckState = () => {
  return {
    type: actionTypes.AUTH_EXAM_CHECK_STATE,
  };
};

export const endExam = () => {
  return {
    type: actionTypes.EXAM_ENDED,
  };
};

export const extendExam = () => {
  return {
    type: actionTypes.EXAM_EXTENDED,
  };
};
