import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import Gaurd from "../../hoc/Gaurd";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import { range } from "../../common/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  inlineTextField: {
    marginRight: theme.spacing(2),
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2, 2, 2, 0),
  },
  input: {
    display: "none",
  },
  documentHeading: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
    fontWeight: "bold",
  },
  fabAdd: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  statusGroup: {
    flexDirection: "row",
  },
  legend: {
    fontSize: "0.75rem",
  },
}));

function EditQualification(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [updatingDoc, setUpdatingDoc] = useState(false);
  const [openFileDialog, setOpenFileDialog] = React.useState(false);
  const [mediaGroups, setMediaGroups] = useState([]);

  // Qualification
  const [qualification, setQualification] = useState({
    qualification_id: "",
    level_id: "",
    code: "",
    qan: "",
    description: "",
    title: "",
    version: new Date(),
    version_no: "",
    year: null,
    qualification_fee: "",
    is_active: "",
    group_id: "",
  });

  // Qualification Docs
  const [qualificationDocs, setQualificationDocs] = useState({
    QUALIFICATION: { title: "", document: "" },
    BSL_VERSION: { title: "", document: "" },
    PURPOSE: { title: "", document: "" },
    OTHER: { title: "", document: "" },
  });

  const [readOnly, setReadOnly] = useState(false);
  // Error Data
  const [errorData, setErrorData] = useState({});

  // Get Qulification with Docs on Mount
  useEffect(() => {
    if (isNaN(props.match.params.id)) {
      setCreateNew(true);
    } else {
      fetchQualification();
    }
  }, []);

  // Get Qualification and Docs

  const fetchQualification = async () => {
    if (!createNew) {
      setUpdatingDoc(true);
      axios
        .post(urls.getQualification(props.match.params.id))
        .then(async (resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setQualification(resp.data.qualification);
            setReadOnly(resp.data.qualification.courses.length > 0);
            console.log(resp.data.qualification);
            fetchMediaGroups(resp.data.qualification.level_id);
            let docs = {
              QUALIFICATION: { title: "", document: "" },
              BSL_VERSION: { title: "", document: "" },
              PURPOSE: { title: "", document: "" },
              OTHER: { title: "", document: "" },
            };
            await resp.data.qualification.qualification_docs.map((doc) => {
              docs = {
                ...docs,
                [doc.type]: {
                  qualification_doc_id: doc.id,
                  title: doc.title,
                  document: doc.document,
                },
              };
            });
            setQualificationDocs(docs);
          } else {
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((err) => {
          props.onEnqueueSnackbar(err.message, 3000);
          props.history.push(`/${err.response.status}`);
        })
        .finally(() => setUpdatingDoc(false));
    }
  };
  const fetchMediaGroups = (level_id) => {
    axios.post(urls.getMediaGroupsByLevel(level_id)).then((resp) => {
      if (resp.status === 200 && resp.data.error === false) {
        setMediaGroups(resp.data.rows);
      }
    });
  };

  // Update Qualification Fields
  const handleChange = (name) => (event) => {
    if (name === "year") {
      setQualification({
        ...qualification,
        [name]: event.format("YYYY"),
      });
    } else if (name === "version") {
      setQualification({
        ...qualification,
        [name]: event.format("YYYY-MM-DD"),
      });
    } else if (name === "level_id") {
      fetchMediaGroups(event.target.value);
      setQualification({ ...qualification, [name]: event.target.value });
    } else {
      setQualification({ ...qualification, [name]: event.target.value });
    }
  };

  // Update Document Title for following types [ QUALIFICATION, PURPOSE, OTHER , BSL_VERSION ]
  const handleDocFieldChange = (name, field = "title") => (event) => {
    let docs = Object.assign({}, qualificationDocs);
    if (field === "document") {
      docs[name].document = event.target.value;
    } else {
      docs[name].title = event.target.value;
    }
    setQualificationDocs(docs);
  };

  // Update Qualifications
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      let reqUrl = createNew
        ? urls.createQualification
        : urls.updateQualification(props.match.params.id);
      axios
        .post(reqUrl, { qualification })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setErrorData({});
            props.onEnqueueSnackbar("Success!!", 1000, "success");
            if (createNew && resp.data.id) {
              setCreateNew(false);
              props.history.push(`/qualifications/list/${resp.data.id}`);
            }
          } else {
            if (resp.data.errorData) {
              setErrorData(resp.data.errorData);
            }
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((error) => {
          if (403 === error.response.status) {
            props.onEnqueueSnackbar("Access Denied", 1000, "warning");
          } else {
            props.onEnqueueSnackbar(
              error.response.data.errorText,
              3000,
              "error"
            );
          }
        })
        .finally(() => setLoading(false));
    } catch (error) {
      props.onEnqueueSnackbar(error.message, 3000, "error");
    }
  };

  // Upload document files for all types
  const handleDocFileChange = (e, type, document_id) => {
    setUpdatingDoc(true);
    e.preventDefault();
    if (e.target.files.length > 0) {
      let file = e.target.files[0];
      const data = new FormData();
      data.append("doc_file", file);
      data.append("doc_type", type);
      data.append("document_id", document_id ? document_id : null);
      data.append("qualification_id", qualification.id);

      axios({
        method: "post",
        url: urls.uploadQualificationDoc,
        data: data,
        config: { headers: { "content-type": "multipart/form-data" } },
      })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setErrorData({});
            props.onEnqueueSnackbar("Success!!", 1000, "success");
            fetchQualification();
          } else {
            if (resp.data.errorData) {
              setErrorData(resp.data.errorData);
            }
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((error) => {
          if (403 === error.response.status) {
            props.onEnqueueSnackbar("Access Denied", 1000, "warning");
          } else {
            props.onEnqueueSnackbar(
              error.response.data.errorText,
              3000,
              "error"
            );
          }
        })
        .finally(() => setUpdatingDoc(false));
    }
  };

  // Get File signed url of the document from S3 on View
  const getDocFile = async (doc_id) => {
    let resp = await axios.get(urls.getQualificationDoc(doc_id));
    window.open(resp.data.url, "_blank");
  };

  // Remove a document
  const handleDocFileRemove = async (doc_id) => {
    setUpdatingDoc(true);
    setOpenFileDialog(false);
    await axios.post(urls.removeQualificationDoc(doc_id));
    await fetchQualification();
    setUpdatingDoc(false);
  };

  // Handle qualification document update
  const handleDocsSubmit = (e) => {
    setUpdatingDoc(true);
    e.preventDefault();
    axios
      .post(urls.updateQualificationDoc, {
        qualification_id: qualification.id,
        docs: qualificationDocs,
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch((error) => {
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      })
      .finally(() => setUpdatingDoc(false));
  };

  /** Create New Version */
  const handleNewVersion = () => {
    try {
      setLoading(true);
      let reqUrl = urls.createQualificationNewVersion(props.match.params.id);
      axios
        .post(reqUrl, { qualification })
        .then((resp) => {
          if (resp.status === 200 && resp.data.error === false) {
            setErrorData({});
            props.onEnqueueSnackbar("Success!!", 1000, "success");
            props.history.push(`/qualifications/list/${resp.data.newVersion}`);
            window.location.reload();
          } else {
            if (resp.data.errorData) {
              setErrorData(resp.data.errorData);
            }
            props.onEnqueueSnackbar(resp.data.errorText, 3000);
          }
        })
        .catch((error) => {
          if (403 === error.response.status) {
            props.onEnqueueSnackbar("Access Denied", 1000, "warning");
          } else {
            props.onEnqueueSnackbar(
              error.response.data.errorText,
              3000,
              "error"
            );
          }
        })
        .finally(() => setLoading(false));
    } catch (error) {
      props.onEnqueueSnackbar(error.message, 3000, "error");
    }
  };
  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} sm={10}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                {loading && <LinearProgress color="secondary" />}
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Qualification
                  </Typography>
                  <form
                    className={classes.formContainer}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="qualification-code"
                      label="Code"
                      className={classes.textField}
                      value={qualification.code}
                      onChange={handleChange("code")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("code")}
                      helperText={errorData.code}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <TextField
                      id="qualification-title"
                      label="Title"
                      className={classes.textField}
                      value={qualification.title}
                      onChange={handleChange("title")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("title")}
                      helperText={errorData.title}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <TextField
                      id="qualification-descriptions"
                      label="Description"
                      className={classes.textField}
                      value={qualification.description}
                      onChange={handleChange("description")}
                      margin="normal"
                      multiline
                      rowsMax="4"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("description")}
                      helperText={errorData.description}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <TextField
                      id="qualification-qan"
                      label="QAN"
                      className={classes.textField}
                      value={qualification.qan}
                      onChange={handleChange("qan")}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("qan")}
                      helperText={errorData.qan}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                    <TextField
                      id="select-level"
                      select
                      label="Qualification Level"
                      className={classes.textField}
                      value={qualification.level_id}
                      onChange={handleChange("level_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("level_id")}
                      helperText={errorData.level_id}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    >
                      {props.levels &&
                        props.levels.map((level) => (
                          <MenuItem key={level.id} value={level.id}>
                            {level.short_desc}
                          </MenuItem>
                        ))}
                    </TextField>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            views={["year", "month"]}
                            label="Version"
                            margin="normal"
                            value={qualification.version}
                            onChange={handleChange("version")}
                            readOnly={readOnly}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            views={["year"]}
                            label="Year"
                            margin="normal"
                            value={
                              qualification.year
                                ? `${qualification.year}`
                                : null
                            }
                            onChange={handleChange("year")}
                            readOnly={readOnly}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <TextField
                      id="select-qualification-group"
                      select
                      label="Qualification Group"
                      className={classes.textField}
                      value={qualification.group_id || ""}
                      onChange={handleChange("group_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("group_id")}
                      helperText={errorData.group_id}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    >
                      {props.qualificationGroups &&
                        props.qualificationGroups.map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            {group.title}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="qualification-fee"
                      label="Annual Qualification Fee"
                      className={classes.inlineTextField}
                      value={qualification.qualification_fee}
                      InputProps={{
                        readOnly: readOnly,
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                      onChange={handleChange("qualification_fee")}
                      margin="normal"
                      error={errorData.hasOwnProperty("qualification_fee")}
                      helperText={errorData.qualification_fee}
                    />
                    <TextField
                      id="module-media-group"
                      select
                      label="Media Group"
                      className={classes.textField}
                      value={qualification.media_group_id || ""}
                      onChange={handleChange("media_group_id")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth={true}
                      error={errorData.hasOwnProperty("media_group_id")}
                      helperText={errorData.media_group_id}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    >
                      {mediaGroups &&
                        mediaGroups.map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            {`${group.name} ${
                              group.year ? `(${group.year})` : ""
                            }`}
                          </MenuItem>
                        ))}
                    </TextField>

                    {!createNew && (
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel
                          component="legend"
                          className={classes.legend}
                        >
                          Status
                        </FormLabel>
                        <RadioGroup
                          aria-label="status"
                          name="status"
                          className={classes.statusGroup}
                          value={`${qualification.is_active}`}
                          onChange={handleChange("is_active")}
                          disabled={readOnly}
                        >
                          <FormControlLabel
                            value={`${0}`}
                            control={<Radio color="secondary" />}
                            label="Not Active"
                            labelPlacement="end"
                            disabled={readOnly}
                          />
                          <FormControlLabel
                            value={`${-1}`}
                            control={<Radio color="secondary" />}
                            label="Pending"
                            labelPlacement="end"
                            disabled={readOnly}
                          />
                          <FormControlLabel
                            value={`${1}`}
                            control={<Radio color="primary" />}
                            label="Active"
                            labelPlacement="end"
                            disabled={readOnly}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </form>
                </CardContent>
                <Gaurd check="update_qualification">
                  <CardActions>
                    {readOnly ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleNewVersion}
                      >
                        New Version
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleSubmit}
                      >
                        {isNaN(props.match.params.id)
                          ? "Create Qualification"
                          : "Update Qualification"}
                      </Button>
                    )}
                  </CardActions>
                </Gaurd>
              </Card>
            </Grid>
            {!createNew && (
              <Grid item xs={12} md={6}>
                <Card>
                  {updatingDoc && <LinearProgress color="secondary" />}
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Documents
                    </Typography>
                    <form
                      className={classes.formContainer}
                      noValidate
                      autoComplete="off"
                    >
                      {/* Qualification Specification */}
                      <div>
                        <Typography className={classes.documentHeading}>
                          Qualification Specification
                        </Typography>
                        <IconButton disabled>
                          <AttachFileIcon />
                        </IconButton>
                        {qualificationDocs.QUALIFICATION.document && (
                          <Button
                            disabled={updatingDoc}
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={() =>
                              getDocFile(
                                qualificationDocs.QUALIFICATION
                                  .qualification_doc_id
                              )
                            }
                          >
                            View
                          </Button>
                        )}
                        <Gaurd check="update_qualification">
                          {!readOnly && (
                            <input
                              accept="image/*,.doc,.pdf,.txt"
                              className={classes.input}
                              id="specification-button-file"
                              multiple
                              type="file"
                              onChange={(e) =>
                                handleDocFileChange(
                                  e,
                                  "QUALIFICATION",
                                  qualificationDocs.QUALIFICATION
                                    .qualification_doc_id
                                )
                              }
                            />
                          )}
                          {!readOnly && (
                            <label htmlFor="specification-button-file">
                              <Button
                                size="small"
                                component="span"
                                color="primary"
                                disabled={updatingDoc}
                                className={classes.button}
                              >
                                {qualificationDocs.QUALIFICATION.document
                                  ? "REPLACE"
                                  : "Upload"}
                              </Button>
                            </label>
                          )}
                          {qualificationDocs.QUALIFICATION.document &&
                            !readOnly && (
                              <Button
                                disabled={updatingDoc}
                                size="small"
                                color="secondary"
                                className={classes.button}
                                onClick={(e) =>
                                  handleDocFileRemove(
                                    qualificationDocs.QUALIFICATION
                                      .qualification_doc_id
                                  )
                                }
                              >
                                Remove
                              </Button>
                            )}
                        </Gaurd>
                      </div>
                      <TextField
                        id="specification-doc"
                        label="Title on Website"
                        className={classes.textField}
                        value={
                          qualificationDocs.QUALIFICATION.title ||
                          "Qualification Specification"
                        }
                        onChange={handleDocFieldChange(
                          "QUALIFICATION",
                          "title"
                        )}
                        margin="none"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("QUALIFICATION_title")}
                        helperText={errorData.QUALIFICATION_title}
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      />

                      {/* Link to BSL Version */}
                      <Typography className={classes.documentHeading}>
                        BSL Version
                      </Typography>
                      <TextField
                        id="bsl-version-url"
                        label="Link to BSL Version Video"
                        className={classes.textField}
                        value={qualificationDocs.BSL_VERSION.document}
                        onChange={handleDocFieldChange(
                          "BSL_VERSION",
                          "document"
                        )}
                        margin="normal"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("BSL_VERSION_document")}
                        helperText={errorData.BSL_VERSION_document}
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      />

                      <TextField
                        id="bsl-version-url"
                        label="Title on Website"
                        className={classes.textField}
                        value={
                          qualificationDocs.BSL_VERSION.title || "BSL Version"
                        }
                        onChange={handleDocFieldChange("BSL_VERSION", "title")}
                        margin="normal"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("BSL_VERSION_title")}
                        helperText={errorData.BSL_VERSION_title}
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      />
                      {/* Purpose Statement */}
                      <div>
                        <Typography className={classes.documentHeading}>
                          Purpose Statement
                        </Typography>
                        <IconButton disabled>
                          <AttachFileIcon />
                        </IconButton>
                        {qualificationDocs.PURPOSE.document && (
                          <Button
                            color="primary"
                            className={classes.button}
                            disabled={updatingDoc}
                            onClick={() =>
                              getDocFile(
                                qualificationDocs.PURPOSE.qualification_doc_id
                              )
                            }
                          >
                            View
                          </Button>
                        )}
                        <Gaurd check="update_qualification">
                          {!readOnly && (
                            <input
                              accept="image/*,.doc,.pdf,.txt"
                              className={classes.input}
                              id="purpose-button-file"
                              multiple
                              type="file"
                              onChange={(e) =>
                                handleDocFileChange(
                                  e,
                                  "PURPOSE",
                                  qualificationDocs.PURPOSE.qualification_doc_id
                                )
                              }
                            />
                          )}

                          {!readOnly && (
                            <label htmlFor="purpose-button-file">
                              <Button
                                size="small"
                                component="span"
                                color="primary"
                                className={classes.button}
                                disabled={updatingDoc}
                              >
                                {qualificationDocs.PURPOSE.document
                                  ? "REPLACE"
                                  : "Upload"}
                              </Button>
                            </label>
                          )}
                          {qualificationDocs.PURPOSE.document && !readOnly && (
                            <Button
                              size="small"
                              color="secondary"
                              className={classes.button}
                              disabled={updatingDoc}
                              onClick={(e) =>
                                handleDocFileRemove(
                                  qualificationDocs.PURPOSE.qualification_doc_id
                                )
                              }
                            >
                              Remove
                            </Button>
                          )}
                        </Gaurd>
                      </div>
                      <TextField
                        id="purpose-doc"
                        label="Title on Website"
                        className={classes.textField}
                        value={
                          qualificationDocs.PURPOSE.title || "Purpose Statement"
                        }
                        onChange={handleDocFieldChange("PURPOSE", "title")}
                        margin="none"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("PURPOSE_title")}
                        helperText={errorData.PURPOSE_title}
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      />
                      {/* Other Document */}
                      <div>
                        <Typography className={classes.documentHeading}>
                          Other Document
                        </Typography>
                        <IconButton disabled>
                          <AttachFileIcon />
                        </IconButton>
                        {qualificationDocs.OTHER.document && (
                          <Button
                            color="primary"
                            className={classes.button}
                            disabled={updatingDoc}
                            onClick={() =>
                              getDocFile(
                                qualificationDocs.OTHER.qualification_doc_id
                              )
                            }
                          >
                            View
                          </Button>
                        )}
                        <Gaurd check="update_qualification">
                          {!readOnly && (
                            <input
                              accept="image/*,.doc,.pdf,.txt"
                              className={classes.input}
                              id="other-button-file"
                              multiple
                              type="file"
                              onChange={(e) =>
                                handleDocFileChange(
                                  e,
                                  "OTHER",
                                  qualificationDocs.OTHER.qualification_doc_id
                                )
                              }
                            />
                          )}
                          {!readOnly && (
                            <label htmlFor="other-button-file">
                              <Button
                                size="small"
                                component="span"
                                color="primary"
                                className={classes.button}
                                disabled={updatingDoc}
                              >
                                {qualificationDocs.OTHER.document
                                  ? "REPLACE"
                                  : "Upload"}
                              </Button>
                            </label>
                          )}
                          {qualificationDocs.OTHER.document && !readOnly && (
                            <Button
                              size="small"
                              color="secondary"
                              className={classes.button}
                              disabled={updatingDoc}
                              onClick={(e) =>
                                handleDocFileRemove(
                                  qualificationDocs.OTHER.qualification_doc_id
                                )
                              }
                            >
                              Remove
                            </Button>
                          )}
                        </Gaurd>
                      </div>
                      <TextField
                        id="other-doc"
                        label="Title on Website"
                        className={classes.textField}
                        value={
                          qualificationDocs.OTHER.title || "Other Document"
                        }
                        onChange={handleDocFieldChange(
                          "OTHER",
                          "Other Document"
                        )}
                        margin="none"
                        fullWidth={true}
                        error={errorData.hasOwnProperty("OTHER_title")}
                        helperText={errorData.OTHER_title}
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      />
                    </form>
                  </CardContent>
                  {!readOnly && (
                    <Gaurd check="update_qualification">
                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleDocsSubmit}
                        >
                          Update Qualification Docs
                        </Button>
                      </CardActions>
                    </Gaurd>
                  )}
                </Card>
                <Gaurd check="update_qualification">
                  {!isNaN(props.match.params.id) && (
                    <Zoom
                      className={classes.fabAdd}
                      key="add"
                      in={true}
                      style={{ transitionDelay: "500ms" }}
                      unmountOnExit
                    >
                      <Fab
                        aria-label="Add"
                        className="classes.fab"
                        color="primary"
                        onClick={() =>
                          props.history.push(
                            `/qualifications/list/${props.match.params.id}/units`
                          )
                        }
                      >
                        Units
                      </Fab>
                    </Zoom>
                  )}
                </Gaurd>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openFileDialog}
        keepMounted
        onClose={() => setOpenFileDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirm Deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this file.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFileDialog(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDocFileRemove} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    assessmentTypes: state.common.assessmentTypes,
    levels: state.common.levels,
    qualificationGroups: state.common.qualificationGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditQualification)
);
