import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import "date-fns";
import Gaurd from "../../hoc/Gaurd";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import MomentUtils from "@date-io/moment";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import * as actions from "../../store/actions";
import { urls } from "../../common/urls";
import moment from "moment";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    fontSize: 18,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  statusGroup: {
    flexDirection: "row",
  },
  legend: {
    fontSize: "0.75rem",
  },
  levelLabel: {
    width: 200,
  },
  cardSpacing: {
    marginBottom: theme.spacing(2),
  },
  exceptionRowLevel: {
    marginTop: theme.spacing(2),
    minWidth: "100px",
    width: "100%",
    maxWidth: "200px",
  },
  exceptionRowModule: {
    marginTop: theme.spacing(2),
    minWidth: "100px",
    width: "100%",
    maxWidth: "200px",
  },
  exceptionRowRate: {
    marginTop: theme.spacing(2),
    width: "80px",
  },
  exceptionRowType: {
    marginTop: theme.spacing(2),
    width: "100px",
  },
  progress: {
    verticalAlign: "middle",
  },
  boxTextField: {
    minWidth: "50%",
  },
}));

function LearnerAdd(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [learner, setLearner] = useState({
    id: "",
    first_name: "",
    last_name: "",
    secondary_phone: "",
    gender: null,
    dob: null,
    hearing: "",
    ethnicity_id: "",
    address: "",
    city: "",
    postcode: "",
    user: {},
    levels: [],
    learner_ras: [],
  });

  const [errorData, setErrorData] = useState({});

  useEffect(() => {
    console.log("[LearnerEdit] updated");
  });

  // handle form field change
  const handleChange = (name) => (e) => {
    if (name === "email" || name === "phone") {
      setLearner({
        ...learner,
        user: { ...learner.user, [name]: e.target.value },
      });
    } else {
      setLearner({ ...learner, [name]: e.target.value });
    }
  };
  /** Handle DOB Change */
  const handleDobChange = (name) => (e) => {
    setLearner({ ...learner, [name]: e ? moment(e).format("YYYY-MM-DD") : "" });
  };
  // Update Learner
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setErrorData({});
    axios
      .post(urls.createLearner, learner)
      .then((resp) => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar("Success!!", 1000, "success");
          if (props.isRegisterLearner) {
            props.onComplete(resp.data.result);
          } else {
            props.history.push(`/people/learners/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar("Access Denied", 1000, "warning");
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, "error");
        }
      });
  }

  return (
    <Container maxWidth="sm">
      <Card>
        {loading && <LinearProgress color="secondary" />}
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Add New Learner
          </Typography>
          <form className={classes.formContainer} noValidate autoComplete="off">
            <TextField
              id="learner-uln"
              label="ULN"
              className={classes.textField}
              value={learner.uln || ""}
              onChange={handleChange("uln")}
              margin="dense"
              fullWidth={true}
              error={errorData.hasOwnProperty("uln")}
              helperText={errorData.uln}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="learner-fname"
                  label="FirstName"
                  className={classes.textField}
                  value={learner.first_name || ""}
                  onChange={handleChange("first_name")}
                  margin="dense"
                  required={true}
                  fullWidth={true}
                  error={errorData.hasOwnProperty("first_name")}
                  helperText={errorData.first_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="learner-;name"
                  label="LastName"
                  className={classes.textField}
                  value={learner.last_name || ""}
                  onChange={handleChange("last_name")}
                  margin="dense"
                  required={true}
                  fullWidth={true}
                  error={errorData.hasOwnProperty("last_name")}
                  helperText={errorData.last_name}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="select-gender"
                  select
                  label="Gender"
                  value={learner.gender || ""}
                  fullWidth={true}
                  required={true}
                  onChange={handleChange("gender")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="dense"
                  error={errorData.hasOwnProperty("gender")}
                  helperText={errorData.gender}
                >
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    label="DOB"
                    margin="dense"
                    fullWidth={true}
                    format="DD-MM-YYYY"
                    required={true}
                    maxDate={new Date()}
                    value={learner.dob || null}
                    onChange={handleDobChange("dob")}
                    error={errorData.hasOwnProperty("dob")}
                    helperText={errorData.start_date}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <TextField
              id="learner-email"
              label="Email"
              className={classes.textField}
              value={(learner.user && learner.user.email) || ""}
              onChange={handleChange("email")}
              margin="dense"
              required={true}
              fullWidth={true}
              error={errorData.hasOwnProperty("email")}
              helperText={errorData.email || errorData.users_email_unique}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="learner-phone"
                  label="Phone"
                  className={classes.textField}
                  value={
                    learner.user && learner.user.phone ? learner.user.phone : ""
                  }
                  onChange={handleChange("phone")}
                  margin="dense"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+44</InputAdornment>
                    ),
                  }}
                  error={errorData.hasOwnProperty("phone")}
                  helperText={errorData.phone || errorData.users_phone_unique}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="secondary-phone"
                  label="Secondary Phone"
                  className={classes.textField}
                  value={learner.secondary_phone || ""}
                  onChange={handleChange("secondary_phone")}
                  margin="dense"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+44</InputAdornment>
                    ),
                  }}
                  error={errorData.hasOwnProperty("secondary_phone")}
                  helperText={errorData.secondary_phone}
                />
              </Grid>
            </Grid>
            <TextField
              id="learner-address"
              label="Address"
              className={classes.textField}
              value={learner.address || ""}
              required={true}
              onChange={handleChange("address")}
              margin="dense"
              fullWidth={true}
              error={errorData.hasOwnProperty("address")}
              helperText={errorData.address}
            />
            <TextField
              id="learner-city"
              label="City"
              className={classes.textField}
              value={learner.city || ""}
              required={true}
              onChange={handleChange("city")}
              margin="dense"
              fullWidth={true}
              error={errorData.hasOwnProperty("city")}
              helperText={errorData.city}
            />
            <TextField
              id="learner-postcode"
              label="Postcode"
              className={classes.textField}
              value={learner.postcode || ""}
              required={true}
              onChange={handleChange("postcode")}
              margin="dense"
              fullWidth={true}
              error={errorData.hasOwnProperty("postcode")}
              helperText={errorData.postcode}
            />
          </form>
        </CardContent>
        <Gaurd check="create_learner">
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}
            >
              Add Learner
            </Button>
          </CardActions>
        </Gaurd>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    modules: state.common.modules,
    centreAdjustments: state.common.centreAdjustments,
    mediaAdjustments: state.common.mediaAdjustments,
    ethnicities: state.common.ethnicities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (data) => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LearnerAdd)
);
