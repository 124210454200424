import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = data => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: data.token,
    userId: data.userId,
    error: data.error,
    errorText: data.errorText
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const auth = (email, password, remember) => {
  return {
    type: actionTypes.AUTH_USER,
    email: email,
    password: password,
    remember: remember
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE
  };
};

/*
 * Actions for getting Auth User Data
 */
export const getAuthUser = () => {
  return {
    type: actionTypes.GET_AUTH_USER
  };
};
export const getAuthUserStart = () => {
  return {
    type: actionTypes.GET_AUTH_USER_START
  };
};

export const getAuthUserSuccess = payload => {
  return {
    type: actionTypes.GET_AUTH_USER_SUCCESS,
    payload: payload
  };
};

export const getAuthUserFail = () => {
  return {
    type: actionTypes.GET_AUTH_USER_FAIL
  };
};
