import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import * as actions from "../../../store/actions";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  textField: {
    width: "100%"
  }
}));

function PhoneField(props) {
  const classes = useStyles();
  const [phone, setPhone] = useState("");
  const [verifiedAt, setVerifiedAt] = useState(null);
  const [error, setError] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [otp, setOtp] = useState(null);
  const [optSent, setOtpSent] = useState(null);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);

  useEffect(() => {
    setPhone(props.data.phone);
    setVerifiedAt(props.data.verifiedAt);
  }, [props.data]);

  const handleChange = e => {
    setPhone(e.target.value);
    props.onChange(e);
  };

  /* Sending OTP for Mobile Verification */
  const handleMobileVerificationOtp = () => {
    setVerifying(true);
    setOtpSent(null);
    setOtpDialogOpen(true);
    let id = props.data.userId;
    axios
      .post(`/api/system/user/${id}/phone/send/otp`)
      .then(resp => {
        if (resp.data.error) {
          setVerifying(false);
          setOtpSent("");
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          setVerifying(false);
          setOtpSent("OTP sent");
        }
      })
      .catch(err => {
        setVerifying(false);
        setOtpSent("");
        this.props.onEnqueueSnackbar(err.message, 3000);
      });
  };
  const handleOtpSubmit = () => {
    let id = props.data.userId;
    setVerifying(true);
    axios
      .post(`/api/system/user/${id}/phone/verify/otp`, { otp: otp })
      .then(resp => {
        if (resp.data.error) {
          setOtpSent(true);
          setOtpSent("");
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        } else {
          setOtpSent(false);
          setOtpSent("OTP sent");
        }
      })
      .catch(err => {
        setOtpSent(false);
        setOtpSent("");
        props.onEnqueueSnackbar(err.message, 3000);
      });
  };
  return (
    <div>
      <FormControl className={clsx(classes.margin, classes.textField)}>
        <InputLabel htmlFor="phone-field">Phone</InputLabel>
        <Input
          id="phone-field"
          type={"text"}
          value={phone}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              {!verifiedAt ? (
                verifying ? (
                  <CircularProgress size={16} thickness={5} />
                ) : (
                  <Button
                    color="secondary"
                    onClick={handleMobileVerificationOtp}
                  >
                    {optSent ? "ReSend" : "Verify"}
                  </Button>
                )
              ) : (
                <IconButton
                  aria-label="phone verifification"
                  //onClick={handleClickShowPassword}
                  //onMouseDown={handleMouseDownPassword}
                >
                  <VerifiedUserOutlined />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
      <Dialog
        open={otpDialogOpen}
        onClose={() => setOtpDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Verify Mobile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter OTP sent on your registered mobile number.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="OTP"
            type="text"
            onChange={value => setOtp(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOtpDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOtpSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PhoneField;
