import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Logo from "../../components/Logo";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { urls } from "../../common/urls";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AlertDialog from "../../components/AlertDialog";

import * as actions from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0),
    position: "relative",
  },
  paperClock: {
    padding: theme.spacing(2),
  },
  card: {
    maxWidth: 345,
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    textAlign: "center",
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  noVideo: {
    position: "absolute",
    top: "0px",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Clock(props) {
  return <div>{props.time}</div>;
}

function LiveExam(props) {
  const classes = useStyles();
  let videoRef = React.createRef();
  const [assessment, setAssessment] = useState({
    course: {},
    centre: {},
    module: {},
  });
  const [playing, setPlaying] = useState(false);
  const [fileSingedUrl, setFileSingedUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [extendTime, setExtendTime] = useState(15);
  const [extendReason, setExtendReason] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [extendError, setExtendError] = useState("");
  const [counter, setCounter] = useState(0);
  //const [duration, setDuration] = useState(moment());
  const [expiry, setExpiry] = useState("");
  const [clock, setClock] = useState("00:00:00");

  const [isPaused, setIsPaused] = useState(false);
  const [openEndExamAlert, setOpenEndExamAlert] = useState(false);

  useEffect(() => {}, []);

  async function handleViewMediaFile(id) {
    if (fileSingedUrl === "") {
      const token = await localStorage.getItem("examToken");
      await axios
        .post(urls.getLiveExamMediaSignedUrl, { token })
        .then((resp) => {
          if (!resp.data.error && resp.data.url) {
            setFileSingedUrl(resp.data.url);
            const assessment = resp.data.assessment;
            const data = resp.data.data;
            setAssessment(assessment);
            setExpiry(moment().add(props.exam.data.total * 60, "s"));
            var duration = moment.duration(0 * 1000, "milliseconds");
            var interval = 1000;

            setInterval(function() {
              duration = moment.duration(duration + interval, "milliseconds");
              let h = duration.asHours();
              let m = duration.asMinutes();
              let s = duration.asSeconds();
              let clock = moment()
                .hour(h)
                .minute(m)
                .second(s)
                .format("HH : mm : ss");
              setClock(clock);
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (videoRef.current) {
        videoRef.current.src = fileSingedUrl;
      }
    }
  }

  /** Start Exam */
  const handleStartExam = async () => {
    try {
      if (!props.exam.examEnded) {
        await handleViewMediaFile();
        setPlaying(true);
        setInterval(() => {
          if (!isPaused) {
            setCounter((val) => val + 1);
          }
        }, 1000);
        setTimeout(() => {
          if (videoRef && videoRef.current) {
            videoRef.current.play();
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /** End Exam */
  const handleEndExam = async () => {
    setOpen(false);
    props.endExam();
    await localStorage.removeItem("examToken");
    //props.history.push('/assessment/live');
  };

  /** Extend Exam */
  const handleExtendChange = (event) => {
    setExtendError("");
    setExtendTime(event.target.value);
  };

  const handleReasonChange = (event) => {
    setExtendError("");
    setExtendReason(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const examExtended = (extendTime) => {
    setExpiry(moment().add(extendTime * 60, "s"));
    setIsPaused(false);
    setOpen(false);
    props.extendExam();
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
  };
  const handleAlertClose = async (val) => {
    if (val === true) {
      if (!extendTime || extendReason === "" || extendReason.length < 10) {
        let msg =
          extendReason.length > 1
            ? "Please provide more detailed reason for asking for extension"
            : "Please provide a valid Reason";
        setExtendError(msg);
      } else {
        setRequesting(true);
        await axios
          .post(urls.extendExam, {
            assessmentNo: assessment.assessment_no,
            extendedBy: extendTime,
            reason: extendReason,
          })
          .then((resp) => {
            if (!resp.data.error) {
              examExtended(extendTime);
            } else {
              setExtendError(resp.data.errorText);
            }
            setRequesting(false);
          })
          .catch((err) => {
            setRequesting(false);
            console.log(err);
            setExtendError(err.message);
          });
      }
    } else {
      handleEndExam();
    }
  };
  // useEffect(() => {
  //   if (!props.exam.examEnded && !isPaused && expiry) {
  //     moment.duration(duration * 1000, "milliseconds");
  //     if (duration) {
  //       let h = duration.asHours();
  //       let m = duration.asMinutes();
  //       let s = duration.asSeconds();
  //       let clock = moment()
  //         .hour(h)
  //         .minute(m)
  //         .second(s)
  //         .format("HH : mm : ss");
  //       setClock(clock);
  //     } else {
  //       if (!props.exam.examExtended) {
  //         setIsPaused(true);
  //         setOpen(true);
  //       } else {
  //         handleEndExam();
  //       }
  //     }
  //   }
  // }, [counter, expiry]);

  const handleCloseEndExamAlert = (agree) => {
    setOpenEndExamAlert(false);
    if (agree) {
      handleEndExam();
    }
  };
  const { data } = props.exam;

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={4}>
          <Logo />
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
          <Typography variant="h5" component="h3" align="center">
            Live Assessment
          </Typography>
          <Typography variant="body1">
            {`${data.title} (${data.assessment_no}), ${data["course.name"]}, ${data["centre.name"]} (${data["centre.number"]})`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box ml={6} pt={2}>
            <Typography variant="body1" align="left">
              Start Time: {data.time}
            </Typography>
            <Typography variant="body1" align="left">
              Active Until:{" "}
              {moment(`${data.date} ${data.time}`, "YYYY-MM-DD HH:mm")
                .add(data.total, "m")
                .format("HH:mm")}
            </Typography>
            <Typography variant="h5" component="h3" align="left">
              <Clock time={clock} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={11} sm={8} md={6}>
          {!props.exam.examEnded && (
            <Box
              display="flex"
              alignItems="center"
              height={1}
              width={1}
              flexDirection="column"
              justifyContent="center"
            >
              <Paper className={classes.paper}>
                <video
                  ref={videoRef}
                  src={fileSingedUrl}
                  controls={false}
                  height="540"
                  width="960"
                  type="video/mp4"
                  autoPlay
                ></video>
                {!fileSingedUrl && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    height={540}
                    width={960}
                    alignItems="center"
                    className={classes.noVideo}
                  >
                    <Typography component="h4">
                      Click Start Button to play the exam video
                    </Typography>
                  </Box>
                )}
              </Paper>
              <Box display="flex" direction="row">
                {!playing && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStartExam}
                  >
                    Start Exam
                  </Button>
                )}
                {playing && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenEndExamAlert(true)}
                  >
                    End Exam
                  </Button>
                )}
              </Box>
            </Box>
          )}
          {props.exam.examEnded && (
            <Box
              display="flex"
              alignItems="center"
              height={1}
              width={1}
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h5" component="h3" align="center">
                Exam Ended
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Extend Assessment?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ minWidth: "400px" }}
          ></DialogContentText>
          <FormControl
            className={classes.formControl}
            style={{ width: "100%" }}
          >
            <InputLabel id="demo-simple-select-label">
              How long you want to Extend?
            </InputLabel>
            <Select
              id="demo-simple-select"
              value={extendTime || 15}
              onChange={handleExtendChange}
            >
              <MenuItem value={15}>15 Minutes</MenuItem>
              <MenuItem value={30}>30 Minutes</MenuItem>
              <MenuItem value={60}>1 Hour</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reason for asking for Extension"
            type="text"
            fullWidth
            multiline
            variant="filled"
            rowsMax="4"
            rows="4"
            onChange={handleReasonChange}
            error={extendError !== ""}
            helperText={extendError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAlertClose(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(e) => handleAlertClose(true)}
            color="secondary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        title="End Exam"
        description="Are you sure you want to End Exam"
        open={Boolean(openEndExamAlert)}
        handleAlertClose={(agree) => handleCloseEndExamAlert(agree)}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    exam: state.exam,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endExam: () => dispatch(actions.endExam()),
    extendExam: () => dispatch(actions.extendExam()),
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveExam)
);
