import io from 'socket.io-client';
const API_URL = process.env.REACT_APP_API_URL;
const roomSocket = io.connect(`${API_URL}/notices`);
const privateSocket = io.connect(`${API_URL}/private`);

function subscribeToRoom(room, cb) {
  console.log(`connected to room ${room}`);
  roomSocket.emit('joinRoom', room);
  roomSocket.on('NEW ITEM', data => {
    cb('updated');
  });
}

function subscribeToPrivateChannel(userId, cb) {
  console.log(`connected to channel ${userId}`);
  privateSocket.emit('joinChannel', userId);
  privateSocket.on('NEW ITEM', data => {
    cb('updated');
  });
  privateSocket.on('updated', data => {
    cb('updated');
  });
}

function updateNotice(userId, noticeId, action, showRead) {
  console.log(`sending to channel ${userId}`);
  privateSocket.emit('updateNotice', { userId, noticeId, action, showRead });
}

export { subscribeToRoom, subscribeToPrivateChannel, updateNotice };
