import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./store/actions";
import Dashboard from "./containers/Dashboard/Dashboard";
import System from "./containers/System/System";

import asyncComponent from "./hoc/asyncComponent/asyncComponent";
import TopAppBar from "./components/Navigation/AppBar";
import Notifier from "./components/Notifier";
import Uploader from "./components/Uploader";

import Box from "@material-ui/core/Box";
import "./css/App.css";
import Aux from "./hoc/Fragment";
import LoadingPage from "./components/LoadingPage";

import Roles from "./containers/System/Roles";
import Users from "./containers/System/User/Users";
import EditUser from "./containers/System/User/EditUser";
import AddUser from "./containers/System/User/AddUser";

import ApprovalList from "./containers/Approval/ApprovalList";
import ViewApproval from "./containers/Approval/ViewApproval";

import ModuleList from "./containers/Qualification/ModuleList";
import EditModule from "./containers/Qualification/EditModule";
import QualificationIndex from "./containers/Qualification";
import QualificationList from "./containers/Qualification/QualificationList";
import EditQualification from "./containers/Qualification/EditQualification";
import Units from "./containers/Qualification/Units";
import Typography from "@material-ui/core/Typography";

import MediaList from "./containers/Media/MediaList";
import EditMedia from "./containers/Media/EditMedia";
//import Marksheet as MediaMarksheet from './components/Media/Marksheet';

import MediaGroupList from "./containers/Media/MediaGroupList";
import EditMediaGroup from "./containers/Media/EditMediaGroup";

import CentreIndex from "./containers/Centres";
import CentreList from "./containers/Centres/CentreList";
import EditCentre from "./containers/Centres/CentreEdit";
import TeacherList from "./containers/Teachers/TeacherList";
import TeacherEdit from "./containers/Teachers/TeacherEdit";
import TeacherCreate from "./containers/Teachers/TeacherCreate";

import ExaminerList from "./containers/Examiner/ExaminerList";
import ExaminerEdit from "./containers/Examiner/ExaminerEdit";

import PeopleIndex from "./containers/People";

import CourseIndex from "./containers/Courses";
import CourseList from "./containers/Courses/CourseList";
import EditCourse from "./containers/Courses/EditCourse";
import CreateCourse from "./containers/Courses/CreateCourse";

import AssessmentIndex from "./containers/Asessments/index";
import AssessmentList from "./containers/Asessments/AssessmentList";
import AssessmentEdit from "./containers/Asessments/AssessmentEdit";
import CreateAssessment from "./containers/Asessments/CreateAssessment";
import LiveAssessment from "./containers/LiveAssessment/index";
import LiveInstructions from "./containers/LiveAssessment/LiveInstructions";
import LiveExam from "./containers/LiveAssessment/LiveExam";

import LearnerList from "./containers/Learner/LearnerList";
import LearnerEdit from "./containers/Learner/LearnerEdit";
import LearnerAdd from "./containers/Learner/LearnerAdd";

import Accounts from "./containers/Accounts/index";
import AnnualFees from "./containers/Accounts/AnnualFees";
import AnnualFeeEdit from "./containers/Accounts/AnnualFeeEdit";

import AssessmentFees from "./containers/Accounts/AssessmentFees";
import Evidence from "./containers/Asessments/Evidence";
import Marksheet from "./containers/Asessments/Marksheet";
import TrackingGrid from "./containers/TrackingGrid";
import ErrorPage from "./components/ErrorPage";
import CentreSignup from "./containers/Registration/CentreSignup";
import CentreRegistration from "./containers/Registration";
import ProfilePage from "./containers/User/Profile";
import VerifyEmail from "./components/VerifyEmail";

const asyncAuth = asyncComponent(() => {
  return import("./containers/Auth/Auth");
});

class App extends Component {
  componentDidMount() {
    console.log("App mounted");
    this.props.onTryAutoSignup();
    this.props.onSetAuthRedirectPath(this.props.location.pathname);
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("App updated");
    if (
      prevProps.isAuthenticated !== this.props.isAuthenticated &&
      this.props.isAuthenticated == true
    ) {
      this.props.onGetCommon();
      this.props.history.push(this.props.authRedirectPath);
    }
  }
  componentWillUnmount() {
    console.log("App will unmount");
  }
  render() {
    const pathname = this.props.location.pathname;
    if (
      this.props.isAuthenticated &&
      pathname.includes("registration/centre")
    ) {
      this.props.history.push("/");
    }
    if (this.props.isAuthenticated && this.props.loading) {
      return <LoadingPage />;
    }
    let routes = (
      <Switch>
        <Route path="/login" component={asyncAuth} />
        <Route path="/registration/centre" exact component={CentreSignup} />
        <Route path="/verify-email/:id" exact component={VerifyEmail} />
        <Redirect to="/login" />
      </Switch>
    );
    if (
      this.props.isAuthenticated &&
      !this.props.loading &&
      !this.props.currentRole
    ) {
      routes = (
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Redirect to="/" />
        </Switch>
      );
    } else if (
      this.props.isAuthenticated &&
      !this.props.loading &&
      this.props.currentRole &&
      this.props.authUser.centre.status == 4
    ) {
      routes = <Route path="/" exact component={CentreRegistration} />;
    } else if (
      this.props.isAuthenticated &&
      !this.props.loading &&
      this.props.currentRole
    ) {
      routes = (
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/system" exact component={System} />
          <Route path="/system/users" exact component={Users} />
          <Route path="/system/users/create" exact component={AddUser} />
          <Route path="/system/users/:id" exact component={EditUser} />
          <Route path="/system/roles" exact component={Roles} />

          <Route path="/approvals" exact component={ApprovalList} />
          <Route path="/approvals/:id" exact component={ViewApproval} />

          <Route path="/centres" exact component={CentreIndex} />
          <Route path="/centres/list" exact component={CentreList} />
          <Route path="/centres/list/:id" exact component={EditCentre} />
          <Route
            path="/centres/list/:id/courses"
            exact
            component={CourseList}
          />
          <Route path="/centres/create" exact component={EditCentre} />

          <Route path="/people" exact component={PeopleIndex} />

          <Route path="/people/teachers" exact component={TeacherList} />
          <Route path="/people/teachers/:id" exact component={TeacherEdit} />
          <Route
            path="/people/teacher/create"
            exact
            component={TeacherCreate}
          />

          <Route path="/people/examiners" exact component={ExaminerList} />
          <Route path="/people/examiners/:id" exact component={ExaminerEdit} />

          <Route path="/qualifications" exact component={QualificationIndex} />
          <Route
            path="/qualifications/create"
            exact
            component={EditQualification}
          />
          <Route
            path="/qualifications/list"
            exact
            component={QualificationList}
          />
          <Route
            path="/qualifications/list/:id"
            exact
            component={EditQualification}
          />
          <Route
            path="/qualifications/list/:id/units"
            exact
            component={Units}
          />
          <Route path="/qualifications/modules" exact component={ModuleList} />
          <Route
            path="/qualifications/modules/:id"
            exact
            component={EditModule}
          />
          <Route
            path="/qualifications/modules/:id/marksheet"
            exact
            component={Marksheet}
          />
          <Route
            path="/qualifications/modules/create"
            exact
            component={EditModule}
          />

          <Route path="/media/create" exact component={EditMedia} />
          <Route path="/media/list" exact component={MediaList} />
          <Route path="/media/list/:id" exact component={EditMedia} />

          <Route path="/media-group/create" exact component={EditMediaGroup} />
          <Route path="/media-group/list" exact component={MediaGroupList} />
          <Route
            path="/media-group/list/:id"
            exact
            component={EditMediaGroup}
          />

          <Route path="/courses" exact component={CourseIndex} />
          <Route path="/courses/list" exact component={CourseList} />

          <Route path="/courses/list/:id" exact component={EditCourse} />
          <Route path="/courses/create" exact component={CreateCourse} />
          <Route
            path="/courses/list/:id/tracking-grid"
            exact
            component={TrackingGrid}
          />
          <Route
            path="/assessments/create"
            exact
            component={CreateAssessment}
          />
          <Route path="/assessments" exact component={AssessmentIndex} />
          <Route path="/assessments/list" exact component={AssessmentList} />
          <Route
            path="/assessments/list/:id"
            exact
            component={AssessmentEdit}
          />

          <Route
            path="/assessments/list/:id/evidence"
            exact
            component={Evidence}
          />

          <Route path="/people/learners" exact component={LearnerList} />
          <Route path="/people/learners/create" exact component={LearnerAdd} />
          <Route path="/people/learners/:id" exact component={LearnerEdit} />

          <Route path="/accounts" exact component={Accounts} />
          <Route path="/account/annual-fees" exact component={AnnualFees} />
          <Route
            path="/account/centre/:id/annual-fees"
            exact
            component={AnnualFeeEdit}
          />
          <Route
            path="/account/assessment-fees"
            exact
            component={AssessmentFees}
          />
          <Route path="/profile" exact component={ProfilePage} />

          <Route
            path="/403"
            exact
            component={() => <ErrorPage error="403" message="Access denied" />}
          />
          <Redirect to="/" />
        </Switch>
      );
    }

    if (pathname.includes("/assessment/live")) {
      return (
        <Aux>
          <Notifier />
          <Switch>
            {this.props.isAuthenticated && (
              <Route path="/assessment/live" exact component={LiveAssessment} />
            )}
            {this.props.exam.isAuthenticated && (
              <Aux>
                <Route
                  path="/assessment/live/instruction"
                  exact
                  component={LiveInstructions}
                />
                <Route
                  path="/assessment/live/exam"
                  exact
                  component={LiveExam}
                />
              </Aux>
            )}
            {this.props.isAuthenticated ? (
              <Redirect to="/assessment/live" />
            ) : (
              <Redirect to="/" />
            )}
          </Switch>
        </Aux>
      );
    }
    return (
      <Aux>
        <Notifier />
        {this.props.isAuthenticated && !this.props.loading && (
          <TopAppBar> {routes}</TopAppBar>
        )}
        {!this.props.isAuthenticated && !this.props.loading && (
          <React.Fragment>{routes}</React.Fragment>
        )}

        {/* <Typography component="div">
          <Box px={2} pt={4} pb={1} fontWeight="fontWeightLight" fontSize={11}>
            © Copyright 2019 | Institute of British Sign Language (IBSL)
          </Box>
        </Typography> */}
        <Uploader />
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    isAuthenticated: state.auth.userId !== null,
    loading: state.authUser.loading || state.auth.loading,
    currentRole: state.authUser.currentRole || null,
    authRedirectPath: state.auth.authRedirectPath,
    exam: state.exam,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onGetCommon: () => dispatch(actions.getCommon()),
    onSetAuthRedirectPath: (redirectPath) =>
      dispatch(actions.setAuthRedirectPath(redirectPath)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
