import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utility';

const initialState = {
  rows: [],
  permissions: [],
  error: null,
  status: null,
  errorText: '',
  loading: false
};

const getRolesStart = (state, action) => {
  return updateObject(state, { error: null, status: null, loading: true });
};

const getRolesSuccess = (state = initialState, action) => {
  return updateObject(state, {
    rows: action.payload.roles,
    permissions: action.payload.permissions,
    error: false,
    status: 200,
    errorText: '',
    loading: false
  });
};

const getRolesFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.payload.errorText,
    status: action.payload.status,
    loading: false
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ROLES_START:
      return getRolesStart(state, action);
    case actionTypes.GET_ROLES_SUCCESS:
      return getRolesSuccess(state, action);
    case actionTypes.GET_ROLES_FAIL:
      return getRolesFail(state, action);
    default:
      return state;
  }
};
