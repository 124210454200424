import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utility';

const initialState = {
  rows: [],
  count: 0,
  total: 0,
  offset: 0,
  limit: 10,
  orderBy: 'id',
  order: 'asc',
  error: null,
  errorText: '',
  status: null,
  loading: false
};

const getUsersStart = (state, action) => {
  return updateObject(state, { error: null, errorText: '', loading: true });
};

const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    rows: action.payload.users,
    count: action.payload.count,
    total: action.payload.total,
    offset: action.payload.offset,
    limit: action.payload.limit,
    orderBy: action.payload.orderBy,
    order: action.payload.order,
    error: action.payload.error,
    status: 200,
    errorText: action.payload.errorText,
    loading: false
  });
};

const getUsersFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorText: action.payload.errorText,
    status: action.payload.status,
    loading: false
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return getUsersStart(state, action);
    case actionTypes.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionTypes.GET_USERS_FAIL:
      return getUsersFail(state, action);
    default:
      return state;
  }
};
