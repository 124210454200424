import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "scroll",
  },
  step: {
    minWidth: "100px",
  },
}));

export default function CourseStepper(props) {
  const classes = useStyles();
  const hideSteps = [111];
  let steps = Object.keys(props.steps).map((item) => parseInt(item));
  if (hideSteps.indexOf(props.activeStep) === -1) {
    steps = steps.filter((item) => hideSteps.indexOf(item) === -1);
  }
  const activeStep = steps.indexOf(props.activeStep);
  return (
    <div className={classes.root}>
      <Stepper
        className={classes.root}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((key) => {
          return (
            <Step key={key} className={classes.step}>
              <StepLabel>
                {props.steps[key]}
                {key === 130 && (
                  <Box display="flex" justifyContent="center">
                    {!props.loading ? (
                      <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={props.handleIssueMedia}
                        disabled={props.activeStep < 120}
                      >
                        {props.title}
                      </Button>
                    ) : (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                )}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
