import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/styles';
import LearnerIcon from '@material-ui/icons/SupervisorAccount';

import 'date-fns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import * as actions from '../../store/actions';
import { urls } from '../../common/urls';
import AssessmentStepper from '../../components/Assessment/AssessmentStepper';
import Autocomplete from '../../components/IntegrationDownshift';
import AlertDialog from '../../components/AlertDialog';
import RegisterLearner from '../../components/Assessment/RegisterLearner';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1)
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));
const assessmentFields = {
  id: '',
  assessment_no: '',
  assessment_type: '',
  centre_id: '',
  course_id: '',
  qualification_id: '',
  module_id: '',
  title: '',
  assessment_date: null,
  assessment_time: null,
  teacher_id: '',
  examiner_id: '',
  marker_examiner_id: '',
  marking_rate: '',
  marking_rate_type: 0,
  marking_due_date: null,
  approved_at: '',
  approved_by: '',
  is_resit: '',
  status: '',
  address: '',
  city: '',
  postcode: ''
};
function CreateAssessment(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({});
  const [errorData, setErrorData] = useState(false);
  const [assessment, setAssessment] = useState(assessmentFields);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [centreNames, setCentreNames] = useState([]);
  const [course, setCourse] = useState([]);
  const [centreCourses, setCentreCourses] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [openRegisterLearner, setOpenRegisterLearner] = useState(false);
  const [centre, setCentre] = useState({ address: {}, levels: {}, config: {} });
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchAssessmentConfig();
      await fetchCentreNames();
      setLoading(false);
    };
    fetchData();
  }, []);

  /** Fetch Course */
  function fetchAssessmentConfig() {
    axios.post(urls.getAssessmentConfig).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        setSteps(resp.data.steps);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Fetch Centres */
  function fetchCentreNames() {
    axios.get(urls.getCentreNames).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        const centres = await resp.data.centres.map(item => ({
          id: item.id,
          label: `${item.name} (${item.number})`
        }));
        setCentreNames(centres);
      }
    });
  }
  /** Fetch Courses for selected Centre */

  function fetchCentreCourses(centerId) {
    axios.get(urls.getCentreCourses(centerId)).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        setCentreCourses(resp.data.courses);
        if (resp.data.courses.length === 0) {
          props.onEnqueueSnackbar(
            'No courses available to book assessment ',
            3000
          );
        }
      }
    });
  }
  /** Fetch Module for selected  */
  function fetchCourseModules(courseId) {
    axios
      .post(urls.getCourseModules(courseId), {
        is_assessment: true,
        is_resit: assessment.is_resit
      })
      .then(async resp => {
        if (resp.status === 200 && !resp.data.error) {
          setCourseModules(resp.data.modules);
          if (resp.data.modules.length === 0) {
            props.onEnqueueSnackbar(
              'No assessment modules available to book assessment ',
              3000
            );
          }
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }

  /** Handle Field Change */
  const handleChange = name => async e => {
    delete errorData[name];
    if (name === 'is_resit') {
      await setCourseModules([]);
      await setAssessment({
        ...assessment,
        course_id: '',
        module_id: ''
      });
    }

    if (name === 'course_id') {
      fetchCourseModules(e.target.value);
      let course = centreCourses.find(c => c.id === e.target.value);
      setCourse(course);
      await setAssessment({
        ...assessment,
        course_id: e.target.value,
        qualification_id: course.qualification_id,
        teacher_id: course.teacher ? course.teacher.id : ''
      });
    } else {
      await setAssessment({ ...assessment, [name]: e.target.value });
    }
  };
  /** get the selected centre */
  const selectedCentre = id => {
    let c = centreNames.filter(item => item.id === id);
    return c.length ? c[0] : null;
  };
  /** handle Course field change */
  function handleCentreChange(id) {
    //let centre = centreNames.filter(item => item.id === id);
    selectedCentre(id);
    //let {address, postcode} = centre.centre_addresses ? centre.centre_addresses[0]: {''};
    setAssessment({ ...assessment, centre_id: id });
    fetchCentre(id);
    delete errorData.centre_id;
    fetchCentreCourses(id);
  }
  /** Clear Centre Selection */
  function handleClearCentre() {
    setAssessment({
      ...assessment,
      centre_id: '',
      course_id: '',
      module_id: '',
      setCentre: { address: {}, levels: {}, config: {} },
      teacher_id: ''
    });
  }
  /** Handle Assessment Date Field Change */
  const handleDateChange = name => e => {
    setAssessment({
      ...assessment,
      [name]: e
    });
    console.log(assessment);
    delete errorData[name];
  };
  // Update Assessment
  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    let asstModule = await courseModules.filter(
      item => item.id === assessment.module_id
    );
    const assessment_type = asstModule.length
      ? asstModule[0].assessment_type
      : null;
    console.log(assessment);
    axios
      .post(urls.createAssessment, {
        assessment: { ...assessment, assessment_type: assessment_type }
      })
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar('Success!!', 1000, 'success');
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/assessments/list/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar('Access Denied', 1000, 'warning');
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, 'error');
        }
      });
  }
  /** Handle  */
  const handleOpenRegisterLearner = () => {
    setOpenRegisterLearner(true);
  };
  /** Handle  */
  const handleCloseRegisterLearner = () => {
    setOpenRegisterLearner(false);
  };

  /**
   *  Send for Approval
   */
  async function hanleSendForApproval(agree) {
    setOpenApprovalAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.requestAssessmentApproval(props.match.params.id)
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }
  function fetchCentre(id) {
    setCourseModules([]);
    axios
      .post(urls.getCentre(id))
      .then(async resp => {
        if (resp.status == 200 && !resp.data.error) {
          let newStatus = resp.data.centre.centre_statuses.pop();
          let levels = resp.data.centre.centre_levels.map(item => ({
            level_id: item.level_id,
            approved: item.approved
          }));
          const centreAdd = resp.data.address ? resp.data.address.PRIMARY : {};
          setCentre({
            ...resp.data.centre,
            status: newStatus,
            address: resp.data.address ? resp.data.address.PRIMARY : {},
            levels: levels,
            config: resp.data.config
          });
          setAssessment({
            ...assessment,
            centre_id: id,
            address: centreAdd.address,
            city: centreAdd.city,
            postcode: centreAdd.postcode
          });
        }
      })
      .catch(err => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }
  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <AssessmentStepper
              steps={steps}
              activeStep={assessment.status}
              {...props}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot}>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography className={classes.title} color="textSecondary">
                  {`Assessment # ${assessment.assessment_no}`}
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="asst-modules"
                    select
                    label="Assessment / Resit"
                    className={classes.textField}
                    value={assessment.is_resit}
                    onChange={handleChange('is_resit')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('is_resit')}
                    helperText={errorData.is_resit}
                  >
                    <MenuItem value={0}>Assessment</MenuItem>
                    <MenuItem value={1}>Resit</MenuItem>
                  </TextField>
                  <TextField
                    id="assessment-title"
                    label="Title"
                    className={classes.textField}
                    value={assessment.title}
                    onChange={handleChange('title')}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty('title')}
                    helperText={errorData.title}
                  />
                  <Autocomplete
                    label="Centre"
                    placeholder="Search in Centre"
                    data={centreNames}
                    onSelect={selected => handleCentreChange(selected.id)}
                    onClear={() => handleClearCentre()}
                    selectedItem={selectedCentre(assessment.centre_id)}
                    error={errorData.hasOwnProperty('centre_id')}
                    helperText={errorData.centre_id}
                  />
                  <TextField
                    id="select-courses"
                    select
                    label="Course"
                    className={classes.textField}
                    value={assessment.course_id}
                    onChange={handleChange('course_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('course_id')}
                    helperText={errorData.course_id}
                  >
                    {centreCourses &&
                      centreCourses.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    id="select-modules"
                    select
                    label="Module"
                    className={classes.textField}
                    value={assessment.module_id}
                    onChange={handleChange('module_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('module_id')}
                    helperText={errorData.module_id}
                  >
                    {courseModules &&
                      courseModules.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.name} - ${item.title}`}
                        </MenuItem>
                      ))}
                  </TextField>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          label="Assessment Date"
                          margin="normal"
                          format="DD-MM-YYYY"
                          required={true}
                          value={assessment.assessment_date || null}
                          onChange={handleDateChange('assessment_date')}
                          error={errorData.hasOwnProperty('assessment_date')}
                          helperText={errorData.assessment_date}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Assessment Time"
                          required={true}
                          value={assessment.assessment_time || null}
                          onChange={handleDateChange('assessment_time')}
                          KeyboardButtonProps={{
                            'aria-label': 'change time'
                          }}
                          error={errorData.hasOwnProperty('assessment_date')}
                          helperText={errorData.assessment_date}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <TextField
                    id="assessment-address"
                    label="Address"
                    className={classes.textField}
                    value={assessment.address || centre.address.address}
                    onChange={handleChange('address')}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty('address')}
                    helperText={errorData.address}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="alternate-city"
                    label="City"
                    required={true}
                    className={classes.textField}
                    value={assessment.city || centre.address.city}
                    onChange={handleChange('city')}
                    margin="dense"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('city')}
                    helperText={errorData.city}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="assessment-postcode"
                    label="Postcode"
                    className={classes.textField}
                    value={assessment.postcode || centre.address.postcode}
                    onChange={handleChange('postcode')}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty('postcode')}
                    helperText={errorData.postcode}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="select-teacher"
                    select
                    label="Teacher"
                    className={classes.textField}
                    value={assessment.teacher_id}
                    onChange={handleChange('teacher_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('teacher_id')}
                    helperText={errorData.teacher_id}
                  >
                    {centreCourses &&
                      centreCourses.map(item =>
                        item.id === assessment.course_id ? (
                          <MenuItem
                            key={item.teacher.id}
                            value={item.teacher.id}
                          >
                            {item.teacher.name}
                          </MenuItem>
                        ) : null
                      )}
                  </TextField>
                </form>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  disabled={assessment.status > 200}
                  onClick={e => handleSubmit(e)}
                >
                  Create Assessment
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {assessment.id && (
          <React.Fragment>
            <Zoom
              className={classes.fab}
              key="add"
              in={true}
              style={{ transitionDelay: '500ms' }}
              unmountOnExit
            >
              <Fab
                aria-label="Add"
                className="classes.fab"
                color="secondary"
                onClick={handleOpenRegisterLearner}
              >
                <LearnerIcon />
              </Fab>
            </Zoom>
            <RegisterLearner
              openRegisterLearner={openRegisterLearner}
              onCloseRegisterLearner={handleCloseRegisterLearner}
              assessment={assessment}
            />
          </React.Fragment>
        )}
        <AlertDialog
          title="Send For Approval"
          description="You won't be able to make changes to course after sending for approval. Are you sure ?"
          open={openApprovalAlert}
          handleAlertClose={agree => hanleSendForApproval(agree)}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    levels: state.common.levels,
    modules: state.common.modules
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: data => dispatch(actions.uploadFile(data)),
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAssessment)
);
