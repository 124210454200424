import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import MomentUtils from "@date-io/moment";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import * as actions from "../../store/actions";
import LearnerAdd from "../../containers/Learner/LearnerAdd";
import { urls } from "../../common/urls";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1, 2),
  },
  tablePaper: {
    padding: 0,
  },
  container: {
    paddingTop: "100px",
    background: theme.palette.background.default,
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontSize: 18,
  },
  menu: {
    width: 200,
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  learnerList: {
    marginTop: theme.spacing(4),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddNewLearner(props) {
  const classes = useStyles();
  const [openRegisterNewLearner, setOpenRegisterNewLearner] = useState(false);

  const [fields, setFields] = useState({
    uln: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
  });

  const [errorData, setErrorData] = useState({});

  useEffect(() => {
    console.log("[RegisterLearner] Did mount");
  }, []);

  const handleOnComplete = () => {};
  return (
    <Dialog
      fullScreen
      open={props.openRegisterNewLearner}
      onClose={(e) => props.onCloseRegisterNewLearner(e, null)}
      TransitionComponent={Transition}
      className={classes.root}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.title}>
              Add New Learners
            </Typography>
          </Box>
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => props.onCloseRegisterNewLearner(e, null)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={10}>
          {
            <Grid container className={classes.learnerList} spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.tablePaper}>
                  <Box p={2}>
                    <Typography variant="h6">
                      Learners Registered on Course: {props.course.name} (
                      {props.course.id})
                    </Typography>
                    <Typography variant="body2">
                      Centre:{" "}
                      {`${props.course.centre.name} (${props.course.centre.number})`}
                    </Typography>
                    <Typography variant="body2">
                      Qualification: {props.course.qualification.title}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <LearnerAdd
                  onComplete={(id) => props.onCloseRegisterNewLearner(null, id)}
                  isRegisterLearner={true}
                />
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    levels: state.common.levels,
    regions: state.common.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = "default") =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === "default" ? true : false,
            autoHideDuration: duration,
            variant: variant,
          },
        })
      ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddNewLearner)
);
