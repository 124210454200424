import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AlertDialog from '../../components/AlertDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { urls } from '../../common/urls';
import * as actions from '../../store/actions';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8)
  },
  table: {
    '& td,th': {
      border: '1px solid #e0e0e0',
      fontSize: '13px'
    },
    '& .strongTd': {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    '& .bg': {
      background: '#eaf1dd'
    }
  },
  noBorder: {
    border: 'none'
  },
  label: {
    background: '#eaf1dd',
    '&.sm': {
      maxWidth: '60px'
    }
  },
  withoutLabel: {
    maxWidth: '50px'
  },
  bg: {
    background: '#eaf1dd'
  },
  cell: {
    borderRight: '1px solid #e0e0e0',
    backgroundColor: '#FFF'
  },
  radio: {
    padding: 0
  },
  fabAdd: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  total: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  required: {
    position: 'relative',
    '&::after': {
      position: 'absolute',
      content: '"*"',
      color: 'red',
      top: '10px',
      right: '10px'
    }
  },
  chip: {
    fontSize: '13px',
    fontWeight: '500',
    padding: '0px 6px',
    height: '28px',
    lineHeight: '28px'
  },
  textField: {
    maxWidth: theme.spacing(10)
  }
}));

let dataTop = [];

const Marksheet = props => {
  const classes = useStyles();
  const [updatingMarks, setUpdatingMarks] = useState(false);
  const [scores, setScores] = useState({});
  const [touched, setTouched] = React.useState(false);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);

  useEffect(() => {
    dataTop = [
      [
        {
          label: 'Centre No',
          key: 'centre_no',
          value: props.assessment.centre.number
        },
        {
          label: 'Assessment Ref',
          key: 'assessment_number',
          value: props.assessment.assessment_no
        },
        {
          label: 'Assessment Date',
          key: 'assessment_date',
          value: props.assessment.assessment_date
        }
      ]
    ];
    const fetchData = async () => {
      await getMarks();
    };
    fetchData();
  }, []);

  /** Get Marks */
  function getMarks() {
    axios.post(urls.getAssessmentMark(props.assessment.id)).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        if (resp.data.rows.length > 0) {
          let scoreObj = {};
          resp.data.rows.forEach(item => {
            if (!item.marksheet_id) {
              scoreObj[item.learner_id] = {
                zero: item.ee_zero || 0,
                actual: item.ee_actual || null,
                pass: item.ee_pass || null
              };
            }
          });
          setScores(scoreObj);
        }
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  const handleMarkChange = (id, type) => e => {
    let score = Object.assign({}, scores[id]);
    score[type] = parseInt(e.target.value);
    score.pass =
      parseInt(score.actual) >= props.assessment.module.pass_grade &&
      parseInt(score.zero) === 0;
    setScores({ ...scores, [id]: score });
    setTouched(true);
  };

  /** Save Marks */
  const handleSaveMarks = () => {
    setUpdatingMarks(true);
    axios
      .post(urls.updateAssessmentMarksheet(props.assessment.id), {
        scores: scores
      })
      .then(async resp => {
        if (resp.status === 200 && resp.data.error === false) {
          props.onEnqueueSnackbar('Success!!', 1000, 'success');
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setTouched(false);
        setUpdatingMarks(false);
      })
      .catch(err => {
        props.onEnqueueSnackbar(err.message, 3000);
        setUpdatingMarks(false);
      });
  };
  const handleAlertClose = () => {
    setOpenApprovalAlert(false);
  };
  return (
    <Container className={classes.root}>
      <section>
        <Table size="small" className={classes.table}>
          <TableBody>
            {dataTop.map((row, index) => (
              <TableRow key={index}>
                {row.map(item => (
                  <React.Fragment key={item.key}>
                    <TableCell
                      className={clsx(classes.label, 'sm', classes.cell)}
                    >
                      {item.label}
                    </TableCell>
                    <TableCell
                      className={clsx(classes.field, classes.cell)}
                      colSpan={item.valSpan}
                    >
                      {item.value}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
      <Box display="flex" justifyContent="flex-end" my={2}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!touched || updatingMarks}
          onClick={handleSaveMarks}
        >
          {!updatingMarks ? (
            'SAVE'
          ) : (
            <CircularProgress size={18} disableShrink />
          )}
        </Button>
      </Box>
      <Paper>
        <section>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className={clsx('bg')}>No “0” Boxes</TableCell>
                <TableCell className={clsx('bg')}>Benchmark</TableCell>
                <TableCell className={clsx('bg')}>Actual</TableCell>
                <TableCell className={clsx('bg')}>Pass/Fail</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="5">Overall</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.learners.map(item => (
                <TableRow key={item.id}>
                  <TableCell>{`${item.learner.first_name} ${item.learner.last_name}`}</TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      className={classes.textField}
                      value={
                        scores[item.learner_id]
                          ? scores[item.learner_id].zero
                          : ''
                      }
                      onChange={handleMarkChange(item.learner_id, 'zero')}
                      inputProps={{ min: '0', max: '100', step: '1' }}
                    />
                  </TableCell>
                  <TableCell className={clsx('strongTd')} align="center">
                    {props.assessment.module.pass_grade}
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      className={classes.textField}
                      value={
                        scores[item.learner_id]
                          ? scores[item.learner_id].actual
                          : ''
                      }
                      onChange={handleMarkChange(item.learner_id, 'actual')}
                      inputProps={{ min: '0', max: '100', step: '1' }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {scores[item.learner_id] && (
                      <Chip
                        className={classes.chip}
                        label={scores[item.learner_id].pass ? 'PASS' : 'FAIL'}
                        color={
                          scores[item.learner_id].pass ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>
      </Paper>
      <AlertDialog
        title="Error"
        description="Please mark all the mandatory fields. Mandatory fields are marked with red astricks"
        open={openApprovalAlert}
        handleAlertClose={agree => handleAlertClose(agree)}
      />
    </Container>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Marksheet);
