import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Gaurd from '../../hoc/Gaurd';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import LearnerIcon from '@material-ui/icons/SupervisorAccount';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import Autocomplete from '../../components/IntegrationDownshift';
import CourseStepper from '../../components/Course/CourseStepper';
import UnitTabs from '../../components/Course/UnitTabs';
import RegisterLearner from '../../components/Course/RegisterLearner';
import AlertDialog from '../../components/AlertDialog';
import Alert from '@material-ui/lab/Alert';
import IssuedMedia from '../../components/Course/IssuedMedia';

import * as actions from '../../store/actions';
import { urls } from '../../common/urls';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  cardRoot: {
    overflow: 'visible'
  },
  container: {},
  title: {
    fontSize: 18
  },
  textField: {
    minWidth: 200
  },
  menu: {
    width: 200
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  learnerfab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  mediafab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(10)
  }
}));

function EditCourse(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({
    id: '',
    name: '',
    centre_id: '',
    qualification_id: '',
    teacher_id: '',
    start_date: null,
    end_date: null,
    active: '',
    status: 100
  });
  const [centreNames, setCentreNames] = useState([]);
  const [steps, setSteps] = useState({});
  const [qualifications, setQualifications] = useState([]);
  const [qualification, setQualification] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [units, setUnits] = useState([]);
  const [dates, setDates] = useState({
    start_date: moment(),
    end_date: moment()
  });
  const [errorData, setErrorData] = useState(false);
  const [openRegisterLearner, setOpenRegisterLearner] = useState(false);
  const [openApprovalAlert, setOpenApprovalAlert] = React.useState(false);
  const [
    openCourseReRegistrationAlert,
    setOpenCourseReRegistrationAlert
  ] = React.useState(false);
  const [openIssueMedia, setOpenIssueMedia] = React.useState(false);
  const [issuing, setIssuing] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [count, setCount] = useState(0);
  const [annualFeePaid, setAnnualFeePaid] = useState(null);
  const [openIssuedMedia, setOpenIssuedMedia] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetchCentreNames();
      await fetchCourse();
    }
    fetchData();
  }, [count]);

  /** Fetch Course */
  function fetchCourse() {
    axios
      .post(urls.getCourse(props.match.params.id))
      .then(async resp => {
        if (resp.status === 200 && !resp.data.error) {
          setCourse(resp.data.course);
          setSteps(resp.data.steps);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      })
      .catch(err => {
        props.onEnqueueSnackbar(err.message, 3000);
        props.history.push(`/${err.response.status}`);
      });
  }

  /** Query Centres */
  function fetchCentreNames() {
    axios.get(urls.getCentreNames).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        const centres = await resp.data.centres.map(item => ({
          id: item.id,
          label: `${item.name} (${item.number})`
        }));
        setCentreNames(centres);
      }
    });
  }
  /** Fetch Centre Qualifications */

  function fetchCentreQualifications(id) {
    axios.get(urls.getCentreQualifications(id)).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        setQualifications(resp.data.qualifications);
        setAnnualFeePaid(resp.data.annualFeePaid);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }

  /** Fetch Centre Teacher for Qualification  */

  function fetchCentreQualTeacher(id, levelId) {
    axios
      .post(urls.getCentreQualTeacher(id), {
        levelId: levelId
      })
      .then(async resp => {
        if (resp.status === 200 && !resp.data.error) {
          setTeachers(resp.data.teachers);
        } else {
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
      });
  }

  /** Fetch Qualification Units */
  function fetchQulificationUnits(id) {
    axios.post(urls.getUnits(id)).then(async resp => {
      if (resp.status === 200 && !resp.data.error) {
        let cunits = resp.data.rows.map(item => {
          let unitObj = course.course_units.filter(
            cunit => cunit.unit_id === item.id
          );
          if (unitObj.length > 0) {
            return {
              ...item,
              learner_fee: unitObj[0].learner_fee,
              resit_fee: unitObj[0].resit_fee
            };
          }
          return item;
        });
        setUnits(cunits);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    });
  }
  const selectedCentre = id => {
    let c = centreNames.filter(item => item.id === id);
    return c.length ? c[0] : null;
  };
  /** Handle Field Change */
  const handleChange = name => async e => {
    setCourse({ ...course, [name]: e.target.value });
    delete errorData[name];
    if (name === 'qualification_id') {
      let qual = await qualifications.filter(
        item => item.id === e.target.value
      );
      await setQualification(qual[0]);
      //fetchCentreQualTeacher(course.centre_id,qual[0].level_id);
      //fetchQulificationUnits(qual[0].id);
    }
  };
  /** Handle Course Date Field Change */
  const handleDateChange = name => e => {
    setDates({ ...dates, [name]: e });
    setCourse({ ...course, [name]: e.format('L') });
    delete errorData[name];
  };
  /** handle Course field change */
  function handleCentreChange(id) {
    selectedCentre(id);
    setCourse({ ...course, centre_id: id });
    delete errorData.centre_id;
  }
  /** Fetch Qualification on Centre Change */
  useEffect(() => {
    if (course.centre_id !== '') {
      fetchCentreQualifications(course.centre_id);
    }
  }, [course.centre_id]);

  /** Set Qualification on Qualification Change */
  useEffect(() => {
    if (course.qualification_id && qualifications.length) {
      let qual = qualifications.filter(
        item => item.id === course.qualification_id
      );
      if (qual.length > 0) {
        setQualification(qual[0]);
      }
    }
  }, [qualifications, course.qualification_id]);

  /** Set Centre Teachers and Qualification Units */
  useEffect(() => {
    if (qualification.level_id) {
      fetchCentreQualTeacher(course.centre_id, qualification.level_id);
      fetchQulificationUnits(qualification.id);
    }
  }, [qualification]);

  function handleClearCentre() {
    setCourse({ ...course, centre_id: '', qualification_id: '' });
    setQualifications([]);
  }
  // Create Course
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(urls.updateCourse(props.match.params.id), { course, units })
      .then(resp => {
        if (resp.status === 200 && resp.data.error === false) {
          setErrorData({});
          props.onEnqueueSnackbar('Success!!', 1000, 'success');
          if (isNaN(props.match.params.id) && resp.data.result) {
            props.history.push(`/courses/list/${resp.data.result}`);
          }
        } else {
          if (resp.data.errorData) {
            setErrorData(resp.data.errorData);
          }
          props.onEnqueueSnackbar(resp.data.errorText, 3000);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (403 === error.response.status) {
          props.onEnqueueSnackbar('Access Denied', 1000, 'warning');
          props.history.push(`/${error.response.status}`);
        } else {
          props.onEnqueueSnackbar(error.response.data.errorText, 3000, 'error');
        }
      });
  }

  /** handle Unit Change */
  function handleUnitChange(unitArr) {
    setUnits(unitArr);
  }
  /** Handle  */
  function handleOpenRegisterLearner() {
    setOpenRegisterLearner(true);
  }
  /** Handle  */
  function handleCloseRegisterLearner() {
    setCount(prev => prev + 1);
    setOpenRegisterLearner(false);
  }

  /**
   *  Send for Approval
   */
  async function hanleSendForApproval(agree) {
    setOpenApprovalAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.requestCourseApproval(props.match.params.id)
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
        fetchCourse();
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }

  /** Handle Issue Media */
  const handleIssueMedia = async () => {
    try {
      setIssuing(true);
      let resp = await axios.post(urls.issueCourseMedia(props.match.params.id));
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
        fetchCourse();
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
      setIssuing(false);
    } catch (error) {
      setIssuing(false);
    }
  };
  /** Download Media */
  async function handleDownloadIssuedMedia() {
    try {
      setDownloading(true);
      let resp = await axios.post(
        urls.getCourseIssuedMedias(props.match.params.id)
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
        window.open(resp.data.url);
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  }
  /**
   *  Re-Open Course Registration
   */
  async function hanleOpenCourseReRegistration(agree) {
    setOpenCourseReRegistrationAlert(false);
    if (agree) {
      let resp = await axios.post(
        urls.requestCourseReRegistration(props.match.params.id)
      );
      if (resp.status === 200 && resp.data.error === false) {
        props.onEnqueueSnackbar('Success!!', 1000, 'success');
        fetchCourse();
      } else {
        props.onEnqueueSnackbar(resp.data.errorText, 3000);
      }
    }
  }

  /** Handle  */
  const handleOpenIssuedMedia = () => {
    setOpenIssuedMedia(true);
  };
  /** Handle  */
  const handleCloseIssuedMedia = () => {
    setOpenIssuedMedia(false);
    fetchCourse();
  };

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        {/* {!annualFeePaid && (
          <Alert severity="warning">
            <Typography>Centre has not paid the Annual Fee</Typography>
          </Alert>
        )}
        <br /> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CourseStepper
              steps={steps}
              activeStep={course.status}
              handleIssueMedia={handleIssueMedia}
              loading={issuing}
              title={course.media ? 'Re-Issue Media' : 'Issue Media'}
              {...props}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              {course.status === 100 &&
                course.course_learners &&
                course.course_learners.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={course.status >= 110}
                    onClick={() => setOpenApprovalAlert(true)}
                  >
                    Send For Approval
                  </Button>
                )}
              {course.status >= 120 &&
                course.course_learners &&
                course.course_learners.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={course.status < 120}
                    onClick={() => setOpenCourseReRegistrationAlert(true)}
                  >
                    Reopen Course Registration
                  </Button>
                )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot}>
              {loading && <LinearProgress color="secondary" />}
              <CardContent>
                <Typography className={classes.title} color="textSecondary">
                  Course
                </Typography>
                <form
                  className={classes.formContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="course-name"
                    label="Name"
                    className={classes.textField}
                    value={course.name}
                    onChange={handleChange('name')}
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    error={errorData.hasOwnProperty('name')}
                    helperText={errorData.name}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  />
                  <Autocomplete
                    label="Centre"
                    placeholder="Search in Centre"
                    data={centreNames}
                    onSelect={selected => handleCentreChange(selected.id)}
                    onClear={() => handleClearCentre()}
                    selectedItem={selectedCentre(course.centre_id)}
                    error={errorData.hasOwnProperty('centre_id')}
                    helperText={errorData.centre_id}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  />
                  <TextField
                    id="select-qualifications"
                    select
                    label="Qualification Level"
                    className={classes.textField}
                    value={course.qualification_id}
                    onChange={handleChange('qualification_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('qualification_id')}
                    helperText={errorData.qualification_id}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  >
                    {qualifications &&
                      qualifications.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.code} - {item.title}
                        </MenuItem>
                      ))}
                  </TextField>
                  {course.qualification_id && (
                    <p>{qualification.description}</p>
                  )}
                  <TextField
                    id="select-teacher"
                    select
                    label="Teacher"
                    className={classes.textField}
                    value={course.teacher_id}
                    onChange={handleChange('teacher_id')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    fullWidth={true}
                    error={errorData.hasOwnProperty('teacher_id')}
                    helperText={errorData.teacher_id}
                    InputProps={{
                      readOnly: course.status > 100
                    }}
                  >
                    {teachers &&
                      teachers.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="Start Date"
                      margin="normal"
                      fullWidth={true}
                      format="DD/MM/YYYY"
                      value={course.start_date || null}
                      onChange={handleDateChange('start_date')}
                      error={errorData.hasOwnProperty('start_date')}
                      helperText={errorData.start_date}
                      InputProps={{
                        readOnly: course.status > 100
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="End Date"
                      margin="normal"
                      format="DD/MM/YYYY"
                      fullWidth={true}
                      value={course.end_date || null}
                      minDate={dates.start_date.add(1, 'days').toDate()}
                      onChange={handleDateChange('end_date')}
                      error={errorData.hasOwnProperty('end_date')}
                      helperText={errorData.end_date}
                      InputProps={{
                        readOnly: course.status > 100
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </form>
              </CardContent>
              <Gaurd check="update_course">
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Update Course
                  </Button>
                </CardActions>
              </Gaurd>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <UnitTabs
                readOnly={true}
                units={units}
                onChange={unitArr => handleUnitChange(unitArr)}
              />
            </Paper>
          </Grid>
        </Grid>
        {course.id && (
          <React.Fragment>
            <Zoom
              className={classes.learnerfab}
              key="add"
              in={true}
              style={{ transitionDelay: '500ms' }}
              unmountOnExit
            >
              <Fab
                aria-label="Add"
                className={classes.fab}
                color="secondary"
                onClick={handleOpenRegisterLearner}
              >
                <LearnerIcon />
              </Fab>
            </Zoom>
            {course.status > 120 && (
              <Gaurd check="create_course">
                <Zoom
                  className={classes.mediafab}
                  key="add"
                  in={true}
                  style={{ transitionDelay: '500ms' }}
                  unmountOnExit
                >
                  <Fab
                    aria-label="Media"
                    className="classes.mediaFab"
                    color="secondary"
                    onClick={handleOpenIssuedMedia}
                  >
                    <PermMediaIcon />
                  </Fab>
                </Zoom>
              </Gaurd>
            )}
          </React.Fragment>
        )}
        <AlertDialog
          title="Send For Approval"
          description="You won't be able to make changes to course after sending for approval. Are you sure ?"
          open={openApprovalAlert}
          handleAlertClose={agree => hanleSendForApproval(agree)}
        />
        {openRegisterLearner && (
          <RegisterLearner
            openRegisterLearner={openRegisterLearner}
            onCloseRegisterLearner={handleCloseRegisterLearner}
            course={course}
          />
        )}
        {openIssuedMedia && (
          <IssuedMedia
            openIssuedMedia={openIssuedMedia}
            onCloseIssuedMedia={handleCloseIssuedMedia}
            course={course}
          />
        )}
        <AlertDialog
          title="Reopen Course Registration"
          description="Are you sure you want to re-open this course for registration. You will be required to resend for Approval after updating the registered learner."
          open={openCourseReRegistrationAlert}
          handleAlertClose={agree => hanleOpenCourseReRegistration(agree)}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    levels: state.common.levels,
    regions: state.common.regions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditCourse)
);
