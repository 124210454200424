import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'scroll'
  },
  step: {
    minWidth: '100px'
  }
}));

export default function AssessmentStepper(props) {
  const classes = useStyles();

  return (
    <div>
      <Stepper
        className={classes.root}
        activeStep={props.activeStep / 10 - 10}
        alternativeLabel
      >
        {Object.keys(props.steps).map(key => (
          <Step key={key} className={classes.step}>
            <StepLabel>{props.steps[key]}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
