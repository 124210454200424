import { combineReducers } from 'redux';
import snackbarReducer from './snackbar';
import authReducer from './auth';
import authUserReducer from './auth-user';
import authExamReducer from './exam';
import usersReducer from './users';
import userReducer from './user';
import rolesReducer from './roles';
import commonReducer from './common';
import noticesReducer from './notices';
import qualificationReducer from './qualifications';
import modulesReducer from './qualification-modules';
import uploaderReducer from './uploader';
import centreReducer from './centres';

const rootReducer = combineReducers({
  auth: authReducer,
  authUser: authUserReducer,
  exam: authExamReducer,
  snackbar: snackbarReducer,
  users: usersReducer,
  user: userReducer,
  roles: rolesReducer,
  common: commonReducer,
  notices: noticesReducer,
  qualifications: qualificationReducer,
  modules: modulesReducer,
  uploader: uploaderReducer,
  centres: centreReducer
});

export default rootReducer;
