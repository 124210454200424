import React from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Logo from "../../components/Logo";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  paper: {
    padding: theme.spacing(3, 2),
    width: "100%",
  },
  card: {
    maxWidth: 345,
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    textAlign: "center",
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));

function LiveInstructions(props) {
  const classes = useStyles();

  const handleNext = () => {
    props.history.push("/assessment/live/exam");
  };
  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={11} sm={8} md={6}>
        <Box
          display="flex"
          alignItems="center"
          height={1}
          width={1}
          justifyContent="center"
        >
          <Paper className={classes.paper}>
            <Logo />
            <Typography variant="h5" component="h3" align="center">
              Assessment
            </Typography>
            <Typography variant="h6" gutterBottom>
              To the Teacher/Assessor
            </Typography>
            <br />
            <Typography variant="subtitle1" gutterBottom>
              Please make sure your are ready to start.
            </Typography>
            <br />
            <Typography variant="body1" gutterBottom>
              <strong>
                Please read the Assessment Guidelines that were provided for
                this Assessment and make sure that the environment is ready to
                begin watching the video clip.
              </strong>
            </Typography>
            <br />
            <Typography variant="h6" gutterBottom>
              Playing the Video:
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              <strong>
                When you press the "Next" button below you will be shown the
                video player screen and the video clip will begin playing.
                Please follow the instructions in the video clip together with
                any instructions provided in the Assessment Guidelines.
              </strong>
            </Typography>
            <Box display="flex" mt={4} justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.history.push("/")}
              >
                Back To Portal
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
export default withRouter(LiveInstructions);
