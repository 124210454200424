import React from "react";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";

function renderInput(inputProps) {
  const {
    InputProps,
    classes,
    ref,
    value,
    clearSelection,
    selectedItem,
    error,
    helperText,
    ...other
  } = inputProps;
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Clear Search"
              style={{ marginRight: -12 }}
              onClick={(e) => clearSelection()}
            >
              {inputProps.inputProps.value ? (
                <ClearIcon fontSize="small" color="secondary" />
              ) : null}
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...other}
      margin="dense"
      error={error || false}
      helperText={helperText || ""}
    />
  );
}

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  //const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
  const isSelected = selectedItem ? selectedItem.id === suggestion.id : false;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

function getSuggestions(
  value,
  data,
  itemCount = 5,
  { showEmpty = false } = {}
) {
  const inputValue = deburr(
    value.trim().replace(/[^a-zA-Z0-9 ]/g, "")
  ).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const regex = new RegExp(inputValue, "gi");
  return inputLength === 0 && !showEmpty
    ? []
    : data.filter((suggestion) => {
        const keep = count < itemCount && suggestion.label.match(regex);

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

export default function IntegrationDownshift(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Downshift
        id="downshift-simple"
        onChange={(selection) => (selection ? props.onSelect(selection) : null)}
        itemToString={(item) => (item ? item.label : "")}
        onStateChange={(e) =>
          e.type === "__autocomplete_unknown__" && props
            ? props.onClear()
            : null
        }
        selectedItem={props.selectedItem || null}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          clearSelection,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: props.placeholder
              ? props.placeholder
              : "Search by typing",
          });

          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                label: props.label,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onFocus },
                inputProps,
                clearSelection,
                error: props.error,
                helperText: props.helperText,
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(
                      inputValue,
                      props.data,
                      props.itemCount
                    ).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}
