import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Logo from '../../components/Logo';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { urls } from '../../common/urls';
import * as actions from '../../store/actions';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
  paper: {
    padding: theme.spacing(3, 2)
  },
  card: {
    maxWidth: 345,
    width: '100%'
  },
  control: {
    padding: theme.spacing(2)
  },
  iconWrapper: {
    textAlign: 'center'
  },
  margin: {
    marginBottom: theme.spacing(2)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },

  otpField: {
    letterSpacing: '40px'
  }
}));

function LiveAssessment(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [otp, setOTP] = React.useState('');
  const [error, setError] = React.useState(null);
  const [touched, setTouched] = React.useState(false);

  const handleChange = prop => event => {
    setOTP(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(prevVal => !prevVal);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleAuthentication = event => {
    setTouched(true);
    if (!otp || otp === '') {
      setError('Please provide a valid OTP');
    } else {
      event.preventDefault();
      setTouched(false);
      setError(null);
      try {
        props.onAuth(otp);
      } catch (error) {
        props.onEnqueueSnackbar(error.message, 3000, 'error');
      }
    }
  };
  useEffect(() => {
    if (!props.exam.isAuthenticated) {
      props.isAuthenticated();
    }
  }, []);
  useEffect(() => {
    if (props.exam.isAuthenticated) {
      props.history.push(props.exam.authRedirectPath);
    }
  }, [props.exam.isAuthenticated, props.exam.authRedirectPath, props.history]);

  useEffect(() => {
    if (props.exam.error) {
      setError(props.exam.errorText);
      setTouched(true);
    }
  }, [props.exam.error, props.exam.errorText]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={11}>
        <Box
          display="flex"
          alignItems="center"
          height={1}
          justifyContent="center"
        >
          <Paper className={classes.paper}>
            <Logo />
            <Box m={4} justifyContent="center">
              <Typography variant="h5" component="h3" align="center">
                Live Assessment
              </Typography>
            </Box>
            <Typography component="p" align="center">
              Please enter the One Time Password (OTP) that was sent by iBSL
              when the Assessment Media was Issued
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              m={4}
              justifyContent="center"
            >
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
                error={touched && error != ''}
              >
                <InputLabel htmlFor="filled-adornment-password">OTP</InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={otp}
                  onChange={handleChange('otp')}
                  className={classes.otpField}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  id="component-error-text"
                  style={{ marginLeft: 0 }}
                >
                  {error}
                </FormHelperText>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAuthentication}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    exam: state.exam
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: otp => dispatch(actions.authExam(otp)),
    isAuthenticated: () => dispatch(actions.authExamCheckState()),
    onEnqueueSnackbar: (message, duration = 1000, variant = 'default') =>
      dispatch(
        actions.enqueueSnackbar({
          message: message,
          options: {
            action: variant === 'default' ? true : false,
            autoHideDuration: duration,
            variant: variant
          }
        })
      )
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveAssessment)
);
